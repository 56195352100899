// ProtectedMenuLink.jsx
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'

const ProtectedMenuLink = ({ to, children, ...rest }) => {
  const { marketplacesAtivos, setShowMarketplaceConfigModal } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleClick = e => {
    // Se não houver nenhum marketplace ativo, impede a navegação e exibe o modal
    if (!marketplacesAtivos || marketplacesAtivos.length === 0) {
      e.preventDefault()
      setShowMarketplaceConfigModal(true)
    } else {
      navigate(to)
    }
  }

  return (
    <a href={to} onClick={handleClick} {...rest}>
      {children}
    </a>
  )
}

export default ProtectedMenuLink
