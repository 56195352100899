import { Tab, Nav, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'
import ExplicacaoMercadoLivre from './ExplicacaoMercadoLivre.jsx'
import ExplicacaoAmazon from './ExplicacaoAmazon.jsx'
import ExplicacaoMagalu from './ExplicacaoMagalu.jsx'
import ExplicacaoAmericanas from './ExplicacaoAmericanas.jsx'
import ExplicacaoViaVarejo from './ExplicacaoViaVarejo.jsx'
function ExplicativoRegrasGerais() {
  // Conteúdo do Tooltip explicando as regras gerais
  const tooltipExplicativo = (
    <Tooltip id="tooltip-explicativo-regras">
      <div style={{ maxWidth: '320px', textAlign: 'justify', padding: '0.5rem' }}>
        <h5 style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Regras Gerais</h5>
        <p style={{ marginBottom: '0.5rem' }}>
          As <strong>Regras Gerais</strong> são parâmetros fundamentais para o cálculo de preço
          (limites de peso, classificações de vendedores, etc.) que afetam a precificação em todos
          os marketplaces.
        </p>
        <p style={{ marginBottom: 0 }}>
          Este resumo oferece <strong>transparência</strong> e facilita o entendimento de como os
          valores são determinados em cada plataforma.
        </p>
      </div>
    </Tooltip>
  )

  return (
    <div className="container mt-4">
      {/* Título e ícone de Tooltip */}
      <div className="d-flex align-items-center">
        <h1 className="me-2">Explicativo - Regras Gerais</h1>
        <OverlayTrigger placement="right" overlay={tooltipExplicativo}>
          <span style={{ cursor: 'pointer' }}>
            <FaInfoCircle size={20} color="#0d6efd" />
          </span>
        </OverlayTrigger>
      </div>

      <Tab.Container defaultActiveKey="mercadoLivre">
        <Row className="mt-3">
          <Col>
            {/* Abas organizadas horizontalmente */}
            <Nav variant="tabs" fill>
              <Nav.Item>
                <Nav.Link eventKey="mercadoLivre">Mercado Livre</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="amazon">Amazon</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="magalu">Magalu</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="americanas">Americanas</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="shopee">Shopee</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="shein">Shein</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="viaVarejo">Via Varejo</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>

        <Row>
          <Col>
            <Tab.Content className="mt-3">
              <Tab.Pane eventKey="mercadoLivre">
                <ExplicacaoMercadoLivre />
              </Tab.Pane>

              <Tab.Pane eventKey="amazon">
                <ExplicacaoAmazon />
              </Tab.Pane>

              <Tab.Pane eventKey="magalu">
                <ExplicacaoMagalu />
              </Tab.Pane>

              <Tab.Pane eventKey="americanas">
                <ExplicacaoAmericanas />
              </Tab.Pane>

              <Tab.Pane eventKey="shopee">
                <h4>Shopee</h4>
                <p>Aqui ficará o conteúdo explicativo sobre as regras gerais da Shopee.</p>
              </Tab.Pane>

              <Tab.Pane eventKey="shein">
                <h4>Shein</h4>
                <p>Aqui ficará o conteúdo explicativo sobre as regras gerais da Shein.</p>
              </Tab.Pane>

              <Tab.Pane eventKey="viaVarejo">
                <ExplicacaoViaVarejo />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  )
}

export default ExplicativoRegrasGerais
