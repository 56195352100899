import { Card, Row, Col, OverlayTrigger, Tooltip, Table } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'

const ExplicacaoMercadoLivre = () => {
  const tooltipMercadoLivre = (
    <Tooltip id="tooltip-mercado-livre">
      <div style={{ maxWidth: '320px', textAlign: 'justify', padding: '0.5rem' }}>
        <h5 style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>
          Detalhes das Regras – Mercado Livre
        </h5>
        <p style={{ marginBottom: '0.5rem' }}>
          Essas regras são usadas para calcular o preço final dos produtos, considerando fatores
          como o peso, o valor mínimo do produto e a classificação do vendedor.
        </p>
        <p style={{ marginBottom: 0 }}>
          A integração desses parâmetros permite uma precificação dinâmica e transparente.
        </p>
      </div>
    </Tooltip>
  )

  return (
    <div>
      <h4 className="d-flex align-items-center">
        Mercado Livre
        <OverlayTrigger placement="right" overlay={tooltipMercadoLivre}>
          <span style={{ cursor: 'pointer', marginLeft: '0.5rem' }}>
            <FaInfoCircle size={18} color="#0d6efd" />
          </span>
        </OverlayTrigger>
      </h4>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={4}>
              <FaixasDePesoMercadoLivre />
            </Col>
            <Col md={4}>
              <ValorMinimoProdutoMercadoLivre />
            </Col>
            <Col md={4}>
              <DescontoNoFreteMercadoLivre />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <ClassificacaoVendedorMercadoLivre />
            </Col>
            <Col md={6}>
              <CriteriosAdicionaisMercadoLivre />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ExplicacaoMercadoLivre

const FaixasDePesoMercadoLivre = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Faixas de Peso e Descontos</Card.Title>
        <Card.Text>
          Nesta tabela, apresentamos as faixas de peso praticadas pelo Mercado Livre e os valores de
          frete correspondentes de acordo com o desconto aplicado. O desconto varia conforme a
          classificação do vendedor: <strong>50% (Categoria Verde)</strong> ou{' '}
          <strong>40% (Categoria Amarela)</strong>.
        </Card.Text>

        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Faixa de Peso (g)</th>
              <th>Frete (Categoria Verde - 50%)</th>
              <th>Frete (Categoria Amarela - 40%)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 a 300</td>
              <td>R$ 19,95</td>
              <td>R$ 23,94</td>
            </tr>
            <tr>
              <td>301 a 499</td>
              <td>R$ 21,45</td>
              <td>R$ 26,94</td>
            </tr>
            <tr>
              <td>501 a 999</td>
              <td>R$ 22,45</td>
              <td>R$ 26,94</td>
            </tr>
            <tr>
              <td>1000 a 1999</td>
              <td>R$ 23,45</td>
              <td>R$ 28,14</td>
            </tr>
            <tr>
              <td>2000 a 2999</td>
              <td>R$ 24,95</td>
              <td>R$ 29,94</td>
            </tr>
            <tr>
              <td>3000 a 3999</td>
              <td>R$ 26,95</td>
              <td>R$ 32,34</td>
            </tr>
            <tr>
              <td>4000 a 4999</td>
              <td>R$ 28,45</td>
              <td>R$ 34,14</td>
            </tr>
          </tbody>
        </Table>

        <Card.Text className="mt-3">
          <ul>
            <li>
              <strong>Categoria Verde (50%):</strong> aplica metade do valor do frete padrão.
            </li>
            <li>
              <strong>Categoria Amarela (40%):</strong> oferece 40% de desconto em relação ao frete
              integral.
            </li>
          </ul>
          Dessa forma, vendedores com melhor classificação (Verde) recebem maior desconto, enquanto
          a categoria Amarela também se beneficia de um frete reduzido, porém em menor proporção.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

const ValorMinimoProdutoMercadoLivre = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Valor Mínimo do Produto</Card.Title>
        <Card.Text>
          Para que o desconto no frete seja aplicado no Mercado Livre, cada produto precisa atingir
          ou ultrapassar um valor mínimo estabelecido. Caso o preço do item fique abaixo desse
          limiar, o desconto não será considerado.
        </Card.Text>
        <Card.Text>
          <strong>Por que existe esse valor mínimo?</strong>
          <br />
          Ele funciona como uma garantia de viabilidade do envio com desconto, impedindo que
          produtos de valor muito baixo sejam beneficiados por subsídios de frete excessivos, o que
          poderia desequilibrar a precificação e a competitividade dentro da plataforma.
        </Card.Text>
        <Card.Text>
          <strong>Exemplo prático:</strong>
          <br />
          Se o valor mínimo for definido em <em>R$ 79,00</em>, produtos com preço inferior a esse
          montante não terão acesso aos descontos de 50% (Categoria Verde) ou 40% (Categoria
          Amarela), sendo calculados com base no frete integral.
        </Card.Text>
        <Card.Text>
          Dessa forma, o Mercado Livre mantém uma precificação justa e sustentável, ao mesmo tempo
          em que incentiva vendedores a praticar valores mais competitivos em seus produtos.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

const DescontoNoFreteMercadoLivre = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Desconto no Frete</Card.Title>
        <Card.Text>
          Quando o produto atinge o valor mínimo estipulado, é aplicado um desconto percentual sobre
          o valor base do frete, o que resulta em um custo final de envio reduzido para o comprador.
        </Card.Text>
        <Card.Text>
          <strong>Mecanismo de Cálculo:</strong>
          <br />O desconto incide diretamente sobre o valor base do frete. Por exemplo, se o valor
          base é R$ 10,00 e o vendedor está na Categoria Verde (com desconto de 50%), o frete final
          será R$ 5,00. Já na Categoria Amarela (40% de desconto), o frete será ajustado para R$
          6,00.
        </Card.Text>
        <Card.Text>
          <strong>Benefícios:</strong>
          <br />
          Essa abordagem torna o envio mais competitivo, incentivando a aquisição e promovendo uma
          precificação sustentável. O desconto é aplicado apenas quando o produto atinge o limiar de
          valor, garantindo que o benefício seja concedido de forma equilibrada.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

const ClassificacaoVendedorMercadoLivre = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Classificação do Vendedor</Card.Title>
        <Card.Text>
          A performance e o histórico do vendedor determinam a porcentagem de desconto aplicada no
          frete.
        </Card.Text>
        <Card.Text>
          Vendedores com melhor desempenho recebem descontos maiores, incentivando a melhoria
          contínua. No contexto do Mercado Livre, as classificações geralmente adotadas são:
          <ul>
            <li>
              <strong>Categoria Verde:</strong> Representa vendedores com alta performance, que
              podem usufruir de um desconto de até <strong>50%</strong> no frete.
            </li>
            <li>
              <strong>Categoria Amarela:</strong> Indica uma performance intermediária, com
              descontos de aproximadamente <strong>40%</strong> no frete.
            </li>
          </ul>
        </Card.Text>
        <Card.Text>
          Essas categorias são baseadas em critérios como avaliações dos clientes, pontualidade na
          entrega e qualidade do atendimento. Essa estrutura de classificação torna o cálculo do
          preço mais justo e transparente para os compradores.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

const CriteriosAdicionaisMercadoLivre = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Critérios Adicionais</Card.Title>
        <Card.Text>
          Além dos parâmetros básicos como faixas de peso, valor mínimo do produto e classificação
          do vendedor, outros fatores podem ajustar os valores finais e influenciar a precificação.
        </Card.Text>
        <Card.Text>
          <strong>Políticas Internas:</strong> Diretrizes definidas pela plataforma, que podem
          incluir ajustes sazonais, promoções e estratégias específicas para manter a
          competitividade e otimizar os custos de envio.
        </Card.Text>
        <Card.Text>
          <strong>Condições de Mercado:</strong> Variáveis externas, como flutuações nos custos
          logísticos, concorrência e tendências de consumo, que podem impactar o valor final do
          frete e a precificação dos produtos.
        </Card.Text>
        <Card.Text>
          A combinação desses critérios adicionais garante que o cálculo do preço seja preciso,
          justo e adaptável às mudanças tanto internas quanto externas, proporcionando uma
          precificação transparente para vendedores e compradores.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}
