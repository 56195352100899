import { Modal, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

const NotificationDetailModal = ({ show, onHide, notification }) => {
  console.log('NotificationDetailModal', notification)
  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="wider-modal">
      <Modal.Header closeButton>
        <Modal.Title>{notification?.title || 'Detalhes da Notificação'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Mensagem:</strong>
        </p>
        <p>{notification?.message}</p>
        {notification?.createdAt && (
          <p>
            <strong>Data:</strong> {new Date(notification.createdAt).toLocaleString()}
          </p>
        )}
        {notification?.url && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong style={{ marginRight: '8px' }}>URL:</strong>
            <span style={{ wordBreak: 'break-all', flexGrow: 1 }}>{notification.url}</span>
            <Button
              variant="primary"
              size="sm"
              style={{ marginLeft: '8px' }}
              onClick={() => window.open(notification.url, '_blank')}
            >
              Abrir
            </Button>
          </div>
        )}
        {notification?.changedContent && (
          <div style={{ marginTop: '16px' }}>
            <p>
              <strong>Conteúdo Alterado:</strong>
            </p>
            <ul>
              {notification.changedContent
                .split('.')
                .filter(sentence => sentence.trim() !== '')
                .map((sentence, index) => (
                  <li key={index}>{sentence.trim()}.</li>
                ))}
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

NotificationDetailModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  notification: PropTypes.object
}

export default NotificationDetailModal
