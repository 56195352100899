import { useState, useEffect } from 'react'
import { Table, Button, Form, Alert, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../../../src/backend/model'

import { FaInfoCircle } from 'react-icons/fa' // Ícone de informação
import { useParams } from 'react-router-dom'
import { carregarRegrasPesoFrete } from './regras-peso-frete'
const CadastroRegrasPesoFrete = () => {
  const { marketplace } = useParams()

  const [regras, setRegras] = useState([])
  const [novaRegra, setNovaRegra] = useState({
    limite_inferior: '',
    limite_superior: '',
    valor_frete: '',
    valor_minimo_produto: ''
  })
  const [mensagem, setMensagem] = useState('')
  const [tipoAlerta, setTipoAlerta] = useState('success')
  const [showAlert, setShowAlert] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [indexParaExcluir, setIndexParaExcluir] = useState(null)

  useEffect(() => {
    async function fetchRegras() {
      const result = await carregarRegrasPesoFrete(marketplace)
      setRegras(result)
    }
    fetchRegras()
  }, [marketplace])

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [showAlert])

  const handleChange = (e, index = null) => {
    const { name, value } = e.target

    if (index !== null) {
      setRegras(prevRegras => {
        const novasRegras = [...prevRegras]
        novasRegras[index][name] = value
        return novasRegras
      })
    } else {
      setNovaRegra(prev => ({ ...prev, [name]: value }))
    }
  }

  const salvarRegra = async index => {
    const regra = regras[index]
    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = {
        requisicao: 'AtualizarRegrasFrete',
        regraId: regra._id,
        ...regra
      }
      await fetchData(url, dados)

      setMensagem('Regra atualizada com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
      //  carregarRegras()
    } catch (error) {
      console.error('Erro ao salvar regra:', error)
      setMensagem('Erro ao salvar regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const adicionarRegra = async () => {
    if (!novaRegra.limite_inferior || !novaRegra.limite_superior || !novaRegra.valor_frete) {
      setMensagem('Todos os campos são obrigatórios.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }

    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'GravarRegraPesoFrete', marketplace, ...novaRegra }
      await fetchData(url, dados)

      setMensagem('Nova regra adicionada com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
      setNovaRegra({
        limite_inferior: '',
        limite_superior: '',
        valor_frete: '',
        valor_minimo_produto: ''
      })
      //  carregarRegras()
    } catch (error) {
      console.error('Erro ao adicionar regra:', error)
      setMensagem('Erro ao adicionar nova regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const confirmarExclusao = index => {
    if (index === null || index < 0 || index >= regras.length) {
      setMensagem('Erro ao excluir: índice inválido.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }
    setIndexParaExcluir(index)
    setShowConfirmModal(true)
  }

  const excluirRegra = async () => {
    if (indexParaExcluir === null || regras.length === 0) {
      setMensagem('Erro ao excluir regra: índice inválido.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }

    const regraId = regras[indexParaExcluir]?._id // Obtém o ID da regra a ser excluída

    if (!regraId) {
      setMensagem('Erro ao excluir: ID da regra não encontrado.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }

    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'ExcluirRegraFrete', regraId }

      console.log('Excluindo regra com ID:', regraId) // Depuração

      await fetchData(url, dados)

      setMensagem('Regra excluída com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)

      // Remove a regra excluída da lista sem precisar recarregar do servidor
      setRegras(prevRegras => prevRegras.filter((_, index) => index !== indexParaExcluir))

      // Fecha o modal de confirmação e reseta o índice
      setShowConfirmModal(false)
      setIndexParaExcluir(null)
    } catch (error) {
      console.error('Erro ao excluir regra:', error)
      setMensagem('Erro ao excluir regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  return (
    <div className="container">
      <h2 className="mt-4 d-flex align-items-center">
        Cadastro de Regras de Peso no Frete{' '}
        <span
          className="ms-2"
          style={{
            color: marketplace.toLowerCase() === 'amazon' ? '#FF9900' : 'inherit', // Laranja da Amazon
            fontWeight: marketplace.toLowerCase() === 'amazon' ? 'bold' : 'normal',
            fontFamily:
              marketplace.toLowerCase() === 'amazon' ? "'Arial Black', sans-serif" : 'inherit'
          }}
        >
          ({marketplace.toUpperCase()})
        </span>{' '}
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-sobre">
              <strong>O que é isso?</strong> <br />
              Aqui você pode gerenciar as regras de peso do frete para **{marketplace.toUpperCase()}
              **.
              <br />
              - Defina limites de peso para faixas específicas.
              <br />
              - Informe o valor do frete para cada faixa.
              <br />- Configure o valor mínimo do produto para ativar essa regra.
            </Tooltip>
          }
        >
          <span className="ms-2">
            <FaInfoCircle size={20} style={{ cursor: 'pointer', color: '#007bff' }} />
          </span>
        </OverlayTrigger>
      </h2>
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que deseja excluir esta regra?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={excluirRegra}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      {showAlert && (
        <Alert variant={tipoAlerta} onClose={() => setShowAlert(false)} dismissible>
          {mensagem}
        </Alert>
      )}

      <h4>Adicionar Nova Regra</h4>
      <div className="d-flex gap-2">
        <Form.Control
          type="number"
          name="limite_inferior"
          placeholder="Limite Inferior"
          value={novaRegra.limite_inferior}
          onChange={handleChange}
        />
        <Form.Control
          type="number"
          name="limite_superior"
          placeholder="Limite Superior"
          value={novaRegra.limite_superior}
          onChange={handleChange}
        />
        <Form.Control
          type="number"
          name="valor_frete"
          placeholder="Valor do Frete"
          value={novaRegra.valor_frete}
          onChange={handleChange}
        />
        <Form.Control
          type="number"
          name="valor_minimo_produto"
          placeholder="Valor Mínimo Produto"
          value={novaRegra.valor_minimo_produto}
          onChange={handleChange}
        />
        <Button variant="primary" onClick={adicionarRegra}>
          Adicionar
        </Button>
      </div>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Limite Inferior (kg)</th>
            <th>Limite Superior (kg)</th>
            <th>Valor do Frete (R$)</th>
            <th>Valor Mínimo do Produto (R$)</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {regras.map((regra, index) => (
            <tr key={regra._id}>
              <td>
                <Form.Control
                  type="number"
                  name="limite_inferior"
                  value={regra.limite_inferior}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="limite_superior"
                  value={regra.limite_superior}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="valor_frete"
                  value={regra.valor_frete}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="valor_minimo_produto"
                  value={regra.valor_minimo_produto}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Button variant="success" onClick={() => salvarRegra(index)}>
                  Salvar
                </Button>{' '}
                <Button variant="danger" onClick={() => confirmarExclusao(index)}>
                  Excluir
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default CadastroRegrasPesoFrete
