import { Card, Row, Col, OverlayTrigger, Tooltip, Table } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'

const ExplicacaoAmazon = () => {
  const tooltipAmazon = (
    <Tooltip id="tooltip-amazon">
      <div style={{ maxWidth: '320px', textAlign: 'justify', padding: '0.5rem' }}>
        <h5 style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Detalhes das Regras – Amazon</h5>
        <p style={{ marginBottom: '0.5rem' }}>
          Na Amazon, a tarifação do frete é definida de acordo com o valor do produto e o seu peso.
        </p>
        <p style={{ marginBottom: 0 }}>
          Produtos de até R$ 79 têm tarifa baseada no valor, enquanto para produtos a partir de R$
          79 a tarifa é calculada com base na faixa de peso.
        </p>
      </div>
    </Tooltip>
  )

  return (
    <div>
      <h4 className="d-flex align-items-center">
        Amazon
        <OverlayTrigger placement="right" overlay={tooltipAmazon}>
          <span style={{ cursor: 'pointer', marginLeft: '0.5rem' }}>
            <FaInfoCircle size={18} color="#FF9900" />
          </span>
        </OverlayTrigger>
      </h4>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={4}>
              <TarifacaoPorValorAmazon />
            </Col>
            <Col md={4}>
              <FaixasDePesoAmazon />
            </Col>
            <Col md={4}>
              <ValorMinimoProdutoAmazon />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <DescontoNoFreteAmazon />
            </Col>
            <Col md={6}>
              <ClassificacaoVendedorAmazon />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12}>
              <CriteriosAdicionaisAmazon />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ExplicacaoAmazon

// Tarifação para produtos com valor de até R$ 79,00
const TarifacaoPorValorAmazon = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Tarifação para Produtos até R$ 79,00</Card.Title>
        <Card.Text>
          Para produtos com valor de até R$ 79,00, a tarifa é definida de acordo com o valor do
          produto:
        </Card.Text>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Faixa de Valor (R$)</th>
              <th>Tarifa</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 a 30</td>
              <td>R$ 4,50</td>
            </tr>
            <tr>
              <td>31 a 78,99</td>
              <td>R$ 8,00</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

// Tarifação para produtos com valor a partir de R$ 79,00, calculada com base no peso (região SP-Zona 1)
const FaixasDePesoAmazon = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Tarifação para Produtos a partir de R$ 79,00</Card.Title>
        <Card.Text>
          Para produtos com valor a partir de R$ 79,00, a tarifa é calculada com base no peso,
          conforme a tabela para SP-Zona 1:
        </Card.Text>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Faixa de Peso (g)</th>
              <th>Tarifa</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 a 249</td>
              <td>R$ 19,95</td>
            </tr>
            <tr>
              <td>250 a 499</td>
              <td>R$ 20,45</td>
            </tr>
            <tr>
              <td>500 a 999</td>
              <td>R$ 21,45</td>
            </tr>
            <tr>
              <td>1000 a 1999</td>
              <td>R$ 22,95</td>
            </tr>
            <tr>
              <td>2000 a 2999</td>
              <td>R$ 23,95</td>
            </tr>
            <tr>
              <td>3000 a 3999</td>
              <td>R$ 25,95</td>
            </tr>
            <tr>
              <td>4000 a 4999</td>
              <td>R$ 27,95</td>
            </tr>
            <tr>
              <td>5000 a 5999</td>
              <td>R$ 36,95</td>
            </tr>
            <tr>
              <td>6000 a 6999</td>
              <td>R$ 39,45</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

// Define que, para a tarifação baseada no peso, o produto deve ter valor igual ou superior a R$ 79,00
const ValorMinimoProdutoAmazon = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Valor do Produto</Card.Title>
        <Card.Text>
          Para a tarifação baseada no peso, o produto deve ter valor a partir de R$ 79,00. Produtos
          com valor inferior serão tarifados conforme a tabela para produtos até R$ 79,00.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

// Explica que a tarifação do frete na Amazon é definida por tarifas fixas, sem aplicação de descontos percentuais baseados na classificação do vendedor
const DescontoNoFreteAmazon = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Tarifação do Frete</Card.Title>
        <Card.Text>Na Amazon, o frete é calculado com base em tarifas fixas:</Card.Text>
        <Card.Text>
          • Para produtos de até R$ 79,00, a tarifa é definida pelo valor do produto.
          <br />• Para produtos a partir de R$ 79,00, a tarifa é definida pela faixa de peso
          (SP-Zona 1).
        </Card.Text>
        <Card.Text>
          Não há aplicação de descontos percentuais diretos baseados na classificação do vendedor.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

// A Amazon não utiliza uma classificação do vendedor que altere diretamente os cálculos do frete
const ClassificacaoVendedorAmazon = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Classificação do Vendedor</Card.Title>
        <Card.Text>
          Embora a Amazon avalie o desempenho dos vendedores, esses indicadores não alteram os
          valores de tarifa. As tarifas são previamente definidas e aplicadas de acordo com o valor
          e o peso do produto.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

// Critérios adicionais que não interferem na tarifação, mas que contextualizam a metodologia de cálculo
const CriteriosAdicionaisAmazon = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Critérios Adicionais</Card.Title>
        <Card.Text>
          O cálculo do frete na Amazon é baseado exclusivamente no valor do produto e no peso. Não
          são aplicados descontos adicionais por classificação do vendedor.
        </Card.Text>
        <Card.Text>
          Essa abordagem garante transparência e consistência nos custos de envio, independentemente
          de métricas de desempenho.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}
