import React, { useState, useEffect } from 'react'
import { Table, Button, FormGroup, Row, Col, Modal, Alert } from 'react-bootstrap'
import { Container, Card, Form } from 'react-bootstrap'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { fetchData } from '../../../../../../src/backend/model'
import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils.jsx'
import { CustomField } from '../../../../../../src/components/CustomField/custom-field'
import Select from 'react-select'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'

const CadastroRegrasDescontoFrete = () => {
  const [regras, setRegras] = useState([])
  const [novaRegra, setNovaRegra] = useState({
    categoria: 'normal', // valor default
    limite_inferior: '',
    limite_superior: '',
    limite_unico: '',
    desconto: '',
    tipo_comparacao: ''
  })

  // Estados de alerta
  const [showAlert, setShowAlert] = useState(false)
  const [corAlerta, setCorAlerta] = useState('success')
  const [alertMessage, setAlertMessage] = useState('')

  // Modal de confirmação de exclusão
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [regraParaExcluir, setRegraParaExcluir] = useState(null)

  useEffect(() => {
    carregarRegras()
  }, [])

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 5000)
      return () => clearTimeout(timer)
    }
  }, [showAlert])

  const carregarRegras = async () => {
    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'CarregarRegrasDescontoFrete' }
      const response = await fetchData(url, dados)
      if (response) setRegras(response)
    } catch (error) {
      console.error('Erro ao carregar regras:', error)
    }
  }

  const handleChange = (e, index = null) => {
    const { name, value } = e.target
    if (index !== null) {
      setRegras(prevRegras => {
        const novasRegras = [...prevRegras]
        novasRegras[index][name] = value
        return novasRegras
      })
    } else {
      setNovaRegra(prev => ({ ...prev, [name]: value }))
    }
  }

  const handleSelectChange = (selectedOption, field, index = null) => {
    const value = selectedOption ? selectedOption.value : ''
    if (index !== null) {
      setRegras(prevRegras => {
        const novasRegras = [...prevRegras]
        novasRegras[index][field] = value
        return novasRegras
      })
    } else {
      setNovaRegra(prev => ({ ...prev, [field]: value }))
    }
  }

  const salvarRegra = async index => {
    const regra = regras[index]
    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'AtualizarRegraDescontoFrete', regraId: regra._id, ...regra }
      await fetchData(url, dados)
      setAlertMessage('Regra atualizada com sucesso!')
      setCorAlerta('success')
      setShowAlert(true)
      carregarRegras()
    } catch (error) {
      console.error('Erro ao salvar regra:', error)
      setAlertMessage('Erro ao salvar regra.')
      setCorAlerta('danger')
      setShowAlert(true)
    }
  }

  const adicionarRegra = async () => {
    // Verifica campos vazios
    const camposVazios = Object.values(novaRegra).some(value => value === '')
    if (camposVazios) {
      setAlertMessage('Todos os campos são obrigatórios.')
      setCorAlerta('danger')
      setShowAlert(true)
      return
    }

    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'GravarRegraDescontoFrete', ...novaRegra }
      const response = await fetchData(url, dados)
      if (response.message === 'Gravado') {
        setAlertMessage('Regra de desconto de frete cadastrada com sucesso')
        setCorAlerta('success')
        setShowAlert(true)
        setNovaRegra({
          categoria: 'normal',
          limite_inferior: '',
          limite_superior: '',
          limite_unico: '',
          desconto: '',
          tipo_comparacao: ''
        })
        carregarRegras()
      } else {
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
        setCorAlerta('danger')
        setShowAlert(true)
      }
    } catch (error) {
      console.error('Erro ao cadastrar regra de desconto de frete:', error)
      setAlertMessage('Erro ao tentar cadastrar a regra. Tente novamente.')
      setCorAlerta('danger')
      setShowAlert(true)
    }
  }

  const confirmarExclusao = index => {
    setRegraParaExcluir(index)
    setShowConfirmModal(true)
  }

  const excluirRegra = async () => {
    try {
      const regraId = regras[regraParaExcluir]._id
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'ExcluirRegraDescontoFrete', regraId }
      await fetchData(url, dados)
      setAlertMessage('Regra excluída com sucesso!')
      setCorAlerta('success')
      setShowAlert(true)
      setShowConfirmModal(false)
      carregarRegras()
    } catch (error) {
      console.error('Erro ao excluir regra:', error)
      setAlertMessage('Erro ao excluir regra.')
      setCorAlerta('danger')
      setShowAlert(true)
    }
  }

  const handleClear = fieldName => {
    if (typeof fieldName !== 'string') return
    setNovaRegra(prev => ({ ...prev, [fieldName]: '' }))
  }

  return (
    <Container className="py-4">
      {/* Cabeçalho com o Banner */}
      <Row className="justify-content-center mb-4">
        <Banner
          title={
            <>
              Cadastro de Regras de Desconto no Frete{' '}
              <span style={{ color: '#00A859' }}>Magalu</span>
            </>
          }
          color="#2e8b57"
          fontSize={40}
        />
      </Row>

      {/* Alerta de mensagem */}
      {showAlert && (
        <Row className="justify-content-center mb-3">
          <Col md={8}>
            <ShowAlertGeneric
              showAlert={showAlert}
              message={alertMessage}
              setShowAlert={setShowAlert}
              variant={corAlerta}
              fontSize="1.5rem"
              dismissible={false}
              isBlurred={true}
            />
          </Col>
        </Row>
      )}

      {/* Formulário de Cadastro de Nova Regra com 2 colunas */}
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="mb-4 shadow-sm">
            <Card.Header as="h5">
              Nova Regra{' '}
              <OverlayTrigger
                placement="right" // Posição do tooltip: top, right, bottom, left
                overlay={
                  <Tooltip id="tooltip-regras">
                    <strong>Lógica de cadastro:</strong>
                    <br />
                    - "Categoria" define se é Normal ou Fulfillment.
                    <br />
                    - "Limite Inferior" e "Limite Superior" definem a faixa de valor.
                    <br />
                    - "Desconto (%)" indica o valor do desconto aplicado.
                    <br />
                    - "Tipo de Comparação" indica se é menor, entre ou maior que a faixa.
                    <br />
                  </Tooltip>
                }
              >
                <span style={{ cursor: 'pointer', marginLeft: '8px' }}>
                  <FaInfoCircle size={18} color="#2e8b57" />
                </span>
              </OverlayTrigger>
            </Card.Header>
            <Card.Body>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  adicionarRegra()
                }}
              >
                <Row>
                  {/* Coluna Esquerda */}
                  <Col md={6}>
                    <Row className="mb-3">
                      <Col>
                        <Select
                          placeholder="Categoria"
                          name="categoria"
                          value={
                            novaRegra.categoria
                              ? {
                                  value: novaRegra.categoria,
                                  label:
                                    novaRegra.categoria.charAt(0).toUpperCase() +
                                    novaRegra.categoria.slice(1)
                                }
                              : null
                          }
                          onChange={selectedOption =>
                            handleSelectChange(selectedOption, 'categoria')
                          }
                          options={[
                            { value: 'normal', label: 'Normal' },
                            { value: 'fulfillment', label: 'Fulfillment' }
                          ]}
                          isClearable
                          styles={{
                            control: base => ({
                              ...base,
                              minHeight: '38px',
                              minWidth: '200px'
                            }),
                            menu: base => ({
                              ...base,
                              zIndex: 9999
                            })
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <CustomField
                          label="Limite Inferior"
                          placeholder="Digite o Limite Inferior"
                          name="limite_inferior"
                          value={novaRegra.limite_inferior}
                          onChange={handleChange}
                          onClear={() => handleClear('limite_inferior')}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <CustomField
                          label="Limite Único"
                          placeholder="Digite o Limite Único"
                          name="limite_unico"
                          value={novaRegra.limite_unico}
                          onChange={handleChange}
                          onClear={() => handleClear('limite_unico')}
                        />
                      </Col>
                    </Row>
                  </Col>

                  {/* Coluna Direita */}
                  <Col md={6}>
                    <Row className="mb-3">
                      <Col>
                        <Select
                          placeholder="Tipo de Comparação"
                          name="tipo_comparacao"
                          value={
                            novaRegra.tipo_comparacao
                              ? {
                                  value: novaRegra.tipo_comparacao,
                                  label:
                                    novaRegra.tipo_comparacao.charAt(0).toUpperCase() +
                                    novaRegra.tipo_comparacao.slice(1)
                                }
                              : null
                          }
                          onChange={selectedOption =>
                            handleSelectChange(selectedOption, 'tipo_comparacao')
                          }
                          options={[
                            { value: 'menor', label: 'Menor que' },
                            { value: 'entre', label: 'Entre' },
                            { value: 'maior', label: 'Maior que' }
                          ]}
                          isClearable
                          styles={{
                            control: base => ({
                              ...base,
                              minHeight: '38px',
                              minWidth: '190px'
                            }),
                            menu: base => ({
                              ...base,
                              zIndex: 9999
                            })
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <CustomField
                          label="Limite Superior"
                          placeholder="Digite o Limite Superior"
                          name="limite_superior"
                          value={novaRegra.limite_superior}
                          onChange={handleChange}
                          onClear={() => handleClear('limite_superior')}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <CustomField
                          label="Desconto (%)"
                          placeholder="Digite o valor do Desconto"
                          name="desconto"
                          value={novaRegra.desconto}
                          onChange={handleChange}
                          onClear={() => handleClear('desconto')}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* Botão de envio */}
                <Row>
                  <Col className="text-right">
                    <Button type="submit" variant="success" className="mt-2">
                      Cadastrar Regra de Desconto de Frete
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Tabela das Regras Existentes */}
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="shadow-sm">
            <Card.Header as="h5">Regras Existentes</Card.Header>
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr className="bg-light">
                    <th>Categoria</th>
                    <th>Limite Inferior</th>
                    <th>Limite Superior</th>
                    <th>Limite Único</th>
                    <th>Desconto (%)</th>
                    <th>Tipo Comparação</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {regras.map((regra, index) => (
                    <tr key={regra._id}>
                      <td>
                        <Select
                          name="categoria"
                          value={
                            regra.categoria
                              ? {
                                  value: regra.categoria,
                                  label:
                                    regra.categoria.charAt(0).toUpperCase() +
                                    regra.categoria.slice(1)
                                }
                              : null
                          }
                          onChange={selectedOption =>
                            handleSelectChange(selectedOption, 'categoria', index)
                          }
                          options={[
                            { value: 'normal', label: 'Normal' },
                            { value: 'fulfillment', label: 'Fulfillment' }
                          ]}
                          isClearable
                          styles={{
                            control: base => ({
                              ...base,
                              minHeight: '38px',
                              minWidth: '250px'
                            }),
                            menu: base => ({
                              ...base,
                              zIndex: 9999
                            })
                          }}
                        />
                      </td>
                      <td>
                        <CustomField
                          name="limite_inferior"
                          value={regra.limite_inferior}
                          onChange={e => handleChange(e, index)}
                          style={{ minWidth: '70px' }}
                        />
                      </td>
                      <td>
                        <CustomField
                          name="limite_superior"
                          value={regra.limite_superior}
                          onChange={e => handleChange(e, index)}
                          style={{ minWidth: '70px' }}
                        />
                      </td>
                      <td>
                        <CustomField
                          name="limite_unico"
                          value={regra.limite_unico}
                          onChange={e => handleChange(e, index)}
                          style={{ minWidth: '70px' }}
                        />
                      </td>
                      <td>
                        <CustomField
                          name="desconto"
                          value={regra.desconto}
                          onChange={e => handleChange(e, index)}
                          style={{ minWidth: '60px' }}
                        />
                      </td>
                      <td>
                        <Select
                          name="tipo_comparacao"
                          value={
                            regra.tipo_comparacao
                              ? {
                                  value: regra.tipo_comparacao,
                                  label:
                                    regra.tipo_comparacao.charAt(0).toUpperCase() +
                                    regra.tipo_comparacao.slice(1)
                                }
                              : null
                          }
                          onChange={selectedOption =>
                            handleSelectChange(selectedOption, 'tipo_comparacao', index)
                          }
                          options={[
                            { value: 'menor', label: 'Menor que' },
                            { value: 'entre', label: 'Entre' },
                            { value: 'maior', label: 'Maior que' }
                          ]}
                          isClearable
                          styles={{
                            control: base => ({
                              ...base,
                              minHeight: '38px',
                              minWidth: '250px'
                            }),
                            menu: base => ({
                              ...base,
                              zIndex: 9999
                            })
                          }}
                        />
                      </td>
                      <td>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => salvarRegra(index)}
                          className="mr-2"
                        >
                          Salvar
                        </Button>
                        <Button variant="danger" size="sm" onClick={() => confirmarExclusao(index)}>
                          Excluir
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal de Confirmação de Exclusão */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que deseja excluir esta regra?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={excluirRegra}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default CadastroRegrasDescontoFrete
