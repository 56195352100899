//AppMenuFunctions.js
import { SYSTEM_OWNER } from '../config/constantes'
import bcrypt from 'bcryptjs'
import { fetchData } from '../backend/model'
//import { useNavigate } from 'react-router-dom'
//import { useContext } from 'react'
//import { AuthContext } from '../context/AuthContext'
import { config } from '../backend/configConexaoGeral'

import history from '../utils/history'
export const isSystemOwner = async (user, password) => {
  // Verifica se o login do usuário é igual ao login do dono do sistema
  if (user !== SYSTEM_OWNER.login) {
    return false
  }

  // Compara a senha fornecida (criptografada) com a senha armazenada (não criptografada)
  const senhaCorreta = await bcrypt.compare(SYSTEM_OWNER.senha, password)

  return senhaCorreta
}
//------------------------------------------
export async function deslogar(logout, rota) {
  let url = config.host + '/login'
  let dados = { requisicao: 'logout' }
  await fetchData(url, dados)

  logout()

  if (config.tipo === 1) {
    window.location.href = '/'
  } else if (config.tipo === 2) {
    rota = '/' + rota

    history.push(rota)
  }
}
