// ExtraFieldsSelector.jsx
import { useState } from 'react'
import { Form, Button, Collapse } from 'react-bootstrap'

const ExtraFieldsSelector = ({ onExtraFieldsChange, setExtraFields, extraFields }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleField = field => {
    const newExtraFields = { ...extraFields, [field]: !extraFields[field] }
    setExtraFields(newExtraFields)
    if (onExtraFieldsChange) {
      onExtraFieldsChange(newExtraFields)
    }
  }

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '1rem',
        borderRadius: '4px',
        marginBottom: '1rem'
      }}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <Button
        variant="secondary"
        style={{ marginBottom: '0.5rem', backgroundColor: '#FFFFFF', color: '#000' }}
      >
        Campos Extras {isExpanded ? '▲' : '▼'}
      </Button>
      <Collapse in={isExpanded}>
        <div id="extra-fields-collapse">
          <Form>
            <Form.Check
              type="checkbox"
              label="Frete"
              checked={extraFields.frete}
              onChange={() => toggleField('frete')}
            />
            <Form.Check
              type="checkbox"
              label="Fixo"
              checked={extraFields.fixo}
              onChange={() => toggleField('fixo')}
            />
            <Form.Check
              type="checkbox"
              label="Comissão"
              checked={extraFields.comissao}
              onChange={() => toggleField('comissao')}
            />
            <Form.Check
              type="checkbox"
              label="Custo"
              checked={extraFields.custo}
              onChange={() => toggleField('custo')}
            />
            <Form.Check
              type="checkbox"
              label="Categoria"
              checked={extraFields.categoria}
              onChange={() => toggleField('categoria')}
            />
          </Form>
        </div>
      </Collapse>
    </div>
  )
}

export default ExtraFieldsSelector
