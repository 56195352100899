import { useState, useContext } from 'react'
import { Form, Button, Container, InputGroup } from 'react-bootstrap'
import { fetchData } from '../../../../src/backend/model.js'
import { config } from '../../../../src/backend/configConexaoGeral'
import { AuthContext } from '../../../../src/context/AuthContext'

const AlterarSenha = () => {
  // Supondo que o AuthContext forneça o tenantId e a rota_base (RotaBase)
  const { tenantId, RotaBase } = useContext(AuthContext)
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  })
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  // Estados para controle de visibilidade das senhas
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

  // Atualiza os valores do formulário conforme o usuário digita
  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  // Valida e envia os dados do formulário para o endpoint "alterar_senha_usuario"
  const handleSubmit = async e => {
    e.preventDefault()
    // Verifica se as novas senhas coincidem
    if (formData.newPassword !== formData.confirmNewPassword) {
      setError('As novas senhas não conferem.')
      return
    }

    setError('')
    try {
      // Monta a URL e os dados para chamar o endpoint no backend
      const url = `${config.host}/tenants`
      const dados = {
        tenantId,
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
        rota_base: RotaBase,
        requisicao: 'alterar_senha_usuario' // novo fluxo para usuário autenticado
      }

      const response = await fetchData(url, dados)

      if (response && response.success) {
        setSuccess('Senha alterada com sucesso!')
        // Limpa o formulário
        setFormData({
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: ''
        })
      } else {
        setError(response.message || 'Erro ao alterar a senha. Tente novamente.')
      }
    } catch (err) {
      console.error('Erro ao alterar a senha:', err)
      setError('Erro ao alterar a senha. Tente novamente.')
    }
  }

  return (
    <Container className="mt-4">
      <h1>Alterar Senha</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="currentPassword" className="mb-3">
          <Form.Label>Senha Atual</Form.Label>
          <InputGroup>
            <Form.Control
              type={showCurrentPassword ? 'text' : 'password'}
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleChange}
              placeholder="Digite sua senha atual"
            />
            <InputGroup.Text
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              style={{ cursor: 'pointer' }}
            >
              <i className={showCurrentPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="newPassword" className="mb-3">
          <Form.Label>Nova Senha</Form.Label>
          <InputGroup>
            <Form.Control
              type={showNewPassword ? 'text' : 'password'}
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              placeholder="Digite a nova senha"
            />
            <InputGroup.Text
              onClick={() => setShowNewPassword(!showNewPassword)}
              style={{ cursor: 'pointer' }}
            >
              <i className={showNewPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="confirmNewPassword" className="mb-3">
          <Form.Label>Confirmar Nova Senha</Form.Label>
          <InputGroup>
            <Form.Control
              type={showConfirmNewPassword ? 'text' : 'password'}
              name="confirmNewPassword"
              value={formData.confirmNewPassword}
              onChange={handleChange}
              placeholder="Confirme a nova senha"
            />
            <InputGroup.Text
              onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
              style={{ cursor: 'pointer' }}
            >
              <i className={showConfirmNewPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Button variant="primary" type="submit">
          Alterar Senha
        </Button>
      </Form>
    </Container>
  )
}

export default AlterarSenha
