// MarketplaceConfigModal.jsx

import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const MarketplaceConfigModal = ({ show, onHide }) => {
  const navigate = useNavigate()

  const handleConfigure = () => {
    onHide() // Fecha o modal
    navigate('/tenant-marketplaces-config') // Redireciona para a página de configuração
  }

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
      <Modal.Header>
        <Modal.Title>Atenção</Modal.Title>
      </Modal.Header>
      <Modal.Body>Olá! Você precisa configurar quais marketplaces você trabalha.</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfigure}>
          Configurar Agora
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MarketplaceConfigModal
