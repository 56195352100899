import { useState, useEffect } from 'react'
import { Dropdown, Badge } from 'react-bootstrap'
import { FaBell } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { fetchData } from '../../../../../src/backend/model'
import { config } from '../../../../../src/backend/configConexaoGeral'
import NotificationDetailModal from './NotificationDetailModal'
const NotificationBell = () => {
  const [notifications, setNotifications] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false) // Estado para controlar a exibição do modal
  const [selectedNotification, setSelectedNotification] = useState(null) // Estado para a notificação selecionada
  const navigate = useNavigate()

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsLoading(true)
      try {
        const url = config.host + '/page-notifications'
        const payload = { requisicao: 'listarNotificacoesPages' }

        const response = await fetchData(url, payload)

        if (response && response.success) {
          setNotifications(response.data)
        }
      } catch (error) {
        console.error('Erro ao buscar notificações:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchNotifications()
  }, [])

  const handleNotificationClick = notification => {
    // Abre o modal com os detalhes da notificação clicada
    setSelectedNotification(notification)
    setShowModal(true)
  }

  return (
    <>
      <Dropdown align="end">
        <Dropdown.Toggle variant="light" id="dropdown-notification" className="position-relative">
          <FaBell size={20} />
          {notifications.length > 0 && (
            <Badge bg="danger" className="position-absolute top-0 start-100 translate-middle">
              {notifications.length}
            </Badge>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {isLoading ? (
            <Dropdown.ItemText>Carregando...</Dropdown.ItemText>
          ) : notifications.length === 0 ? (
            <Dropdown.ItemText>Nenhuma notificação</Dropdown.ItemText>
          ) : (
            notifications.map(notification => (
              <Dropdown.Item
                key={notification._id}
                onClick={() => handleNotificationClick(notification)}
              >
                <div>
                  <strong>{notification.title}</strong>
                  <small style={{ marginLeft: '5px', color: '#999' }}>
                    {new Date(notification.createdAt).toLocaleString()}
                  </small>
                </div>
                <div>{notification.message}</div>
              </Dropdown.Item>
            ))
          )}
        </Dropdown.Menu>
      </Dropdown>

      {/* Renderiza o modal de detalhes */}
      {selectedNotification && (
        <NotificationDetailModal
          show={showModal}
          onHide={() => setShowModal(false)}
          notification={selectedNotification}
        />
      )}
    </>
  )
}

export default NotificationBell
