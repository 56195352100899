import Banner from '../../../../../src/components/pages/CadastroGeral/Banner'
import logoTopo from '../Usuarios/login/logo_topo.jpg'
import { ShowAlertGeneric } from '../../../../../src/utils/form-utils.jsx'
import { Link } from 'react-router-dom'
import { useState } from 'react'
//import loadIps from '/src/components/pages/Usuarios/login/login';

//import { AuthContext } from '../../../../../src/context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { fetchData } from '../../../../../src/backend/model'

import { config } from '../../../../../src/backend/configConexaoGeral'
import { Navbar, Nav, Button } from 'react-bootstrap'

import logoCentro from './logo_centro.jpg'
import { CustomTooltip } from '../../../../../src/components/pages/Home/NavbarModelagemcompleta.jsx'
function Home() {
  const navigate = useNavigate()
  const [showAlertMessage, setShowAlertMessage] = useState(false)

  const [alertMessage, setAlertMessage] = useState('')

  function ClicklinkEntrar() {
    navigate('/login')
  }
  //inicio-pf
  return (
    <div>
      <Navbar bg="light" expand="lg" className="index-top-header-home">
        <div className="d-flex align-items-center">
          <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
            <img
              src={logoTopo}
              alt="Logo"
              className="logo-topo-homePF"
              style={{
                top: 0,
                left: 0,
                zIndex: 9999
              }}
            />
          </Navbar.Brand>
          <Banner
            title="Preço Fácil Market"
            fontFamily="Josefin Sans"
            color="#0bd692"
            width="42 %"
            fontSize="30px"
            classEfeito3dMouseOver=""
          />
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto menu-items-container">
            <Nav.Link href="#funcionalidades" id="index-link-nav-link">
              Funcionalidades
            </Nav.Link>
            <Nav.Link onClick={() => navigate('/planos-detalhes-pf')} id="index-link-nav-link">
              Planos sob consulta
            </Nav.Link>
            <Nav.Link href="#afiliado" id="index-link-nav-link">
              Seja um Afiliado
            </Nav.Link>
            <Nav.Link onClick={() => navigate('/fale-conosco')} id="index-link-nav-link">
              Fale conosco
            </Nav.Link>

            <button
              onClick={() => navigate('/cadastro-tenant')}
              style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
            >
              <Nav.Link as="span" className="experimente-gratis-btn">
                Experimente
              </Nav.Link>
            </button>
          </Nav>
        </Navbar.Collapse>
        <CustomTooltip setAlertMessage={setAlertMessage} setShowAlertMessage={setShowAlertMessage}>
          <div className="entrar-linkPF" onClick={ClicklinkEntrar}>
            Entrar
          </div>
        </CustomTooltip>
      </Navbar>

      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true}
        setShowAlert={setShowAlertMessage}
      />

      <div className="banner-container-homePF">
        <div className="sales-text-block">
          <h2 className="title-vendas">Para suas vendas online</h2>
          <h1 className="main-chamada">
            1 maximize seus lucros e otimize suas vendas com Preço Fácil Market
          </h1>
          <p className="detalhes">
            Preço Fácil Market é a solução definitiva para precificação inteligente, conectando você
            aos maiores marketplaces com preços sempre competitivos.
          </p>
          <Button
            variant="success"
            className="btn-experimente-gratis"
            onClick={() => navigate('/cadastro-tenant')}
          >
            começe agora
          </Button>
        </div>
        <div className="planos-circuloPF">
          <div className="preco-chamada">Planos a partir de</div>
          <div className="preco-valor">
            R$<span className="preco-destaque">159</span>,00
          </div>

          <div className="preco-info" onClick={() => navigate('/planos-detalhes-pf')}>
            saiba mais
          </div>
        </div>

        <div className="local-banner-centro">
          <img src={logoCentro} alt="Logo" className="logo-centro-homePF" />
        </div>
      </div>
    </div>
  )
}
export default Home

//--------------------------------------------
const loadIps = async () => {
  let dados = {
    requisicao: 'RetornarTodosIps'
  }
  let url = config.host + '/liberar_ips'
  let response = await fetchData(url, dados)
  return response
  // Store the IPs in the state variable
}
