import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import MarketplaceFilter from './MarketplaceFilter'
import MargemColorConfigurator from './MargemColorConfigurator'
import AtivoFilter from './AtivoFilter.jsx'
import ExtraFieldsSelector from './ExtraFieldsSelector'
const SidebarFilters = ({
  onMarketplaceChange, // callback para atualizar a seleção dos marketplaces
  onAtivoChange, // callback para atualizar o status "ativo"
  initialMargemConfig, // configuração inicial para os indicadores de margem
  tenantId, // tenantId necessário para o MargemColorConfigurator
  onMargemConfigSave, // callback para salvar a nova configuração de margem
  carregarPrecosVenda,
  setFiltro,
  filtro,
  setPrecos,
  marketplacesDisponiveis,
  setSelectedMarketplaces,
  precosCompleto,
  onExtraFieldsChange,
  setExtraFields,
  extraFields
}) => {
  const [showConfigurator, setShowConfigurator] = useState(false)
  const [margemConfig, setMargemConfig] = useState(
    initialMargemConfig || {
      highThreshold: 20,
      highColor: '#00FF00', // verde
      lowThreshold: 5,
      lowColor: '#FF0000' // vermelho
    }
  )

  const handleSaveConfig = config => {
    setMargemConfig(config)
    setShowConfigurator(false)
    if (onMargemConfigSave) onMargemConfigSave(config)
  }

  return (
    <div style={{ width: '250px', padding: '1rem', borderRight: '1px solid #ccc' }}>
      <h5 style={{ marginBottom: '1rem' }}>Filtros e Configurações</h5>

      {/* Seção de Marketplace */}
      <div style={{ marginBottom: '1rem' }}>
        <MarketplaceFilter
          marketplaces={marketplacesDisponiveis}
          onChange={selectedIds => setSelectedMarketplaces(selectedIds)}
          setPrecos={setPrecos}
          precosCompleto={precosCompleto.current}
        />
      </div>

      {/* Botão para abrir o modal do MargemColorConfigurator */}
      <div style={{ marginBottom: '1rem' }}>
        <Button
          variant="info"
          onClick={() => setShowConfigurator(true)}
          style={{ width: '100%', fontSize: '15px', backgroundColor: '#FFFFFF' }}
        >
          Configurar Indicadores de Margem
        </Button>
      </div>

      {/* Seção de filtro "Ativo" */}
      <div>
        <AtivoFilter
          carregarPrecosVenda={carregarPrecosVenda}
          setFiltro={setFiltro}
          filtro={filtro}
        />
      </div>
      {/* Seção de campos extras */}
      <div style={{ marginBottom: '1rem' }}>
        <ExtraFieldsSelector
          onExtraFieldsChange={onExtraFieldsChange}
          setExtraFields={setExtraFields}
          extraFields={extraFields}
        />
      </div>
      {/* Modal para configuração de indicadores de margem */}
      <Modal
        show={showConfigurator}
        onHide={() => setShowConfigurator(false)}
        dialogClassName="my-dialog-class"
      >
        <style>
          {`
            .my-dialog-class {
              max-width: 400px !important;
              background-color: #FFFFFF !important;
            }
          `}
        </style>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: 'center', fontSize: '20px' }}>
            Configurar Indicadores de Margem
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MargemColorConfigurator
            initialConfig={margemConfig}
            tenantId={tenantId}
            onSave={handleSaveConfig}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SidebarFilters
