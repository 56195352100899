import { Card, OverlayTrigger, Tooltip, Table } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'

const ExplicacaoAmericanas = () => {
  const tooltipAmericanas = (
    <Tooltip id="tooltip-americanas">
      <div style={{ maxWidth: '320px', textAlign: 'justify', padding: '0.5rem' }}>
        <h5 style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>
          Detalhes das Regras – Americanas
        </h5>
        <p style={{ marginBottom: '0.5rem' }}>
          As condições de frete na Americanas variam conforme o valor do produto, o peso e o nível
          de subsídio aplicado.
        </p>
        <p style={{ marginBottom: 0 }}>
          Confira as diferentes faixas e os respectivos valores tarifados para cada condição.
        </p>
      </div>
    </Tooltip>
  )

  return (
    <div>
      <h4 className="d-flex align-items-center">
        Americanas
        <OverlayTrigger placement="right" overlay={tooltipAmericanas}>
          <span style={{ cursor: 'pointer', marginLeft: '0.5rem' }}>
            <FaInfoCircle size={18} color="#FF0000" />
          </span>
        </OverlayTrigger>
      </h4>
      <Card className="mb-3">
        <Card.Body>
          <SectionProdutosAte3999 />
          <SectionProdutosDe40A89999 />
          <SectionProdutosAcimaDe90 />
        </Card.Body>
      </Card>
    </div>
  )
}

export default ExplicacaoAmericanas

// Seção para produtos com valor até R$ 39,99 (tarifa 0)
const SectionProdutosAte3999 = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Produtos até R$ 39,99</Card.Title>
        <Card.Text>Para produtos com valor até R$ 39,99, o frete é de R$ 0,00.</Card.Text>
      </Card.Body>
    </Card>
  )
}

// Seção para produtos com valor entre R$ 40,00 e R$ 89,99
const SectionProdutosDe40A89999 = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Produtos de R$ 40,00 a R$ 89,99</Card.Title>
        <Card.Text>
          Nesta faixa, o frete é calculado com base em um único intervalo de peso (0 a 30.000 g) e
          varia conforme o nível de subsídio aplicado:
        </Card.Text>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th colSpan="2" style={{ textAlign: 'center' }}>
                Faixa de Peso (g)
              </th>
              <th colSpan="4" style={{ textAlign: 'center' }}>
                Tarifa (Níveis)
              </th>
            </tr>
            <tr>
              <th>De</th>
              <th>Até</th>
              <th>Nível 1</th>
              <th>Nível 2</th>
              <th>Nível 3</th>
              <th>Full</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0</td>
              <td>30000</td>
              <td>R$ 12,90</td>
              <td>R$ 7,74</td>
              <td>R$ 6,45</td>
              <td>R$ 2,58</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

// Seção para produtos com valor igual ou superior a R$ 90,00
const SectionProdutosAcimaDe90 = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Produtos Acima de R$ 90,00</Card.Title>
        <Card.Text>
          Para produtos com valor a partir de R$ 90,00, o frete é definido com base em faixas de
          peso e no nível de subsídio aplicado:
        </Card.Text>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th rowSpan="2">Faixa de Peso (g)</th>
              <th colSpan="4" style={{ textAlign: 'center' }}>
                Tarifa (R$)
              </th>
            </tr>
            <tr>
              <th>Sem Subsidio</th>
              <th>Subsídio 40%</th>
              <th>Subsídio 50%</th>
              <th>Subsídio 80%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 a 499</td>
              <td>R$ 37,90</td>
              <td>R$ 22,74</td>
              <td>R$ 18,95</td>
              <td>R$ 6,58</td>
            </tr>
            <tr>
              <td>500 a 999</td>
              <td>R$ 40,90</td>
              <td>R$ 24,54</td>
              <td>R$ 20,45</td>
              <td>R$ 7,18</td>
            </tr>
            <tr>
              <td>1000 a 1999</td>
              <td>R$ 41,90</td>
              <td>R$ 25,14</td>
              <td>R$ 20,95</td>
              <td>R$ 7,38</td>
            </tr>
            <tr>
              <td>2000 a 4999</td>
              <td>R$ 50,90</td>
              <td>R$ 30,54</td>
              <td>R$ 25,45</td>
              <td>R$ 9,18</td>
            </tr>
            <tr>
              <td>5000 a 8999</td>
              <td>R$ 72,90</td>
              <td>R$ 43,74</td>
              <td>R$ 36,45</td>
              <td>R$ 9,18</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
