// CadastroRegraGlobal.js
import { useState, useEffect, useContext } from 'react'
import { Button, FormGroup } from 'react-bootstrap'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { fetchData } from '../../../../../../src/backend/model'
import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils.jsx'
import { CustomField } from '../../../../../../src/components/CustomField/custom-field'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { AuthContext } from '../../../../../../src/context/AuthContext'
import Select from 'react-select'
const CadastroRegraGlobal = () => {
  const [regraGlobal, setRegraGlobal] = useState({
    id_marketplace: '',
    tipo_frete: '',
    valor_fixo: '',
    transicao_frete: '',
    antes_frete: '',
    percentual: '',
    margem: ''
  })
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('danger')
  const [marketplaces, setMarketplaces] = useState([])
  const { tenantId } = useContext(AuthContext)
  const marketplaceOptions = marketplaces.map(marketplace => ({
    value: marketplace.id,
    label: marketplace.nome
  }))

  const handleSelectChange = selectedOption => {
    handleChange({
      target: {
        name: 'id_marketplace',
        value: selectedOption ? selectedOption.value : ''
      }
    })
  }
  useEffect(() => {
    const carregarDados = async () => {
      try {
        const response = await CarregarMarketplaces()
        setMarketplaces(response || [])
      } catch (error) {
        console.error('Erro ao carregar os marketplaces:', error)
        // Trate o erro conforme necessário
      }
    }

    carregarDados()
  }, [])

  const validarCampos = () => {
    // Verifica se algum dos campos está vazio
    const camposVazios = Object.values(regraGlobal).some(value => value === '')
    if (camposVazios) {
      setAlertMessage('Todos os campos são obrigatórios.')
      setCorAlerta('danger')
      setShowAlert(true)
      return false // Indica que a validação falhou
    }
    return true // Indica que a validação foi bem-sucedida
  }
  const handleSubmit = async event => {
    event.preventDefault()

    if (!validarCampos()) {
      return // Se a validação falhar, não continua com o envio
    }
    let dados = {
      ...regraGlobal,
      requisicao: 'GravarRegraGlobalPF'
    }
    let url = config.host + '/regraGlobalPF'

    try {
      let response = await fetchData(url, dados)

      if (response.message === 'Gravado') {
        setRegraGlobal({
          id_marketplace: '',
          tipo_frete: '',
          valor_fixo: '',
          transicao_frete: '',
          antes_frete: '',
          percentual: '',
          margem: ''
        })

        setShowAlert(true)
        setCorAlerta('success')
        setAlertMessage('Regra Global cadastrada com sucesso')
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar cadastrar a regra global. Tente novamente.')
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    setRegraGlobal({
      ...regraGlobal,
      [name]: value
    })
  }

  function handleClear(fieldName) {
    // Atualiza o estado para limpar o campo específico
    setRegraGlobal(prevState => ({
      ...prevState,
      [fieldName]: '' // Limpa o campo específico com base no nome fornecido
    }))

    // Chama a função handleChange com um evento simulado para o campo específico
    handleChange({ target: { name: fieldName, value: '' } })
  }

  return (
    <div
      className="d-flex align-items-center form-containerPF"
      style={{ height: '100vh', position: 'relative' }}
    >
      <div className="text-center mx-auto mx-autoPF" style={{ maxWidth: '500px' }}>
        <Banner title="Cadastro de Regra Global" color="#2e8b57" fontSize={60} />

        <FormGroup style={{ paddingTop: '20px' }}>
          {showAlert && (
            <ShowAlertGeneric
              showAlert={showAlert}
              message={alertMessage}
              setShowAlert={setShowAlert}
              variant={corAlerta}
              className="show-alert-genericPF"
              fontSize="1.5rem"
              dismissible={false}
              isBlurred={true}
            />
          )}
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* Primeira coluna com 4 campos */}
              <div className="col">
                <div className="mb-5">
                  <CustomField
                    label="Tipo de Frete"
                    placeholder="Digite o Tipo de Frete"
                    width="100%"
                    value={regraGlobal.tipo_frete}
                    onChange={e =>
                      handleChange({
                        target: { name: 'tipo_frete', value: e.target.value }
                      })
                    }
                    className="Efeito3dMouseOver-class"
                    onClear={() => handleClear('tipo_frete')}
                  />
                </div>
                <div className="mb-5">
                  <CustomField
                    label="Valor Fixo"
                    placeholder="Digite o Valor Fixo"
                    width="100%"
                    value={regraGlobal.valor_fixo}
                    onChange={e =>
                      handleChange({
                        target: { name: 'valor_fixo', value: e.target.value }
                      })
                    }
                    className="Efeito3dMouseOver-class"
                    onClear={() => handleClear('valor_fixo')}
                  />
                </div>
                <div className="mb-5">
                  <CustomField
                    label="Transição de Frete"
                    placeholder="Digite a Transição de Frete"
                    width="100%"
                    value={regraGlobal.transicao_frete}
                    onChange={e =>
                      handleChange({
                        target: {
                          name: 'transicao_frete',
                          value: e.target.value
                        }
                      })
                    }
                    className="Efeito3dMouseOver-class"
                    onClear={() => handleClear('transicao_frete')}
                  />
                </div>
              </div>

              {/* Segunda coluna com 3 campos */}
              <div className="col">
                <div className="mb-5">
                  <Select
                    placeholder="Selecione o Marketplace"
                    className="Efeito3dMouseOver-class custom-react-select-menu"
                    value={marketplaceOptions.find(
                      option => option.value === regraGlobal.id_marketplace
                    )}
                    onChange={handleSelectChange}
                    options={marketplaceOptions}
                    isClearable
                    styles={{
                      menu: provided => ({ ...provided, zIndex: 999 })
                    }}
                  />
                </div>
                <div className="mb-5">
                  <CustomField
                    label="Antes do Frete"
                    placeholder="Digite o valor para 'Antes do Frete'"
                    width="100%"
                    value={regraGlobal.antes_frete}
                    onChange={e =>
                      handleChange({
                        target: { name: 'antes_frete', value: e.target.value }
                      })
                    }
                    className="Efeito3dMouseOver-class"
                    onClear={() => handleClear('antes_frete')}
                  />
                </div>
                <div className="mb-5">
                  <CustomField
                    label="Percentual"
                    placeholder="Digite o Percentual"
                    width="100%"
                    value={regraGlobal.percentual}
                    onChange={e =>
                      handleChange({
                        target: { name: 'percentual', value: e.target.value }
                      })
                    }
                    className="Efeito3dMouseOver-class"
                    onClear={() => handleClear('percentual')}
                  />
                </div>
                <div className="mb-5">
                  <CustomField
                    label="Margem"
                    placeholder="Digite a Margem"
                    width="100%"
                    value={regraGlobal.margem}
                    onChange={e =>
                      handleChange({
                        target: { name: 'margem', value: e.target.value }
                      })
                    }
                    className="Efeito3dMouseOver-class"
                    onClear={() => handleClear('margem')}
                  />
                </div>
              </div>
            </div>

            <Button type="submit" className="btn btn-success" style={{ marginTop: '20px' }}>
              Cadastrar Regra Global
            </Button>
          </form>
        </FormGroup>
      </div>
    </div>
  )
}

export default CadastroRegraGlobal
//-----------------------------------------------------------------
export async function CarregarMarketplaces() {
  try {
    let dados = {
      requisicao: 'CarregarMarketplacesPF'
    }
    let url = config.host + '/marketplacePF'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar os marketplaces:', error)
    // Trate o erro conforme necessário
  }
}
