// PrivateMenuItem.js
import { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { AuthContext } from '../context/AuthContext'
import MarketplaceConfigModal from '../../PRECO_FACIL_MARKET/src/components/pages/Marketplaces/TenantMarktplaceConfig/MarketplaceConfigModal.jsx'
// Constantes para controle de menus
const RESTRICTED_MENUS = [
  'CADASTRAR MARKETPLACE',
  'GERENCIAR MARKETPLACE',
  'Regras',
  'Marketplaces',
  'Sub Sistemas',
  'Cadastro de Serviço',
  'Usuarios cadastrados',
  'Painel do Presidente',
  'ClientEmail',
  'Notificacoes'
]

// Telas que requerem verificação de acessoModulos
const TELAS_COM_ACESSO_MODULOS = ['corte', 'modelagem', 'plotagem', 'relogiodeponto']

export const PrivateMenuItem = ({ children, tela }) => {
  PrivateMenuItem.propTypes = {
    children: PropTypes.node,
    tela: PropTypes.string.isRequired
  }

  const { isAuthenticated, nivel_acesso, telasPermissao, acessoModulos, idNivelAcesso } =
    useContext(AuthContext)

  const [isOwner, setIsOwner] = useState(false)

  useEffect(() => {
    const checkSystemOwner = () => {
      if (nivel_acesso === 'owner') {
        setIsOwner(true)
      }
    }
    checkSystemOwner()
  }, [nivel_acesso])

  // Se o usuário não estiver autenticado, não renderiza nada
  if (!isAuthenticated) {
    return null
  }

  // Se o usuário for o proprietário do sistema, permite o acesso a todos os menus
  if (isOwner) {
    return children
  }

  const telaMinuscula = tela.toLowerCase()

  // Verifica se a tela está na lista de menus restritos
  if (RESTRICTED_MENUS.some(menu => menu.toLowerCase() === telaMinuscula)) {
    return null
  }

  let podeAcessar = false

  if (tela) {
    if (TELAS_COM_ACESSO_MODULOS.includes(telaMinuscula)) {
      // Apenas para telas específicas, define podeAcessar com base em acessoModulos
      if (acessoModulos && acessoModulos[telaMinuscula] === true) {
        podeAcessar = true
      }
    } else {
      // Para outras telas, usa a lógica de permissões existente
      if (
        nivel_acesso &&
        (nivel_acesso.toLowerCase() === 'administrador' || nivel_acesso.toLowerCase() === 'owner')
      ) {
        podeAcessar = true
      } else if (telasPermissao && telasPermissao.length > 0) {
        podeAcessar = telasPermissao.some(
          permissao =>
            permissao?.tela.toLowerCase() === telaMinuscula && permissao?.id_nivel === idNivelAcesso
        )
      }
    }

    // Se o usuário não tem permissão, não renderiza nada
    if (!podeAcessar) {
      return null
    }
  }

  // Se o usuário tem permissão, renderiza os children
  return podeAcessar ? children : null
}

export default PrivateMenuItem
