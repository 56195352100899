import React from 'react'

import { DraggableContainer } from '/src/components/DraggableContainer/draggable-container'

function Banner({
  title,
  fontSize = 80,
  fontFamily = 'Arial', // Valor padrão para a fonte
  color,
  className = '',
  theme,
  draggableId,
  components,
  width = '100%',
  classEfeito3dMouseOver = 'Efeito3dMouseOver-class',
  positionConfig,
  marginTop = '0',
  marginBottom = '0'
}) {
  const bannerClassName = theme === 'hitech' ? `golden ${className}` : className

  const content = (
    <div style={{ width: width }} className={classEfeito3dMouseOver}>
      <div className="parentClass">
        <div className={`banner ${bannerClassName}`}>
          <h3
            style={{
              fontSize: fontSize,
              fontFamily: fontFamily, // Definindo a fonte a partir da propriedade
              color: color,
              marginBottom: marginBottom,
              marginTop: marginTop
            }}
            className={`${bannerClassName}`}
          >
            {title}
          </h3>
        </div>
      </div>
    </div>
  )

  // Se draggableId for fornecido, envolve o conteúdo em DraggableContainer.
  if (draggableId !== undefined) {
    return (
      <DraggableContainer id={draggableId} components={components} positionConfig={positionConfig}>
        {content}
      </DraggableContainer>
    )
  }

  return content
}

export default Banner
