import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../../../../../src/context/AuthContext'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { CarregarMarketplaces } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraGlobal/cadastro-regra-global'

const TenantMarketplacesConfig = () => {
  const [marketplaces, setMarketplaces] = useState([])
  const { tenantId, marketplacesAtivos, setMarketplacesAtivos } = useContext(AuthContext)

  useEffect(() => {
    const carregarDados = async () => {
      try {
        const response = await CarregarMarketplaces()
        setMarketplaces(response || [])
      } catch (error) {
        console.error('Erro ao carregar os marketplaces:', error)
      }
    }
    carregarDados()
  }, [])

  const handleMarketplaceChange = async marketplaceId => {
    const marketplaceIdStr = String(marketplaceId)
    const updatedMarketplaces = marketplacesAtivos.includes(marketplaceIdStr)
      ? marketplacesAtivos.filter(id => id !== marketplaceIdStr)
      : [...marketplacesAtivos, marketplaceIdStr]

    setMarketplacesAtivos(updatedMarketplaces)

    const url = config.host + '/regraGlobalPF'
    const dados = {
      tenantId,
      marketplaces: updatedMarketplaces,
      requisicao: 'saveTenantMarketplaces'
    }

    try {
      await fetchData(url, dados)
    } catch (error) {
      console.error('Erro ao salvar configuração:', error)
    }
  }

  return (
    <div style={styles.container}>
      <Banner
        title="CONFIGURAR MARKETPLACES EM USO"
        color="#2e8b57"
        width="100%"
        fontSize={35}
        style={styles.banner}
        marginBottom="30px"
      />

      <form style={styles.form}>
        {marketplaces.map(marketplace => {
          const marketplaceIdStr = String(marketplace._id)
          return (
            <div key={marketplaceIdStr} style={styles.checkboxContainer}>
              <input
                type="checkbox"
                id={marketplaceIdStr}
                checked={marketplacesAtivos.includes(marketplaceIdStr)}
                onChange={() => handleMarketplaceChange(marketplaceIdStr)}
                style={styles.checkbox}
              />
              <label htmlFor={marketplaceIdStr} style={styles.label}>
                {marketplace.nome}
              </label>
            </div>
          )
        })}
      </form>
    </div>
  )
}

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'center'
  },
  banner: {
    margin: '0 auto',
    display: 'block'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    justifyContent: 'flex-start',
    width: '200px'
  },
  checkbox: {
    marginRight: '10px'
  },
  label: {
    fontSize: '16px'
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer'
  }
}

export default TenantMarketplacesConfig
