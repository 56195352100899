import { Card, Row, Col, OverlayTrigger, Tooltip, Table } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'

const ExplicacaoViaVarejo = () => {
  const tooltipViaVarejo = (
    <Tooltip id="tooltip-viavarejo">
      <div style={{ maxWidth: '320px', textAlign: 'justify', padding: '0.5rem' }}>
        <h5 style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>
          Detalhes das Regras – Via Varejo
        </h5>
        <p style={{ marginBottom: '0.5rem' }}>
          As condições de frete na Via Varejo variam conforme o valor do produto e o peso, além de
          depender da nota atribuída (que define o nível de subsídio aplicado).
        </p>
        <p style={{ marginBottom: 0 }}>
          Confira abaixo as condições para produtos com valor até R$ 69,90 e para compras acima
          desse valor.
        </p>
      </div>
    </Tooltip>
  )

  return (
    <div>
      <h4 className="d-flex align-items-center">
        Via Varejo
        <OverlayTrigger placement="right" overlay={tooltipViaVarejo}>
          <span style={{ cursor: 'pointer', marginLeft: '0.5rem' }}>
            <FaInfoCircle size={18} color="#0000FF" />
          </span>
        </OverlayTrigger>
      </h4>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={6}>
              <FreteParaProdutosAte69990 />
            </Col>
            <Col md={6}>
              <FreteParaComprasAcimaDe69990 />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ExplicacaoViaVarejo

// Para produtos com valor até R$ 69,90
const FreteParaProdutosAte69990 = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Produtos até R$ 69,90</Card.Title>
        <Card.Text>
          Para produtos com valor de até R$ 69,90, a tarifa é definida com base no peso,
          aplicando-se a seguinte condição:
        </Card.Text>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Peso (g)</th>
              <th>Tarifa</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 a 30</td>
              <td>R$ 0,00</td>
            </tr>
          </tbody>
        </Table>
        <Card.Text>Esta condição é válida para todos os estados de origem.</Card.Text>
      </Card.Body>
    </Card>
  )
}

// Para produtos com valor acima de R$ 69,90
const FreteParaComprasAcimaDe69990 = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Compras acima de R$ 69,90</Card.Title>
        <Card.Text>
          Para produtos com valor superior a R$ 69,90, o frete é calculado conforme a faixa de peso
          e o nível de subsídio, definido pela nota atribuída:
        </Card.Text>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th rowSpan="2">Faixa de Peso (g)</th>
              <th colSpan="3" style={{ textAlign: 'center' }}>
                Tarifa (R$)
              </th>
            </tr>
            <tr>
              <th>Nota 1 a 3,9</th>
              <th>Nota 4 a 4,9</th>
              <th>Nota 5</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 a 499</td>
              <td>R$ 29,00</td>
              <td>R$ 17,40</td>
              <td>R$ 14,50</td>
            </tr>
            <tr>
              <td>501 a 999</td>
              <td>R$ 32,00</td>
              <td>R$ 19,20</td>
              <td>R$ 16,00</td>
            </tr>
            <tr>
              <td>1000 a 2000</td>
              <td>R$ 34,00</td>
              <td>R$ 20,40</td>
              <td>R$ 17,00</td>
            </tr>
            <tr>
              <td>2001 a 5000</td>
              <td>R$ 42,00</td>
              <td>R$ 25,20</td>
              <td>R$ 21,00</td>
            </tr>
          </tbody>
        </Table>
        <Card.Text>
          As colunas representam:
          <br />• <strong>Nota 1 a 3,9:</strong> sem subsídio.
          <br />• <strong>Nota 4 a 4,9:</strong> com subsídio de 40%.
          <br />• <strong>Nota 5:</strong> com subsídio de 50%.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}
