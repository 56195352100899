// MargemColorConfigurator.jsx
import { useState, useEffect } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import { ChromePicker } from 'react-color'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'

const MargemColorConfigurator = ({ tenantId, initialConfig, onSave }) => {
  // Estados iniciais baseados na configuração passada ou valores padrão
  const [highThreshold, setHighThreshold] = useState(initialConfig?.highThreshold || 20)
  const [highColor, setHighColor] = useState(initialConfig?.highColor || '#00FF00')
  const [lowThreshold, setLowThreshold] = useState(initialConfig?.lowThreshold || 5)
  const [lowColor, setLowColor] = useState(initialConfig?.lowColor || '#FF0000')

  // Estados para exibir os pickers de cor
  const [displayHighPicker, setDisplayHighPicker] = useState(false)
  const [displayLowPicker, setDisplayLowPicker] = useState(false)

  // Estado para feedback
  const [message, setMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')

  // Atualiza os estados internos se initialConfig mudar
  useEffect(() => {
    if (initialConfig) {
      setHighThreshold(initialConfig.highThreshold)
      setHighColor(initialConfig.highColor)
      setLowThreshold(initialConfig.lowThreshold)
      setLowColor(initialConfig.lowColor)
    }
  }, [initialConfig])

  // Função para salvar a configuração no servidor:
  const handleSave = async () => {
    // Monta o payload
    const payload = {
      tenantId,
      highThreshold,
      highColor,
      lowThreshold,
      lowColor
    }
    try {
      let response
      // Se já existir uma configuração (identificada por _id), atualiza; caso contrário, cria nova.
      if (initialConfig && initialConfig._id) {
        payload.id = initialConfig._id
        payload.requisicao = 'updateMargemConfigColorPF'
        response = await fetchData(`${config.host}/simulacaoPF`, payload)
      } else {
        payload.requisicao = 'createMargemConfigColorPF'
        response = await fetchData(`${config.host}/simulacaoPF`, payload)
      }

      if (response.success) {
        setMessage('Configuração salva com sucesso!')
        setAlertVariant('success')
        // Chama a callback onSave para que o componente pai possa atualizar o estado global
        if (onSave) onSave(response.data)
      } else {
        setMessage(response.message || 'Erro ao salvar configuração.')
        setAlertVariant('danger')
      }
    } catch (error) {
      setMessage(error.message)
      setAlertVariant('danger')
    }
  }

  return (
    <div
      style={{
        padding: '1rem',
        border: '1px solid #ccc',
        borderRadius: '5px',
        maxWidth: '400px'
      }}
    >
      {message && <Alert variant={alertVariant}>{message}</Alert>}
      <Form>
        <Form.Group>
          <Form.Label>Margem ≥</Form.Label>
          <Form.Control
            type="number"
            value={highThreshold}
            onChange={e => setHighThreshold(Number(e.target.value))}
          />
          <div style={{ marginTop: '0.5rem' }}>
            <Button variant="light" onClick={() => setDisplayHighPicker(!displayHighPicker)}>
              Selecionar cor{' '}
              <span
                style={{
                  display: 'inline-block',
                  width: '20px',
                  height: '20px',
                  backgroundColor: highColor,
                  marginLeft: '0.5rem',
                  border: '1px solid #ccc'
                }}
              />
            </Button>
            {displayHighPicker && (
              <div style={{ position: 'absolute', zIndex: 2 }}>
                <ChromePicker
                  color={highColor}
                  onChangeComplete={color => setHighColor(color.hex)}
                />
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => setDisplayHighPicker(false)}
                  style={{ marginTop: '0.5rem' }}
                >
                  Fechar
                </Button>
              </div>
            )}
          </div>
        </Form.Group>

        <Form.Group style={{ marginTop: '1rem' }}>
          <Form.Label>Margem ≤</Form.Label>
          <Form.Control
            type="number"
            value={lowThreshold}
            onChange={e => setLowThreshold(Number(e.target.value))}
          />
          <div style={{ marginTop: '0.5rem' }}>
            <Button variant="light" onClick={() => setDisplayLowPicker(!displayLowPicker)}>
              Selecionar cor{' '}
              <span
                style={{
                  display: 'inline-block',
                  width: '20px',
                  height: '20px',
                  backgroundColor: lowColor,
                  marginLeft: '0.5rem',
                  border: '1px solid #ccc'
                }}
              />
            </Button>

            {displayLowPicker && (
              <div style={{ position: 'absolute', zIndex: 2 }}>
                <ChromePicker color={lowColor} onChangeComplete={color => setLowColor(color.hex)} />
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => setDisplayLowPicker(false)}
                  style={{ marginTop: '0.5rem' }}
                >
                  Fechar
                </Button>
              </div>
            )}
          </div>
        </Form.Group>
        <Button variant="primary" onClick={handleSave} style={{ marginTop: '1rem' }}>
          Salvar Configuração
        </Button>
      </Form>
    </div>
  )
}

export default MargemColorConfigurator
