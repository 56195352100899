import { Card, Row, Col, OverlayTrigger, Tooltip, Table } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'

const ExplicacaoMagalu = () => {
  const tooltipMagalu = (
    <Tooltip id="tooltip-magalu">
      <div style={{ maxWidth: '320px', textAlign: 'justify', padding: '0.5rem' }}>
        <h5 style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Detalhes das Regras – Magalu</h5>
        <p style={{ marginBottom: '0.5rem' }}>
          Essas regras definem o cálculo do frete para produtos vendidos na Magalu, considerando o
          valor do produto, o peso e a classificação do vendedor.
        </p>
        <p style={{ marginBottom: 0 }}>
          Vendedores Premium podem receber um desconto adicional sobre a tarifa base de frete.
        </p>
      </div>
    </Tooltip>
  )

  return (
    <div>
      <h4 className="d-flex align-items-center">
        Magalu
        <OverlayTrigger placement="right" overlay={tooltipMagalu}>
          <span style={{ cursor: 'pointer', marginLeft: '0.5rem' }}>
            <FaInfoCircle size={18} color="#2AB598" />
          </span>
        </OverlayTrigger>
      </h4>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={4}>
              <FaixasDePesoMagalu />
            </Col>
            <Col md={4}>
              <ValorMinimoProdutoMagalu />
            </Col>
            <Col md={4}>
              <DescontoNoFreteMagalu />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <ClassificacaoVendedorMagalu />
            </Col>
            <Col md={6}>
              <CriteriosAdicionaisMagalu />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ExplicacaoMagalu

const FaixasDePesoMagalu = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Faixas de Peso e Tarifa</Card.Title>
        <Card.Text>
          Para produtos a partir de R$ 79,00, o frete é calculado com base no peso do produto. A
          tabela a seguir apresenta a tarifa base para SP-Zona 1:
        </Card.Text>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Faixa de Peso (g)</th>
              <th>Tarifa Base</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0 a 299</td>
              <td>R$ 18,50</td>
            </tr>
            <tr>
              <td>300 a 599</td>
              <td>R$ 20,00</td>
            </tr>
            <tr>
              <td>600 a 999</td>
              <td>R$ 21,50</td>
            </tr>
            <tr>
              <td>1000 a 1999</td>
              <td>R$ 23,00</td>
            </tr>
            <tr>
              <td>2000 a 2999</td>
              <td>R$ 24,00</td>
            </tr>
            <tr>
              <td>3000 a 3999</td>
              <td>R$ 26,00</td>
            </tr>
            <tr>
              <td>4000 a 4999</td>
              <td>R$ 28,00</td>
            </tr>
            <tr>
              <td>5000 a 5999</td>
              <td>R$ 35,00</td>
            </tr>
            <tr>
              <td>6000 a 6999</td>
              <td>R$ 38,00</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

const ValorMinimoProdutoMagalu = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Valor Mínimo do Produto</Card.Title>
        <Card.Text>
          Para que a tarifa baseada no peso seja aplicada, o produto deve ter um valor mínimo de R$
          79,00. Produtos com valor inferior a esse limite não se qualificam para o cálculo do frete
          com tarifa variável.
        </Card.Text>
        <Card.Text>
          Essa regra garante que o frete seja calculado de forma sustentável, evitando subsídios
          excessivos para produtos de baixo valor.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

const DescontoNoFreteMagalu = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Desconto no Frete</Card.Title>
        <Card.Text>
          Na Magalu, após calcular a tarifa base de frete com base no peso do produto, pode ser
          aplicado um desconto adicional dependendo da classificação do vendedor.
        </Card.Text>
        <Card.Text>
          <strong>Mecanismo de Desconto:</strong>
          <br />
          Vendedores com classificação <strong>Premium</strong> recebem um desconto de 20% sobre a
          tarifa base. Vendedores classificados como <strong>Regular</strong> utilizam o valor
          integral da tarifa.
        </Card.Text>
        <Card.Text>
          Exemplo: Se a tarifa base calculada for R$ 23,00 e o vendedor for Premium, o frete final
          será de R$ 18,40.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

const ClassificacaoVendedorMagalu = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Classificação do Vendedor</Card.Title>
        <Card.Text>
          A classificação do vendedor na Magalu é definida com base em indicadores de desempenho,
          como qualidade do atendimento, pontualidade e avaliações dos clientes.
        </Card.Text>
        <Card.Text>
          <ul>
            <li>
              <strong>Premium:</strong> Vendedores com desempenho excepcional, elegíveis para um
              desconto adicional de 20% no frete.
            </li>
            <li>
              <strong>Regular:</strong> Vendedores com desempenho padrão, sem desconto extra sobre a
              tarifa base.
            </li>
          </ul>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

const CriteriosAdicionaisMagalu = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Critérios Adicionais</Card.Title>
        <Card.Text>
          Além do valor mínimo do produto e do peso, outros fatores podem influenciar o cálculo do
          frete na Magalu:
        </Card.Text>
        <Card.Text>
          <strong>Logística:</strong> A localização do vendedor e a eficiência da cadeia de
          distribuição podem reduzir os custos de envio.
        </Card.Text>
        <Card.Text>
          <strong>Promoções e Estratégias:</strong> Campanhas promocionais e ajustes sazonais podem
          alterar temporariamente as tarifas.
        </Card.Text>
        <Card.Text>
          Essa combinação de critérios permite que o frete seja calculado de forma dinâmica,
          alinhando competitividade e sustentabilidade.
        </Card.Text>
      </Card.Body>
    </Card>
  )
}
