//routePrecoFacilMarket

import { Routes, Route } from 'react-router-dom'
import Home from '../../PRECO_FACIL_MARKET/src/components/pages/Home/index'
import Inicio from '../../PRECO_FACIL_MARKET/src/components/pages/Inicio/inicio'
import UserRegistration from '../components/pages/Usuarios/usuarios'
import ListaProdutosMercadoLivre from '../components/pages/Marketplaces/MercadoLivre/Produtos/listagem'
import CadastroProduto from '../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CadastroProduto/cadastro-produto'
import EditarProduto from '../../PRECO_FACIL_MARKET/src/components/pages/Produtos/EdicaoProduto/edicao-produto'
import CadastroCategoria from '../../PRECO_FACIL_MARKET/src/components/pages/Categorias/CadastroCategoria/cadastro-categoria'
import EditarCategoria from '../../PRECO_FACIL_MARKET/src/components/pages/Categorias/EdicaoCategoria/edicao-categoria'
import AccessLevelRegistration from '../components/pages/Usuarios/NivelAcesso/nivel_acesso'
import IpRegistration from '../components/pages/Usuarios/login/GerenciadorDeIPs'
import ConfiguracaoUsuarioPrecoFacilMarket from '../../PRECO_FACIL_MARKET/src/components/pages/Usuarios/NivelAcesso/config_niveis_acesso'
import VisualizarTenants from '../components/pages/Tenant/visualizarTenants.jsx'
import AcessoNegado from '../components/pages/Usuarios/login/AcessoNegado'
import CadastroSubSistema from '../components/pages/CadastroSubSistema/cadastro-sub-sistema'
import PrecoFacilMarketLogin from '../../PRECO_FACIL_MARKET/src/components/pages/Usuarios/login/login'
import PrecoFacilMarketHome from '../../PRECO_FACIL_MARKET/src/components/pages/Home/index'
import CadastroMarketPlace from '../../PRECO_FACIL_MARKET/src/components/pages/Marketplaces/CadastroMarketplace/cadastro-marketplace'
import EditarMarketplace from '../../PRECO_FACIL_MARKET/src/components/pages/Marketplaces/EdicaoMarketplace/edicao-marketplace'
import CadastroRegrasDescontoFrete from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/regra-frete'
import CadastroRegrasPesoFrete from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/regras-peso-frete.jsx'
import RegraMagalu from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraMagalu/regra-magalu'
import CadastroRegrasFreteAmVv from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/cadastro-regras-freteAmVv'
import ModelagemCompletaTenants from '../components/pages/Tenant/tenant'
import CalculoPrecoPF from '../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/calculo-precoPF.jsx'
import CadastroRegraDescontoFreteML from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/cadastro-regras-freteML'
import ConfiguracaoRegrasFreteMercadoLivre from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegrasMercadoLivre/config_regras_frete_ML'
import SelecionarGrupoVendedor from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/ConfigRegraAmericanas/config-regra-americanas'
import CadastroValorImposto from '../../PRECO_FACIL_MARKET/src/components/pages/CadastroImposto/cadastro-imposto'
import TenantMarketplacesConfig from '../../PRECO_FACIL_MARKET/src/components/pages/Marketplaces/TenantMarktplaceConfig/tenant-marketplace-config'
import PlanosDetalhesPF from '../../PRECO_FACIL_MARKET/src/components/pages/Home/plano-detalhes'
import PasswordRecovery from '../components/pages/Usuarios/login/PasswordRecovery.jsx'
import ResetarSenha from '../components/pages/Usuarios/login/ResetarSenha.jsx'
import SelectPlan from '../components/pages/Payments/SelectPlan.jsx'
import PaymentSuccess from '../components/pages/Payments/PaymentSuccess.jsx'
import PaymentFailure from '../components/pages/Payments/PaymentFailure.jsx'
import PaymentPending from '../components/pages/Payments/PaymentPending.jsx'
import EmailClient from '../components/pages/EmailClient/email-client.jsx'
import { PrivateRoute } from './routeModelagemCompleta.jsx'
import SubscriptionPricingPanel from '../components/pages/SubscriptionPricingPanel/SubscriptionPricingPanel.jsx'
import CadastroRegraFreteShopee from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/CadastroRegraFreteShopee/CadastroRegraFreteShopee.jsx'
import ConfigurarValorMinFreteML from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegrasMercadoLivre/configurar-valor-min-frete-ml.jsx'
import EditorRegrasPesoFreteML from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/EditorRegrasPesoFreteML/EditorRegrasPesoFreteML.jsx'
import GerenciarRegrasFreteAmericanasViaVarejo from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/RegrasFreteAmericanaViaVarejo/regras-frete-americanas-viavarejo.jsx'
import GerenciarNiveisVendedorAmericanasViaVarejo from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/RegrasFreteAmericanaViaVarejo/niveis-vendedor-americanas-viavarejo.jsx'
import PrecosVenda from '../../PRECO_FACIL_MARKET/src/components/pages/Produtos/PrecosVenda/precos-venda'
import AlterarDados from '../components/pages/Usuarios/AlterarDados.jsx'
import AlterarSenha from '../components/pages/Usuarios/AlterarSenha.jsx'
import FaleConosco from '../components/pages/Home/FaleConosco.jsx'
import ExplicativoRegrasGerais from '../../PRECO_FACIL_MARKET/src/components/pages/Explicativo/regrasGerais/ExplicativoRegrasGerais.jsx'
//------------------------------------------------------------
function AppRoutes() {
  return (
    <Routes>
      {/* Rotas públicas */}

      <Route path="/acesso-negado" element={<AcessoNegado />} />
      <Route path="/login" element={<PrecoFacilMarketLogin />} />
      <Route
        path="/cadastro-tenant"
        element={
          <ModelagemCompletaTenants
            sistema="precofacilmarket"
            titulo="Maximize seus lucros com precificação inteligente!"
            descricao1="Defina os preços ideais para impulsionar suas vendas em diferentes plataformas."
            descricao2="Nosso sistema analítico coloca a estratégia de precificação inteligente a seu alcance, impulsionando o crescimento de seu negócio em várias plataformas de vendas online."
          />
        }
      />
      <Route path="/" element={<PrecoFacilMarketHome />} />
      <Route path="/home" element={<PrecoFacilMarketHome />} />
      <Route path="/precofacilmarket" element={<PrecoFacilMarketHome />} />

      {/* Rotas protegidas */}

      <Route
        path="/cadastro-sub-sistema"
        element={
          <PrivateRoute tela="Cadastro de Sub Sistemas">
            <CadastroSubSistema />
          </PrivateRoute>
        }
      />

      <Route
        path="/configuracao-usuario-precofacilmarket"
        element={
          <PrivateRoute tela="Configuração de Níveis de Acesso">
            <ConfiguracaoUsuarioPrecoFacilMarket />
          </PrivateRoute>
        }
      />

      <Route
        path="/gerenciador-de-ips"
        element={
          <PrivateRoute tela="GERENCIAR IPS DE ACESSO">
            <IpRegistration />
          </PrivateRoute>
        }
      />

      <Route
        // path="*"
        element={
          <PrivateRoute tela="Início">
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/inicio-pf"
        element={
          <PrivateRoute tela="Início">
            <Inicio />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute tela="Início">
            <Inicio />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-nivel-acesso"
        element={
          <PrivateRoute tela="CADASTRAR NIVEL ACESSO">
            <AccessLevelRegistration />
          </PrivateRoute>
        }
      />

      <Route
        path="/cadastro-usuario"
        element={
          <PrivateRoute tela="CADASTRAR USUÁRIO">
            <UserRegistration />
          </PrivateRoute>
        }
      />

      <Route
        path="/cadastro-categoria"
        element={
          <PrivateRoute tela="CADASTRAR CATEGORIA">
            <CadastroCategoria />
          </PrivateRoute>
        }
      />
      <Route
        path="/edicao-categoria"
        element={
          <PrivateRoute tela="GERENCIAR CATEGORIA">
            <EditarCategoria />
          </PrivateRoute>
        }
      />

      <Route
        path="/listagem-produtos-mercadolivre"
        element={
          <PrivateRoute tela="Listagem Produtos Mercado Livre">
            <ListaProdutosMercadoLivre />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-regras-freteML"
        element={
          // <PrivateRoute tela="Cadastro de Regras de Frete Mercado Livre">
          <CadastroRegraDescontoFreteML />
          // </PrivateRoute>
        }
      />
      <Route path="/config-regras-frete-ML" element={<ConfiguracaoRegrasFreteMercadoLivre />} />

      <Route
        path="/calculo-precoPF"
        element={
          <PrivateRoute tela="CALCULO DE PREÇO">
            <CalculoPrecoPF />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-produto"
        element={
          <PrivateRoute tela="CADASTRO BASE">
            <CadastroProduto />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-produto"
        element={
          <PrivateRoute tela="GERENCIAR CADASTRO BASE">
            <EditarProduto />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-marketplace"
        element={
          <PrivateRoute tela="Cadastro de Marketplace">
            <CadastroMarketPlace />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-marketplace"
        element={
          <PrivateRoute tela="Editar Marketplace">
            <EditarMarketplace />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-regra-desconto-frete"
        element={
          <PrivateRoute tela="Cadastro de Regra">
            {/* referente a MAGALU */}
            <CadastroRegrasDescontoFrete />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-regra-peso-frete/:marketplace"
        // path='/cadastro-regra-peso-frete'
        element={
          <PrivateRoute tela="Cadastro de Regra">
            {/* referente a MAGALU */}
            <CadastroRegrasPesoFrete />
          </PrivateRoute>
        }
      />

      <Route
        path="/regra-magalu"
        element={
          <PrivateRoute tela="Regra Magalu">
            <RegraMagalu />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-regra-freteAmVv/:marketplace"
        element={
          <PrivateRoute tela="Cadastro de Regra">
            <CadastroRegrasFreteAmVv />
          </PrivateRoute>
        }
      />
      <Route
        path="/config-regras-frete-americanas/:marketplace"
        element={
          <PrivateRoute tela="Configuração de Regras Americanas">
            <SelecionarGrupoVendedor />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-imposto"
        element={
          <PrivateRoute tela="CADASTRAR IMPOSTO">
            <CadastroValorImposto />
          </PrivateRoute>
        }
      />
      <Route
        path="/tenant-marketplaces-config"
        element={
          // <PrivateRoute tela="Configuração de Marketplaces">
          <TenantMarketplacesConfig />
          // </PrivateRoute>
        }
      />

      <Route
        path="/planos-detalhes-pf"
        element={
          // <PrivateRoute tela="Planos e Preços">
          <PlanosDetalhesPF />
          // </PrivateRoute>
        }
      />
      <Route
        path="/assinar-plano"
        element={
          <PrivateRoute tela="Assinar Plano">
            <SelectPlan sistema="precofacilmarket" />
          </PrivateRoute>
        }
      />

      <Route
        path="/precofacil/payment-success"
        element={<PaymentSuccess systemName="Preço Fácil Market" />}
      />
      <Route
        path="/precofacil/payment-failure"
        element={<PaymentFailure systemName="Preço Fácil Market" />}
      />
      <Route
        path="/precofacil/payment-pending"
        element={<PaymentPending systemName="Preço Fácil Market" />}
      />
      <Route path="/email-client" element={<EmailClient />} />
      <Route
        path="/visualizar-tenants"
        element={
          //apenas o owner pode acessar
          <PrivateRoute tela="Usuarios cadastrados">
            <VisualizarTenants />
          </PrivateRoute>
        }
      />
      <Route path="/subscription-pricing-panel" element={<SubscriptionPricingPanel />} />
      <Route path="/cadastro-regra-freteShopee" element={<CadastroRegraFreteShopee />} />
      <Route path="/configurar-valor-min-frete-ml" element={<ConfigurarValorMinFreteML />} />
      <Route path="/editor-regras-peso-frete-ml" element={<EditorRegrasPesoFreteML />} />
      <Route
        path="/gerenciar-regras-frete-americanas-viavarejo/:marketplace"
        element={<GerenciarRegrasFreteAmericanasViaVarejo />}
      />
      <Route
        path="/gerenciar-niveis-vendedor-americanas-viavarejo/:marketplace"
        element={<GerenciarNiveisVendedorAmericanasViaVarejo />}
      />
      <Route
        path="/precos-venda"
        element={
          <PrivateRoute tela="PRECOS DE VENDA">
            <PrecosVenda />
          </PrivateRoute>
        }
      />
      <Route path="/password-recovery/:sistem" element={<PasswordRecovery />} />
      <Route path="/resetar-senha/:sistem/:token" element={<ResetarSenha />} />
      <Route
        path="/alterar-dados"
        element={
          <PrivateRoute tela="Alterar Dados">
            <AlterarDados />
          </PrivateRoute>
        }
      />
      <Route
        path="/alterar-senha"
        element={
          <PrivateRoute tela="Alterar Senha">
            <AlterarSenha />
          </PrivateRoute>
        }
      />
      <Route
        path="/fale-conosco"
        element={
          // <PrivateRoute tela="Fale Conosco">
          <FaleConosco />
          // </PrivateRoute>
        }
      />
      <Route path="/explicativo-regras-gerais" element={<ExplicativoRegrasGerais />} />
    </Routes>
  )
}

export default AppRoutes
