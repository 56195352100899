import { useState, useEffect } from 'react'
import { Container, Form } from 'react-bootstrap'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils.jsx'
import { fetchData } from '../../../../../../src/backend/model'

import { config } from '../../../../../../src/backend/configConexaoGeral'
import { getNiveisDeAcesso } from '../../../../../../src/components/pages/Usuarios/usuarios'
import {
  RetornarTodosNiveis,
  atualizarCheckboxesComDados,
  nomeDoLabel,
  nomeDoCheckbox,
  renderCheckboxes,
  GravarNivelAcesso,
  DeletarNivelAcesso
} from '../../../../../../src/components/pages/Usuarios/NivelAcesso/config_niveis_acesso'
import { AuthContext } from '/src/context/AuthContext'
import { useContext } from 'react'

const ConfiguracaoUsuarioPrecoFacilMarket = () => {
  const [niveisDeAcesso, setNiveisDeAcesso] = useState([])
  const [nivelDeAcessoSelecionado, setNivelDeAcessoSelecionado] = useState('')
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [idsNiveisAcesso, setIdsNiveisAcesso] = useState({})
  const [responseData, setResponseData] = useState(null)
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [labels, setLabels] = useState({
    usuario: 'Usuário',
    cadastroUsuario: 'Cadastro de Usuário',
    cadastrarNiveisAcesso: 'Cadastrar Níveis Acesso',
    configuracaoNiveisAcesso: 'Configuração de Níveis de Acesso',
    inicio: 'Início',
    ipsDeAcesso: 'Gerenciar IPs de Acesso',
    produto: 'Produto',
    cadastroProduto: 'Cadastro de Produto',
    gerenciarCadastroBase: 'GERENCIAR CADASTRO BASE',
    categoria: 'Categoria',
    cadastroCategoria: 'Cadastro de Categoria',
    editarCategoria: 'Editar Categoria',
    marketplace: 'Marketplace',
    cadastroMarketplace: 'Cadastro de Marketplace',
    editarMarketplace: 'Editar Marketplace',
    regras: 'Regras',
    cadastroRegra: 'Cadastro de Regra',
    editarRegra: 'Editar Regra',
    regraMagalu: 'Regra Magalu',
    calculoPreco: 'Calculo de Preço',
    cadastroBase: 'Cadastro Base',
    precosDeVenda: 'Preços de Venda',
    gerenciarCategoria: 'GERENCIAR CATEGORIA',
    assinarPlano: 'ASSINAR PLANO',
    emailClient: 'EMAIL CLIENT',
    configurarMarketplacesEmUso: 'CONFIGURAR MARKETPLACES EM USO',
    cadastrarImposto: 'CADASTRAR IMPOSTO',
    imposto: 'IMPOSTO'
  })

  const [checkboxes, setCheckboxes] = useState({
    usuario: false,
    cadastroUsuario: false,
    cadastrarNiveisAcesso: false,
    configuracaoNiveisAcesso: false,
    inicio: false,
    ipsDeAcesso: false,
    produto: false,
    cadastroProduto: false,
    gerenciarCadastroBase: false,
    categoria: false,
    cadastroCategoria: false,
    editarCategoria: false,
    marketplace: false,
    cadastroMarketplace: false,
    editarMarketplace: false,
    regras: false,
    cadastroRegra: false,
    editarRegra: false,
    regraMagalu: false,
    calculoPreco: false,
    cadastroBase: false,
    precosDeVenda: false,
    gerenciarCategoria: false,
    assinarPlano: false,
    emailClient: false,
    configurarMarketplacesEmUso: false,
    cadastrarImposto: false,
    imposto: false
  })
  const names = [
    'usuario',
    'cadastroUsuario',
    'cadastrarNiveisAcesso',
    'configuracaoNiveisAcesso',
    'inicio',
    'ipsDeAcesso',
    'produto',
    'cadastroProduto',
    'gerenciarCadastroBase',
    'categoria',
    'cadastroCategoria',
    'editarCategoria',
    'marketplace',
    'cadastroMarketplace',
    'editarMarketplace',
    'regras',
    'cadastroRegra',
    'editarRegra',
    'regraMagalu',
    'calculoPreco',
    'cadastroBase',
    'precosDeVenda',
    'gerenciarCategoria',
    'assinarPlano',
    'emailClient',
    'configurarMarketplacesEmUso',
    'cadastrarImposto',
    'imposto'
  ]

  const titles = [
    'Usuário',
    'Cadastro de Usuário',
    'CADASTRAR NIVEL ACESSO',
    'Configuração Níveis de Acesso',
    'Início',
    'Gerenciar IPs de Acesso',
    'Produto',
    'Cadastro de Produto',
    'GERENCIAR CADASTRO BASE',
    'Categoria',
    'Cadastro de Categoria',
    'Editar Categoria',
    'Marketplace',
    'Cadastro de Marketplace',
    'Editar Marketplace',
    'Regras',
    'Cadastro de Regra',
    'Editar Regra',
    'Regra Magalu',
    'Calculo de Preço',
    'CADASTRO BASE',
    'PRECOS DE VENDA',
    'GERENCIAR CATEGORIA',
    'Assinar Plano',
    'Email Client',
    'CONFIGURAR MARKETPLACES EM USO',
    'CADASTRAR IMPOSTO',
    'Imposto'
  ]

  useEffect(() => {
    if (responseData && responseData.length > 0) {
      atualizarCheckboxesComDados(
        responseData,
        checkboxes,
        setCheckboxes,
        setIdsNiveisAcesso,
        labels
      )
    }
  }, [responseData])

  useEffect(() => {
    const carregarNiveisDeAcesso = async () => {
      getNiveisDeAcesso(setNiveisDeAcesso, RotaBase)
    }
    carregarNiveisDeAcesso()
  }, [])

  const handleChange = async e => {
    const nivelSelecionado = e.target.value
    setNivelDeAcessoSelecionado(nivelSelecionado)

    // Desmarca todos os checkboxes ao mudar o nível de acesso
    const checkboxesDesmarcados = Object.keys(checkboxes).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {})
    setCheckboxes(checkboxesDesmarcados)

    if (nivelSelecionado) {
      const response = await RetornarTodosNiveis(nivelSelecionado, RotaBase, tenantId)

      setResponseData(response) // Armazenar a resposta para uso no useEffect
    }
  }

  const handleCheckboxChange = e => {
    if (nivelDeAcessoSelecionado === '') {
      setAlertMessage('Selecione um nível de acesso!')
      setShowAlertMessage(true)
      return
    }
    const { name, checked } = e.target

    setCheckboxes({ ...checkboxes, [name]: checked })

    // Verifica se o checkbox foi marcado ou desmarcado
    if (checked) {
      GravarNivelAcesso(name, labels, RotaBase, nivelDeAcessoSelecionado, tenantId)
    } else {
      const idParaDeletar = idsNiveisAcesso[name]
      if (idParaDeletar) {
        DeletarNivelAcesso(idParaDeletar)
      }
    }
  }

  return (
    <>
      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true} // ou algum outro critério que deseja usar para blur
        setShowAlert={setShowAlertMessage}
      />
      <Container>
        <Banner title="Configuração de nível de acesso" fontSize="60px" width="73%" />

        <Form.Control
          as="select"
          style={{ width: '30%', marginTop: '20px', fontSize: '1.25rem' }}
          value={nivelDeAcessoSelecionado}
          onChange={handleChange}
        >
          <option value="">Selecione um Nível de Acesso</option>
          {niveisDeAcesso.map(nivel => (
            <option key={nivel.value} value={nivel.value}>
              {nivel.label}
            </option>
          ))}
        </Form.Control>

        <div className="checkbox-container">
          <div className="checkboxes-container">
            <div className="checkbox-blockPF">
              <Banner title="Páginas do sistema:" fontSize="30px" width="100%" />
              {renderCheckboxes(
                0,
                10,
                nomeDoCheckbox,
                nomeDoLabel,
                checkboxes,
                handleCheckboxChange,
                titles,
                names
              )}
            </div>
            <div className="checkbox-blockPF">
              <Banner title="Páginas do sistema:" fontSize="30px" width="100%" />
              {renderCheckboxes(
                11,
                20,
                nomeDoCheckbox,
                nomeDoLabel,
                checkboxes,
                handleCheckboxChange,
                titles,
                names
              )}
            </div>
            <div className="checkbox-blockPF">
              <Banner title="Páginas do sistema:" fontSize="30px" width="100%" />
              {renderCheckboxes(
                21,
                27,
                nomeDoCheckbox,
                nomeDoLabel,
                checkboxes,
                handleCheckboxChange,
                titles,
                names
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ConfiguracaoUsuarioPrecoFacilMarket
