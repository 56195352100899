// SubscriptionPricingPanel.jsx
import { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Card, Form, Button, Spinner, InputGroup } from 'react-bootstrap'
import { fetchData } from '../../../backend/model.js'
import { config } from '../../../backend/configConexaoGeral.js'
import { ShowAlertGeneric } from '../../../utils/form-utils.jsx'
import { AuthContext } from '../../../context/AuthContext.jsx'

const SubscriptionPricingPanel = () => {
  // Array estático com os sistemas
  const SYSTEMS = [
    { id: 'corte', nome: 'Corte' },
    { id: 'plotagem', nome: 'Plotagem' },
    { id: 'modelagem', nome: 'Modelagem' },
    { id: 'relogiodeponto', nome: 'Relógio de Ponto' },
    { id: 'precofacilmarket', nome: 'Preço Fácil Market' }
    // Adicione outros sistemas conforme necessário
  ]

  // Obtém tenantId e RotaBase do contexto de autenticação
  const { RotaBase, tenantId } = useContext(AuthContext)

  // Estado para o sistema selecionado. Por padrão, o primeiro sistema é selecionado.
  const [selectedSystem, setSelectedSystem] = useState(SYSTEMS[0].id)
  // Estado para armazenar os valores da configuração do sistema selecionado
  const [pricingConfig, setPricingConfig] = useState({
    valorMensal: '',
    descontoTrimestral: '',
    descontoSemestral: '',
    descontoAnual: '',
    periodoGratuito: false,
    diasPeriodoGratuito: 0
  })
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('success') // Pode ser 'success' ou 'danger'

  // Função auxiliar para mapear o id do sistema para o sufixo usado no schema
  const getSuffix = system => {
    switch (system) {
      case 'corte':
        return 'Corte'
      case 'plotagem':
        return 'Plotagem'
      case 'modelagem':
        return 'Modelagem'
      case 'relogiodeponto':
        return 'RelogioDePonto'
      case 'precofacilmarket':
        return 'PrecoFacilMarket'
      default:
        return ''
    }
  }

  // Buscar a configuração de preços para o sistema selecionado
  useEffect(() => {
    const fetchConfig = async () => {
      setLoading(true)
      try {
        const url = `${config.host}/configuracoesGerais`
        console.log('selectedSystem', selectedSystem)
        const dados = {
          rota_base: RotaBase,
          system: selectedSystem,
          requisicao: 'getSubscriptionPricing'
        }

        const response = await fetchData(url, dados)

        if (response.success && response.data) {
          setPricingConfig({
            valorMensal: response.data.valorMensal || '',
            descontoTrimestral: response.data.descontoTrimestral || '',
            descontoSemestral: response.data.descontoSemestral || '',
            descontoAnual: response.data.descontoAnual || '',
            periodoGratuito: response.data.temPeriodoGratuito || false, // Agora pega de TrialPeriod
            diasPeriodoGratuito: response.data.diasPeriodoGratuito || 0 // Agora pega de TrialPeriod
          })
        } else {
          // Se não houver configuração, limpa ou define os valores padrão
          setPricingConfig({
            valorMensal: '',
            descontoTrimestral: '',
            descontoSemestral: '',
            descontoAnual: '',
            periodoGratuito: false, // Resetar para falso se não houver dado
            diasPeriodoGratuito: 0
          })
        }
      } catch (error) {
        console.error('Erro ao buscar configuração:', error)
        setPricingConfig({
          valorMensal: '',
          descontoTrimestral: '',
          descontoSemestral: '',
          descontoAnual: '',
          periodoGratuito: false, // Resetar para falso se não houver dado
          diasPeriodoGratuito: 0
        })
      } finally {
        setLoading(false)
      }
    }

    fetchConfig()
  }, [selectedSystem, tenantId, RotaBase])

  // Manipulador da mudança no select de sistemas
  const handleSystemChange = e => {
    setSelectedSystem(e.target.value)
  }

  // Manipulador para as alterações dos campos do formulário
  const handleInputChange = e => {
    const { name, value } = e.target
    setPricingConfig(prevConfig => ({
      ...prevConfig,
      [name]: value
    }))
  }

  // Envia a configuração para a API
  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const url = `${config.host}/configuracoesGerais`
      const dados = {
        rota_base: RotaBase,
        system: selectedSystem,
        valorMensal: pricingConfig.valorMensal,
        descontoTrimestral: pricingConfig.descontoTrimestral,
        descontoSemestral: pricingConfig.descontoSemestral,
        descontoAnual: pricingConfig.descontoAnual,
        periodoGratuito: pricingConfig.periodoGratuito, // Novo campo
        diasPeriodoGratuito: pricingConfig.diasPeriodoGratuito, // Novo campo
        requisicao: 'updateSubscriptionPricing'
      }

      const response = await fetchData(url, dados)
      if (response.success) {
        setAlertVariant('success')
        setAlertMessage('Configuração salva com sucesso!')
        setShowAlert(true)
      } else {
        setAlertVariant('danger')
        setAlertMessage('Erro ao salvar configuração.')
        setShowAlert(true)
      }
    } catch (error) {
      console.error('Erro ao salvar configuração:', error)
      alert('Erro ao salvar configuração.')
    }
  }

  // Cálculo dos totais com desconto
  const monthly = parseFloat(pricingConfig.valorMensal) || 0
  const discountTrimestral = parseFloat(pricingConfig.descontoTrimestral) || 0
  const discountSemestral = parseFloat(pricingConfig.descontoSemestral) || 0
  const discountAnual = parseFloat(pricingConfig.descontoAnual) || 0
  const totalTrimestral = 3 * monthly * (1 - discountTrimestral / 100)
  const totalSemestral = 6 * monthly * (1 - discountSemestral / 100)
  const totalAnual = 12 * monthly * (1 - discountAnual / 100)

  return (
    <Container fluid className="mt-5">
      <Row className="justify-content-center">
        <Col md={5} lg={4}>
          <Card className="shadow-sm">
            <Card.Header className="bg-primary text-white">
              <h4 style={{ color: '#FFFFFF' }}>Configuração de Preços de Assinatura</h4>
            </Card.Header>
            <Card.Body>
              <ShowAlertGeneric
                message={alertMessage}
                showAlert={showAlert}
                variant={alertVariant}
                setShowAlert={setShowAlert}
              />

              <Form onSubmit={handleSubmit}>
                {/* Linha para seleção do sistema */}
                <Row className="mb-4">
                  <Col>
                    <Form.Group controlId="systemSelect">
                      <Form.Label className="fw-bold text-nowrap">Selecione o Sistema</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedSystem}
                        onChange={handleSystemChange}
                        className="rounded"
                      >
                        {SYSTEMS.map(sistema => (
                          <option key={sistema.id} value={sistema.id}>
                            {sistema.nome}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Campo para Valor Mensal */}
                <Row className="mb-4">
                  <Col>
                    <Form.Group controlId="valorMensal">
                      <Form.Label className="fw-bold text-nowrap">
                        Valor Mensal da Assinatura
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="valorMensal"
                        value={pricingConfig.valorMensal}
                        onChange={handleInputChange}
                        placeholder="Digite o valor mensal"
                        step="1"
                        className="rounded"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Campo para Desconto Trimestral com total ao lado */}
                <Row className="mb-4">
                  <Col>
                    <Form.Group controlId="descontoTrimestral">
                      <Form.Label className="fw-bold text-nowrap">
                        Desconto para Plano Trimestral (%)
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="descontoTrimestral"
                          value={pricingConfig.descontoTrimestral}
                          onChange={handleInputChange}
                          placeholder="Digite o desconto trimestral"
                          step="1"
                          className="rounded"
                        />
                        <InputGroup.Text>R$ {totalTrimestral.toFixed(2)}</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Campo para Desconto Semestral com total ao lado */}
                <Row className="mb-4">
                  <Col>
                    <Form.Group controlId="descontoSemestral">
                      <Form.Label className="fw-bold text-nowrap">
                        Desconto para Plano Semestral (%)
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="descontoSemestral"
                          value={pricingConfig.descontoSemestral}
                          onChange={handleInputChange}
                          placeholder="Digite o desconto semestral"
                          step="1"
                          className="rounded"
                        />
                        <InputGroup.Text>R$ {totalSemestral.toFixed(2)}</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Campo para Desconto Anual com total ao lado */}
                <Row className="mb-4">
                  <Col>
                    <Form.Group controlId="descontoAnual">
                      <Form.Label className="fw-bold text-nowrap">
                        Desconto para Plano Anual (%)
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="descontoAnual"
                          value={pricingConfig.descontoAnual}
                          onChange={handleInputChange}
                          placeholder="Digite o desconto anual"
                          step="0.01"
                          className="rounded"
                        />
                        <InputGroup.Text>R$ {totalAnual.toFixed(2)}</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="d-grid">
                      {/* Checkbox para habilitar período gratuito */}
                      <Row className="mb-4">
                        <Col>
                          <Form.Group controlId="periodoGratuito">
                            <Form.Check
                              type="checkbox"
                              label="Habilitar Período Gratuito"
                              name="periodoGratuito"
                              checked={pricingConfig.periodoGratuito}
                              onChange={e =>
                                setPricingConfig(prev => ({
                                  ...prev,
                                  periodoGratuito: e.target.checked
                                }))
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Campo para definir dias do período gratuito */}
                      {pricingConfig.periodoGratuito && (
                        <Row className="mb-4">
                          <Col>
                            <Form.Group controlId="diasPeriodoGratuito">
                              <Form.Label className="fw-bold">Dias do Período Gratuito</Form.Label>
                              <Form.Control
                                type="number"
                                name="diasPeriodoGratuito"
                                value={pricingConfig.diasPeriodoGratuito}
                                onChange={e =>
                                  setPricingConfig(prev => ({
                                    ...prev,
                                    diasPeriodoGratuito: e.target.value
                                  }))
                                }
                                placeholder="Digite o número de dias"
                                step="1"
                                className="rounded"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      <Button
                        variant="primary"
                        type="submit"
                        disabled={loading}
                        className="rounded"
                      >
                        {loading ? (
                          <>
                            <Spinner animation="border" size="sm" /> Salvando...
                          </>
                        ) : (
                          'Salvar Configuração'
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default SubscriptionPricingPanel
