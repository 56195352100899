import React, { useState, useEffect, useContext } from 'react'
import Banner from '../../../../../src/components/pages/CadastroGeral/Banner'
import { Button, FormGroup, Row, Col, Form } from 'react-bootstrap'
import { CustomField } from '../../../../../src/components/CustomField/custom-field.jsx'
import { ShowAlertGeneric } from '../../../../../src/utils/form-utils.jsx'
import { fetchData } from '../../../../../src/backend/model'
import { AuthContext } from '../../../../../src/context/AuthContext'
import { config } from '../../../../../src/backend/configConexaoGeral'
import { carregarUltimoImposto } from './cadastro_imposto.js'

const CadastroValorImposto = () => {
  const [valorImposto, setValorImposto] = useState('')
  const [nomeImposto, setNomeImposto] = useState('icms')
  const [showAlerta, setShowAlerta] = useState(false)
  const [mensagemAlerta, setMensagemAlerta] = useState('')
  const [variant, setVariant] = useState('danger')
  const [ultimoImposto, setUltimoImposto] = useState(null)

  const { tenantId } = useContext(AuthContext)

  useEffect(() => {
    const fetchImposto = async () => {
      await carregarUltimoImposto(tenantId, nomeImposto, setUltimoImposto)
    }
    if (tenantId) {
      fetchImposto()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId, nomeImposto])

  const handleValorChange = e => {
    setValorImposto(e.target.value)
  }

  const handleNomeChange = e => {
    setNomeImposto(e.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const dados = {
        tenantId: tenantId,
        nome: nomeImposto,
        valor: valorImposto,
        requisicao: 'cadastrarOuAtualizarImposto'
      }
      let url = config.host + '/impostoPF'
      const response = await fetchData(url, dados)

      if (response.success) {
        setVariant('success')
        setShowAlerta(true)
        setMensagemAlerta('Imposto cadastrado com sucesso!')
        await carregarUltimoImposto(tenantId, nomeImposto, setUltimoImposto) // Recarrega o último imposto após cadastro
      } else {
        setShowAlerta(true)
        setVariant('danger')
        setMensagemAlerta('Erro ao cadastrar imposto.')
      }

      // Limpar os campos após salvar
      setValorImposto('')
    } catch (error) {
      console.error('Erro ao cadastrar o imposto:', error)
      setShowAlerta(true)
      setMensagemAlerta('Erro ao cadastrar imposto.')
    }
  }

  const handleClear = () => {
    setValorImposto('')
    setNomeImposto('')
  }

  return (
    <div style={styles.container}>
      <Banner title="Cadastro de Imposto" width={'41vw'} fontSize={'4vw'} />

      <form onSubmit={handleSubmit} style={styles.form}>
        <ShowAlertGeneric
          message={mensagemAlerta}
          showAlert={showAlerta}
          variant={variant}
          setShowAlert={setShowAlerta}
        />
        <CustomField
          label="Nome do Imposto"
          placeholder="Digite o nome do imposto"
          value={nomeImposto}
          onChange={handleNomeChange}
          style={styles.input}
          onClear={handleClear}
        />
        <div style={{ marginTop: '2.2vw' }}>
          <CustomField
            label="Valor do Imposto (%)"
            placeholder="Digite o valor"
            value={valorImposto}
            onChange={handleValorChange}
            style={styles.input}
            type="number"
            onClear={handleClear}
          />
        </div>
        <Button type="submit" style={styles.button}>
          Salvar
        </Button>
        {ultimoImposto !== null && (
          <div style={styles.ultimoImposto}>
            <strong>Último Imposto Cadastrado:</strong> {ultimoImposto}%
          </div>
        )}
      </form>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5vw'
  },
  input: {
    width: '10vw' // Ajuste a largura conforme necessário
  },
  button: {
    marginTop: '10px'
  },
  ultimoImposto: {
    marginTop: '20px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    textAlign: 'center',
    width: '100%'
  }
}

export default CadastroValorImposto
