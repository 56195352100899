import { useState, useEffect, useContext } from 'react'
import { Table, Button, Form, Alert, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { config } from '../../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../../../../src/backend/model'
import { AuthContext } from '../../../../../../../src/context/AuthContext.jsx'
import { FaInfoCircle } from 'react-icons/fa'
const EditorRegrasPesoFreteML = () => {
  const { tenantId } = useContext(AuthContext)
  const [regras, setRegras] = useState([])
  const [novaRegra, setNovaRegra] = useState({
    limite_inferior: '',
    limite_superior: '',
    valor_frete: '',
    valor_minimo_produto: ''
  })
  const [mensagem, setMensagem] = useState('')
  const [tipoAlerta, setTipoAlerta] = useState('success')
  const [showAlert, setShowAlert] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [regraParaExcluir, setRegraParaExcluir] = useState(null)

  useEffect(() => {
    carregarRegras()
  }, [])

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [showAlert])

  const carregarRegras = async () => {
    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'CarregarRegrasPesoFrete', marketplace: 'mercadolivre' }
      const response = await fetchData(url, dados)

      if (response) {
        setRegras(response)
      }
    } catch (error) {
      console.error('Erro ao carregar regras:', error)
    }
  }

  const handleChange = (e, index = null) => {
    const { name, value } = e.target

    if (index !== null) {
      setRegras(prevRegras => {
        const novasRegras = [...prevRegras]
        novasRegras[index][name] = value
        return novasRegras
      })
    } else {
      setNovaRegra(prev => ({ ...prev, [name]: value }))
    }
  }

  const salvarRegra = async index => {
    const regra = regras[index]
    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'AtualizarRegrasFreteML', regraId: regra._id, ...regra }
      await fetchData(url, dados)

      setMensagem('Regra atualizada com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
    } catch (error) {
      console.error('Erro ao salvar regra:', error)
      setMensagem('Erro ao salvar regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const adicionarRegra = async () => {
    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = {
        requisicao: 'GravarRegraPesoFrete',
        tenantId,
        marketplace: 'mercadolivre',
        ...novaRegra
      }
      const response = await fetchData(url, dados)

      if (response) {
        setMensagem('Nova regra adicionada com sucesso!')
        setTipoAlerta('success')
        setShowAlert(true)
        setNovaRegra({
          limite_inferior: '',
          limite_superior: '',
          valor_frete: '',
          valor_minimo_produto: ''
        })
        carregarRegras()
      }
    } catch (error) {
      console.error('Erro ao adicionar regra:', error)
      setMensagem('Erro ao adicionar nova regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const confirmarExclusao = index => {
    setRegraParaExcluir(index)
    setShowConfirmModal(true)
  }

  const excluirRegra = async () => {
    try {
      const regraId = regras[regraParaExcluir]._id
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'ExcluirRegraFreteML', regraId }
      await fetchData(url, dados)

      setMensagem('Regra excluída com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
      setShowConfirmModal(false)
      carregarRegras()
    } catch (error) {
      console.error('Erro ao excluir regra:', error)
      setMensagem('Erro ao excluir regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  return (
    <div className="container">
      <h2 className="mt-4">Editor de Regras de Peso do Frete - Mercado Livre</h2>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="tooltip-sobre">
            <strong>O que é isso?</strong> <br />
            Aqui você pode gerenciar as regras de peso do frete para o Mercado Livre. <br />
            - Defina limites de peso para faixas específicas. <br />
            - Informe o valor do frete para cada faixa. <br />- Configure o valor mínimo do produto
            para ativar essa regra.
          </Tooltip>
        }
      >
        <span className="ms-2">
          <FaInfoCircle size={20} style={{ cursor: 'pointer', color: '#007bff' }} />
        </span>
      </OverlayTrigger>
      {showAlert && (
        <Alert variant={tipoAlerta} onClose={() => setShowAlert(false)} dismissible>
          {mensagem}
        </Alert>
      )}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Limite Inferior (kg)</th>
            <th>Limite Superior (kg)</th>
            <th>Valor do Frete (R$)</th>
            <th>Valor Mínimo do Produto (R$)</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {regras.map((regra, index) => (
            <tr key={regra._id}>
              <td>
                <Form.Control
                  type="number"
                  name="limite_inferior"
                  value={regra.limite_inferior}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="limite_superior"
                  value={regra.limite_superior}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="valor_frete"
                  value={regra.valor_frete}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="valor_minimo_produto"
                  value={regra.valor_minimo_produto}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Button variant="success" onClick={() => salvarRegra(index)}>
                  Salvar
                </Button>{' '}
                <Button variant="danger" onClick={() => confirmarExclusao(index)}>
                  Excluir
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h4>Adicionar Nova Regra</h4>
      <div className="d-flex gap-2">
        <Form.Control
          type="number"
          name="limite_inferior"
          placeholder="Limite Inferior"
          value={novaRegra.limite_inferior}
          onChange={handleChange}
        />
        <Form.Control
          type="number"
          name="limite_superior"
          placeholder="Limite Superior"
          value={novaRegra.limite_superior}
          onChange={handleChange}
        />
        <Form.Control
          type="number"
          name="valor_frete"
          placeholder="Valor do Frete"
          value={novaRegra.valor_frete}
          onChange={handleChange}
        />
        <Form.Control
          type="number"
          name="valor_minimo_produto"
          placeholder="Valor Mínimo Produto"
          value={novaRegra.valor_minimo_produto}
          onChange={handleChange}
        />
        <Button variant="primary" onClick={adicionarRegra}>
          Adicionar
        </Button>
      </div>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que deseja excluir esta regra?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={excluirRegra}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default EditorRegrasPesoFreteML
