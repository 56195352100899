// edicao-marketplace
import { useState, useEffect, useContext } from 'react'
import { useQuery } from 'react-query'
import { FormGroup } from 'react-bootstrap'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import CustomTable from '../../../../../../src/components/CustomTable/custom-table'
import { fetchData } from '../../../../../../src/backend/model'
import { AuthContext } from '../../../../../../src/context/AuthContext'
import { config } from '../../../../../../src/backend/configConexaoGeral'
const EdicaoMarketplace = () => {
  const [dadosEdicao, setDadosEdicao] = useState({})
  const [updatedData, setUpdatedData] = useState([])
  //const [showAlert, setShowAlert] = useState(false)
  const { tenantId } = useContext(AuthContext)
  const {
    data: marketplaces,
    isLoading: isLoadingMarketplaces,
    refetch: refetchMarketplaces,
    isError: isErrorCarregaMarketplaces
  } = useQuery(
    'marketplaces',
    () => CarregarMarketplaces(tenantId), // Função para buscar marketplaces
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    setUpdatedData(marketplaces)
  }, [marketplaces])

  // Definir cabeçalhos da tabela e outras configurações
  const headerCells = [
    { label: 'Nome', dataKey: 'nome', width: '100%', edit: true }
    // Removido a coluna 'Descrição' pois só editaremos o nome
  ]

  return (
    <div style={{ position: 'relative' }}>
      <div
        className="d-flex flex-column align-items-center form-container"
        style={{ marginTop: '30px' }}
      >
        <Banner title="Edição de Marketplace" color="#2e8b57" width="32%" fontSize={60} />
      </div>

      <FormGroup
        style={{
          maxWidth: '1000px',
          margin: '-10px auto 0 auto',
          paddingTop: '-10px'
        }}
      >
        <CustomTable
          headerCells={headerCells}
          data={marketplaces}
          updatedData={updatedData}
          setUpdatedData={setUpdatedData}
          dadosEdicao={dadosEdicao}
          setDadosEdicao={setDadosEdicao}
          isDeleteEdit={{
            configKeySheet: 'marketplacePF',
            exclusaoConfig: {
              tab: 'marketplacePF',
              invalidateQueries: 'marketplaces'
            },
            deletaDoBanco: true
          }}
          width="970px"
          tableHeight="750px"
        />
      </FormGroup>
    </div>
  )
}

export default EdicaoMarketplace

//---------------------------------------------------------------------------------------------
export async function CarregarMarketplaces() {
  try {
    let dados = {
      requisicao: 'CarregarMarketplacesPF'
    }

    let url = config.host + '/marketplacePF'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar categorias:', error)
    // Trate o erro conforme necessário
  }
}
