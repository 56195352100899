// comissao-display.js

import { calcularFreteParaAmericanas } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/calculo-precoPF.js'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../../../src/backend/model'
import { calcularFreteParaAmazon, calcularFreteComDescontoMagalu } from './calculo-precoPF.js'

export function calcularAmericanasPrecoVendaSimples(
  precoVenda,
  custo,
  fixo,

  comissaoPercent,
  impostoPercent,
  regrasAmericanas,
  peso,
  descontoVendedorAmericanas
) {
  // Calcula o frete utilizando a função previamente definida
  const frete = calcularFreteParaAmericanas(
    peso,
    precoVenda,
    regrasAmericanas,
    descontoVendedorAmericanas
  )

  // Calcula a venda líquida descontando imposto e comissão
  const denominador = 1 - (impostoPercent + comissaoPercent) / 100
  const netSale = precoVenda * denominador

  // O lucro é o líquido menos os custos (produto + fixo)
  const lucroCalc = netSale - (custo + (fixo ?? 0) + frete)

  // A margem é o lucro em % do preço de venda
  const margemCalc = precoVenda > 0 ? (lucroCalc / precoVenda) * 100 : 0
  console.log('margem', margemCalc)
  console.log('lucro', lucroCalc)
  return { frete, lucro: lucroCalc, margem: margemCalc }
}
export function addColorsToMarketplaces(marketplaces, colors) {
  if (!Array.isArray(marketplaces)) {
    console.error('marketplaces is not an array', marketplaces)
    return []
  }
  return marketplaces.map(marketplace => ({
    ...marketplace,
    cor: colors[marketplace.nome] || '#000000'
  }))
}

export function addFixoParameter(input, fixoEntrada) {
  if (Array.isArray(input)) {
    return input.map(item => {
      const nome = item.nome.toLowerCase()
      let fixo = ''
      if (nome === 'magalu' || nome === 'shein') {
        fixo = 5
      } else if (nome === 'shopee') {
        fixo = 4
      } else if (nome === 'via varejo') {
        fixo = 3
      } else if ((nome === 'mercado livre' || nome === 'amazon') && fixoEntrada !== undefined) {
        fixo = fixoEntrada
      }
      return { ...item, fixo }
    })
  } else {
    const item = input
    const nome = item.nome.toLowerCase()
    let fixo = ''
    if (nome === 'magalu' || nome === 'shein') {
      fixo = 5
    } else if (nome === 'shopee') {
      fixo = 4
    } else if (nome === 'via varejo') {
      fixo = 3
    } else if ((nome === 'mercado livre' || nome === 'amazon') && fixoEntrada !== undefined) {
      fixo = fixoEntrada
    }
    return { ...item, fixo }
  }
}

export function calcularPrecoVendaIterativoMagalu({
  precoVendaInicial,
  custo,
  fixo,
  impostoPercent,
  comissaoPercent,
  valorFrete,
  margemDesejada,
  tolerancia = 0.1,
  maxIter = 20
}) {
  let vendaCalc = precoVendaInicial
  let iter = 0
  let margemCalc = 0
  while (iter < maxIter) {
    const denominador = 1 - (impostoPercent + comissaoPercent) / 100
    const netSale = vendaCalc * denominador - valorFrete
    const lucroCalc = netSale - (custo + fixo)
    margemCalc = vendaCalc > 0 ? (lucroCalc / vendaCalc) * 100 : 0
    if (Math.abs(margemCalc - margemDesejada) < tolerancia) break
    if (margemCalc < margemDesejada) {
      vendaCalc += 0.5
    } else {
      vendaCalc -= 0.5
    }
    iter++
  }
  const denominadorFinal = 1 - (impostoPercent + comissaoPercent) / 100
  const netSaleFinal = vendaCalc * denominadorFinal - valorFrete
  const lucroFinal = netSaleFinal - (custo + fixo)
  return { precoVenda: vendaCalc, lucro: lucroFinal, margem: margemCalc }
}

export function calcularViaVarejoPrecoVendaSimples(
  precoVenda,
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasViaVarejo,
  peso,
  descontoVendedorViaVarejo
) {
  const frete = calcularFreteParaAmericanas(
    peso,
    precoVenda,
    regrasViaVarejo,
    descontoVendedorViaVarejo
  )
  const denominador = 1 - (impostoPercent + comissaoPercent) / 100
  const netSale = precoVenda * denominador - frete
  const lucroCalc = netSale - (custo + fixo)
  const margemCalc = precoVenda > 0 ? (lucroCalc / precoVenda) * 100 : 0
  return { frete, lucro: lucroCalc, margem: margemCalc }
}

export function calcularViaVarejoPrecoVendaIterativoMargem({
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasViaVarejo,
  peso,
  descontoVendedorViaVarejo,
  margemDesejada
}) {
  const maxIter = 200 // limite de iterações para evitar loop infinito

  // Chute inicial baseado na fórmula genérica do handleMargemChange

  let precoVendaGuess =
    (custo + (fixo ?? 0)) / (1 - (impostoPercent + comissaoPercent) / 100 - margemDesejada / 100)

  let result = calcularViaVarejoPrecoVendaSimples(
    precoVendaGuess,
    custo,
    fixo ?? 0, // Garantindo que fixo nunca seja null ou undefined
    comissaoPercent,
    impostoPercent,
    regrasViaVarejo,
    peso,
    descontoVendedorViaVarejo
  )

  let fator = 0.01,
    fator2 = 0.001
  let ajustefino = false
  let total_aumentou_ajuste_fino = 0
  let margem

  for (let iter = 0; iter < maxIter; iter++) {
    margem = parseFloat(result.margem.toFixed(2))

    if (margem === margemDesejada) {
      break
    }

    if (result.margem < margemDesejada) {
      if (!ajustefino) {
        precoVendaGuess += 1
      } else {
        total_aumentou_ajuste_fino++
        if (total_aumentou_ajuste_fino == 1) {
          precoVendaGuess += fator
        } else {
          precoVendaGuess += fator2
        }
      }
    } else {
      ajustefino = true

      if (total_aumentou_ajuste_fino <= 1) {
        precoVendaGuess -= fator
      } else {
        precoVendaGuess -= fator2
      }
    }

    result = calcularViaVarejoPrecoVendaSimples(
      precoVendaGuess,
      custo,
      fixo ?? 0,
      comissaoPercent,
      impostoPercent,
      regrasViaVarejo,
      peso,
      descontoVendedorViaVarejo
    )
  }

  return { precoVenda: precoVendaGuess, ...result }
}

export function calcularAmericanasPrecoVendaIterativoMargem({
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasAmericanas,
  peso,
  descontoVendedorAmericanas,
  margemDesejada
}) {
  const maxIter = 200 // limite de iterações para evitar loop infinito

  // Chute inicial baseado na fórmula genérica do handleMargemChange
  let precoVendaGuess =
    (custo + (fixo ?? 0)) / (1 - (impostoPercent + comissaoPercent) / 100 - margemDesejada / 100)

  let result = calcularAmericanasPrecoVendaSimples(
    precoVendaGuess,
    custo,
    fixo,
    comissaoPercent,
    impostoPercent,
    regrasAmericanas,
    peso,
    descontoVendedorAmericanas
  )

  let fator = 0.01,
    fator2 = 0.001
  let ajustefino = false
  let total_aumentou_ajuste_fino = 0
  let margem

  for (let iter = 0; iter < maxIter; iter++) {
    margem = parseFloat(result.margem.toFixed(2))

    if (margem === margemDesejada) {
      break
    }

    if (result.margem < margemDesejada) {
      if (!ajustefino) {
        precoVendaGuess += 1
      } else {
        total_aumentou_ajuste_fino++
        if (total_aumentou_ajuste_fino == 1) {
          precoVendaGuess += fator
        } else {
          precoVendaGuess += fator2
        }
      }
    } else {
      ajustefino = true

      if (total_aumentou_ajuste_fino <= 1) {
        precoVendaGuess -= fator
      } else {
        precoVendaGuess -= fator2
      }
    }

    result = calcularAmericanasPrecoVendaSimples(
      precoVendaGuess,
      custo,
      fixo,
      comissaoPercent,
      impostoPercent,
      regrasAmericanas,
      peso,
      descontoVendedorAmericanas
    )
  }

  return { precoVenda: precoVendaGuess, ...result }
}

// export function calcularViaVarejoPrecoVendaIterativoLucro(
//   custo,
//   fixo,
//   comissaoPercent,
//   impostoPercent,
//   regrasViaVarejo,
//   peso,
//   descontoVendedorViaVarejo,
//   lucroDesejado,
//   tolerance = 0.01,
//   maxIter = 50
// ) {
//   let iter = 0
//   let precoVendaGuess = (custo + fixo + lucroDesejado) / (1 - comissaoPercent / 100)
//   let result = calcularViaVarejoPrecoVendaSimples(
//     precoVendaGuess,
//     custo,
//     fixo,
//     comissaoPercent,
//     impostoPercent,
//     regrasViaVarejo,
//     peso,
//     descontoVendedorViaVarejo
//   )
//   while (Math.abs(result.lucro - lucroDesejado) > tolerance && iter < maxIter) {
//     if (result.lucro < lucroDesejado) {
//       precoVendaGuess += 1
//     } else {
//       precoVendaGuess -= 1
//     }
//     result = calcularViaVarejoPrecoVendaSimples(
//       precoVendaGuess,
//       custo,
//       fixo,
//       comissaoPercent,
//       impostoPercent,
//       regrasViaVarejo,
//       peso,
//       descontoVendedorViaVarejo
//     )
//     iter++
//   }
//   return { precoVenda: precoVendaGuess, ...result }
// }
export function calcularViaVarejoPrecoVendaIterativoLucro(
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasViaVarejo,
  peso,
  descontoVendedorViaVarejo,
  lucroDesejado
) {
  // Garante que fixo seja um número válido
  fixo = isNaN(parseFloat(fixo)) ? 0 : parseFloat(fixo)

  // Chute inicial: estimativa baseada na fórmula geral
  let precoVendaGuess = (custo + fixo + lucroDesejado) / (1 - comissaoPercent / 100)

  // Calcula os valores iniciais com a função simples
  let result = calcularViaVarejoPrecoVendaSimples(
    precoVendaGuess,
    custo,
    fixo,
    comissaoPercent,
    impostoPercent,
    regrasViaVarejo,
    peso,
    descontoVendedorViaVarejo
  )

  const maxIter = 200
  let fator = 0.01,
    fator2 = 0.001,
    ajustefino = false,
    total_aumentou_ajuste_fino = 0,
    lucro

  // Loop iterativo usando for para ajustar o preço de venda
  for (let iter = 0; iter < maxIter; iter++) {
    // Arredonda o lucro para duas casas decimais para comparação
    lucro = parseFloat(result.lucro.toFixed(2))

    // Se o lucro calculado for igual ao desejado, encerra o loop
    if (lucro === lucroDesejado) {
      break
    }

    // Ajusta o preço de venda com base na comparação do lucro
    if (result.lucro < lucroDesejado) {
      if (!ajustefino) {
        precoVendaGuess += 1
      } else {
        total_aumentou_ajuste_fino++
        if (total_aumentou_ajuste_fino === 1) {
          precoVendaGuess += fator
        } else {
          precoVendaGuess += fator2
        }
      }
    } else {
      ajustefino = true
      if (total_aumentou_ajuste_fino <= 1) {
        precoVendaGuess -= fator
      } else {
        precoVendaGuess -= fator2
      }
    }

    // Recalcula os valores com o novo chute
    result = calcularViaVarejoPrecoVendaSimples(
      precoVendaGuess,
      custo,
      fixo,
      comissaoPercent,
      impostoPercent,
      regrasViaVarejo,
      peso,
      descontoVendedorViaVarejo
    )
  }

  // Retorna o preço de venda final e os demais resultados calculados
  return { precoVenda: precoVendaGuess, ...result }
}

export async function obterDescontoMagalu(tenantId, marketplace, regrasDescontoFreteMagalu) {
  // Busca a última regra selecionada para esse marketplace:

  const ultimaRegra = await fetchUltimaRegraSelecionada(marketplace._id, tenantId)

  // Se houver uma regra selecionada, podemos usá-la diretamente ou buscar na lista.
  if (ultimaRegra) {
    // Caso a estrutura seja idêntica àquela da lista, podemos comparar pelo _id:
    const regraEncontrada = regrasDescontoFreteMagalu.find(r => r._id === ultimaRegra)

    if (regraEncontrada && regraEncontrada.desconto != null) {
      return regraEncontrada.desconto
    }
    // Se não encontrar na lista, pode ser que o objeto retornado já contenha o desconto:
    if (ultimaRegra.desconto != null) {
      return ultimaRegra.desconto
    }
  }
  // Caso não haja regra selecionada, retorna 0 (nenhum desconto)
  return 0
}

export async function fetchUltimaRegraSelecionada(idMarketplace, tenantId) {
  if (!idMarketplace) return null
  try {
    const dados = {
      tenantId,
      marketplace: idMarketplace,
      requisicao: 'BuscarUltimaRegraSelecionada'
    }
    const url = config.host + '/regraDescontoFrete'
    const response = await fetchData(url, dados)
    // Retorna diretamente o objeto da regra, se encontrado:
    return response?.regra || null
  } catch (error) {
    console.error('Erro ao buscar a última regra selecionada:', error)
    return null
  }
}

export function calcularAmericanasPrecoVendaIterativo(
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasAmericanas,
  peso,
  descontoVendedorAmericanas,
  lucroDesejado // lucro absoluto desejado
) {
  // Garante que fixo seja um número válido
  fixo = isNaN(parseFloat(fixo)) ? 0 : parseFloat(fixo)

  // Chute inicial: uma estimativa baseada na fórmula geral
  let precoVendaGuess = (custo + fixo + lucroDesejado) / (1 - comissaoPercent / 100)

  let result = calcularAmericanasPrecoVendaSimples(
    precoVendaGuess,
    custo,
    fixo,
    comissaoPercent,
    impostoPercent,
    regrasAmericanas,
    peso,
    descontoVendedorAmericanas
  )

  let maxIter = 200
  let fator = 0.01,
    fator2 = 0.001,
    ajustefino = false,
    total_aumentou_ajuste_fino = 0,
    lucro

  for (let iter = 0; iter < maxIter; iter++) {
    lucro = parseFloat(result.lucro.toFixed(2))

    if (lucro === lucroDesejado) {
      console.log('iter', iter)
      break
    }

    if (result.lucro < lucroDesejado) {
      if (!ajustefino) {
        precoVendaGuess += 1
      } else {
        total_aumentou_ajuste_fino++
        if (total_aumentou_ajuste_fino === 1) {
          precoVendaGuess += fator
          console.log('total_aumentou_ajuste_fino +', total_aumentou_ajuste_fino)
        } else {
          precoVendaGuess += fator2
          console.log('total_aumentou_ajuste_fino +', total_aumentou_ajuste_fino)
        }
      }
    } else {
      ajustefino = true
      if (total_aumentou_ajuste_fino <= 1) {
        precoVendaGuess -= fator
        console.log('total_aumentou_ajuste_fino -', total_aumentou_ajuste_fino)
      } else {
        console.log('total_aumentou_ajuste_fino', total_aumentou_ajuste_fino)
        precoVendaGuess -= fator2
      }
    }

    result = calcularAmericanasPrecoVendaSimples(
      precoVendaGuess,
      custo,
      fixo,
      comissaoPercent,
      impostoPercent,
      regrasAmericanas,
      peso,
      descontoVendedorAmericanas
    )
  }

  return { precoVenda: precoVendaGuess, ...result }
}

export async function CarregarRegrasFreteShopee() {
  try {
    const dados = {
      requisicao: 'CarregarRegrasFreteShopee'
    }
    const url = config.host + '/regrasFreteShopee'
    const response = await fetchData(url, dados)
    return response
  } catch (error) {
    console.error('Erro ao carregar regras de frete Shopee:', error)
    // Trate o erro conforme necessário
  }
}

export function recalcShopeeCalculations(
  marketplaceId,
  freteExtraActive,
  marketplacesWithComissoes,
  lucro,
  produtoSelecionado,
  ultimoImposto,
  regraFreteShopee,
  setVenda,
  setMargem
) {
  // Usa o valor atual do campo "lucro" para este marketplace
  const currentLucro = parseFloat(lucro[marketplaceId]) || 0
  const custo = parseFloat(produtoSelecionado?.custo) || 0

  // Obtenha o marketplace e o fixo
  let marketplace = marketplacesWithComissoes.find(m => m._id === marketplaceId)
  marketplace = addFixoParameter(marketplace)
  const fixoNum = parseFloat(marketplace.fixo) || 0

  // Obtenha a comissão e o imposto (já fornecidos)
  const comissaoPercent = parseFloat(marketplace.comissao) || 0
  const impostoPercent = ultimoImposto // por exemplo, 4

  // Se for Shopee e o checkbox estiver marcado, inclua o extra
  const extraFrete =
    marketplace.nome === 'Shopee' && freteExtraActive ? parseFloat(regraFreteShopee) : 0

  // Fórmula para recalcular a venda:
  // venda = (custo + fixo + lucro) / [1 - ((imposto + comissão + extraFrete)/100)]
  const denominador = 1 - (impostoPercent + comissaoPercent + extraFrete) / 100
  const precoVendaCalc = (custo + fixoNum + currentLucro) / denominador
  const margemCalc = precoVendaCalc > 0 ? (currentLucro / precoVendaCalc) * 100 : 0

  // Atualiza os estados de venda e margem
  setVenda(prev => ({ ...prev, [marketplaceId]: precoVendaCalc.toFixed(2) }))
  setMargem(prev => ({ ...prev, [marketplaceId]: margemCalc.toFixed(2) }))
}

export function recalcMLCalculations(
  marketplaceId,
  freteGratisAtivo,
  produtoSelecionado,
  marketplacesWithComissoes,
  ultimoImposto,
  venda,
  setValorFreteMercadoLivre,
  setLucro,
  setMargem,
  regrasPesoFreteMercadoLivre,
  peso,
  descontoAtualFreteMercadoLivre,
  valorMinFreteGratisML,
  setSelectedMarketplacesData,
  marketplace
) {
  const custo = parseFloat(produtoSelecionado?.custo) || 0
  const fixo = parseFloat(marketplacesWithComissoes.find(m => m._id === marketplaceId)?.fixo) || 0
  const comissao =
    parseFloat(marketplacesWithComissoes.find(m => m._id === marketplaceId)?.comissao) || 0
  const imposto = ultimoImposto || 0

  // Obtenha o valor atual do campo "venda sugerida"
  const salePrice = parseFloat(venda[marketplaceId]) || 0
  if (salePrice <= 0) {
    console.warn('Preço de venda inválido para recalcular os valores.')
    return
  }

  if (!freteGratisAtivo) {
    // 🔄 Agora, se o checkbox estiver DESMARCADO, o cálculo será feito SEM o frete
    const denominador = 1 - (imposto + comissao) / 100
    const netSale = salePrice * denominador
    const lucroSemFrete = netSale - (custo + fixo)
    const margemSemFrete = salePrice > 0 ? (lucroSemFrete / salePrice) * 100 : 0

    setValorFreteMercadoLivre(0)
    setLucro(prev => ({ ...prev, [marketplaceId]: lucroSemFrete.toFixed(2) }))
    setMargem(prev => ({ ...prev, [marketplaceId]: margemSemFrete.toFixed(2) }))
  } else {
    // 🔄 Agora, se o checkbox estiver MARCADO, o frete será incluído no cálculo
    const resultado = calcularMLPrecoVendaSimples(
      salePrice,
      custo,
      fixo,
      comissao,
      imposto,
      regrasPesoFreteMercadoLivre,
      peso,
      descontoAtualFreteMercadoLivre,
      valorMinFreteGratisML
    )
    console.log('salePrice', salePrice)
    console.log('fixo', fixo)
    console.log('resultado', resultado)
    //setValorFreteMercadoLivre(resultado.frete.toFixed(2))
    ConfiguraFreteEFixoMercadoLivre(
      salePrice,
      setValorFreteMercadoLivre,
      setSelectedMarketplacesData,
      marketplace,
      resultado.frete
    )
    setLucro(prev => ({ ...prev, [marketplaceId]: resultado.lucro.toFixed(2) }))
    setMargem(prev => ({ ...prev, [marketplaceId]: resultado.margem.toFixed(2) }))
  }
}

export function calcularMLPrecoVendaSimples(
  precoVenda,
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasFrete,
  peso,
  descontoFrete,
  valorMinFreteGratisML
) {
  // Calcula o valor do frete usando a função já existente,
  // passando o parâmetro valorMinFreteGratisML para que a lógica interna
  // compare o preço de venda com o limite (por exemplo, 79 reais).
  const frete = calcularFreteComDescontoMercadoLivre(
    regrasFrete,
    peso,
    descontoFrete,
    precoVenda,
    valorMinFreteGratisML
  )

  // Calcula a venda líquida (descontando imposto e comissão)
  const denominador = 1 - (impostoPercent + comissaoPercent) / 100
  const netSale = precoVenda * denominador
  // O lucro real é o líquido menos os custos (produto + fixo + frete)
  const lucroCalc = netSale - (custo + fixo + frete)
  // A margem é o lucro em % do preço de venda
  const margemCalc = precoVenda > 0 ? (lucroCalc / precoVenda) * 100 : 0

  return { frete, lucro: lucroCalc, margem: margemCalc }
}

export function calcularFreteComDescontoMercadoLivre(
  regrasPesoFreteMercadoLivre,
  pesoAtual,
  descontoPercentual,
  precoVendaBruto,
  valorMinFreteGratisML
) {
  // Filtrar as faixas que atendem ao peso atual
  const faixasPeso = regrasPesoFreteMercadoLivre.filter(
    regra => pesoAtual >= regra.limite_inferior && pesoAtual <= regra.limite_superior
  )

  if (faixasPeso.length === 0) {
    console.error('Nenhuma faixa de frete encontrada para:', { pesoAtual, precoVendaBruto })
    throw new Error('Nenhuma faixa de frete encontrada para o peso e preço informados.')
  }

  // Filtrar as regras que se aplicam ao valor do produto
  const faixasValidas = faixasPeso.filter(regra => precoVendaBruto >= regra.valor_minimo_produto)

  let faixaEscolhida
  if (faixasValidas.length > 0) {
    // Escolhe a regra com o maior valor_minimo_produto dentre as válidas
    faixaEscolhida = faixasValidas.reduce((prev, curr) =>
      prev.valor_minimo_produto > curr.valor_minimo_produto ? prev : curr
    )
  } else {
    // Se nenhuma regra for válida (valor do produto abaixo de todas as faixas), escolhe a regra com o menor valor_minimo_produto
    faixaEscolhida = faixasPeso.reduce((prev, curr) =>
      prev.valor_minimo_produto < curr.valor_minimo_produto ? prev : curr
    )
  }

  let valorFreteComDesconto
  if (precoVendaBruto >= valorMinFreteGratisML) {
    // Se o produto estiver acima do valor mínimo para frete grátis, aplica desconto
    const descontoFinal = !isNaN(descontoPercentual) ? descontoPercentual : 0
    valorFreteComDesconto = ((faixaEscolhida.valor_frete * (100 - descontoFinal)) / 100).toFixed(2)
  } else {
    // Para preços abaixo de valorMinFreteGratisML, retorna o frete fixo da regra
    valorFreteComDesconto = faixaEscolhida.valor_frete.toFixed(2)
  }

  return parseFloat(valorFreteComDesconto)
}

// export function recalcAmazonCalculations(
//   marketplaceId,
//   freteGratisAtivo,
//   produtoSelecionado,
//   marketplacesWithComissoes,
//   ultimoImposto,
//   venda,
//   regrasPesoFreteAmazon,
//   setValorFreteAmazon,
//   setLucro,
//   setMargem
// ) {
//   const custo = parseFloat(produtoSelecionado?.custo) || 0
//   const fixo = parseFloat(marketplacesWithComissoes.find(m => m._id === marketplaceId)?.fixo) || 0
//   const comissao =
//     parseFloat(marketplacesWithComissoes.find(m => m._id === marketplaceId)?.comissao) || 0
//   const imposto = ultimoImposto || 0

//   // Obtenha o valor atual do campo "Venda Sugerida"
//   const salePrice = parseFloat(venda[marketplaceId]) || 0
//   if (salePrice <= 0) {
//     console.warn('Preço de venda inválido para recalcular os valores.')
//     return
//   }

//   const valorFreteCalculado = calcularFreteParaAmazon(
//     produtoSelecionado.gramas,
//     salePrice, // valor do produto
//     regrasPesoFreteAmazon // as regras específicas da Amazon
//   )

//   if (freteGratisAtivo) {
//     // Se o checkbox estiver marcado, o vendedor paga o frete
//     const denominador = 1 - (imposto + comissao) / 100
//     const netSale = salePrice * denominador
//     const lucroComFrete = netSale - (custo + fixo + valorFreteCalculado)
//     const margemComFrete = salePrice > 0 ? (lucroComFrete / salePrice) * 100 : 0

//     // setValorFreteAmazon(valorFreteCalculado.toFixed(2))
//     setLucro(prev => ({ ...prev, [marketplaceId]: lucroComFrete.toFixed(2) }))
//     //  setMargem(prev => ({ ...prev, [marketplaceId]: margemComFrete.toFixed(2) }))
//   } else {
//     // Se o checkbox não estiver marcado, o cliente paga o frete
//     // => frete = 0 para o vendedor
//     const denominador = 1 - (imposto + comissao) / 100
//     const netSale = salePrice * denominador
//     const lucroSemFrete = netSale - (custo + fixo)
//     const margemSemFrete = salePrice > 0 ? (lucroSemFrete / salePrice) * 100 : 0

//     setValorFreteAmazon('0.00')
//     setLucro(prev => ({ ...prev, [marketplaceId]: lucroSemFrete.toFixed(2) }))
//     setMargem(prev => ({ ...prev, [marketplaceId]: margemSemFrete.toFixed(2) }))
//   }
// }
export function calcularMLPrecoVendaIterativo(
  custo,
  fixo = 0, // Define um valor padrão para evitar erros
  comissaoPercent,
  impostoPercent,
  regrasFrete,
  peso,
  descontoFrete,
  valorMinFreteGratisML,
  lucroDesejado
) {
  const maxIter = 200 // limite de iterações para evitar loop infinito

  // Garante que fixo seja um número válido
  fixo = isNaN(parseFloat(fixo)) ? 0 : parseFloat(fixo)

  // Chute inicial: uma estimativa baseada na fórmula geral
  let precoVendaGuess = (custo + fixo + lucroDesejado) / (1 - comissaoPercent / 100)

  let result = calcularMLPrecoVendaSimples(
    precoVendaGuess,
    custo,
    fixo,
    comissaoPercent,
    impostoPercent,
    regrasFrete,
    peso,
    descontoFrete,
    valorMinFreteGratisML
  )

  let fator = 0.01,
    fator2 = 0.001
  let ajustefino = false
  let total_aumentou_ajuste_fino = 0
  let lucro

  for (let iter = 0; iter < maxIter; iter++) {
    lucro = parseFloat(result.lucro.toFixed(2))

    if (lucro === lucroDesejado) {
      console.log('iter', iter)
      break
    }

    if (result.lucro < lucroDesejado) {
      if (!ajustefino) {
        precoVendaGuess += 1
      } else {
        total_aumentou_ajuste_fino++
        if (total_aumentou_ajuste_fino == 1) {
          precoVendaGuess += fator
          console.log('total_aumentou_ajuste_fino +', total_aumentou_ajuste_fino)
        } else {
          precoVendaGuess += fator2
          console.log('total_aumentou_ajuste_fino +', total_aumentou_ajuste_fino)
        }
      }
    } else {
      ajustefino = true
      if (total_aumentou_ajuste_fino <= 1) {
        precoVendaGuess -= fator
        console.log('total_aumentou_ajuste_fino -', total_aumentou_ajuste_fino)
      } else {
        console.log('total_aumentou_ajuste_fino', total_aumentou_ajuste_fino)
        precoVendaGuess -= fator2
      }
    }

    result = calcularMLPrecoVendaSimples(
      precoVendaGuess,
      custo,
      fixo,
      comissaoPercent,
      impostoPercent,
      regrasFrete,
      peso,
      descontoFrete,
      valorMinFreteGratisML
    )
  }

  return { precoVenda: precoVendaGuess, ...result }
}

export function calcularMLPrecoVendaIterativoMargem(
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasFrete,
  peso,
  descontoFrete,
  valorMinFreteGratisML,
  margemDesejada
) {
  const maxIter = 200 // limite de iterações para evitar loop infinito

  // Chute inicial: usamos uma fórmula aproximada (baseada na fórmula genérica do handleMargemChange)
  let precoVendaGuess =
    (custo + fixo) / (1 - (impostoPercent + comissaoPercent) / 100 - margemDesejada / 100)

  let result = calcularMLPrecoVendaSimples(
    precoVendaGuess,
    custo,
    fixo,
    comissaoPercent,
    impostoPercent,
    regrasFrete,
    peso,
    descontoFrete,
    valorMinFreteGratisML
  )
  console.log('result mercadolivre', result)
  let fator = 0.01,
    fator2 = 0.001
  //let  contador = 0
  let ajustefino = false
  let total_aumentou_ajuste_fino = 0
  let margem
  for (let iter = 0; iter < maxIter; iter++) {
    // Verifica se a margem calculada está dentro da faixa aceitável
    margem = parseFloat(result.margem.toFixed(2))

    //console.log('margemDesejada', margemDesejada)
    if (margem === margemDesejada) {
      break
    }

    if (result.margem < margemDesejada) {
      if (!ajustefino) {
        precoVendaGuess += 1
        // contador++
        // console.log('contador aumentou', contador)
      } else {
        // contador++
        total_aumentou_ajuste_fino++
        // console.log('contador aumentou ajustefino', contador)
        if (total_aumentou_ajuste_fino == 1) {
          precoVendaGuess += fator
        } else {
          //  contador++
          // console.log('contador aumentou ajustefino fator2', contador)
          precoVendaGuess += fator2
        } // Incrementa se a margem calculada for menor que a desejada
      }
    } else {
      ajustefino = true

      if (total_aumentou_ajuste_fino <= 1) {
        // contador++
        // console.log('contador diminuiu ajuste fino', contador)
        precoVendaGuess -= fator
      } else {
        // contador++
        // console.log('contador diminuiu ajuste fino fator2', contador)
        precoVendaGuess -= fator2
      }
      // precoVendaGuess -= fator // Decrementa se a margem calculada for maior que a desejada
    }

    result = calcularMLPrecoVendaSimples(
      precoVendaGuess,
      custo,
      fixo,
      comissaoPercent,
      impostoPercent,
      regrasFrete,
      peso,
      descontoFrete,
      valorMinFreteGratisML
    )
  }

  return { precoVenda: precoVendaGuess, ...result }
}
export function calcularAmazonPrecoVendaIterativoMargem(
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasPesoFreteAmazon,
  peso,
  margemDesejada
) {
  const maxIter = 200 // limite de iterações para evitar loop infinito

  // Chute inicial baseado na fórmula genérica do handleMargemChange
  let precoVendaGuess =
    (custo + fixo) / (1 - (impostoPercent + comissaoPercent) / 100 - margemDesejada / 100)

  let result = calcularAmazonPrecoVendaSimples(
    precoVendaGuess,
    custo,
    fixo,
    comissaoPercent,
    impostoPercent,
    regrasPesoFreteAmazon,
    peso
  )

  let fator = 0.01,
    fator2 = 0.001
  let ajustefino = false

  let total_aumentou_ajuste_fino = 0
  let margem

  for (let iter = 0; iter < maxIter; iter++) {
    margem = parseFloat(result.margem.toFixed(2))

    if (margem === margemDesejada) {
      break
    }

    if (result.margem < margemDesejada) {
      if (!ajustefino) {
        precoVendaGuess += 1
      } else {
        total_aumentou_ajuste_fino++
        if (total_aumentou_ajuste_fino == 1) {
          precoVendaGuess += fator
        } else {
          precoVendaGuess += fator2
        }
      }
    } else {
      ajustefino = true

      if (total_aumentou_ajuste_fino <= 1) {
        precoVendaGuess -= fator
      } else {
        precoVendaGuess -= fator2
      }
    }

    result = calcularAmazonPrecoVendaSimples(
      precoVendaGuess,
      custo,
      fixo,
      comissaoPercent,
      impostoPercent,
      regrasPesoFreteAmazon,
      peso
    )
  }

  return { precoVenda: precoVendaGuess, ...result }
}

export function calcularMagaluPrecoVendaIterativoMargem({
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasDescontoFreteMagalu,
  regrasPesoFreteMagalu,
  peso,
  margemDesejada,
  tenantId,
  marketplace,
  descontoFrete
}) {
  const maxIter = 200 // limite de iterações para evitar loop infinito

  // Chute inicial baseado na fórmula genérica do handleMargemChange
  let precoVendaGuess =
    (custo + fixo) / (1 - (impostoPercent + comissaoPercent) / 100 - margemDesejada / 100)

  let result = calcularMagaluPrecoVendaSimples(
    precoVendaGuess,
    custo,
    fixo,
    comissaoPercent,
    impostoPercent,
    regrasDescontoFreteMagalu,
    regrasPesoFreteMagalu,
    peso,
    tenantId,
    marketplace,
    descontoFrete
  )

  let fator = 0.01,
    fator2 = 0.001
  let ajustefino = false
  let total_aumentou_ajuste_fino = 0
  let margem

  for (let iter = 0; iter < maxIter; iter++) {
    margem = parseFloat(result.margemCalcMagalu.toFixed(2))

    if (margem === margemDesejada) {
      break
    }

    if (result.margemCalcMagalu < margemDesejada) {
      if (!ajustefino) {
        precoVendaGuess += 1
      } else {
        total_aumentou_ajuste_fino++
        if (total_aumentou_ajuste_fino == 1) {
          precoVendaGuess += fator
        } else {
          precoVendaGuess += fator2
        }
      }
    } else {
      ajustefino = true

      if (total_aumentou_ajuste_fino <= 1) {
        precoVendaGuess -= fator
      } else {
        precoVendaGuess -= fator2
      }
    }

    result = calcularMagaluPrecoVendaSimples(
      precoVendaGuess,
      custo,
      fixo,
      comissaoPercent,
      impostoPercent,
      regrasDescontoFreteMagalu,
      regrasPesoFreteMagalu,
      peso,
      tenantId,
      marketplace,
      descontoFrete
    )
  }

  return { precoVenda: precoVendaGuess, ...result }
}

export function calcularMagaluPrecoVendaSimples(
  precoVenda,
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasDescontoFreteMagalu,
  regrasPesoFreteMagalu,
  peso,
  tenantId,
  marketplace,
  descontoFrete
) {
  // Calcula o valor do frete para Magalu com base nas regras definidas

  const freteBruto = calcularFreteParaMagalu(peso, precoVenda, regrasPesoFreteMagalu)

  // Aplica o desconto no frete
  const frete = (freteBruto * (100 - descontoFrete)) / 100

  // Aplica os percentuais de imposto e comissão sobre o preço de venda
  const denominador = 1 - (impostoPercent + comissaoPercent) / 100
  const netSale = precoVenda * denominador - frete // Ajuste aqui para subtrair o frete corretamente

  // Calcula o lucro subtraindo os custos (produto, fixo e frete)
  const lucroCalc = netSale - (custo + fixo)

  // Calcula a margem de lucro em relação ao preço de venda
  const margemCalcMagalu = precoVenda > 0 ? (lucroCalc / precoVenda) * 100 : 0

  return { frete, lucroCalc, margemCalcMagalu }
}

export function calcularFreteParaMagalu(peso, precoVendaBruto, regrasFreteMagalu) {
  // Encontrar a faixa de peso correspondente ao peso do produto
  const faixaPesoCorreta = regrasFreteMagalu.find(
    regra => peso >= regra.limite_inferior && peso <= regra.limite_superior
  )

  if (!faixaPesoCorreta) {
    throw new Error(`Nenhuma faixa de peso encontrada para o peso ${peso}g no Magalu.`)
  }

  return faixaPesoCorreta.valor_frete
}

export async function fetchValorMinFrete(setValorMinFreteGratisML) {
  try {
    let dados = { requisicao: 'getMinFreteGratisMercadoLivre' }
    let url = config.host + '/configuracoesGerais'

    const response = await fetchData(url, dados)

    if (response.success) {
      setValorMinFreteGratisML(response.data.valorMinimoFreteGratisMercadoLivre || 79)
    }
  } catch (error) {
    console.error('Erro ao buscar valor mínimo de frete grátis:', error)
  }
}

// export const calcularPrecoFinalComFrete = (
//   custo,
//   margem,
//   comissao,
//   regrasFrete,
//   peso,
//   descontoFrete
// ) => {
//   let convergiu = false
//   let iteracoes = 0
//   // Inicia sem o frete incluso
//   let precoVendaBruto = calcularPrecoSemFrete(custo, margem, comissao)
//   let freteAtual = 0

//   while (!convergiu && iteracoes < 10) {
//     iteracoes++

//     let novoFrete = calcularFreteComDescontoMercadoLivre(
//       regrasFrete,
//       peso,
//       descontoFrete,
//       precoVendaBruto
//     )

//     // Verifica convergência com tolerância
//     if (Math.abs(novoFrete - freteAtual) < 0.01) {
//       convergiu = true
//     } else {
//       freteAtual = novoFrete
//       // Inclui o frete no custo para o novo cálculo
//       precoVendaBruto = calcularPrecoSemFrete(custo + freteAtual, margem, comissao)
//     }
//   }

//   return { precoVendaBruto, freteAtual }
// }

const calcularPrecoSemFrete = (custo, margem, comissao) => {
  const margemDecimal = margem / 100
  const precoBase = custo + custo * margemDecimal

  return parseFloat((precoBase / (1 - comissao / 100)).toFixed(2))
}

export function calcularFretePorMarketplace(params) {
  const {
    marketplace,
    venda,
    produtoSelecionado,
    regrasPesoFreteMercadoLivre,
    peso,
    descontoAtualFreteMercadoLivre,
    valorMinFreteGratisML,
    regrasPesoFreteAmazon,
    regrasPesoFreteShopee,
    regrasAmericanas,
    descontoVendedorAmericanas,
    regrasViaVarejo,
    descontoVendedorViaVarejo,
    regrasPesoFreteMagalu,
    descontoAtualFreteMagalu,
    setValorFreteMagalu
  } = params

  const marketplaceId = marketplace._id
  const precoVendaBruto = parseFloat(venda[marketplaceId]) || 0
  let freteCalculado = 0

  if (marketplace.nome === 'Mercado Livre') {
    freteCalculado = calcularFreteComDescontoMercadoLivre(
      regrasPesoFreteMercadoLivre,
      peso,
      descontoAtualFreteMercadoLivre,
      precoVendaBruto,
      valorMinFreteGratisML
    )
  } else if (marketplace.nome === 'Via Varejo') {
    freteCalculado = calcularFreteParaAmericanas(
      peso,
      precoVendaBruto,
      regrasViaVarejo,
      descontoVendedorViaVarejo
    )
  }

  return freteCalculado
}

export function calcularValoresSemFrete(precoVenda, custo, fixo, impostoPercent, comissaoPercent) {
  // Calcula o percentual líquido da venda, ou seja, o fator multiplicador
  // após a dedução de impostos e comissão
  const percentualLiquido = 1 - (impostoPercent + comissaoPercent) / 100

  // Valor da venda líquida (após deduzir impostos e comissão)
  const vendaLiquida = precoVenda * percentualLiquido

  // Lucro sem considerar o frete: subtrai os custos fixos do valor líquido
  const lucroSemFrete = vendaLiquida - (custo + fixo)

  // Margem sem frete: percentual do lucro em relação ao preço de venda
  const margemSemFrete = precoVenda > 0 ? (lucroSemFrete / precoVenda) * 100 : 0

  return { vendaLiquida, lucroSemFrete, margemSemFrete }
}

export function ConfiguraFreteEFixoMercadoLivre(
  valorVenda,
  setValorFreteMercadoLivre,
  setSelectedMarketplacesData,
  marketplace,
  frete
) {
  let updatedMarketplace
  if (valorVenda >= 79) {
    setValorFreteMercadoLivre(frete)
    updatedMarketplace = addFixoParameter(marketplace, 0)
  } else {
    updatedMarketplace = addFixoParameter(marketplace, frete)
    setValorFreteMercadoLivre(0)
  }

  // Atualiza somente o marketplace referente, mantendo os demais
  setSelectedMarketplacesData(prev =>
    prev.map(item => (item._id === marketplace._id ? updatedMarketplace : item))
  )
}

export function ConfiguraFreteEFixoAmazon(
  valorVenda,
  setValorFreteAmazon,
  setSelectedMarketplacesData,
  marketplace,
  frete
) {
  let updatedMarketplace
  if (valorVenda >= 79) {
    setValorFreteAmazon(frete)
    updatedMarketplace = addFixoParameter(marketplace, 0)
  } else {
    updatedMarketplace = addFixoParameter(marketplace, frete)
    setValorFreteAmazon(0)
  }

  // Atualiza somente o marketplace referente, mantendo os demais
  setSelectedMarketplacesData(prev =>
    prev.map(item => (item._id === marketplace._id ? updatedMarketplace : item))
  )
}

export function calcularAmazonPrecoVendaSimples(
  precoVenda,
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasPesoFreteAmazon,
  peso
) {
  // Calcula o valor do frete usando a função específica para a Amazon
  const frete = calcularFreteParaAmazon(peso, precoVenda, regrasPesoFreteAmazon)

  // Calcula a venda líquida (descontando imposto e comissão)
  const denominador = 1 - (impostoPercent + comissaoPercent) / 100
  const netSale = precoVenda * denominador

  // O lucro real é o líquido menos os custos (produto + fixo + frete)
  const lucroCalcAmazon = netSale - (custo + fixo + frete)

  // A margem é o lucro em % do preço de venda
  const margemCalcAmazon = precoVenda > 0 ? (lucroCalcAmazon / precoVenda) * 100 : 0

  return { frete, lucro: lucroCalcAmazon, margem: margemCalcAmazon }
}

export function calcularAmazonPrecoVendaIterativoLucro({
  custo,
  fixo,
  comissaoPercent,
  impostoPercent,
  regrasPesoFreteAmazon,
  peso,
  lucroDesejado
}) {
  const maxIter = 200 // Limite de iterações para evitar loop infinito

  // Chute inicial: baseado na soma dos custos + lucro desejado, ajustado pela comissão
  let precoVendaGuess = (custo + (fixo ?? 0) + lucroDesejado) / (1 - comissaoPercent / 100)

  let result = calcularAmazonPrecoVendaSimples(
    precoVendaGuess,
    custo,
    fixo ?? 0,
    comissaoPercent,
    impostoPercent,
    regrasPesoFreteAmazon,
    peso
  )
  console.log('[DEBUG] Resultado inicial:', result)
  // Fatores para ajustes finos
  let fator = 1,
    fator2 = 0.1
  let ajusteFino = false
  let totalAumentouAjusteFino = 0
  let lucro

  for (let iter = 0; iter < maxIter; iter++) {
    lucro = parseFloat(result.lucro.toFixed(2))

    if (lucro === lucroDesejado) {
      // Se o lucro calculado está dentro da tolerância desejada, encerra o loop
      break
    }

    if (result.lucro < lucroDesejado) {
      // Se o lucro calculado é menor que o desejado, aumenta o preço
      if (!ajusteFino) {
        precoVendaGuess += 1
      } else {
        totalAumentouAjusteFino++
        if (totalAumentouAjusteFino === 1) {
          precoVendaGuess += fator
        } else {
          precoVendaGuess += fator2
        }
      }
    } else {
      // Se o lucro calculado é maior que o desejado, diminui o preço
      ajusteFino = true
      if (totalAumentouAjusteFino <= 1) {
        precoVendaGuess -= fator
      } else {
        precoVendaGuess -= fator2
      }
    }

    result = calcularAmazonPrecoVendaSimples(
      precoVendaGuess,
      custo,
      fixo ?? 0,
      comissaoPercent,
      impostoPercent,
      regrasPesoFreteAmazon,
      peso
    )
  }

  return { precoVenda: precoVendaGuess, ...result }
}
