// MarketplaceFilter.jsx
import { useState, useEffect } from 'react'
import { Form, Button, Collapse } from 'react-bootstrap'
import { CarregarMarketplaces } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraGlobal/cadastro-regra-global'

const MarketplaceFilter = ({ onChange, setPrecos, precosCompleto }) => {
  const [marketplaces, setMarketplaces] = useState([])
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([])
  const [error, setError] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    const fetchMarketplaces = async () => {
      try {
        const result = await CarregarMarketplaces()
        setMarketplaces(result)
        if (selectedMarketplaces.length === 0 && result.length > 0) {
          const allIds = result.map(mp => mp._id)
          setSelectedMarketplaces(allIds)
          onChange?.(allIds)
          setPrecos(precosCompleto)
        }
      } catch (err) {
        setError(err.message)
      }
    }
    if (precosCompleto.length > 0) fetchMarketplaces()
  }, [onChange, precosCompleto, selectedMarketplaces.length, setPrecos])

  const handleCheckboxChange = (id, isChecked) => {
    const updated = isChecked
      ? [...selectedMarketplaces, id]
      : selectedMarketplaces.filter(item => item !== id)
    setSelectedMarketplaces(updated)
    onChange?.(updated)

    if (updated.length === 0) {
      setPrecos(precosCompleto)
    } else {
      const filtered = precosCompleto.filter(item => updated.includes(item.marketplace))
      setPrecos(filtered)
    }
  }

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '1rem',
        borderRadius: '5px',
        width: '217 px',
        marginBottom: '1rem'
      }}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <Button
        variant="secondary"
        style={{
          marginBottom: '0.5rem',
          marginLeft: '0.5rem',
          width: '170px', // largura fixa para alinhar com os demais
          whiteSpace: 'nowrap', // evita quebra de linha
          backgroundColor: '#FFFFFF',
          color: '#000'
        }}
      >
        Marketplaces {isExpanded ? '▲' : '▼'}
      </Button>
      <Collapse in={isExpanded}>
        <div>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <Form>
            {marketplaces.map(mp => (
              <div key={mp._id} style={{ marginBottom: '0.5rem' }}>
                <input
                  type="checkbox"
                  id={mp._id}
                  checked={selectedMarketplaces.includes(mp._id)}
                  onChange={e => handleCheckboxChange(mp._id, e.target.checked)}
                />
                <label htmlFor={mp._id} style={{ marginLeft: '0.5rem' }}>
                  {mp.nome}
                </label>
              </div>
            ))}
          </Form>
        </div>
      </Collapse>
    </div>
  )
}

export default MarketplaceFilter
