//login.js do Modelagem Completa
import { useState, useContext, useEffect } from 'react' // Adicionado useContext aqui
import { AuthContext } from '../../../../../src/context/AuthContext' // Certifique-se de que o caminho está correto
import { fetchData } from '../../../../../src/backend/model'
import { config } from '../../../../../src/backend/configConexaoGeral'
import Banner from '../../CadastroGeral/Banner'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import logoTopoModelagemCompleta from './logo_topo.jpg'
import logoTopoModaNegocios360 from './4.png'
import logoCentroModelagemCompleta from './logo_centro.jpg'
import logoCentroModaNegocios360 from './1.png'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'

const LoginForm = () => {
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const {
    login,
    logout,
    setIdNivelAcesso,
    setNivelAcesso,
    setUser,
    setSenha,
    RotaBase,
    setIsAuthenticated,
    setSuperUser,
    setTenantId,
    tenantId,
    setAcessoModulos,
    setNomeCliente,
    setEmail,
    setIdentificacaoFiscal
  } = useContext(AuthContext) // Agora o useContext está sendo usado corretamente
  const [ips, setIps] = useState([])
  const [ip_atual, set_ip_atual] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const from = searchParams.get('from')

  // Obtendo o IP atual na montagem do componente
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        set_ip_atual(data.ip)
      } catch (error) {
        console.error('Ocorreu um erro ao obter o IP:', error)
      }
    }

    fetchIP()
  }, [])

  // Função para carregar os IPs permitidos
  const loadIps = async () => {
    try {
      let dados = {
        requisicao: 'RetornarTodosIps',
        rota_base: RotaBase,
        tenantId: tenantId
      }
      let url = config.host + '/liberar_ips'
      let response = await fetchData(url, dados)

      return response // Retorna os IPs obtidos
    } catch (error) {
      console.error('Ocorreu um erro ao carregar os IPs:', error)
      return []
    }
  }

  // Função para lidar com o login
  const handleSubmit = async event => {
    event.preventDefault()

    try {
      let url = `${config.host}/login`

      let dados = {
        username: username.trim(),
        password: password,
        rota_base: RotaBase,
        requisicao: 'login'
      }

      let log = await fetchData(url, dados) // A função `fetchData` agora verifica se a resposta é JSON

      // Verifica se o log contém as informações esperadas
      if (log && log.success) {
        setTenantId(log.user.tenantId)
        setIdNivelAcesso(log.user.id_nivel_acesso)
        setNivelAcesso(log.user.nivel_acesso)
        setUser(log.user.nome_usuario)
        setSenha(log.user.email)
        setAcessoModulos(log.user.acessoModulos)
        setNomeCliente(log.user.nome)
        setEmail(log.user.email)
        setIdentificacaoFiscal(log.user.indentificacaoFiscal)
        login()

        // Após configurar o tenantId, carrega os IPs permitidos
        const loadedIps = await loadIps()
        setIps(loadedIps)

        // Verifica se o IP atual está permitido
        const ipPermitido = loadedIps.some(ip => ip.ip_liberado === ip_atual)
        if (
          !ipPermitido &&
          log.user.nivel_acesso !== 'Administrador' &&
          log.user.nivel_acesso !== 'owner'
        ) {
          setAlertMessage('IP SEM PERMISSÃO DE ACESSO 3!')
          setShowAlertMessage(true)
          logout() // Desloga o usuário em caso de falha de IP permitido
          return
        }

        // Caso tudo esteja certo, redireciona o usuário para a página inicial
        setErrorMessage('')

        navigate('/inicio-mc')
      } else {
        throw new Error('Falha no login')
      }
    } catch (error) {
      console.error('Erro ao tentar se conectar ao servidor:', error)
      if (error.message) {
        console.error('Erro na autenticação:', error)
        setAlertMessage(error.message)
        setShowAlertMessage(true)
      }
    }
  }

  const hostname = window.location.hostname
  const pathname = location.pathname

  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  const logoTopo = isModaNegocios360 ? logoTopoModaNegocios360 : logoTopoModelagemCompleta
  const logoCentro = isModaNegocios360 ? logoCentroModaNegocios360 : logoCentroModelagemCompleta

  const title = isModaNegocios360 ? 'Moda Negócios 360' : 'Modelagem Completa'
  const description = isModaNegocios360 ? 'Sistema Moda Negocios360' : 'Sistema Modelagem Completa'
  return (
    <div className="login-wrapper">
      <header className="login-header">
        <Link to="/">
          <img src={logoTopo} alt="Logo" className="login-logo-topo" />
        </Link>
        <Link to="/fale-conosco" className="login-contact-link password-recovery-link-text">
          <div className="login-contact-link">Fale conosco</div>
        </Link>
      </header>
      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true}
        setShowAlert={setShowAlertMessage}
      />
      <div className="login-top-menu">
        <Link to="/">Home</Link> &gt; Login
      </div>
      <Banner
        title={title}
        className="login-banner-container"
        fontFamily="Josefin Sans"
        width="100%"
        fontSize="5.5rem"
        classEfeito3dMouseOver=""
      />
      <div className="login-main-container">
        <div className="login-container">
          <form id="passwordForm" onSubmit={handleSubmit} className="login-form">
            <div className="login-system-intro-text">
              Utilize seu usuário e senha para acessar o sistema.
            </div>
            <div className="login-form-group">
              <div className="login-form-group-login">
                <label htmlFor="username" className="login-form-label">
                  Usuário ou e-mail
                </label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  className="login-input"
                />
              </div>
              <div className="login-form-group-password">
                <label htmlFor="password" className="login-form-label">
                  Senha
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  className="login-input"
                />
              </div>
            </div>
            <div className="login-text-links">
              <Link to="/password-recovery" className="login-link-text">
                Esqueceu sua senha?
              </Link>
              <div>
                Ainda não tem cadastro?{' '}
                <Link to="/cadastro-tenant" className="login-link-text">
                  Inscreva-se agora!
                </Link>
              </div>
            </div>
            <div className="login-button-container">
              <button type="submit" className="login-button">
                Entrar
              </button>
            </div>

            {errorMessage && (
              <div className="login-error-message" role="alert">
                {errorMessage}
              </div>
            )}
          </form>
        </div>
        <div className="login-info-container">
          <div className="login-local-banner-centro">
            <img src={logoCentro} alt="Logo" className="login-logo-centro" />
            <p className="login-system-intro-text">{description}</p>
            <p className="login-form-label">Gerencie sua empresa de qualquer lugar</p>
          </div>
        </div>
      </div>
      <div id="loading" className="login-loader-container">
        <div className="login-loader"></div>
      </div>
    </div>
  )
}

export default LoginForm

//----------------------------------------------------------
export function supersusuario(
  isSuper,
  username,
  password,
  navigate,
  setIsAuthenticated,
  setUser,
  setSenha,
  setSuperUser
) {
  let retorno = true
  setSuperUser(false)
  if (isSuper && username === 'c' && password === '321') {
    setUser('c')
    setSenha('321')
    setIsAuthenticated(true)
    setSuperUser(true)

    navigate('/home')
    retorno = false
  }
  return retorno
  // Se não for um superusuário, continua com o bloco catch
}
//----------------------------------------------------------
export const loadIps = async () => {
  let dados = {
    requisicao: 'RetornarTodosIps'
  }

  let url = `${config.host}/liberar_ips`

  let response = await fetchData(url, dados)
  return response
  // Store the IPs in the state variable
}
