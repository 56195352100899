import { useState, useEffect } from 'react'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils.jsx'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { CustomField } from '../../../../../../src/components/CustomField/custom-field.jsx'
import { FaInfoCircle } from 'react-icons/fa' // Ícone de informação

const ConfigurarValorMinFreteML = () => {
  const [valorMinFrete, setValorMinFrete] = useState('')
  const [loading, setLoading] = useState(false)
  const [showAlerta, setShowAlerta] = useState(false)
  const [mensagemAlerta, setMensagemAlerta] = useState('')
  const [variant, setVariant] = useState('danger')

  // Buscar o valor salvo no banco ao carregar a página
  useEffect(() => {
    async function fetchValorMinFrete() {
      try {
        let dados = { requisicao: 'getMinFreteGratisMercadoLivre' }
        let url = config.host + '/configuracoesGerais'

        const response = await fetchData(url, dados)

        if (response.success) {
          setValorMinFrete(response.data.valorMinimoFreteGratisMercadoLivre || 0)
        }
      } catch (error) {
        console.error('Erro ao buscar valor mínimo de frete grátis:', error)
        setShowAlerta(true)
        setMensagemAlerta('Erro ao buscar o valor.')
        setVariant('danger')
      }
    }

    fetchValorMinFrete()
  }, [])

  // Função para salvar o novo valor no banco
  const handleSave = async () => {
    if (valorMinFrete === '') {
      setShowAlerta(true)
      setMensagemAlerta('Digite um valor antes de salvar.')
      setVariant('warning')
      return
    }

    setLoading(true)
    try {
      let dados = {
        requisicao: 'setMinFreteGratisMercadoLivre',
        valor: parseFloat(valorMinFrete)
      }
      let url = config.host + '/configuracoesGerais'

      const response = await fetchData(url, dados)

      if (response.success) {
        setShowAlerta(true)
        setMensagemAlerta('Valor atualizado com sucesso!')
        setVariant('success')
      } else {
        setShowAlerta(true)
        setMensagemAlerta('Erro ao atualizar o valor.')
        setVariant('danger')
      }
    } catch (error) {
      console.error('Erro ao atualizar valor mínimo de frete grátis:', error)
      setShowAlerta(true)
      setMensagemAlerta('Erro inesperado ao atualizar.')
      setVariant('danger')
    } finally {
      setLoading(false)
    }
  }

  const handleClear = () => {
    setValorMinFrete('')
  }

  return (
    <div style={styles.container}>
      <h2 className="mt-4 d-flex align-items-center">
        Configurar Valor Mínimo de Frete Grátis (Mercado Livre)
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-sobre">
              <strong>O que é isso?</strong> <br />
              Aqui você pode configurar o valor mínimo de compra necessário para que um pedido
              receba **frete grátis** no Mercado Livre.
              <br />
              - Informe o valor mínimo desejado.
              <br />
              - Esse valor impactará diretamente a oferta de frete grátis.
              <br />- Alterações feitas aqui são aplicadas globalmente aos seus produtos.
            </Tooltip>
          }
        >
          <span className="ms-2">
            <FaInfoCircle size={20} style={{ cursor: 'pointer', color: '#007bff' }} />
          </span>
        </OverlayTrigger>
      </h2>

      <form style={styles.form}>
        <ShowAlertGeneric
          message={mensagemAlerta}
          showAlert={showAlerta}
          variant={variant}
          setShowAlert={setShowAlerta}
        />

        <CustomField
          label="Valor mínimo para frete grátis"
          placeholder="Digite o valor"
          value={valorMinFrete}
          onChange={e => setValorMinFrete(e.target.value)}
          style={styles.input}
          buttonTop="15px"
          type="number"
          onClear={handleClear}
        />

        <Button onClick={handleSave} disabled={loading} style={styles.button}>
          {loading ? 'Salvando...' : 'Salvar Configuração'}
        </Button>
      </form>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5vw'
  },
  input: {
    width: '15vw',
    marginTop: '30px'
  },
  button: {
    marginTop: '10px'
  }
}

export default ConfigurarValorMinFreteML
