//calculo-precoPF
import {
  ResultSearch,
  mergeComissoesWithMarketplaces,
  CarregarComissoesProduto,
  ComissaoMarketplace,
  CarregarRegrasPorGrupoAmVv,
  calcularFreteParaAmazon,
  carregarRegrasAmericanas,
  carregarRegrasViaVarejo,
  carregarDescontoVendedorAmericanas,
  carregarDescontoVendedorViaVarejo
} from './calculo-precoPF.js'
import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { AuthContext } from '/src/context/AuthContext'
import SelectMarketPlace from '/PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/SelectMarkeplace/select-marketplace'
import { Button, Row, Col, Modal, Form, Table } from 'react-bootstrap'
import {
  CarregarRegrasPrecoFrete, // peso na verdade é desconto em porcentagem
  CarregarRegrasPesoFrete,
  carregaIdMarketplace
} from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraMagalu/regra-magalu'
import { CarregarProdutos } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/EdicaoProduto/edicao-produto'

import { CustomField } from '../../../../../../src/components/CustomField/custom-field'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { CarregarMarketplaces } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraGlobal/cadastro-regra-global'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../../../src/backend/model'
import { FaPlus } from 'react-icons/fa'
import CadastroProduto from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CadastroProduto/cadastro-produto'
import { carregarUltimoImposto } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/CadastroImposto/cadastro_imposto.js'
import { useDynamicState } from '../../../../../../src/utils/form-utils'
import { ProfitMarginCalculator } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/comissao-display.jsx'
import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils.jsx'
import ListaProdutosSimulacao from './ListaProdutosSimulacao'

function CalculoPreco({
  onSimulacaoGravada,
  produtoParaRevisao,
  precoParaRevisao,
  lucroParaRevisao,
  margemParaRevisao,
  marketplaceAtivo,
  esconderListaProdutos = false,
  setRefreshFlag,
  revisaoId,
  mostrarBotaoManter = false
}) {
  const [produtos, setProdutos] = useState({})
  const [produtoSelecionado, setProdutoSelecionado] = useState({})
  const [precoVenda, setPrecoVenda] = useState('')
  const [lucro, setLucro] = useState('')
  const [editableTotal, setEditableTotal] = useState('')
  const [margem, setMargem] = useState('')
  const { tenantId } = useContext(AuthContext)
  const [Dados, setDados] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [marketplaces, setMarketplaces] = useState([])
  const [mostrarRadioBoxes, setMostrarRadioBoxes] = useState(false)
  const [selectedMarketplaces, setSelectedMarketplaces] = useState({})
  const [comissoes, setComissoes] = useState([])
  const [comissaoMarketplace, setComissaoMarketplace] = useState(null)
  const [trava, setTrava] = useState({
    margem: false,
    precoVenda: false,
    lucro: false
  })
  const [displayDetails, setDisplayDetails] = useState(false) // referente as comissoes
  const [displayDetailsPrecoVendaSugerido, setDisplayDetailsPrecoVendaSugerido] = useState(false)
  const [isOpen, setIsOpen] = useState(false) //referente as comissoes
  const [isOpenPrecoVendaSugerido, setIsOpenPrecoVendaSugerido] = useState(false)
  const [stateMargem, setDynamicStateMargem] = useDynamicState()
  const [statePrecoVendaSugerido, setDynamicStatePrecoVendaSugerido] = useDynamicState()
  const [stateLucro, setDynamicStateLucro] = useDynamicState()
  const [marketplacesWithComissoes, setMarketplacesWithComissoes] = useState()

  const [showAddProductModal, setShowAddProductModal] = useState(false)
  const toggleAddProductModal = () => setShowAddProductModal(!showAddProductModal)
  const [hasMarketPlacesSelecionadosNosChecbox, setHasMarketPlacesSelecionadosNosChecbox] =
    useState(false)
  const [idMarketplace, setIdMarketplace] = useState('') ///usando para magalu e amazon
  const [idMarketplaceAmericanas, setIdMarketplaceAmericanas] = useState('') //usando para americanas
  const [idMarketplaceViaVarejo, setIdMarketplaceViaVarejo] = useState('') //usando para via varejo
  const [ultimaRegraSelecionada, setUltimaRegraSelecionada] = useState('')
  const [ultimaRegraSelecionadaML, setUltimaRegraSelecionadaML] = useState('')
  const [descontoAtualFreteMagalu, setDescontoAtualFreteMagalu] = useState(0)
  const [descontoAtualFreteMercadoLivre, setDescontoAtualFreteMercadoLivre] = useState(0)
  const [valorFreteMagalu, setValorFreteMagalu] = useState(0)
  const [valorFreteMercadoLivre, setValorFreteMercadoLivre] = useState(0)
  const [valorFreteAmericanas, setValorFreteAmericanas] = useState(0)
  const [valorFreteViaVarejo, setValorFreteViaVarejo] = useState(0)
  const [valorFreteAmazon, setValorFreteAmazon] = useState(0)
  const [regrasPesoFreteMercadoLivre, setRegrasPesoFreteMercadoLivre] = useState([])
  const [regrasPesoFreteAmazon, setRegrasPesoFreteAmazon] = useState([])
  const [regrasPorGrupoAmericanas, setRegrasPorGrupoAmericanas] = useState([])
  const [regrasPorGrupoViaVarejo, setRegrasPorGrupoViaVarejo] = useState([])
  const [grupoConfiguradoViaVarejo, setGrupoConfiguradoViaVarejo] = useState('')
  const [ultimoImposto, setUltimoImposto] = useState('')
  const [isLoadingRegrasAmericanas, setIsLoadingRegrasAmericanas] = useState(true)
  const [marketplaceColors, setMarketplaceColors] = useState({
    Amazon: '#FF9900', // Laranja Amazon
    'Mercado Livre': '#FFE600', // Amarelo Mercado Livre
    Shein: '#EE1D52', // Vermelho Shein
    Magalu: '#2AB598', // Turquesa Magalu
    Americanas: '#FF0000', // Vermelho Americanas
    'Via Varejo': '#0000FF', // Azul Via Varejo
    Shopee: '#FF69B4' // Rosa Shopee
  })
  const [regrasDescontoFreteMagalu, setRegrasDescontoFreteMagalu] = useState([])
  // Supondo que esta função busque os dados do produto por um ID ou nome
  //crie um useEffect para carregar todos os produtos usando a funcao CarregarProdutos
  const [regrasAmericanas, setRegrasAmericanas] = useState([])
  const [regrasViaVarejo, setRegrasViaVarejo] = useState([])
  const [descontoVendedorAmericanas, setDescontoVendedorAmericanas] = useState()
  const [descontoVendedorViaVarejo, setDescontoVendedorViaVarejo] = useState()
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('danger')
  const [simulacaoData, setSimulacaoData] = useState({})
  const [produtoParaCalculo, setProdutoParaCalculo] = useState(null)
  const [reset, setReset] = useState(false)

  const handleSelecionarProdutoParaCalculo = produto => {
    setProdutoSelecionado({
      sku: produto.sku,
      descricao: produto.descricao,
      custo: produto.custo,
      gramas: produto.gramas,
      categoriaId: produto.categoriaId,
      _id: produto._id
    })
  }

  const handleSimulacaoUpdate = data => {
    setSimulacaoData(data)
  }
  useEffect(() => {
    const carregarProdutoSelecionado = async () => {
      if (!produtoParaRevisao || !tenantId) return

      const produtos = await CarregarProdutos(tenantId)
      const produto = produtos.find(p => p._id === produtoParaRevisao)

      if (produto) {
        setProdutoSelecionado({
          sku: produto.sku,
          descricao: produto.descricao,
          custo: produto.custo,
          gramas: produto.gramas,
          categoriaId: produto.categoriaId,
          _id: produto._id
        })
      }
    }

    carregarProdutoSelecionado()
  }, [produtoParaRevisao, tenantId])

  useEffect(() => {
    if (marketplaceAtivo && marketplaces.length > 0) {
      const marketplaceSelecionado = marketplaces.find(mp => mp.nome === marketplaceAtivo)
      if (marketplaceSelecionado) {
        setSelectedMarketplaces({ [marketplaceSelecionado._id]: true })
      }
    }
  }, [marketplaceAtivo, marketplaces])

  useEffect(() => {
    //let peso = obterPesoEmbalagem(25, 20, 7)

    const fetchImposto = async () => {
      await carregarUltimoImposto(tenantId, 'icms', setUltimoImposto)
    }
    if (tenantId) {
      fetchImposto()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId])
  useEffect(() => {
    const fetchData = async () => {
      if (!tenantId) return
      setIsLoading(true)
      const resultado = await CarregarProdutos(tenantId)

      setProdutos(resultado)

      // Faça algo com o resultado, se necessário
    }

    fetchData()
  }, [tenantId])

  useEffect(() => {
    const fetchTenantMarketplaces = async () => {
      try {
        const url = config.host + '/regraGlobalPF'

        const dados = { tenantId, requisicao: 'getTenantMarketplaces' }

        const response = await fetchData(url, dados)

        if (response) {
          setSelectedMarketplaces(response.marketplaces || [])

          const allMarketplaces = await CarregarMarketplaces()

          const tenantMarketplaces = allMarketplaces.filter(marketplace =>
            response.marketplaces.includes(marketplace._id)
          )

          setMarketplaces(tenantMarketplaces)
        }
      } catch (error) {
        console.error('Erro ao carregar marketplaces do tenant:', error)
        // Trate o erro conforme necessário
      }
    }

    if (tenantId) {
      fetchTenantMarketplaces()
    }
  }, [tenantId])

  useEffect(() => {
    if (produtoSelecionado) {
      const comissoes = async () => {
        try {
          const response = await CarregarComissoesProduto(produtoSelecionado.categoriaId)

          setComissoes(response || [])
        } catch (error) {
          console.error('Erro ao carregar as comissões:', error)
          // Trate o erro conforme necessário
        }
      }
      // if (produtoSelecionado?.length > 0)
      comissoes()
    }
  }, [produtoSelecionado])

  const { data: regrasDesconto, isLoading: isLoadingRegras } = useQuery(
    'regrasDescontoFrete',
    () => CarregarRegrasPrecoFrete(),
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    async function carregarFreteAmazon() {
      try {
        const regrasPeso = await CarregarRegrasPesoFrete('amazon') // Carrega as regras (LimitePesoPF)
        setRegrasPesoFreteAmazon(regrasPeso)
        // Supondo que "precoVenda" seja o valor de venda sugerido (convertido para número)
        const valor = parseFloat(precoVenda) || 0
        const valorFrete = calcularFreteParaAmazon(produtoSelecionado.gramas, valor, regrasPeso)
        setValorFreteAmazon(valorFrete)
      } catch (error) {
        console.error('Erro ao carregar o frete da Amazon:', error)
      }
    }

    if (produtoSelecionado && produtoSelecionado.gramas) {
      carregarFreteAmazon()
    }
  }, [produtoSelecionado, precoVenda])

  useEffect(() => {
    const carregarDados = async () => {
      try {
        const dadosCarregados = await CarregarRegrasPesoFrete('mercadolivre')
        // console.log('🚚 Tabela de fretes Mercado Livre carregada:', dadosCarregados)
        setRegrasPesoFreteMercadoLivre(dadosCarregados)
      } catch (err) {
        console.error('❌ Erro ao carregar as regras de peso de frete do Mercado Livre:', err)
      }
    }
    carregarDados()
  }, [])

  useEffect(() => {
    async function carregarDados() {
      let idMarketplace = await carregaIdMarketplace('Magalu')

      setIdMarketplace(idMarketplace[0]._id)
    }
    carregarDados()
  }, [])

  useEffect(() => {
    async function carregarDados() {
      let idMarketplace = await carregaIdMarketplace('Americanas')

      setIdMarketplaceAmericanas(idMarketplace[0]._id)
    }
    carregarDados()
  }, [])

  useEffect(() => {
    async function carregarDados() {
      let idMarketplace = await carregaIdMarketplace('Via Varejo')

      setIdMarketplaceViaVarejo(idMarketplace[0]._id)
    }
    carregarDados()
  }, [])

  useEffect(() => {
    async function fetchUltimaRegraSelecionada() {
      //referente a magalu
      if (idMarketplace) {
        try {
          let dados = {
            tenantId: tenantId,
            marketplace: idMarketplace,
            requisicao: 'BuscarUltimaRegraSelecionada'
          }
          let url = config.host + '/regraDescontoFrete'
          let response = await fetchData(url, dados)
          let ultimaRegraSelecionada = response?.regra // Suponha que o backend retorna o objeto da regra com '_id'

          if (ultimaRegraSelecionada) {
            setUltimaRegraSelecionada(ultimaRegraSelecionada)
          }
        } catch (error) {
          console.error('Erro ao buscar a última regra selecionada:', error)
        }
      }
    }

    fetchUltimaRegraSelecionada()
  }, [tenantId, idMarketplace])
  //----------------------ULTIMA REGRA SELECIONADA DO MERCADO LIVRE ( SE É VERDE OU AMARELO)-------------------
  useEffect(() => {
    async function fetchUltimaRegraSelecionada() {
      if (idMarketplace) {
        try {
          let dados = {
            tenantId: tenantId,
            requisicao: 'BuscarUltimaRegraDescontoFreteMercadoLivre'
          }
          let url = config.host + '/regraDescontoFrete'

          let response = await fetchData(url, dados)

          let ultimaRegraSelecionada = response

          if (ultimaRegraSelecionada) {
            setUltimaRegraSelecionadaML(ultimaRegraSelecionada)
          }
        } catch (error) {
          console.error('Erro ao buscar a última regra selecionada:', error)
        }
      }
    }

    fetchUltimaRegraSelecionada()
  }, [tenantId, idMarketplace])
  function getDescontoById(ultimaRegraSelecionada, regras) {
    // Encontrar a regra que corresponde ao ID fornecido
    const regraEncontrada = regras.find(regra => regra._id === ultimaRegraSelecionada)

    // Se a regra for encontrada, retornar o valor de desconto, senão retornar undefined ou algum valor padrão
    return regraEncontrada ? regraEncontrada.desconto : undefined
  }

  useEffect(() => {
    async function fetchDesconto() {
      if (regrasDesconto) {
        const desconto = await getDescontoById(ultimaRegraSelecionada, regrasDesconto)

        setDescontoAtualFreteMagalu(desconto)
      }
    }

    fetchDesconto()
  }, [regrasDesconto, ultimaRegraSelecionada])

  useEffect(() => {
    async function fetchDesconto() {
      const desconto = ultimaRegraSelecionadaML.regraId?.desconto

      setDescontoAtualFreteMercadoLivre(desconto)
    }

    fetchDesconto()
  }, [ultimaRegraSelecionadaML])

  //------------------------------REFERENTE A AMERICANAS E VIA VAREJO-----------------------------------

  useEffect(() => {
    async function carregarParametrosAmericanas() {
      if (!tenantId || !idMarketplaceAmericanas) return
      setIsLoadingRegrasAmericanas(true)
      try {
        const regras = await carregarRegrasAmericanas()
        setRegrasAmericanas(regras)
        const desconto = await carregarDescontoVendedorAmericanas(tenantId, idMarketplaceAmericanas)
        setDescontoVendedorAmericanas(desconto)
      } catch (error) {
        console.error('Erro ao carregar parâmetros para Americanas:', error)
      } finally {
        setIsLoadingRegrasAmericanas(false)
      }
    }
    if (tenantId) {
      carregarParametrosAmericanas()
    }
  }, [tenantId, idMarketplaceAmericanas])

  useEffect(() => {
    const carregarParametrosViaVarejo = async () => {
      try {
        if (!tenantId || !idMarketplaceAmericanas) {
          return
        }
        const regras = await carregarRegrasViaVarejo()
        console.log('regras via varejo no useeffect', regras)
        setRegrasViaVarejo(regras)

        const desconto = await carregarDescontoVendedorViaVarejo(tenantId, idMarketplaceViaVarejo)

        setDescontoVendedorViaVarejo(desconto)
      } catch (error) {
        console.error('Erro ao carregar parâmetros para Via Varejo:', error)
      }
    }

    if (tenantId && idMarketplaceViaVarejo) {
      carregarParametrosViaVarejo()
    }
  }, [tenantId, idMarketplaceViaVarejo])

  useEffect(() => {
    const carregarRegras = async () => {
      if (idMarketplaceViaVarejo && tenantId) {
        try {
          const response = await CarregarRegrasPorGrupoAmVv(
            grupoConfiguradoViaVarejo,
            idMarketplaceViaVarejo
          )

          setRegrasPorGrupoViaVarejo(response || [])
        } catch (error) {
          console.error('Erro ao carregar as regras por grupo:', error)
        }
      }
    }
    carregarRegras()
  }, [idMarketplaceViaVarejo, tenantId, grupoConfiguradoViaVarejo])

  //-----------useEffect para carregar as regras de frete ------MAGALU---------------------
  useEffect(() => {
    async function carregarRegrasDescontoMagalu() {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'CarregarRegrasDescontoFrete' }
      const response = await fetchData(url, dados)

      setRegrasDescontoFreteMagalu(response)
    }
    carregarRegrasDescontoMagalu()
  }, [])

  //----------------------------------------------------------

  function valorFretePorPeso(regrasPesoFrete, pesoAtual, valorProduto) {
    // Filtrar as faixas de peso que incluem o peso atual

    const faixasPeso = regrasPesoFrete.filter(
      regra => regra.limite_inferior <= pesoAtual && regra.limite_superior >= pesoAtual
    )

    if (faixasPeso.length === 0) {
      throw new Error('Nenhuma faixa de peso encontrada para o peso atual.')
    }

    // Filtrar as faixas de peso que também correspondem ao valor mínimo do produto
    const faixasValidas = faixasPeso.filter(regra => valorProduto >= regra.valor_minimo_produto)

    if (faixasValidas.length === 0) {
      throw new Error('Nenhuma faixa de peso encontrada que corresponda ao valor do produto.')
    }

    // Encontrar a regra mais específica (com maior valor_minimo_produto)
    const faixa = faixasValidas.reduce((prev, curr) => {
      return prev.valor_minimo_produto > curr.valor_minimo_produto ? prev : curr
    })

    return parseFloat(faixa.valor_frete)
  }

  // Função para resetar os campos e começar uma nova simulação
  const limparCampos = () => {
    setProdutoSelecionado({
      sku: '',
      descricao: '',
      custo: '',
      gramas: '',
      _id: ''

      // Defina todas as propriedades que você precisa resetar
    })
    setReset(true)
    // Opcional: depois de um tempo ou logo em seguida, retorne para false
    setTimeout(() => setReset(false), 0)
  }

  // Função para salvar os dados da simulação (a implementar)
  const gravarSimulacao = async () => {
    // Cada chave de simulacaoData representa um marketplaceId com os valores { precoVenda, lucro, margem }

    const simulacoesArray = Object.keys(simulacaoData).map(marketplaceId => ({
      tenantId,
      produtoId: produtoSelecionado._id, // ou produtoSelecionado.sku, conforme necessário
      marketplace: marketplaceId,
      simulationData: simulacaoData[marketplaceId]
    }))

    const payload = {
      requisicao: 'gravarSimulacao',
      simulacoes: simulacoesArray
    }

    try {
      const url = `${config.host}/simulacaoPF`
      await fetchData(url, payload)
      alert('Simulações gravadas com sucesso!')

      if (typeof setRefreshFlag === 'function') {
        setRefreshFlag(prev => !prev)
      }
      // Aqui você chama o callback após sucesso:
      if (onSimulacaoGravada) onSimulacaoGravada()
    } catch (error) {
      console.error('Erro ao gravar simulações:', error)
      alert('Erro ao gravar simulações.')
    }
  }

  function btnInput(e) {
    const inputValue = e.target.value // Pega o valor digitado como string

    setEditableTotal(inputValue)

    const value = e.target.value
    let resultsFromSearch = []

    // Se o input tiver um valor, obtenha os resultados da pesquisa.
    if (value) {
      // resultsFromSearch = ResultSearch(
      //   value,
      //   [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      //   produtos
      // );

      let resultsFromSearch = ResultSearch(value, produtos)

      setDados(resultsFromSearch)
    }
  }
  // crie um useeffect para mostrar o valor de Dados  em um console.log

  function handleClear() {
    // Define o valor de editableTotal como uma string vazia
    setEditableTotal('')

    // Cria um evento falso com um valor de target vazio para simular o campo CustomField vazio
    const fakeEvent = { target: { value: '' } }

    // Chama a função btnInput com o evento falso
    btnInput(fakeEvent)
  }
  useEffect(() => {
    if (produtoSelecionado && marketplaces.length && comissoes.length) {
      let comissao
      if (!selectedMarketplaces) {
        comissao = ComissaoMarketplace(comissoes, marketplaces[0])
      } else {
        comissao = ComissaoMarketplace(comissoes, selectedMarketplaces)
      }

      setComissaoMarketplace(comissao)

      // Chamada da função para recalcular os preços
      recalcularPreco(comissao)
    }
  }, [produtoSelecionado, marketplaces, comissoes, selectedMarketplaces])

  useEffect(() => {
    if (marketplaces?.length > 0 && comissoes?.length > 0) {
      const marktpla = mergeComissoesWithMarketplaces(marketplaces, comissoes, marketplaceColors)

      setMarketplacesWithComissoes(marktpla)
    }
  }, [marketplaces, comissoes])

  const recalcularPreco = comissao => {
    // Aqui você usaria as funções que já definiu para calcular o preço de venda e o lucro
    // com base na comissão que você acabou de definir e a margem que já existe.
    const margemPercentual = parseFloat(margem)
    const custo = parseFloat(produtoSelecionado?.custo || 0)

    if (custo && !isNaN(margemPercentual) && !isNaN(comissao)) {
      const incrementoPorMargem = custo * (margemPercentual / 100)
      const precoVendaLiquido = custo + incrementoPorMargem
      const precoVendaBruto = precoVendaLiquido / (1 - comissao / 100)

      setPrecoVenda(precoVendaBruto.toFixed(2))
      setLucro(incrementoPorMargem.toFixed(2))
    }
  }

  const handleRowClick = produto => {
    setProdutoSelecionado({
      sku: produto.sku,
      descricao: produto.descricao,
      custo: produto.custo,
      gramas: produto.gramas,
      categoriaId: produto.categoriaId,
      _id: produto._id
      // Inclua outras propriedades conforme necessário
    })
    setEditableTotal('')
  }

  const handleCustoChange = newCusto => {
    setProdutoSelecionado(prev => ({ ...prev, custo: newCusto }))

    if (trava.precoVenda && precoVenda) {
      recalculateFromPrecoVenda(newCusto, parseFloat(precoVenda))
    } else if (trava.margem && margem) {
      recalculateFromMargem(newCusto, parseFloat(margem))
    } else if (trava.lucro && lucro) {
      recalculateFromLucro(newCusto, parseFloat(lucro))
    }
  }

  const recalculateFromPrecoVenda = (custo, precoVenda) => {
    const novoLucro = precoVenda - custo

    const novaMargem = precoVenda !== 0 ? (novoLucro / precoVenda) * 100 : 0

    setLucro(novoLucro.toFixed(2))
    setMargem(novaMargem.toFixed(2))
  }
  const recalculateFromMargem = (custo, margem) => {
    const novoPrecoVenda = custo / (1 - margem / 100)
    const novoLucro = novoPrecoVenda - custo

    setPrecoVenda(novoPrecoVenda.toFixed(2))
    setLucro(novoLucro.toFixed(2))
  }
  const recalculateFromLucro = (custo, lucro) => {
    const novoPrecoVenda = custo + lucro
    const novaMargem = novoPrecoVenda !== 0 ? (lucro / novoPrecoVenda) * 100 : 0

    setPrecoVenda(novoPrecoVenda.toFixed(2))
    setMargem(novaMargem.toFixed(2))
  }

  useEffect(() => {
    // Verifica se `selectedMarketplaces` existe e, em seguida, verifica cada entrada
    const hasTrueValue =
      selectedMarketplaces &&
      Object.entries(selectedMarketplaces).some(
        ([key, value]) => key !== 'status_ativo' && value === true
      )

    setHasMarketPlacesSelecionadosNosChecbox(hasTrueValue)
  }, [selectedMarketplaces])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/* Cabeçalho com Banner */}
      {!esconderListaProdutos && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '10vh',
            marginTop: '1vw',
            marginLeft: '25%'
          }}
        >
          <Banner
            title="CÁLCULO DE PREÇO"
            color="#2e8b57"
            width="48vw"
            fontSize={50}
            classEfeito3dMouseOver=""
          />
        </div>
      )}

      {/* Alert genérico */}
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          isBlurred={true}
          setShowAlert={setShowAlert}
          variant={corAlerta}
          className="show-alert-genericPF"
          fontSize="1.5rem"
          dismissible={false}
        />
      )}

      {/* Conteúdo principal dividido em duas colunas */}
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2vw', width: '100%' }}>
        {/* Coluna esquerda: Lista de Produtos */}

        <div
          style={{
            width: '40%',
            padding: '1vw',
            borderRight: !esconderListaProdutos ? '1px solid #ccc' : 'none',
            overflowY: 'auto',
            marginTop: '-2vw'
          }}
        >
          {!esconderListaProdutos && (
            <div>
              <FaPlus
                onClick={toggleAddProductModal}
                size={20}
                color="green"
                style={{ cursor: 'pointer' }}
                title="Adicionar Produto"
              />
            </div>
          )}
          {!esconderListaProdutos && (
            <div
              style={{
                width: '105%',
                padding: '1vw',

                overflowY: 'auto'
              }}
            >
              <ListaProdutosSimulacao onSelectProduto={handleSelecionarProdutoParaCalculo} />
            </div>
          )}
        </div>

        {/* Coluna direita: Demais componentes */}
        <div style={{ width: '60%', padding: '1vw' }}>
          {/* Controles superiores: Botão de adicionar e Marketplace */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1vw',
              marginLeft: '23%'
            }}
          >
            {/* <CustomField
              label="pesquisa"
              placeholder="pesquisa"
              width="20vw"
              value={editableTotal}
              disabled={isLoading}
              onChange={btnInput}
              onClear={handleClear}
              className={'Efeito3dMouseOver-class'}
            /> */}
            {!esconderListaProdutos && (
              <SelectMarketPlace
                marketplaces={marketplaces}
                setDisplayDetails={setDisplayDetails}
                selectedMarketplaces={selectedMarketplaces}
                setSelectedMarketplaces={setSelectedMarketplaces}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                marketplaceColors={marketplaceColors}
              />
            )}
          </div>

          {/* Modal para cadastro de novo produto */}
          <Modal
            show={showAddProductModal}
            onHide={toggleAddProductModal}
            contentClassName="custom-modal-content-width"
            className="custom-modal-dialog-PF"
          >
            <Modal.Header closeButton>
              <Modal.Title>Adicionar Novo Produto</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
              <CadastroProduto />
            </Modal.Body>
            <Modal.Footer className="custom-modal-footer">
              <Button variant="secondary" onClick={toggleAddProductModal}>
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Tabela para exibir os dados do produto (quando necessário) */}
          {editableTotal && (
            <div style={{ marginBottom: '1vw' }}>
              <Table striped bordered hover style={{ backgroundColor: 'white', width: '100%' }}>
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Descrição</th>
                    <th>Custo</th>
                    <th>Peso</th>
                  </tr>
                </thead>
                <tbody>
                  {Dados.map(produto => (
                    <tr key={produto.sku} onClick={() => handleRowClick(produto)}>
                      <td>{produto.sku}</td>
                      <td>{produto.descricao}</td>
                      <td>
                        {produto?.custo !== undefined && produto?.custo !== null
                          ? produto.custo.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            })
                          : ''}
                      </td>
                      <td>{produto.gramas}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          {/* Formulário com os dados do produto e simulação */}
          <div style={{ borderRadius: '5px', padding: '1vw', backgroundColor: '#f8f9fa' }}>
            <Form>
              <div
                style={{
                  display: 'flex',
                  padding: '0.5vw',
                  marginLeft: !esconderListaProdutos ? '0' : '-17vw', // Move para a esquerda apenas quando `esconderListaProdutos` for true
                  transition: 'margin 0.3s ease-in-out' // Adiciona um efeito suave na transição
                }}
              >
                {/* Código do Produto */}
                <div style={{ flex: '25%', padding: '0.5vw' }}>
                  <div className="form-field-PF">
                    <strong>Código do Produto:</strong>
                    <div
                      style={{
                        fontWeight: 'normal',
                        marginTop: '0.2vh',
                        fontSize: '1.8vh'
                      }}
                    >
                      {produtoSelecionado.sku || ''}
                    </div>
                  </div>
                </div>

                {/* Descrição do Produto */}
                <div style={{ flex: '40%', padding: '0.5vw' }}>
                  <div className="form-field-PF">
                    <strong>Descrição do Produto:</strong>
                    <div
                      style={{
                        fontWeight: 'bold',
                        marginTop: '0.2vh',
                        fontSize: '2.3vh',
                        color: '#277a4c'
                      }}
                    >
                      {produtoSelecionado.descricao || 'Não disponível'}
                    </div>
                  </div>
                </div>

                {/* Peso */}
                <div style={{ flex: '10%', padding: '0.5vw' }}>
                  <div className="form-field-PF">
                    <strong>Peso:</strong>
                    <div
                      style={{
                        fontWeight: 'normal',
                        marginTop: '0.2vh',
                        fontSize: '1.8vh'
                      }}
                    >
                      {produtoSelecionado.gramas ? `${produtoSelecionado.gramas} g` : ''}
                    </div>
                  </div>
                </div>

                {/* Custo */}
                <div style={{ flex: '10%', padding: '0.5vw' }}>
                  <div className="form-field-PF">
                    <strong>Custo</strong>
                    <CurrencyInput
                      value={produtoSelecionado.custo}
                      onChange={handleCustoChange}
                      onFocus={() => setMostrarRadioBoxes(true)}
                    />
                  </div>
                </div>
              </div>

              <Row>
                <Col md={12} style={{ marginLeft: '-5.5vw' }}>
                  <div
                    id="comissao"
                    style={{
                      width: !esconderListaProdutos ? '90%' : '75%', // Reduz a largura quando esconderListaProdutos for true
                      display: 'flex',
                      justifyContent: !esconderListaProdutos ? 'center' : 'flex-start', // Alinha à esquerda quando esconderListaProdutos for true
                      transition: 'all 0.3s ease-in-out', // Anima a transição para suavizar o movimento
                      marginLeft: !esconderListaProdutos ? '0' : '-19vw'
                    }}
                  >
                    {marketplacesWithComissoes && (
                      <ProfitMarginCalculator
                        produtoSelecionado={produtoSelecionado}
                        marketplacesWithComissoes={marketplacesWithComissoes}
                        selectedMarketplaces={selectedMarketplaces}
                        valorFretePorPeso={valorFretePorPeso}
                        descontoAtualFreteMagalu={descontoAtualFreteMagalu}
                        descontoAtualFreteMercadoLivre={descontoAtualFreteMercadoLivre}
                        regrasDescontoFreteMagalu={regrasDescontoFreteMagalu}
                        regrasPesoFreteMercadoLivre={regrasPesoFreteMercadoLivre}
                        regrasPesoFreteAmazon={regrasPesoFreteAmazon}
                        peso={produtoSelecionado.gramas}
                        setValorFreteMagalu={setValorFreteMagalu}
                        setValorFreteMercadoLivre={setValorFreteMercadoLivre}
                        setValorFreteAmazon={setValorFreteAmazon}
                        setValorFreteAmericanas={setValorFreteAmericanas}
                        setValorFreteViaVarejo={setValorFreteViaVarejo}
                        valorFreteMagalu={valorFreteMagalu}
                        valorFreteMercadoLivre={valorFreteMercadoLivre}
                        valorFreteAmazon={valorFreteAmazon}
                        valorFreteAmericanas={valorFreteAmericanas}
                        valorFreteViaVarejo={valorFreteViaVarejo}
                        marketplaceColors={marketplaceColors}
                        setDynamicStateMargem={setDynamicStateMargem}
                        stateMargem={stateMargem}
                        setDynamicStatePrecoVendaSugerido={setDynamicStatePrecoVendaSugerido}
                        statePrecoVendaSugerido={statePrecoVendaSugerido}
                        setDynamicStateLucro={setDynamicStateLucro}
                        stateLucro={stateLucro}
                        regrasPorGrupoAmericanas={regrasPorGrupoAmericanas}
                        regrasPorGrupoViaVarejo={regrasPorGrupoViaVarejo}
                        ultimoImposto={ultimoImposto}
                        regrasAmericanas={regrasAmericanas}
                        regrasViaVarejo={regrasViaVarejo}
                        descontoVendedorAmericanas={descontoVendedorAmericanas}
                        descontoVendedorViaVarejo={descontoVendedorViaVarejo}
                        onSimulacaoUpdate={handleSimulacaoUpdate}
                        reset={reset}
                        precoParaRevisao={precoParaRevisao}
                        lucroParaRevisao={lucroParaRevisao}
                        margemParaRevisao={margemParaRevisao}
                        revisaoId={revisaoId}
                        mostrarBotaoManter={mostrarBotaoManter}
                        isLoadingRegrasAmericanas={isLoadingRegrasAmericanas}
                      />
                    )}
                  </div>
                </Col>
              </Row>

              <Row style={{ marginBottom: '1vw', display: 'flex', justifyContent: 'flex-end' }}>
                <Col xs={12} style={{ display: 'flex', gap: '1vw' }}>
                  <Button
                    variant="danger"
                    onClick={limparCampos}
                    style={{ padding: '0.2vw', width: '7vw' }}
                  >
                    Limpar
                  </Button>
                  <Button
                    variant="success"
                    onClick={gravarSimulacao}
                    style={{ padding: '0.2vw', width: '10vw' }}
                  >
                    Gravar Simulação
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalculoPreco

const CurrencyInput = ({ value, onChange, onFocus, onBlur }) => {
  CurrencyInput.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
  }
  const [inputValue, setInputValue] = useState('')

  // Atualiza o valor do input formatado quando o valor externo muda
  useEffect(() => {
    if (value !== undefined && value !== null) {
      setInputValue(
        value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL'
        })
      )
    } else {
      setInputValue('')
    }
  }, [value])

  const handleChange = e => {
    const cleanValue = e.target.value.replace(/\D/g, '')

    const numericValue = cleanValue === '' ? '' : parseFloat(cleanValue) / 100

    // Atualiza o valor de entrada para refletir o que o usuário digitou
    setInputValue(e.target.value)

    // Chama onChange apenas com valores numéricos
    if (numericValue !== '') {
      onChange(numericValue)
    }
  }

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      style={{ width: '5.3vw' }}
    />
  )
}

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
