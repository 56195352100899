import { useState, useContext, useEffect } from 'react'
import { Form, Button, Container } from 'react-bootstrap'
import { fetchData } from '../../../../src/backend/model.js'
import { config } from '../../../../src/backend/configConexaoGeral'
import { AuthContext } from '../../../../src/context/AuthContext'
import { ConsultarLocatario } from '../../../../src/components/pages/Tenant/tenant.jsx'

const AlterarDados = () => {
  // Utilizamos o AuthContext apenas para obter RotaBase, email e usuário
  const { RotaBase, email, user } = useContext(AuthContext)

  // Incluímos _id no estado, mesmo que não seja exibido na UI
  const [formData, setFormData] = useState({
    _id: '',
    usuario: '',
    nome: '',
    tipoLocatario: 'Pessoa Física',
    identificacaoFiscal: '',
    endereco: '',
    telefone: ''
  })
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  // Busca os dados atualizados do tenant diretamente do banco utilizando ConsultarLocatario.
  // Utiliza o campo 'email' se disponível; caso contrário, usa 'usuario'.
  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess('')
      }, 3000) // 5000 ms = 5 segundos
      return () => clearTimeout(timer)
    }
  }, [success])

  useEffect(() => {
    async function fetchTenantData() {
      if (RotaBase && (email || user)) {
        let campoConsulta, valorConsulta
        if (email) {
          campoConsulta = 'email'
          valorConsulta = email
        } else {
          campoConsulta = 'usuario'
          valorConsulta = user
        }
        const response = await ConsultarLocatario(campoConsulta, valorConsulta, RotaBase)
        if (response && response.length > 0) {
          const tenantData = response[0]
          setFormData({
            _id: tenantData._id, // Armazena o _id para envio na atualização
            usuario: tenantData.usuario || '',
            nome: tenantData.nome || '',
            tipoLocatario: tenantData.tipoLocatario || 'Pessoa Física',
            identificacaoFiscal: tenantData.identificacaoFiscal || '',
            endereco: tenantData.endereco || '',
            telefone: tenantData.telefone || ''
          })
        }
      }
    }
    fetchTenantData()
  }, [RotaBase, email, user])

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  // Envia os dados atualizados para o endpoint 'editTenant'
  const handleSubmit = async e => {
    e.preventDefault()
    setError('')
    setSuccess('')
    try {
      const url = `${config.host}/tenants`
      const dados = {
        _id: formData._id, // Agora incluímos o _id do tenant
        usuario: formData.usuario,
        nome: formData.nome,
        tipoLocatario: formData.tipoLocatario,
        identificacaoFiscal: formData.identificacaoFiscal,
        endereco: formData.endereco,
        telefone: formData.telefone,
        requisicao: 'editTenant'
      }

      const response = await fetchData(url, dados)

      if (response && response.success) {
        setSuccess('Dados atualizados com sucesso!')
      } else {
        setError(response.message || 'Erro ao atualizar os dados. Tente novamente.')
      }
    } catch (err) {
      console.error('Erro ao atualizar dados:', err)
      setError('Erro ao atualizar os dados. Tente novamente.')
    }
  }

  return (
    <Container className="mt-4">
      <h1>Alterar Dados</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green', fontSize: '1.8rem' }}>{success}</p>}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="usuario" className="mb-3">
          <Form.Label>Usuário</Form.Label>
          <Form.Control
            type="text"
            name="usuario"
            value={formData.usuario}
            onChange={handleChange}
            placeholder="Digite seu usuário"
          />
        </Form.Group>
        <Form.Group controlId="nome" className="mb-3">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            placeholder="Digite seu nome"
          />
        </Form.Group>
        <Form.Group controlId="tipoLocatario" className="mb-3">
          <Form.Label>Tipo Locatário</Form.Label>
          <Form.Select name="tipoLocatario" value={formData.tipoLocatario} onChange={handleChange}>
            <option value="Pessoa Física">Pessoa Física</option>
            <option value="Pessoa Jurídica">Pessoa Jurídica</option>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="identificacaoFiscal" className="mb-3">
          <Form.Label>Identificação Fiscal</Form.Label>
          <Form.Control
            type="text"
            name="identificacaoFiscal"
            value={formData.identificacaoFiscal}
            onChange={handleChange}
            placeholder="Digite sua identificação fiscal"
          />
        </Form.Group>
        <Form.Group controlId="endereco" className="mb-3">
          <Form.Label>Endereço</Form.Label>
          <Form.Control
            type="text"
            name="endereco"
            value={formData.endereco}
            onChange={handleChange}
            placeholder="Digite seu endereço"
          />
        </Form.Group>
        <Form.Group controlId="telefone" className="mb-3">
          <Form.Label>Telefone</Form.Label>
          <Form.Control
            type="text"
            name="telefone"
            value={formData.telefone}
            onChange={handleChange}
            placeholder="Digite seu telefone"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Atualizar Dados
        </Button>
      </Form>
    </Container>
  )
}

export default AlterarDados
