// AppModelagemCompleta.jsx

import { useEffect, useState, useContext, useRef, useMemo } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { QueryClient, QueryClientProvider } from 'react-query'
import AppRoutes from '../routes/routeModelagemCompleta'
import {
  modulosAssinados,
  getAvailableModules
} from '../../src/components/pages/Payments/SelectPlan.js'
import { AuthContext } from '../context/AuthContext'
import { useInactivityLogout } from './useInactivityLogout/useInactivityLogout'
import { isSystemOwner, deslogar } from '../utils/AppMenuFunctions'
import RelogioDePontoMenu from './MenusModulosModaNegocios360/RelogioDePontoMenu'
import ModelagemMenu from './MenusModulosModaNegocios360/ModelagemMenu'
import CorteMenu from './MenusModulosModaNegocios360/CorteMenu'
import PlotagemMenu from './MenusModulosModaNegocios360/PlotagemMenu'

import PrivateMenuItem from './PrivateMenuItem'
// Inicialização do QueryClient
const queryClient = new QueryClient()

// Componente Principal AppModelagemCompleta
const AppModelagemCompleta = ({ rotaBase }) => {
  AppModelagemCompleta.propTypes = {
    rotaBase: PropTypes.string.isRequired
  }

  const location = useLocation()
  const navigate = useNavigate()
  const { isAuthenticated, logout, assinaturaStatus, tenantId, RotaBase } = useContext(AuthContext)

  const [subscribedModules, setSubscribedModules] = useState([])
  const isPlanPageActive = location.pathname === '/assinar-plano'
  // Refs para Navbar e Título
  const navbarCollapseRef = useRef(null)
  const titleRef = useRef(null)
  const menuItemsRef = useRef(null)
  // Estado para controlar o tamanho da fonte
  const tamanhoFonte = '1.1rem'
  const [titleFontSize, setTitleFontSize] = useState(tamanhoFonte) // Valor inicial
  const [reductionCount, setReductionCount] = useState(0)
  const [lastAvailableWidth, setLastAvailableWidth] = useState(null)
  const [availableModules, setAvailableModules] = useState([])
  // Função para obter o nível de zoom do navegador
  // Função aprimorada para obter o nível de zoom do navegador
  // Ref para armazenar o devicePixelRatio inicial
  const initialDevicePixelRatioRef = useRef(window.devicePixelRatio || 1)

  // Função aprimorada para obter o nível de zoom do navegador
  const getZoomLevel = () => {
    const initialDPR = initialDevicePixelRatioRef.current
    const currentDPR = window.devicePixelRatio || 1
    const zoom = (currentDPR / initialDPR) * 100
    return Math.round(zoom)
  }

  useEffect(() => {
    const fetchAvailableModules = async () => {
      const modules = await getAvailableModules(tenantId, RotaBase)

      setAvailableModules(modules)
    }

    fetchAvailableModules()
  }, [tenantId, RotaBase])

  // Função para buscar módulos assinados
  useEffect(() => {
    modulosAssinados(setSubscribedModules, tenantId, rotaBase) // Chama a função ao montar o componente
  }, [tenantId])

  // Hook para logout por inatividade
  useInactivityLogout()

  const handleNavigate = () => {
    navigate('/funcionarios-ativos', { state: { origem: 'Corte' } })
  }

  const hostname = window.location.hostname
  const pathname = location.pathname

  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  const titulo = isModaNegocios360 ? 'Moda Negócios 360' : 'Modelagem Completa'

  // Determina o título a ser exibido
  const displayTitle = titulo // Pode adicionar lógica para abreviar se necessário

  // Função para calcular a largura total dos itens do menu
  const calculateMenuItemsWidth = () => {
    if (menuItemsRef.current) {
      const items = menuItemsRef.current.querySelectorAll('.navbar-nav > *')
      let totalWidth = 0
      items.forEach(item => {
        totalWidth += item.offsetWidth
      })

      return totalWidth
    }
    return 0
  }
  // Função para verificar overflow e ajustar o tamanho da fonte

  const checkOverflowAndAdjustFont = () => {
    if (navbarCollapseRef.current && titleRef.current && menuItemsRef.current) {
      const navbarWidth = navbarCollapseRef.current.clientWidth
      const titleWidth = titleRef.current.scrollWidth
      const menuWidth = calculateMenuItemsWidth()
      const availableWidth = navbarWidth - menuWidth - 40 // 40px de buffer adicional

      // Verifica se há necessidade de ajuste
      if (availableWidth < titleWidth) {
        // Obtemos o nível de zoom atual
        const zoomLevel = getZoomLevel()
        console.log('Nível de Zoom:', zoomLevel, '%')

        // Aumenta o fator de redução conforme o zoom aumenta
        let reductionFactor = 0.9 - reductionCount * 0.05 // Fator inicial de redução

        // Se o zoom for maior que 110%, aplicar uma redução mais agressiva
        if (zoomLevel > 110) {
          console.log('zoomLevel maior que 110', zoomLevel)
          reductionFactor -= (zoomLevel - 80) * 0.01 // Cada % acima de 100 aumenta a redução
        } // Redução mais agressiva a cada ciclo
        const newFontSize = Math.max(0.2, (availableWidth / titleWidth) * reductionFactor)

        // Atualiza o contador de redução para aplicar uma redução progressivamente maior
        setReductionCount(reductionCount + 1)
        setTitleFontSize(`${newFontSize}rem`)
      } else {
        // Caso tenha espaço, mantém ou redefine o tamanho original e reseta o contador
        setReductionCount(0)
        setTitleFontSize(tamanhoFonte) // Valor original
      }

      // Armazena a largura disponível atual
      setLastAvailableWidth(availableWidth)
    }
  }

  // Debounce para otimizar a função de resize
  const debounce = (func, delay) => {
    let debounceTimer
    return function () {
      const context = this
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => func.apply(context, args), delay)
    }
  }

  // Adiciona o event listener para resize
  useEffect(() => {
    const handleResize = debounce(() => {
      checkOverflowAndAdjustFont()
    }, 100) // 100ms de debounce

    window.addEventListener('resize', handleResize)

    // Verifica o overflow ao montar o componente

    setTimeout(() => {
      checkOverflowAndAdjustFont()
    }, 100) // Pequeno delay para garantir que o DOM esteja montado

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [subscribedModules, isAuthenticated])

  return (
    <QueryClientProvider client={queryClient}>
      <div style={{ paddingTop: isAuthenticated ? '70px' : '0px' }}>
        {isAuthenticated && (
          <Navbar bg="dark" expand="lg" fixed="top" className="navbar-container">
            {/* Toggle único para controlar o colapso */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav" ref={navbarCollapseRef}>
              {/* Contêiner do Título */}
              <div className="navbar-brand-title">
                <Navbar.Brand
                  as={Link}
                  to="/inicio-mc"
                  className="logo navbar-brand-title"
                  ref={titleRef}
                  style={{ fontSize: titleFontSize, transition: 'font-size 0.3s ease' }}
                >
                  {displayTitle}
                </Navbar.Brand>
              </div>

              {/* Itens de Navegação */}
              <Nav className="navbar-nav" ref={menuItemsRef}>
                <PainelPresidenteMenu sistema={'moda.negocios360'} />
                <Nav.Link as={Link} to="/inicio-mc" className="custom-dropdown-item">
                  Home
                </Nav.Link>
                {/* Relatórios */}
                <PrivateMenuItem tela="Relatórios">
                  <NavDropdown title="Relatórios" id="relatorios-dropdown">
                    <PrivateMenuItem tela="Relatório de Corte">
                      <NavDropdown
                        title="Corte"
                        id="relatorios-corte-dropdown"
                        className="custom-dropdown-item"
                        drop="end"
                      >
                        <NavDropdown.Item
                          as={Link}
                          to="/resumo-corte"
                          className="nav-dropdown-item"
                        >
                          Resumo do Corte
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/analise-receita-corte"
                          className="nav-dropdown-item"
                        >
                          Análise de Receita
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/analise-lucro-bruto-corte"
                          className="nav-dropdown-item"
                        >
                          Análise de Lucro Bruto
                        </NavDropdown.Item>
                      </NavDropdown>
                    </PrivateMenuItem>

                    <PrivateMenuItem tela="Relatório de Modelagem">
                      <NavDropdown
                        title="Modelagem"
                        id="relatorios-modelagem-dropdown"
                        className="custom-dropdown-item"
                        drop="end"
                      >
                        <NavDropdown.Item
                          as={Link}
                          to="/resumo-modelagem"
                          className="nav-dropdown-item"
                        >
                          Resumo da Modelagem
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/analise-receita-modelagem"
                          className="nav-dropdown-item"
                        >
                          Análise de Receita
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/analise-lucro-bruto-modelagem"
                          className="nav-dropdown-item"
                        >
                          Análise de Lucro Bruto
                        </NavDropdown.Item>
                      </NavDropdown>
                    </PrivateMenuItem>

                    <PrivateMenuItem tela="Relatório de Plotagem">
                      <NavDropdown
                        title="Plotagem"
                        id="relatorios-plotagem-dropdown"
                        className="custom-dropdown-item"
                        drop="end"
                      >
                        <NavDropdown.Item
                          as={Link}
                          to="/resumo-plotagem"
                          className="nav-dropdown-item"
                        >
                          Resumo da Plotagem
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/analise-receita-plotagem"
                          className="nav-dropdown-item"
                        >
                          Análise de Receita
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to="/analise-lucro-bruto-plotagem"
                          className="nav-dropdown-item"
                        >
                          Análise de Lucro Bruto
                        </NavDropdown.Item>
                      </NavDropdown>
                    </PrivateMenuItem>

                    <NavDropdown.Item
                      as={Link}
                      to="/relatorios-financeiro"
                      className="nav-dropdown-item"
                    >
                      Financeiro
                    </NavDropdown.Item>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Marketplaces */}
                <PrivateMenuItem tela="Marketplaces">
                  <NavDropdown title="Marketplaces" id="marketplaces-dropdown">
                    <NavDropdown.Item
                      as={Link}
                      to="/pagamentos-marketplaces"
                      className="nav-dropdown-item"
                    >
                      Gerenciamento de Pagamentos
                    </NavDropdown.Item>

                    <NavDropdown
                      title="Mercado Livre"
                      id="mercado-livre-dropdown"
                      className="custom-dropdown-item"
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="/listagem-produtos-mercadolivre"
                        className="nav-dropdown-item"
                      >
                        Listagem de Produtos
                      </NavDropdown.Item>
                    </NavDropdown>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Financeiro */}
                <PrivateMenuItem tela="Financeiro">
                  <NavDropdown title="Financeiro" id="financeiro-dropdown">
                    <NavDropdown.Item
                      as={Link}
                      to="/relatorio-debitos-clientes"
                      className="nav-dropdown-item"
                    >
                      Financeiro Geral
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/financeiro" className="nav-dropdown-item">
                      Gestão Financeira do Cliente
                    </NavDropdown.Item>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Administração Geral */}
                <PrivateMenuItem tela="Administração Geral">
                  <NavDropdown title="Administração Geral" id="admin-geral-dropdown">
                    {/* Submenu Cadastros */}
                    <NavDropdown
                      title="Cadastros"
                      id="cadastros-submenu"
                      drop="end"
                      className="custom-dropdown-item"
                    >
                      {/* <NavDropdown.Item
                        as={Link}
                        to="/cadastro-geral"
                        className="nav-dropdown-item"
                      >
                        Cliente/Funcionário
                      </NavDropdown.Item> */}
                      {availableModules.length > 1 ||
                      !availableModules.every(modulo => modulo === 'corte') ? (
                        <NavDropdown.Item
                          as={Link}
                          to="/cadastro-categoria"
                          className="nav-dropdown-item"
                        >
                          Categoria
                        </NavDropdown.Item>
                      ) : null}
                      <NavDropdown.Item
                        as={Link}
                        to="/cadastro-produto"
                        className="nav-dropdown-item"
                      >
                        Produto/Tecido
                      </NavDropdown.Item>
                      <PrivateMenuItem tela="Cadastro de Serviço">
                        <NavDropdown.Item
                          as={Link}
                          to="/cadastro-servico"
                          className="nav-dropdown-item"
                        >
                          Serviço
                        </NavDropdown.Item>
                      </PrivateMenuItem>
                    </NavDropdown>

                    {/* Submenu Vinculações */}
                    {/* <NavDropdown
                      title="Vinculações"
                      id="vinculacoes-submenu"
                      drop="end"
                      className="custom-dropdown-item"
                    >
                      <PrivateMenuItem tela="Cadastro Associação Serviço Categoria">
                        <NavDropdown.Item
                          as={Link}
                          to="/associacao-servico-categoria"
                          className="nav-dropdown-item"
                        >
                          Vincular Categorias a Módulos
                        </NavDropdown.Item>
                      </PrivateMenuItem>
                    </NavDropdown> */}

                    {/* Submenu Visualização e Edição */}
                    <NavDropdown
                      title="Visualização e Edição"
                      id="visualizacao-edicao-submenu"
                      drop="end"
                      className="custom-dropdown-item"
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="/visualizacao-categorias"
                        className="nav-dropdown-item"
                      >
                        Categorias
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* Modulos Free */}
                    <NavDropdown
                      title="Modulos Free"
                      id="modulos-free"
                      drop="end"
                      className="custom-dropdown-item"
                    >
                      <NavDropdown.Item as={Link} to="/pdf-toolkit" className="nav-dropdown-item">
                        PDF Toolkit
                      </NavDropdown.Item>
                    </NavDropdown>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Sub Sistemas */}
                <PrivateMenuItem tela="Sub Sistemas">
                  <NavDropdown title="Sub Sistemas" id="sub-sistema-dropdown">
                    <NavDropdown.Item
                      as={Link}
                      to="/cadastro-sub-sistema"
                      className="nav-dropdown-item"
                    >
                      Cadastro de Sub Sistema
                    </NavDropdown.Item>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Plotagem */}
                <PlotagemMenu />
                {/* Corte */}
                <CorteMenu />
                {/* Modelagem */}
                <ModelagemMenu />
                {/* relogiodeponto */}
                <RelogioDePontoMenu />

                {/* ultimo modulo */}

                {/* Assinar Plano */}
                <PrivateMenuItem tela="Assinar Plano">
                  {assinaturaStatus !== 'ativa' ? (
                    <Nav.Link as={Link} to="/assinar-plano" className="cta-button">
                      Assinar Plano
                    </Nav.Link>
                  ) : (
                    <Nav.Link
                      as={Link}
                      to="/assinar-plano"
                      className={`cta-button-secondary ${isPlanPageActive ? '' : ''}`}
                    >
                      {subscribedModules.length === 3 ? 'Meus Módulos' : 'Adicionar Módulos'}
                    </Nav.Link>
                  )}
                </PrivateMenuItem>

                {/* Sair */}
                <Nav.Link onClick={() => deslogar(logout, 'modelagemcompleta')}>Sair</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}

        {/* Rotas da Aplicação */}
        <AppRoutes rotaBase={rotaBase} />
      </div>
    </QueryClientProvider>
  )
}

export default AppModelagemCompleta

// Componente separado para o menu "Painel do Presidente"

export const PainelPresidenteMenu = sistema => {
  return (
    <PrivateMenuItem tela="Painel do Presidente">
      <NavDropdown title="Painel do Presidente" id="painel-do-presidente-dropdown">
        <NavDropdown.Item as={Link} to="/visualizar-tenants" className="nav-dropdown-item">
          Usuários Cadastrados
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/subscription-pricing-panel" className="nav-dropdown-item">
          Configurar Valores de Assinaturas
        </NavDropdown.Item>

        {sistema.sistema === 'moda.negocios360' && (
          <NavDropdown title="Modulo" className="custom-dropdown-item" drop="end">
            <NavDropdown.Item as={Link} to="/criar-modulo" className="nav-dropdown-item">
              Criar Módulo
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/ver-modulos" className="nav-dropdown-item">
              Ver Módulos
            </NavDropdown.Item>
          </NavDropdown>
        )}

        <NavDropdown title="PRECOFACILMARKET" id="mercadolivre-dropdown">
          {/* Menu Regras */}
          <NavDropdown title="Regras" id="mercadolivre-dropdown">
            <NavDropdown title="Magalu" id="magalu-dropdown">
              <NavDropdown.Item as={Link} to="/cadastro-regra-desconto-frete">
                GERENCIAR REGRA DE DESCONTO DE FRETE
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/cadastro-regra-peso-frete/magalu">
                GERENCIAR REGRA DE PESO DE FRETE
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Amazon" id="amazon-dropdown">
              <NavDropdown.Item as={Link} to="/cadastro-regra-peso-frete/amazon">
                GERENCIAR REGRA DE PESO DE FRETE
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Mercado Livre" id="mercadolivre-dropdown">
              {/* <NavDropdown.Item as={Link} to="/cadastro-regra-peso-frete/mercadolivre">
                CADASTRO DE REGRA DE PESO DE FRETE
              </NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/cadastro-regras-freteML">
                GERENCIAR REGRA DE DESCONTO DE FRETE
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/editor-regras-peso-frete-ml">
                GERENCIAR REGRA DE PESO DE FRETE
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/configurar-valor-min-frete-ml">
                CONFIGURAR VALOR MINIMO DE FRETE
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Americanas" id="mercadolivre-dropdown">
              <NavDropdown.Item
                as={Link}
                to="/gerenciar-regras-frete-americanas-viavarejo/americanas"
              >
                GERENCIAR REGRAS DE FRETE
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/gerenciar-niveis-vendedor-americanas-viavarejo/americanas"
              >
                GERENCIAR NIVEL DE VENDEDOR
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/cadastro-regra-freteAmVv/americanas">
                Cadastrar Regras
              </NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown title="Via Varejo" id="mercadolivre-dropdown">
              <NavDropdown.Item
                as={Link}
                to="/gerenciar-regras-frete-americanas-viavarejo/viavarejo"
              >
                GERENCIAR REGRAS DE FRETE
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/gerenciar-niveis-vendedor-americanas-viavarejo/viavarejo"
              >
                GERENCIAR NIVEL DE VENDEDOR
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="SHOPEE" id="mercadolivre-dropdown">
              <NavDropdown.Item as={Link} to="/cadastro-regra-freteShopee">
                Configurar Regras
              </NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
          <NavDropdown.Item as={Link} to="/editar-marketplace">
            GERENCIAR MARKETPLACE
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/cadastro-marketplace">
            CADASTRAR MARKETPLACE
          </NavDropdown.Item>
        </NavDropdown>
      </NavDropdown>
    </PrivateMenuItem>
  )
}
