// FeedbackImportacao.jsx
import React from 'react'
import { Button } from 'react-bootstrap'

const FeedbackImportacao = ({
  insertedCount,
  duplicateCount,
  duplicadosEnviados,
  duplicadosExistentes,
  onOverwriteProduct,
  onOverwriteAll
}) => {
  console.log('duplicadosEnviados', duplicadosEnviados)
  console.log('duplicadosExistentes', duplicadosExistentes)
  return (
    <div style={{ border: '2px solid #007bff', padding: '10px', marginTop: '20px' }}>
      <h4>Resumo da Importação</h4>
      <p>
        Produtos Inseridos: <strong>{insertedCount}</strong> <br />
        Produtos Duplicados: <strong>{duplicateCount}</strong>
      </p>
      {duplicateCount > 0 && (
        <>
          <Button variant="warning" onClick={onOverwriteAll} style={{ marginBottom: '10px' }}>
            Sobrescrever Todos
          </Button>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Tabela dos produtos enviados (duplicados) com botão sobrescrever */}
            <div style={{ flex: 1, marginRight: '10px' }}>
              <h5>Produtos Enviados </h5>
              <table style={{ width: '100%', borderCollapse: 'collapse' }} border="1">
                <thead>
                  <tr>
                    {duplicadosEnviados.length > 0 &&
                      Object.keys(duplicadosEnviados[0]).map((key, idx) => (
                        <th key={idx} style={{ padding: '5px' }}>
                          {key}
                        </th>
                      ))}
                    <th style={{ padding: '5px' }}>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {duplicadosEnviados.map((produto, idx) => (
                    <tr key={idx}>
                      {Object.keys(produto).map((key, j) => (
                        <td key={j} style={{ padding: '5px' }}>
                          {produto[key]}
                        </td>
                      ))}
                      <td style={{ padding: '5px' }}>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => onOverwriteProduct(produto)}
                        >
                          Sobrescrever
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Tabela dos produtos existentes no banco */}
            <div style={{ flex: 1 }}>
              <h5>Produtos Existentes no Banco (Duplicados)</h5>
              <table style={{ width: '100%', borderCollapse: 'collapse' }} border="1">
                <thead>
                  <tr>
                    {duplicadosExistentes.length > 0 &&
                      Object.keys(duplicadosExistentes[0]).map((key, idx) => (
                        <th key={idx} style={{ padding: '5px' }}>
                          {key}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {duplicadosExistentes.map((produto, idx) => (
                    <tr key={idx}>
                      {Object.keys(produto).map((key, j) => (
                        <td key={j} style={{ padding: '5px' }}>
                          {produto[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default FeedbackImportacao
