import React from 'react'
//import Banner from '/src/components/pages/CadastroGeral/Banner'
//import logoTopoModelagemCompleta from '../Usuarios/login/logo_topo.jpg'
//import logoModaNegocios360 from '../Usuarios/login/4.png'
import nomeModaNegocios360 from '../Usuarios/login/6.png'

import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import { useState, useEffect } from 'react'
import { NavbarModelagemcompleta } from './NavbarModelagemcompleta.jsx'
import { getLogoTopo, getTitle } from '../../../../src/components/pages/Tenant/tenant.jsx'
//import loadIps from '/src/components/pages/Usuarios/login/login';

// import { AuthContext } from '/src/context/AuthContext'
import { useNavigate, useLocation } from 'react-router-dom'
// import { fetchData } from '/src/backend/model'

// import { config } from '/src/backend/configConexaoGeral'
import { Button } from 'react-bootstrap'

// import logoCentro from './logo_centro.jpg'
import logoCentro1 from './CapaEmVideo1.mp4'
import logoCentro2 from './CapaEmVideo2.mp4'
import logoCentro3 from './CapaEmVideo3.mp4'
function Home() {
  const navigate = useNavigate()
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const location = useLocation()
  const hostname = window.location.hostname
  const pathname = location.pathname

  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  let sistema
  if (isModaNegocios360) {
    sistema = 'moda.negocios360'
  } else {
    sistema = 'modelagemcompleta'
  }

  // const logoTopo = isModaNegocios360 ? logoModaNegocios360 : logoTopoModelagemCompleta

  // // Definindo alturas com base na condição
  // const logoTopoHeight = isModaNegocios360 ? '55px' : '60px' // Ajuste os valores conforme necessário
  // const logoTopoWidth = isModaNegocios360 ? '65px' : '70px' // Ajuste os valores conforme necessário
  // const titleHeight = isModaNegocios360 ? '60px' : undefined // Mantém a altura somente se for uma imagem

  const videos = React.useMemo(() => [logoCentro1, logoCentro2, logoCentro3], []) // Array com os vídeos
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVideoIndex(prevIndex => (prevIndex + 1) % videos.length) // Atualiza o índice
    }, 5000) // Troca o vídeo a cada 5 segundos

    return () => clearInterval(interval) // Limpa o intervalo ao desmontar
  }, [])

  return (
    <div>
      <NavbarModelagemcompleta
        setShowAlertMessage={() => {}}
        setAlertMessage={() => {}}
        logoTopo={getLogoTopo(sistema)}
        // logoTopoHeight={logoTopoHeight}
        //logoTopoWidth={logoTopoWidth}
        title={
          sistema === 'moda.negocios360' ? (
            <img src={nomeModaNegocios360} alt="Moda Negócios 360" style={{ height: '150px' }} />
          ) : (
            getTitle(sistema)
          )
        }
        // titleHeight={titleHeight}
      />
      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true}
        setShowAlert={setShowAlertMessage}
      />
      <div style={{ backgroundColor: '#ffeb3b', padding: '10px', textAlign: 'center' }}>
        <strong>Não assinante?</strong> Você tem direito a 15 dias gratuitos, e apos isso 10 usos
        dos serviços por mês. Aproveite!
      </div>

      <div className="banner-container-homeMC">
        <div className="sales-text-block">
          <h2 className="title-vendas">Para a inovação em moda</h2>
          <h1 className="main-chamada">
            maximize cada criação e supere expectativas com a gestão completa em corte, modelagem e
            plotagem da {isModaNegocios360 ? 'Moda Negócios 360' : 'ModelagemCompleta'}
          </h1>

          <p className="detalhes">
            {isModaNegocios360 ? 'Moda Negócios 360' : 'ModelagemCompleta'} é o sistema ideal para
            visionários da moda, oferecendo ferramentas de gerenciamento para corte, modelagem e
            plotagem, auxiliando quem presta serviços de excelência para uma indústria que valoriza
            a perfeição.
          </p>

          <Button
            variant="success"
            className="btn-experimente-gratis"
            onClick={() => navigate('/cadastro-tenant')}
          >
            começe agora
          </Button>
        </div>
        <div className="planos-circuloMC">
          <div className="preco-chamada">Conheça nossos Planos</div>
          <div className="preco-valor">{/* R$<span className="preco-destaque">59</span>,90 */}</div>
          <div className="preco-info" onClick={() => navigate('/saiba-mais', { replace: true })}>
            saiba mais
          </div>
        </div>

        <div className="local-banner-centroMC">
          {/* <img src={logoCentro} alt="Logo" className="logo-centro-homeMC" /> */}
          <video key={currentVideoIndex} className="logo-centro-homeMC" autoPlay muted>
            <source src={videos[currentVideoIndex]} type="video/mp4" />
            Seu navegador não suporta a exibição de vídeos.
          </video>
        </div>
      </div>
    </div>
  )
}
export default Home

//--------------------------------------------
// const loadIps = async RotaBase => {
//   let dados = {
//     requisicao: 'RetornarTodosIps',
//     rota_base: RotaBase
//   }
//   let url = config.host + '/liberar_ips'

//   let response = await fetchData(url, dados)

//   return response
//   // Store the IPs in the state variable
// }

//--------------------------------------------
