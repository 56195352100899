//AlertaPrecosDesatualizadosMarketplaces.jsx
import { useState, useEffect, useRef } from 'react'
import { Alert, Modal, Button, Table } from 'react-bootstrap'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import CalculoPreco from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/calculo-precoPF.jsx'

const AlertaPrecosDesatualizadosMarketplaces = ({
  tenantId,
  marketplaceNome,
  requisicaoEndpoint,
  onProdutoSelecionado,
  height = 'auto',
  refreshFlag,
  setRefreshFlag
}) => {
  const [produtosDesatualizados, setProdutosDesatualizados] = useState([]) //referente a shopee
  const [simulacoesDesatualizadas, setSimulacoesDesatualizadas] = useState([]) // referente a comissao cobrada por qualquer marketplace

  const [showModal, setShowModal] = useState(false)
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState(null)
  const [detalhesRegra, setDetalhesRegra] = useState({})
  const [taxasFreteShopeeAlteradas, setTaxasFreteShopeeAlteradas] = useState([])
  const [produtoSelecionado, setProdutoSelecionado] = useState(null)
  const [taxaFreteShopee, setTaxaFreteShopee] = useState(null) //referente a shopee]
  const [revisaoId, setRevisaoId] = useState(0)
  // Estado para armazenar os produtos desatualizados do Mercado Livre
  const [produtosDesatualizadosML, setProdutosDesatualizadosML] = useState([])
  // Estado para armazenar os detalhes da regra (ultimaAtualizacao e desconto)
  const [regraML, setRegraML] = useState({})
  const [faixasPesoDesatualizadasML, setFaixasPesoDesatualizadasML] = useState([])
  // 1. Novo estado para armazenar as regras de desconto do Mercado Livre
  const [regrasDescontoML, setRegrasDescontoML] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const modalBodyRef = useRef(null)
  const [valorMinimoFreteGratisML, setValorMinimoFreteGratisML] = useState(null) // Dados da configuração de frete grátis
  const [regrasDescontoFreteMagalu, setRegrasDescontoFreteMagalu] = useState(null)
  const [produtosAfetadosFaixas, setProdutosAfetadosFaixas] = useState([])
  const [produtosImpactadosFreteMagalu, setProdutosImpactadosFreteMagalu] = useState([])
  const [produtosAtualizadosRegrasPesoAmazon, setProdutosAtualizadosRegrasPesoAmazon] = useState([])
  const [produtosAtualizadosRegrasPesoAmericanas, setProdutosAtualizadosRegrasPesoAmericanas] =
    useState([])
  const [produtosAtualizadosRegrasPesoViaVarejo, setProdutosAtualizadosRegrasPesoViaVarejo] =
    useState([])
  const [niveisVendedorDesatualizadosAmericanas, setNiveisVendedorDesatualizadosAmericanas] =
    useState([])
  const [comisaoDeNiveisVendedorViaVarejo, setComisaoDeNiveisVendedorViaVarejo] = useState([])
  const [niveisVendedorDesatualizadosViaVarejo, setNiveisVendedorDesatualizadosViaVarejo] =
    useState([])
  const [freteGratisExtraShopee, setFreteGratisExtraShopee] = useState([])
  const [valorImposto, setValorImposto] = useState([])
  const URL_BASE = `${config.host}/simulacaoPF`

  // Função 1: Buscar regras de desconto atualizadas para o Mercado Livre
  // Essa função chama o endpoint que retorna as regras de desconto de frete atualizadas para o tenant,
  // usando a requisicao 'verificarRegrasDescontoFreteAtualizadasML'
  const carregarRegrasDescontoML = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'verificarRegrasDescontoFreteAtualizadasML'
      })
      // Se a resposta for bem-sucedida e contiver dados, atualiza o estado
      if (response.success && response.data && response.data.length > 0) {
        setRegrasDescontoML(response.data)
      } else {
        setRegrasDescontoML([])
      }
    } catch (error) {
      console.error('Erro ao buscar regras de desconto ML:', error)
      setRegrasDescontoML([])
    }
  }

  // Função 2: Buscar produtos desatualizados para o Mercado Livre
  // Essa função consulta o endpoint com a requisicao 'getProdutosPrecosDesatualizadosML',
  // que retorna os produtos que precisam de atualização de preço com base na regra de desconto.
  const carregarProdutosDesatualizadosML = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'getProdutosPrecosDesatualizadosML'
      })
      if (response.success) {
        setProdutosDesatualizadosML(response.data)
        setRegraML(response.regraMercadoLivre)
      } else {
        setProdutosDesatualizadosML([])
      }
    } catch (error) {
      console.error('Erro ao carregar produtos desatualizados ML:', error)
      setProdutosDesatualizadosML([])
    }
  }

  // Função 3: Buscar produtos desatualizados para a Shopee
  // Essa função utiliza a propriedade requisicaoEndpoint (passada como prop) para chamar o endpoint que retorna
  // os produtos da Shopee que precisam de atualização devido à alteração na taxa fixa de frete.
  const carregarProdutosDesatualizados = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        requisicao: requisicaoEndpoint
      })
      if (response.success && response.data && response.data.length > 0) {
        setTaxaFreteShopee(response.regraShopee.percentualFreteGratisExtra)
        setTaxasFreteShopeeAlteradas(response.data)
        setUltimaAtualizacao(response.regraShopee.ultimaAtualizacao || response.ultimaAtualizacao)
        setDetalhesRegra(response.regraShopee || {})
      } else {
        setTaxasFreteShopeeAlteradas([])
      }
    } catch (error) {
      console.error(`Erro ao carregar produtos desatualizados (${marketplaceNome}):`, error)
    }
  }

  // Função 4: Buscar produtos desatualizados por faixas de peso para o Mercado Livre
  // Essa função chama o endpoint com a requisicao 'getProdutosFaixasPesoDesatualizadosML'
  // para obter os produtos que têm faixas de peso desatualizadas.
  const carregarFaixasPesoDesatualizadasML = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        requisicao: 'getProdutosFaixasPesoDesatualizadosML'
      })
      if (response.success && response.data && response.data.length > 0) {
        setFaixasPesoDesatualizadasML(response.data)
      } else {
        setFaixasPesoDesatualizadasML([])
      }
    } catch (error) {
      console.error('Erro ao carregar faixas de peso desatualizadas ML:', error)
    }
  }

  // Função 5: Buscar simulações desatualizadas
  // Essa função chama o endpoint com a requisicao 'verificarSimulacoesDesatualizadas'
  // para obter simulações que podem estar desatualizadas devido à alteração nas comissões de marketplaces.
  const carregarSimulacoesDesatualizadas = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        requisicao: 'verificarSimulacoesDesatualizadas'
      })
      if (response.success && response.data && response.data.length > 0) {
        setSimulacoesDesatualizadas(response)
      } else {
        setSimulacoesDesatualizadas([])
      }
    } catch (error) {
      console.error(`Erro ao carregar simulações desatualizadas (${marketplaceNome}):`, error)
    }
  }

  // Função 6: Carregar configuração de valor mínimo para frete grátis ML
  // Chama o endpoint 'verificarValorMinimoFreteGratisDesatualizadoML' e extrai o primeiro item do array retornado em response.data.
  const carregarValorMinimoFreteGratisML = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        requisicao: 'verificarValorMinimoFreteGratisDesatualizadoML'
      })
      // Supondo que a resposta retorne os dados na propriedade "data" como um array
      if (response.success && response.data && response.data.length > 0) {
        setValorMinimoFreteGratisML(response.data)
      } else {
        setValorMinimoFreteGratisML(null)
      }
    } catch (error) {
      console.error('Erro ao carregar valor mínimo frete grátis ML:', error)
      setValorMinimoFreteGratisML(null)
    }
  }

  // Função 7: Carregar campos atualizados das regras de desconto de frete Magalu
  // Chama o endpoint 'verificarCamposAtualizadosRegrasDescontoFreteMagalu' e extrai os dados retornados em response.data.
  const carregarCamposAtualizadosRegrasDescontoFreteMagalu = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'verificarCamposAtualizadosRegrasDescontoFreteMagalu'
      })

      if (response.success && response.data && response.data.length > 0) {
        setRegrasDescontoFreteMagalu(response.data)
      } else {
        setRegrasDescontoFreteMagalu(null)
      }
    } catch (error) {
      console.error('Erro ao carregar regras de desconto de frete Magalu:', error)
      setRegrasDescontoFreteMagalu(null)
    }
  }
  // Função 8: Carregar faixas de peso atualizadas para Magalu
  // Chama o endpoint 'verificarCamposAtualizadosFaixasPesoMagalu' e extrai os dados retornados em response.data.
  const carregarFaixasPesoAtualizadasMagalu = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'verificarCamposAtualizadosFaixasPesoMagalu'
      })
      if (response.success && response.data && response.data.length > 0) {
        setProdutosAfetadosFaixas(response.data)
      } else {
        setProdutosAfetadosFaixas([])
      }
    } catch (error) {
      console.error('Erro ao carregar faixas de peso atualizadas Magalu:', error)
      setProdutosAfetadosFaixas([])
    }
  }

  // Função 9: Carregar regras de desconto para Correios magalu
  // Chama o endpoint 'verificarRegrasDescontoFreteAtualizadasCorreios' e extrai os dados retornados em response.data.
  const carregarRegrasDescontoCorreiosMagalu = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'verificarRegrasDescontoFreteAtualizadasCorreios'
      })
      if (response.success && response.data && response.data.length > 0) {
        setProdutosImpactadosFreteMagalu(response.data)
      } else {
        setProdutosImpactadosFreteMagalu([])
      }
    } catch (error) {
      console.error('Erro ao buscar regras de desconto para Correios:', error)
      setProdutosImpactadosFreteMagalu([])
    }
  }

  // Função 10: Carregar produtos atualizados nas regras peso da amazon
  // Chama o endpoint 'verificarProdutosAtualizadosRegrasPesoAmazon' e extrai os dados retornados em response.data.
  const carregarProdutosAtualizadosRegrasPesoAmazon = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'verificarCamposAtualizadosFaixasPesoAmazon'
      })
      if (response.success && response.data && response.data.length > 0) {
        setProdutosAtualizadosRegrasPesoAmazon(response.data)
      } else {
        setProdutosAtualizadosRegrasPesoAmazon([])
      }
    } catch (error) {
      console.error('Erro ao buscar produtos atualizados nas regras peso da amazon:', error)
      setProdutosAtualizadosRegrasPesoAmazon([])
    }
  }

  // Funcao 11: Carregar produtos atualizados nas regras peso das Americanas
  // Chama o endpoint 'getProdutosFaixasPesoDesatualizadosAmericanas' e extrai os dados retornados em response.data.
  const carregarProdutosAtualizadosRegrasPesoAmericanas = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'getProdutosFaixasPesoDesatualizadosAmericanas'
      })
      if (response.success && response.data && response.data.length > 0) {
        setProdutosAtualizadosRegrasPesoAmericanas(response.data)
      } else {
        setProdutosAtualizadosRegrasPesoAmericanas([])
      }
    } catch (error) {
      console.error('Erro ao buscar produtos atualizados nas regras peso das Americanas:', error)
      setProdutosAtualizadosRegrasPesoAmericanas([])
    }
  }

  // Funcao 12: Carregar produtos atualizados nas regras peso da ViaVarejo
  // Chama o endpoint 'getProdutosFaixasPesoDesatualizadosViavarejo' e extrai os dados retornados em response.data.
  const carregarProdutosAtualizadosRegrasPesoViaVarejo = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'getProdutosFaixasPesoDesatualizadosViavarejo'
      })
      if (response.success && response.data && response.data.length > 0) {
        setProdutosAtualizadosRegrasPesoViaVarejo(response.data)
      } else {
        setProdutosAtualizadosRegrasPesoViaVarejo([])
      }
    } catch (error) {
      console.error('Erro ao buscar produtos atualizados nas regras peso da ViaVarejo:', error)
      setProdutosAtualizadosRegrasPesoViaVarejo([])
    }
  }

  // Funcao 13: Carregar produtos atualizados realacionados ao valor de desconto do nivel de vendedor das americanas
  // Chama o endpoint 'verificarNiveisVendedorDesatualizadosAmericanas' e extrai os dados retornados em response.data.
  const carregarNiveisVendedorDesatualizadosAmericanas = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'verificarNiveisVendedorDesatualizadosAmericanas'
      })
      if (response.success && response.data && response.data.length > 0) {
        setNiveisVendedorDesatualizadosAmericanas(response.data)
      } else {
        setNiveisVendedorDesatualizadosAmericanas([])
      }
    } catch (error) {
      console.error(
        'Erro ao buscar produtos atualizados realacionados ao valor de desconto do nivel de vendedor das americanas:',
        error
      )
      setNiveisVendedorDesatualizadosAmericanas([])
    }
  }

  // Funcao 14: Carregar produtos atualizados realacionados ao valor de desconto do nivel de vendedor da ViaVarejo
  // Chama o endpoint 'verificarComisaoVendedorDesatualizadosViavarejo' e extrai os dados retornados em response.data.
  const carregarComisaoNiveisVendedorDesatualizadosViaVarejo = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'verificarComisaoVendedorDesatualizadosViavarejo'
      })
      if (response.success && response.data && response.data.length > 0) {
        setComisaoDeNiveisVendedorViaVarejo(response.data)
      } else {
        setComisaoDeNiveisVendedorViaVarejo([])
      }
    } catch (error) {
      console.error(
        'Erro ao buscar produtos atualizados realacionados ao valor de desconto do nivel de vendedor da ViaVarejo:',
        error
      )
      setComisaoDeNiveisVendedorViaVarejo([])
    }
  }

  // Funcao 15: Carregar produtos atualizados realacionados ao valor de desconto do nivel de vendedor da ViaVarejo
  // Chama o endpoint 'verificarNiveisVendedorDesatualizadosViavarejo' e extrai os dados retornados em response.data.
  const carregarNiveisVendedorDesatualizadosViaVarejo = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'verificarNiveisVendedorDesatualizadosViavarejo'
      })
      if (response.success && response.data && response.data.length > 0) {
        setNiveisVendedorDesatualizadosViaVarejo(response.data)
      } else {
        setNiveisVendedorDesatualizadosViaVarejo([])
      }
    } catch (error) {
      console.error(
        'Erro ao buscar produtos atualizados realacionados ao valor de desconto do nivel de vendedor da ViaVarejo:',
        error
      )
      setNiveisVendedorDesatualizadosViaVarejo([])
    }
  }

  // Funcao 16: Carregar produtos atualizados relacionados a shoppe quando o valor da taxa fixa de frete gratis extra
  // Chama o endpoint 'verificarFreteGratisExtraAtualizadoShopee'  informando sobre alteraçao do valor do campo percentualFreteGratisExtra, e extrai os dados retornados em response.data.
  const carregarFreteGratisExtraShopee = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'verificarFreteGratisExtraAtualizadoShopee'
      })
      if (response.success && response.data && response.data.length > 0) {
        setFreteGratisExtraShopee(response.data)
      } else {
        setFreteGratisExtraShopee([])
      }
    } catch (error) {
      console.error(
        'Erro ao buscar produtos atualizados relacionados a shoppe quando o valor da taxa fixa de frete gratis extra:',
        error
      )
      setFreteGratisExtraShopee([])
    }
  }

  // Funcao 17: Carregar produtos atualizados relacionados a mudança no valor  de imposto que serao todos os produtos afetados
  // Chama o endpoint 'verificarValorImpostoAtualizado'  informando sobre alteraçao do valor do campo valorImposto, e extrai os dados retornados em response.data.
  const carregarValorImposto = async () => {
    try {
      const response = await fetchData(URL_BASE, {
        tenantId,
        requisicao: 'verificarValorImpostoAtualizado'
      })
      if (response.success && response.data && response.data.length > 0) {
        setValorImposto(response.data)
      } else {
        setValorImposto([])
      }
    } catch (error) {
      console.error(
        'Erro ao buscar produtos atualizados relacionados a mudança no valor  de imposto que serao todos os produtos afetados:',
        error
      )
      setValorImposto([])
    }
  }

  // UseEffect unificado para carregar todos os dados do servidor
  useEffect(() => {
    const carregarTodosDados = async () => {
      try {
        await Promise.all([
          carregarRegrasDescontoML(), // Carrega as regras de desconto ML
          carregarProdutosDesatualizadosML(), // Carrega os produtos desatualizados ML
          carregarProdutosDesatualizados(), // Carrega os produtos desatualizados da Shopee
          carregarFaixasPesoDesatualizadasML(), // Carrega as faixas de peso desatualizadas ML
          carregarSimulacoesDesatualizadas(), // Carrega as simulações desatualizadas (comissões)
          carregarValorMinimoFreteGratisML(), // Carrega a configuração de valor mínimo para frete grátis ML
          carregarCamposAtualizadosRegrasDescontoFreteMagalu(), // Carrega os campos atualizados das regras de desconto de frete Magalu
          carregarFaixasPesoAtualizadasMagalu(), // Carrega as faixas de peso atualizadas para Magalu
          carregarRegrasDescontoCorreiosMagalu(), // Carrega as regras de desconto para Correios
          carregarProdutosAtualizadosRegrasPesoAmazon(), // Carrega os produtos atualizados nas regras peso da amazon
          carregarProdutosAtualizadosRegrasPesoAmericanas(), // Carrega os produtos atualizados nas regras peso das americanas
          carregarProdutosAtualizadosRegrasPesoViaVarejo(), // Carrega os produtos atualizados nas regras peso da viavarejo
          carregarNiveisVendedorDesatualizadosAmericanas(), // Carrega os produtos atualizados realacionados ao valor de desconto do nivel de vendedor das americanas
          carregarComisaoNiveisVendedorDesatualizadosViaVarejo(), // Carrega os produtos atualizados realacionados ao valor de desconto do nivel de vendedor da ViaVarejo
          carregarNiveisVendedorDesatualizadosViaVarejo(), // Carrega os produtos atualizados realacionados ao valor de desconto do nivel de vendedor da ViaVarejo
          carregarFreteGratisExtraShopee(), // Carrega os produtos atualizados relacionados a shoppe quando o valor da taxa fixa de frete gratis extra
          carregarValorImposto() // Carrega os produtos atualizados relacionados a mudança no valor  de imposto que serao todos os produtos afetados
        ])
        setIsLoading(false)
      } catch (error) {
        console.error('Erro ao carregar todos os dados:', error)
      }
    }

    if (tenantId) {
      carregarTodosDados()
    }
  }, [tenantId, requisicaoEndpoint, refreshFlag])

  function unirAtualizacoes(
    taxasFreteShopeeAlteradas,
    simulacoesResponse,
    produtosDesatualizadosML,
    faixasPesoDesatualizadasML,
    regrasDescontoML,
    valorMinimoFreteGratisML,
    regrasDescontoFreteMagalu,
    produtosAfetadosFaixas,
    produtosImpactadosFreteMagalu, // referente a quando o usuario muda de pontuaçao de entregas na magalu
    produtosAtualizadosRegrasPesoAmazon,
    produtosAtualizadosRegrasPesoAmericanas,
    produtosAtualizadosRegrasPesoViaVarejo,
    niveisVendedorDesatualizadosAmericanas,
    comisaoDeNiveisVendedorViaVarejo,
    niveisVendedorDesatualizadosViaVarejo,
    freteGratisExtraShopee,
    valorImposto
  ) {
    // Mapas para cada fonte, utilizando a chave composta: "produtoId-marketplace"
    const mapTaxas = {}
    const mapSimulacoes = {}
    const mapML = {}
    const mapFaixasPesoML = {}
    const mapRegrasDesconto = {}
    const mapValorMinimo = {}
    const mapMagalu = {}
    const mapProdAfetFaixas = {} //  dados vindos do endpoint de faixas atualizadas para Magalu
    const mapProdImpactadosMagalu = {}
    const mapProdutosAtualizadosRegrasPesoAmazon = {}
    const mapProdutosAtualizadosRegrasPesoAmericanas = {}
    const mapProdutosAtualizadosRegrasPesoViaVarejo = {}
    const mapNiveisVendedorDesatualizadosAmericanas = {}
    const mapComisaoDeNiveisVendedorViaVarejo = {}
    const mapNiveisVendedorDesatualizadosViaVarejo = {}
    const mapFreteGratisExtraShopee = {}
    const mapValorImposto = {}

    // Mapeamento para regras de desconto frete Magalu
    if (regrasDescontoFreteMagalu && Array.isArray(regrasDescontoFreteMagalu)) {
      regrasDescontoFreteMagalu.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapMagalu[key] = item
      })
    }

    // Mapeamento para regras de desconto ML
    if (regrasDescontoML && Array.isArray(regrasDescontoML)) {
      regrasDescontoML.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapRegrasDesconto[key] = item
      })
    }

    // Mapeamento para faixas de peso desatualizadas ML
    if (faixasPesoDesatualizadasML && Array.isArray(faixasPesoDesatualizadasML)) {
      faixasPesoDesatualizadasML.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapFaixasPesoML[key] = item
      })
    }

    // Mapeamento para taxas fixas de frete Shopee
    if (taxasFreteShopeeAlteradas && Array.isArray(taxasFreteShopeeAlteradas)) {
      taxasFreteShopeeAlteradas.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`

        mapTaxas[key] = item
      })
    }

    // Mapeamento para simulações (supondo que simulacoesResponse.data seja um array)
    if (simulacoesResponse && Array.isArray(simulacoesResponse.data)) {
      simulacoesResponse.data.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapSimulacoes[key] = item
      })
    }

    // Mapeamento para produtos desatualizados ML
    if (produtosDesatualizadosML && Array.isArray(produtosDesatualizadosML)) {
      produtosDesatualizadosML.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapML[key] = item
      })
    }

    // Mapeamento para configuração do valor mínimo para frete grátis ML
    if (valorMinimoFreteGratisML && Array.isArray(valorMinimoFreteGratisML)) {
      valorMinimoFreteGratisML.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapValorMinimo[key] = item
      })
    }

    // Novo mapeamento: produtos afetados pelas alterações nas faixas de peso para Magalu
    if (produtosAfetadosFaixas && Array.isArray(produtosAfetadosFaixas)) {
      produtosAfetadosFaixas.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapProdAfetFaixas[key] = item
      })
    }

    // Novo mapeamento: produtos impactados pelas alterações nas faixas para Magalu
    if (produtosImpactadosFreteMagalu && Array.isArray(produtosImpactadosFreteMagalu)) {
      produtosImpactadosFreteMagalu.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapProdImpactadosMagalu[key] = item
      })
    }

    // Novo mapeamento: produtos afetados pelas alterações nas regras de peso para Amazon
    if (produtosAtualizadosRegrasPesoAmazon && Array.isArray(produtosAtualizadosRegrasPesoAmazon)) {
      produtosAtualizadosRegrasPesoAmazon.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`

        mapProdutosAtualizadosRegrasPesoAmazon[key] = item
      })
    }

    // Novo mapeamento: produtos afetados pelas alterações nas regras de peso para Americanas
    if (
      produtosAtualizadosRegrasPesoAmericanas &&
      Array.isArray(produtosAtualizadosRegrasPesoAmericanas)
    ) {
      produtosAtualizadosRegrasPesoAmericanas.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapProdutosAtualizadosRegrasPesoAmericanas[key] = item
      })
    }

    // Novo mapeamento: produtos afetados pelas alterações nas regras de peso para ViaVarejo
    if (
      produtosAtualizadosRegrasPesoViaVarejo &&
      Array.isArray(produtosAtualizadosRegrasPesoViaVarejo)
    ) {
      produtosAtualizadosRegrasPesoViaVarejo.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapProdutosAtualizadosRegrasPesoViaVarejo[key] = item
      })
    }

    // Novo mapeamento: produtos afetados pelas alterações nos niveis de vendedor das americanas
    if (
      niveisVendedorDesatualizadosAmericanas &&
      Array.isArray(niveisVendedorDesatualizadosAmericanas)
    ) {
      niveisVendedorDesatualizadosAmericanas.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapNiveisVendedorDesatualizadosAmericanas[key] = item
      })
    }

    // Novo mapeamento: produtos afetados pelas alterações nos niveis de vendedor via varejo
    if (comisaoDeNiveisVendedorViaVarejo && Array.isArray(comisaoDeNiveisVendedorViaVarejo)) {
      comisaoDeNiveisVendedorViaVarejo.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapComisaoDeNiveisVendedorViaVarejo[key] = item
      })
    }

    // Novo Mapeamento : viavarejo para indicar sobre alteracao sobre o nivel de vendedor que esta no niveisVendedorDesatualizadosViaVarejo
    if (
      niveisVendedorDesatualizadosViaVarejo &&
      Array.isArray(niveisVendedorDesatualizadosViaVarejo)
    ) {
      niveisVendedorDesatualizadosViaVarejo.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapNiveisVendedorDesatualizadosViaVarejo[key] = item
      })
    }

    // Novo Mapeamento relacionado ao freteGratisExtraShopee
    if (freteGratisExtraShopee && Array.isArray(freteGratisExtraShopee)) {
      freteGratisExtraShopee.forEach(item => {
        const key = `${item.produtoId._id}-${item.marketplace}`
        mapFreteGratisExtraShopee[key] = item
      })
    }
    valorImposto.forEach(doc => {
      const pId = doc.produtoId?._id?.toString()
      if (pId) {
        mapValorImposto[pId] = doc
      }
      // Se tiver documento sem productId, esse seria a config global
      // mapImposto['GLOBAL'] = doc
    })
    // mapeamento relacionado ao valor de imposto

    const allKeys = new Set([
      ...Object.keys(mapTaxas),
      ...Object.keys(mapSimulacoes),
      ...Object.keys(mapML),
      ...Object.keys(mapFaixasPesoML),
      ...Object.keys(mapRegrasDesconto),
      ...Object.keys(mapValorMinimo),
      ...Object.keys(mapMagalu),
      ...Object.keys(mapProdAfetFaixas),
      ...Object.keys(mapProdImpactadosMagalu),
      ...Object.keys(mapProdutosAtualizadosRegrasPesoAmazon),
      ...Object.keys(mapProdutosAtualizadosRegrasPesoAmericanas),
      ...Object.keys(mapProdutosAtualizadosRegrasPesoViaVarejo),
      ...Object.keys(mapNiveisVendedorDesatualizadosAmericanas),
      ...Object.keys(mapComisaoDeNiveisVendedorViaVarejo),
      ...Object.keys(mapNiveisVendedorDesatualizadosViaVarejo),
      ...Object.keys(mapFreteGratisExtraShopee)
    ])

    const mergedData = []

    allKeys.forEach(key => {
      let item = null
      // Ordem de prioridade: regras de desconto ML > configuração de valor mínimo > taxas > simulações > ML > faixas de peso ML > regras Magalu > produtos afetados por faixas
      if (mapRegrasDesconto[key]) {
        item = { ...mapRegrasDesconto[key] }
      } else if (mapValorMinimo[key]) {
        item = { ...mapValorMinimo[key] }
      } else if (mapTaxas[key]) {
        item = { ...mapTaxas[key] }
      } else if (mapSimulacoes[key]) {
        item = { ...mapSimulacoes[key] }
      } else if (mapML[key]) {
        item = { ...mapML[key] }
      } else if (mapFaixasPesoML[key]) {
        item = { ...mapFaixasPesoML[key] }
      } else if (mapMagalu[key]) {
        item = { ...mapMagalu[key] }
      } else if (mapProdAfetFaixas[key]) {
        item = { ...mapProdAfetFaixas[key] }
      } else if (mapProdImpactadosMagalu[key]) {
        item = { ...mapProdImpactadosMagalu[key] }
      } else if (mapProdutosAtualizadosRegrasPesoAmazon[key]) {
        item = { ...mapProdutosAtualizadosRegrasPesoAmazon[key] }
      } else if (mapProdutosAtualizadosRegrasPesoAmericanas[key]) {
        item = { ...mapProdutosAtualizadosRegrasPesoAmericanas[key] }
      } else if (mapProdutosAtualizadosRegrasPesoViaVarejo[key]) {
        item = { ...mapProdutosAtualizadosRegrasPesoViaVarejo[key] }
      } else if (mapNiveisVendedorDesatualizadosAmericanas[key]) {
        item = { ...mapNiveisVendedorDesatualizadosAmericanas[key] }
      } else if (mapComisaoDeNiveisVendedorViaVarejo[key]) {
        item = { ...mapComisaoDeNiveisVendedorViaVarejo[key] }
      } else if (mapNiveisVendedorDesatualizadosViaVarejo[key]) {
        item = { ...mapNiveisVendedorDesatualizadosViaVarejo[key] }
      } else if (mapFreteGratisExtraShopee[key]) {
        item = { ...mapFreteGratisExtraShopee[key] }
      } else if (mapValorImposto[key]) {
        item = { ...mapValorImposto[key] }
      }

      if (!item) return

      item.motivo = item.motivo || []

      // Mensagem para produtos afetados pelas alterações nas regras de peso (Amazon)
      if (
        mapProdutosAtualizadosRegrasPesoAmazon[key] &&
        mapProdutosAtualizadosRegrasPesoAmazon[key].camposAlterados
      ) {
        Object.entries(mapProdutosAtualizadosRegrasPesoAmazon[key].camposAlterados).forEach(
          ([campo, valor]) => {
            const mensagem = `Regras de peso da Amazon no campo "${campo}" atualizado para ${valor}`
            if (!item.motivo.includes(mensagem)) {
              item.motivo.push(mensagem)
            }
          }
        )
      }

      // Mensagem para produtos impactados pelas alterações nas faixas (Magalu)
      if (mapProdImpactadosMagalu[key]) {
        const { categoria, desconto } = mapProdImpactadosMagalu[key]
        // Apenas adiciona a mensagem se ambos os campos estiverem presentes
        if (categoria !== undefined && desconto !== undefined) {
          const mensagem = `Nível de pontuação do vendedor (Magalu) alterado para categoria "${categoria}" desconto ${desconto}%`
          if (!item.motivo.includes(mensagem)) {
            item.motivo.push(mensagem)
          }
        }
      }
      // Adiciona mensagens referentes às alterações das regras Magalu (regrasDescontoFreteMagalu)
      if (mapMagalu[key] && mapMagalu[key].camposAlterados) {
        Object.entries(mapMagalu[key].camposAlterados).forEach(([campo, valor]) => {
          const mensagem = `Valor de pontuação em porcentagem que define descontos em frete no campo "${campo}" atualizado para ${valor}%`
          if (!item.motivo.includes(mensagem)) {
            item.motivo.push(mensagem)
          }
        })
      }

      // Mensagem para produtos afetados pelas alterações nas faixas de peso (novo objeto)
      if (mapProdAfetFaixas[key] && mapProdAfetFaixas[key].camposAlterados) {
        Object.entries(mapProdAfetFaixas[key].camposAlterados).forEach(([campo, valor]) => {
          const mensagem = `Alteração nas faixas de peso (Magalu): campo "${campo}" atualizado para ${valor}`
          if (!item.motivo.includes(mensagem)) {
            item.motivo.push(mensagem)
          }
        })
      }

      // Mensagem para alteração do valor mínimo para frete grátis ML
      if (mapValorMinimo[key]) {
        const msgFreteMin = `Valor mínimo para frete grátis alterado para ${mapValorMinimo[key].valorMinimoFreteGratisMercadoLivre}`
        if (!item.motivo.includes(msgFreteMin)) {
          item.motivo.push(msgFreteMin)
        }
      }

      // Mensagem para taxa fixa de frete Shopee
      if (mapTaxas[key]) {
        const msgTaxaFreteShopee = `Taxa fixa de frete Shopee alterada para ${mapTaxas[key].simulationData.fixo}%`
        if (!item.motivo.includes(msgTaxaFreteShopee)) {
          item.motivo.push(msgTaxaFreteShopee)
        }
      }

      // Mensagem para comissão alterada (via simulação)
      if (
        mapSimulacoes[key] &&
        mapSimulacoes[key].simulationData &&
        mapSimulacoes[key].simulationData.comissao != null
      ) {
        const msgComissao = `Comissão alterada para ${mapSimulacoes[key].simulationData.comissao}%`
        if (!item.motivo.includes(msgComissao)) {
          item.motivo.push(msgComissao)
        }
      }

      // Mensagem para desconto ML
      if (mapML[key]) {
        const msgNivel = `Desconto nível vendedor ML alterado para ${mapML[key].desconto}%`
        if (!item.motivo.includes(msgNivel)) {
          item.motivo.push(msgNivel)
        }
      }

      // Mensagem para alterações nas faixas de peso ML
      if (mapFaixasPesoML[key] && mapFaixasPesoML[key].camposAlterados) {
        Object.entries(mapFaixasPesoML[key].camposAlterados).forEach(([campo, valor]) => {
          const mensagem = `Alteração nas faixas de peso: campo "${campo}" atualizado para ${valor}`
          if (!item.motivo.includes(mensagem)) {
            item.motivo.push(mensagem)
          }
        })
      }

      // Mensagem para as alteracoes das regras de peso das Americanas
      if (
        mapProdutosAtualizadosRegrasPesoAmericanas[key] &&
        mapProdutosAtualizadosRegrasPesoAmericanas[key].camposAlterados
      ) {
        Object.entries(mapProdutosAtualizadosRegrasPesoAmericanas[key].camposAlterados).forEach(
          ([campo, valor]) => {
            const mensagem = `Alteração nas regras de peso para Americanas no campo "${campo}" atualizado para ${valor}`
            if (!item.motivo.includes(mensagem)) {
              item.motivo.push(mensagem)
            }
          }
        )
      }

      // Mensagem para as alteracoes das regras de peso das ViaVarejo
      if (
        mapProdutosAtualizadosRegrasPesoViaVarejo[key] &&
        mapProdutosAtualizadosRegrasPesoViaVarejo[key].camposAlterados
      ) {
        Object.entries(mapProdutosAtualizadosRegrasPesoViaVarejo[key].camposAlterados).forEach(
          ([campo, valor]) => {
            const mensagem = `Alteração nas regras de peso para ViaVarejo no campo "${campo}" atualizado para ${valor}`
            if (!item.motivo.includes(mensagem)) {
              item.motivo.push(mensagem)
            }
          }
        )
      }

      // Mensagem para informar que o nivel de vendedor mudou, informar o nivel de vendedor e o valor de desconto do novo nivel para as americanas
      if (mapNiveisVendedorDesatualizadosAmericanas[key]) {
        const msgNivel = `Nivel de vendedor alterado para  ${mapNiveisVendedorDesatualizadosAmericanas[key].nome_nivel} desconto sobre o valor do frete é de ${mapNiveisVendedorDesatualizadosAmericanas[key].desconto}%`
        if (!item.motivo.includes(msgNivel)) {
          item.motivo.push(msgNivel)
        }
      }

      // Mensagem para informar que o nivel de vendedor mudou, informar o nivel de vendedor e o valor de desconto do novo nivel para a viavarejo
      if (mapComisaoDeNiveisVendedorViaVarejo[key]) {
        const msgNivel = `Comissão de  ${mapComisaoDeNiveisVendedorViaVarejo[key].nome_nivel} de vendedor  alterada para  ${mapComisaoDeNiveisVendedorViaVarejo[key].desconto}%`
        if (!item.motivo.includes(msgNivel)) {
          item.motivo.push(msgNivel)
        }
      }

      // Mensagem para informar que o nivel de vendedor mudou, informar o nivel de vendedor e o valor de desconto do novo nivel para a viavarejo , a mensagem sera const msgNivel = `Nivel de vendedor alterado para ..
      if (mapNiveisVendedorDesatualizadosViaVarejo[key]) {
        const msgNivel = `Nivel de vendedor alterado para  ${mapNiveisVendedorDesatualizadosViaVarejo[key].nome_nivel} desconto sobre o valor do frete é de ${mapNiveisVendedorDesatualizadosViaVarejo[key].desconto}%`
        if (!item.motivo.includes(msgNivel)) {
          item.motivo.push(msgNivel)
        }
      }

      // Menssagem relacionada a regra de frete gratis SHOPEE
      if (mapFreteGratisExtraShopee[key]) {
        const msgNivel = `Regra de frete gratis alterada para  ${mapFreteGratisExtraShopee[key].percentualFreteGratisExtra}%`
        if (!item.motivo.includes(msgNivel)) {
          item.motivo.push(msgNivel)
        }
      }

      // Mensagem relacionada ao valor de imposto
      const prodIdFromKey = key.split('-')[0]
      const docImposto = mapValorImposto[prodIdFromKey]
      // Se não houver docImposto e não for "GLOBAL", não adiciona aviso
      if (docImposto) {
        const msgImposto = `Imposto alterado para ${docImposto.valorImposto}%`
        if (!item.motivo.includes(msgImposto)) {
          item.motivo.push(msgImposto)
        }
      }

      mergedData.push(item)
    })

    return { success: true, data: mergedData }
  }

  useEffect(() => {
    if (isLoading) return
    const resultadoMesclado = unirAtualizacoes(
      taxasFreteShopeeAlteradas,
      simulacoesDesatualizadas,
      produtosDesatualizadosML,
      faixasPesoDesatualizadasML,
      regrasDescontoML,
      valorMinimoFreteGratisML,
      regrasDescontoFreteMagalu,
      produtosAfetadosFaixas,
      produtosImpactadosFreteMagalu,
      produtosAtualizadosRegrasPesoAmazon,
      produtosAtualizadosRegrasPesoAmericanas,
      produtosAtualizadosRegrasPesoViaVarejo,
      niveisVendedorDesatualizadosAmericanas,
      comisaoDeNiveisVendedorViaVarejo,
      niveisVendedorDesatualizadosViaVarejo,
      freteGratisExtraShopee,
      valorImposto
    )

    setProdutosDesatualizados(resultadoMesclado.data)
  }, [
    isLoading,
    taxasFreteShopeeAlteradas,
    simulacoesDesatualizadas,
    produtosDesatualizadosML,
    faixasPesoDesatualizadasML,
    regrasDescontoML,
    valorMinimoFreteGratisML,
    regrasDescontoFreteMagalu,
    produtosAfetadosFaixas,
    produtosImpactadosFreteMagalu,
    produtosAtualizadosRegrasPesoAmazon,
    produtosAtualizadosRegrasPesoAmericanas,
    produtosAtualizadosRegrasPesoViaVarejo,
    niveisVendedorDesatualizadosAmericanas,
    comisaoDeNiveisVendedorViaVarejo,
    niveisVendedorDesatualizadosViaVarejo,
    freteGratisExtraShopee,
    valorImposto
  ])
  if (produtosDesatualizados.length === 0) return null
  // Fora do componente, você pode deixar como função auxiliar, ou definir dentro do map
  function getBaseText(str) {
    // Remove o trecho que contém número (com ou sem decimal, . ou ,) + eventual espaço + símbolo de porcentagem
    // Exemplos que serão removidos: "19 %", "12%", "19.5 %", "19,5%", etc.
    return str.replace(/\d+[.,]?\d*\s*%/, '').trim()
  }

  // Crie um "map" para armazenar o texto-base e a cor associada
  const colorMap = {}
  // Paleta de cores que você deseja ir atribuindo
  const colorPalette = ['#f8b195', '#c06c84', '#6c5b7b', '#355c7d']
  // Índice global para avançar na paleta
  let colorIndex = 0
  return (
    <div style={{ width: '100%', height }}>
      <Alert variant="warning" style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}>
        ⚠️ <strong>{produtosDesatualizados.length} produto(s)</strong> precisam ter seus preços
        atualizados devido a alterações em regras de calculos de preços.
      </Alert>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        dialogClassName="ALD-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Produtos com Preços Desatualizados</Modal.Title>
        </Modal.Header>

        <Modal.Body className="ALD-modal-body" ref={modalBodyRef}>
          {/* <p>
            Houve uma alteração nas regras do marketplace <strong>{marketplaceNome}</strong> em{' '}
            <strong>{new Date(ultimaAtualizacao).toLocaleString()}</strong>.
          </p>

          {detalhesRegra.percentualFreteGratisExtra && (
            <p>
              Novo percentual do Frete Grátis Extra:{' '}
              <strong>{detalhesRegra.percentualFreteGratisExtra}%</strong>.
            </p>
          )} */}

          <Table striped bordered hover className="ALD-tabela">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Descrição</th>
                <th>Marketplace</th>
                <th>Margem (%)</th>
                <th>Venda Sugerida</th>
                <th>Lucro</th>

                <th>Motivo</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {produtosDesatualizados.map((produto, index) => (
                <tr key={`${produto.produtoId._id}-${index}`}>
                  <td>{produto.produtoId.sku}</td>
                  <td>{produto.produtoId.descricao}</td>
                  <td>{produto.marketplaceNome}</td>
                  <td>{produto.simulationData.margem}</td>
                  <td>{produto.simulationData.precoVenda}</td>
                  <td>{produto.simulationData.lucro}</td>

                  <td style={{ textAlign: 'left', width: '200px' }}>
                    <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                      {produto.motivo.map((item, index) => {
                        // Obtém o texto-base, sem a porcentagem
                        const baseText = getBaseText(item)

                        // Se já existir uma cor para este texto-base, pega do map;
                        // senão, atribui a próxima cor da paleta e guarda no map
                        let backgroundColor = colorMap[baseText]
                        if (!backgroundColor) {
                          backgroundColor = colorPalette[colorIndex % colorPalette.length]
                          colorMap[baseText] = backgroundColor
                          colorIndex++
                        }

                        return (
                          <li
                            key={index}
                            style={{
                              border: '1px solid #ccc',
                              padding: '5px',
                              marginBottom: '5px',
                              borderRadius: '4px',
                              backgroundColor,
                              color: '#fff'
                            }}
                          >
                            {item}
                          </li>
                        )
                      })}
                    </ul>
                  </td>

                  <td>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        setRevisaoId(prev => prev + 1)
                        setProdutoSelecionado(produto)
                        // Após atualizar, verifica se o ref está definido e rola suavemente até o final
                        if (modalBodyRef.current) {
                          modalBodyRef.current.scrollTo({
                            top: modalBodyRef.current.scrollHeight,
                            behavior: 'smooth'
                          })
                        }
                      }}
                    >
                      Revisar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {produtoSelecionado && (
            <div className="ALD-revisao-container">
              <h5 className="ALD-revisao-titulo" style={{ position: 'relative', zIndex: 9999 }}>
                Revisando Valores
              </h5>

              <div className="ALD-calculo-preco-container">
                <CalculoPreco
                  produtoParaRevisao={produtoSelecionado.produtoId._id}
                  precoParaRevisao={produtoSelecionado.simulationData.precoVenda}
                  lucroParaRevisao={produtoSelecionado.simulationData.lucro}
                  margemParaRevisao={produtoSelecionado.simulationData.margem}
                  marketplaceAtivo={produtoSelecionado.marketplaceNome}
                  esconderListaProdutos={true}
                  setRefreshFlag={setRefreshFlag}
                  revisaoId={revisaoId}
                  mostrarBotaoManter={true}
                />
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AlertaPrecosDesatualizadosMarketplaces
