import { useState, useEffect, useContext, useMemo, useRef } from 'react'
import { Table, Button, Form, Alert, Modal } from 'react-bootstrap'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { AuthContext } from '../../../../../../src/context/AuthContext'
import SidebarFilters from './SidebarFilters'
import { FaTrashAlt } from 'react-icons/fa'
import AlertaPrecosDesatualizadosMarketplaces from './AlertaPrecosDesatualizadosMarketplaces'
import CalculoPreco from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/calculo-precoPF.jsx'
const PrecosVenda = () => {
  const { tenantId } = useContext(AuthContext)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedIdToDelete, setSelectedIdToDelete] = useState(null)
  const [precos, setPrecos] = useState([])
  const precosCompleto = useRef([])
  const [message, setMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('success')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  // Estados para filtros globais e por coluna
  const [searchTerm, setSearchTerm] = useState('')
  const [filterSKU, setFilterSKU] = useState('')
  const [filterProduto, setFilterProduto] = useState('')
  const [filterMarketplace, setFilterMarketplace] = useState('')
  const [filterPrecoVenda, setFilterPrecoVenda] = useState('')
  const [filterLucro, setFilterLucro] = useState('')
  const [filterMargem, setFilterMargem] = useState('')

  // Estado para ordenação
  const [sortConfig, setSortConfig] = useState({ column: null, direction: 'asc' })
  const [filtro, setFiltro] = useState('ativos')
  const [produtoParaRevisao, setProdutoParaRevisao] = useState(null)
  const [precoParaRevisao, setPrecoParaRevisao] = useState(null)
  const [lucroParaRevisao, setLucroParaRevisao] = useState(null)
  const [margemParaRevisao, setMargemParaRevisao] = useState(null)
  const [marketplaceAtivo, setMarketplaceAtivo] = useState(null)

  // Estado para configuração de indicadores de margem
  const [margemConfig, setMargemConfig] = useState({
    highThreshold: 20,
    highColor: '#00FF00', // verde
    lowThreshold: 5,
    lowColor: '#FF0000' // vermelho
  })
  const [marketplacesDisponiveis, setMarketplacesDisponiveis] = useState([])
  // Estado para marketplaces selecionados (usado pelo filtro)
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([])
  const [extraFieldsChange, setExtraFieldsChange] = useState(null)
  // Estado para os campos extras (retornado pelo ExtraFieldsSelector)
  const [extraFields, setExtraFields] = useState({
    frete: false,
    fixo: false,
    comissao: false,
    custo: false,
    categoria: false
  })
  const [refreshFlag, setRefreshFlag] = useState(false)
  // Função que será passada para o CalculoPreco:
  const handleSimulacaoGravada = () => {
    // Alternar o estado para forçar re-render do alerta:
    setRefreshFlag(prev => !prev)
  }
  const handleProdutoSelecionado = (produtoId, preco, lucro, margem) => {
    setProdutoParaRevisao(produtoId)
    setPrecoParaRevisao(preco)
    setLucroParaRevisao(lucro)
    setMargemParaRevisao(margem)
    setMarketplaceAtivo('Shopee') // Marketplace fixo neste caso, pode ser dinâmico
  }

  const handleExtraFieldsChange = fields => {
    setExtraFields(fields)
  }
  // Carrega a configuração de margem
  useEffect(() => {
    const fetchMargemConfig = async () => {
      try {
        const payload = { tenantId, requisicao: 'getMargemConfigColorPF' }
        const url = `${config.host}/simulacaoPF`
        const response = await fetchData(url, payload)
        if (response.success && Array.isArray(response.data) && response.data.length > 0) {
          setMargemConfig(response.data[0])
        }
      } catch (error) {
        console.error('Erro ao carregar configuração de margem: ', error)
      }
    }
    if (tenantId) {
      fetchMargemConfig()
    }
  }, [tenantId])

  // Carrega os registros de precificação
  const carregarPrecosVenda = async novoFiltro => {
    setLoading(true)
    try {
      const payload = {
        requisicao: 'getSimulacoes',
        tenantId,
        filtro: novoFiltro
      }
      const url = `${config.host}/simulacaoPF`
      const response = await fetchData(url, payload)
      if (response.success) {
        setPrecos(response.data)

        precosCompleto.current = response.data
      } else {
        setError('Erro ao carregar os preços de venda.')
      }
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (tenantId) {
      carregarPrecosVenda()
    }
  }, [tenantId])

  // Função para ordenar os dados
  const handleSort = columnKey => {
    setSortConfig(prev => {
      if (prev.column === columnKey) {
        return { column: columnKey, direction: prev.direction === 'asc' ? 'desc' : 'asc' }
      } else {
        return { column: columnKey, direction: 'asc' }
      }
    })
  }

  const sortIndicator = columnKey => {
    if (sortConfig.column !== columnKey) return null
    return sortConfig.direction === 'asc' ? '▲' : '▼'
  }

  // Função para colorir a margem conforme a configuração
  const getMargemColor = margemStr => {
    const margemNum = parseFloat(margemStr)
    if (isNaN(margemNum)) return 'inherit'
    if (margemNum >= margemConfig.highThreshold) return margemConfig.highColor
    if (margemNum <= margemConfig.lowThreshold) return margemConfig.lowColor
    return 'inherit'
  }

  // Combina filtros e ordenação
  const finalData = useMemo(() => {
    let temp = [...precos]

    // Filtra pelos marketplaces selecionados
    if (selectedMarketplaces.length > 0) {
      temp = temp.filter(item => selectedMarketplaces.includes(item.marketplace))
    }

    // Filtros por coluna
    temp = temp.filter(item => {
      const sku = item.produtoId?.sku?.toLowerCase() || ''
      const produto = item.produtoId?.descricao?.toLowerCase() || ''
      const marketplaceNome = item.marketplaceNome?.toLowerCase() || ''
      const precoVenda = item.simulationData?.precoVenda?.toString().toLowerCase() || ''
      const lucro = item.simulationData?.lucro?.toString().toLowerCase() || ''
      const margem = item.simulationData?.margem?.toString().toLowerCase() || ''

      if (filterSKU && !sku.includes(filterSKU.toLowerCase())) return false
      if (filterProduto && !produto.includes(filterProduto.toLowerCase())) return false
      if (filterMarketplace && !marketplaceNome.includes(filterMarketplace.toLowerCase()))
        return false
      if (filterPrecoVenda && !precoVenda.includes(filterPrecoVenda.toLowerCase())) return false
      if (filterLucro && !lucro.includes(filterLucro.toLowerCase())) return false
      if (filterMargem && !margem.includes(filterMargem.toLowerCase())) return false

      return true
    })

    // Filtro global
    if (searchTerm) {
      const lowerTerm = searchTerm.toLowerCase()
      temp = temp.filter(item => {
        const sku = item.produtoId?.sku?.toLowerCase() || ''
        const produto = item.produtoId?.descricao?.toLowerCase() || ''
        const marketplaceNome = item.marketplaceNome?.toLowerCase() || ''
        const precoVenda = item.simulationData?.precoVenda?.toString().toLowerCase() || ''
        const lucro = item.simulationData?.lucro?.toString().toLowerCase() || ''
        const margem = item.simulationData?.margem?.toString().toLowerCase() || ''

        return (
          sku.includes(lowerTerm) ||
          produto.includes(lowerTerm) ||
          marketplaceNome.includes(lowerTerm) ||
          precoVenda.includes(lowerTerm) ||
          lucro.includes(lowerTerm) ||
          margem.includes(lowerTerm)
        )
      })
    }

    // Ordenação
    if (sortConfig.column) {
      temp.sort((a, b) => {
        let valA, valB
        switch (sortConfig.column) {
          case 'sku':
            valA = a.produtoId?.sku || ''
            valB = b.produtoId?.sku || ''
            break
          case 'produto':
            valA = a.produtoId?.descricao || ''
            valB = b.produtoId?.descricao || ''
            break
          case 'marketplace':
            valA = a.marketplaceNome || ''
            valB = b.marketplaceNome || ''
            break
          case 'precoVenda':
            valA = parseFloat(a.simulationData?.precoVenda) || 0
            valB = parseFloat(b.simulationData?.precoVenda) || 0
            break
          case 'lucro':
            valA = parseFloat(a.simulationData?.lucro) || 0
            valB = parseFloat(b.simulationData?.lucro) || 0
            break
          case 'margem':
            valA = parseFloat(a.simulationData?.margem) || 0
            valB = parseFloat(b.simulationData?.margem) || 0
            break
          default:
            valA = ''
            valB = ''
        }
        if (valA < valB) return sortConfig.direction === 'asc' ? -1 : 1
        if (valA > valB) return sortConfig.direction === 'asc' ? 1 : -1
        return 0
      })
    }

    return temp
  }, [
    precos,
    searchTerm,
    filterSKU,
    filterProduto,
    filterMarketplace,
    filterPrecoVenda,
    filterLucro,
    filterMargem,
    sortConfig,
    selectedMarketplaces
  ])

  // Funções para exclusão de registro
  const confirmDelete = id => {
    setSelectedIdToDelete(id)
    setShowConfirmModal(true)
  }

  const handleDeleteConfirm = async () => {
    if (!selectedIdToDelete) return
    try {
      const payload = { requisicao: 'deleteSimulacao', id: selectedIdToDelete }
      const url = `${config.host}/simulacaoPF`
      const response = await fetchData(url, payload)
      if (response.success) {
        setMessage('Registro excluído com sucesso!')
        setAlertVariant('success')
        setPrecos(prev => prev.filter(item => item._id !== selectedIdToDelete))
      } else {
        setMessage(response.message || 'Erro ao excluir registro.')
        setAlertVariant('danger')
      }
    } catch (err) {
      setMessage('Erro ao excluir registro.')
      setAlertVariant('danger')
    }
    setShowConfirmModal(false)
    setSelectedIdToDelete(null)
  }

  const handleDeleteCancel = () => {
    setShowConfirmModal(false)
    setSelectedIdToDelete(null)
  }

  const handleAtivoChange = async (id, novoStatus) => {
    try {
      const url = `${config.host}/simulacaoPF`
      const dados = {
        id: id,
        ativo: novoStatus, // Valor atualizado
        requisicao: 'atualizarStatusAtivo',
        tenantId
      }
      const response = await fetchData(url, dados)
      if (response.success) {
        carregarPrecosVenda() // Recarrega a tabela para refletir a mudança
      } else {
        setError('Erro ao atualizar o status.')
      }
    } catch (error) {
      setError('Erro ao atualizar o status.')
      console.error('Erro em handleAtivoChange:', error)
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      {/* Sidebar para filtros e configurações */}
      <div style={{ width: '250px', display: 'flex', flexDirection: 'column' }}>
        <SidebarFilters
          onMarketplaceChange={selectedIds => setSelectedMarketplaces(selectedIds)}
          initialMargemConfig={margemConfig}
          tenantId={tenantId}
          onMargemConfigSave={config => setMargemConfig(config)}
          carregarPrecosVenda={carregarPrecosVenda}
          setFiltro={setFiltro}
          filtro={filtro}
          setSelectedMarketplaces={setSelectedMarketplaces}
          precosCompleto={precosCompleto}
          setPrecos={setPrecos}
          marketplacesDisponiveis={marketplacesDisponiveis}
          extraFieldsChange={extraFieldsChange}
          onExtraFieldsChange={handleExtraFieldsChange}
          setExtraFields={setExtraFields}
          extraFields={extraFields}
        />
        <AlertaPrecosDesatualizadosMarketplaces
          tenantId={tenantId}
          marketplaceNome="Shopee"
          requisicaoEndpoint="getProdutosPrecosDesatualizadosShopee"
          height="200px" // Exemplo de configuração da altura
          onProdutoSelecionado={handleProdutoSelecionado}
          refreshFlag={refreshFlag}
          setRefreshFlag={setRefreshFlag}
        />
      </div>
      {/* Conteúdo principal */}
      <div style={{ flex: 1, padding: '2rem' }}>
        <h2>Preços de Venda</h2>
        {message && <Alert variant={alertVariant}>{message}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        <Modal show={showConfirmModal} onHide={handleDeleteCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Exclusão</Modal.Title>
          </Modal.Header>
          <Modal.Body>Tem certeza de que deseja excluir este registro?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteCancel}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirm}>
              Excluir
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Barra de busca global */}
        <Form.Group controlId="search" className="mb-3" style={{ maxWidth: '600px' }}>
          <Form.Control
            type="text"
            placeholder="Pesquisar em SKU, Produto, Marketplace, Preço de Venda, Lucro ou Margem"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Form.Group>
        {/* Tabela com rolagem vertical */}
        {loading ? (
          <p>Carregando...</p>
        ) : (
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th onClick={() => handleSort('sku')} style={{ cursor: 'pointer' }}>
                    SKU {sortIndicator('sku')}
                  </th>
                  <th onClick={() => handleSort('produto')} style={{ cursor: 'pointer' }}>
                    Produto {sortIndicator('produto')}
                  </th>
                  <th onClick={() => handleSort('marketplace')} style={{ cursor: 'pointer' }}>
                    Marketplace {sortIndicator('marketplace')}
                  </th>
                  <th onClick={() => handleSort('precoVenda')} style={{ cursor: 'pointer' }}>
                    Preço de Venda {sortIndicator('precoVenda')}
                  </th>
                  <th onClick={() => handleSort('lucro')} style={{ cursor: 'pointer' }}>
                    Lucro {sortIndicator('lucro')}
                  </th>
                  <th onClick={() => handleSort('margem')} style={{ cursor: 'pointer' }}>
                    Margem (%) {sortIndicator('margem')}
                  </th>
                  {extraFields.frete && <th>Frete</th>}
                  {extraFields.fixo && <th>Fixo</th>}
                  {extraFields.comissao && <th>Comissão</th>}
                  {extraFields.custo && <th>Custo</th>}
                  {extraFields.categoria && <th>Categoria</th>}
                  <th onClick={() => handleSort('ativo')} style={{ cursor: 'pointer' }}>
                    Ativo {sortIndicator('ativo')}
                  </th>
                  <th
                    onClick={() => {
                      if (selectedIdToDelete) {
                        confirmDelete(selectedIdToDelete)
                      } else {
                        alert('Selecione um registro para excluir.')
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <FaTrashAlt style={{ color: 'red', fontSize: '20px' }} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {finalData.length === 0 ? (
                  <tr>
                    <td colSpan="8" style={{ textAlign: 'center' }}>
                      Nenhum registro encontrado.
                    </td>
                  </tr>
                ) : (
                  finalData.map(item => (
                    <tr key={item._id}>
                      <td>{item.produto?.sku}</td>
                      <td>{item.produto?.descricao}</td>
                      <td>{item.marketplaceNome || item.marketplace}</td>
                      <td>{item.simulationData?.precoVenda}</td>
                      <td>{item.simulationData?.lucro}</td>
                      <td style={{ color: getMargemColor(item.simulationData?.margem) }}>
                        {item.simulationData?.margem}
                      </td>
                      {extraFields.frete && <td>{item.simulationData?.frete}</td>}
                      {extraFields.fixo && <td>{item.simulationData?.fixo}</td>}
                      {extraFields.comissao && <td>{item.relacionamento.comissao}</td>}
                      {extraFields.custo && <td>{item.produto?.custo.toFixed(2)}</td>}
                      {extraFields.categoria && <td>{item.categoria.nome}</td>}
                      <td>
                        <input
                          type="checkbox"
                          checked={item.ativo}
                          onChange={e => handleAtivoChange(item._id, e.target.checked)}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="deleteSelection"
                          value={item._id}
                          checked={selectedIdToDelete === item._id}
                          onChange={() => setSelectedIdToDelete(item._id)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  )
}

export default PrecosVenda
