// AppPrecoFacilMarket.js
import { useContext, useState, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import AppRoutes from '../routes/routePrecoFacilMarket'
import { fetchData } from '../backend/model'
import { CarregarMarketplaces } from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraGlobal/cadastro-regra-global'

import { config } from '../backend/configConexaoGeral'

import { deslogar } from '../utils/AppMenuFunctions'
import PrivateMenuItem from './PrivateMenuItem'
import { PainelPresidenteMenu } from './AppModelagemCompleta.jsx'
const queryClient = new QueryClient()
import ProtectedMenuLink from './ProtectedMenuLink'
import { FaUser } from 'react-icons/fa'
import NotificationBell from '../../PRECO_FACIL_MARKET/src/components/pages/Notificacoes/NotificationBell.jsx'
function AppPrecoFacilMarket() {
  let { isAuthenticated, assinaturaStatus, logout } = useContext(AuthContext) // Substitua pelo estado real

  const { tenantId, marketplacesAtivos } = useContext(AuthContext)
  const [marketplaces, setMarketplaces] = useState([])

  useEffect(() => {
    const fetchTenantMarketplaces = async () => {
      try {
        const url = config.host + '/regraGlobalPF'

        const dados = { tenantId, requisicao: 'getTenantMarketplaces' }

        const response = await fetchData(url, dados)

        if (response) {
          const allMarketplaces = await CarregarMarketplaces()

          const tenantMarketplaces = allMarketplaces.filter(marketplace =>
            marketplacesAtivos.includes(marketplace._id)
          )

          setMarketplaces(tenantMarketplaces)
        }
      } catch (error) {
        console.error('Erro ao carregar marketplaces do tenant:', error)
        // Trate o erro conforme necessário
      }
    }

    if (tenantId) {
      fetchTenantMarketplaces()
    }
  }, [tenantId, marketplacesAtivos])

  const isMarketplacePresent = nome => {
    const retorno = marketplaces.some(marketplace => marketplace.nome === nome)

    return retorno
  }
  //inicio-pf
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        {isAuthenticated && (
          <Navbar bg="dark" expand="lg">
            <Navbar.Brand as={Link} to="/" className="logo" style={{ fontSize: '1.2rem' }}>
              PREÇO FÁCIL MARKET
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbar-nav">
                <Nav.Link as={Link} to="/inicio-pf">
                  Inicio
                </Nav.Link>
                <PainelPresidenteMenu sistema={'precofacilmarket'} />
                {/* Menu Usuário */}
                {/* <PrivateMenuItem tela="Usuário"> */}
                <NavDropdown title="Usuário" id="usuario-dropdown">
                  <PrivateMenuItem tela="CADASTRAR USUÁRIO">
                    <NavDropdown.Item as={ProtectedMenuLink} to="/cadastro-usuario">
                      CADASTRAR USUÁRIO
                    </NavDropdown.Item>
                  </PrivateMenuItem>
                  <PrivateMenuItem tela="CADASTRAR NIVEL ACESSO">
                    <NavDropdown.Item as={ProtectedMenuLink} to="/cadastro-nivel-acesso">
                      CADASTRAR NIVEL ACESSO
                    </NavDropdown.Item>
                  </PrivateMenuItem>
                  <PrivateMenuItem tela="Configuração de Níveis de Acesso">
                    <NavDropdown.Item
                      as={ProtectedMenuLink}
                      to="/configuracao-usuario-precofacilmarket"
                    >
                      CONFIGURAÇÃO DE NIVEL DE ACESSO
                    </NavDropdown.Item>
                  </PrivateMenuItem>
                  <PrivateMenuItem tela="GERENCIAR IPS DE ACESSO">
                    <NavDropdown.Item as={Link} to="/gerenciador-de-ips">
                      GERENCIAR IPS DE ACESSO
                    </NavDropdown.Item>
                  </PrivateMenuItem>
                </NavDropdown>
                {/* </PrivateMenuItem> */}

                {/* Menu Imposto */}
                <PrivateMenuItem tela="Imposto">
                  <NavDropdown title="Imposto" id="produto-dropdown">
                    <PrivateMenuItem tela="CADASTRAR IMPOSTO">
                      <NavDropdown.Item as={ProtectedMenuLink} to="/cadastro-imposto">
                        CADASTRAR IMPOSTO
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Menu Produto */}
                <PrivateMenuItem tela="Produto">
                  <NavDropdown title="Produto" id="produto-dropdown">
                    <PrivateMenuItem tela="CALCULO DE PREÇO">
                      <NavDropdown.Item as={ProtectedMenuLink} to="/calculo-precoPF">
                        CALCULO DE PREÇO
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="CADASTRO BASE">
                      <NavDropdown.Item as={ProtectedMenuLink} to="/cadastro-produto">
                        CADASTRO BASE
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="GERENCIAR CADASTRO BASE">
                      <NavDropdown.Item as={ProtectedMenuLink} to="/editar-produto">
                        GERENCIAR CADASTRO BASE
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="PRECOS DE VENDA">
                      <NavDropdown.Item as={ProtectedMenuLink} to="/precos-venda">
                        PRECOS DE VENDA
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Menu Categoria */}
                <PrivateMenuItem tela="Categoria">
                  <NavDropdown title="Categoria" id="categoria-dropdown">
                    <PrivateMenuItem tela="CADASTRAR CATEGORIA">
                      <NavDropdown.Item as={ProtectedMenuLink} to="/cadastro-categoria">
                        CADASTRAR CATEGORIA
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="GERENCIAR CATEGORIA">
                      <NavDropdown.Item as={ProtectedMenuLink} to="/edicao-categoria">
                        GERENCIAR CATEGORIA
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Menu Marketplace */}
                <PrivateMenuItem tela="Marketplace">
                  <NavDropdown title="Marketplace" id="marketplace-dropdown">
                    {/* {isMarketplacePresent('Amazon') && (
                      <NavDropdown title="AMAZON" id="amazon-dropdown">
                        <NavDropdown.Item as={ProtectedMenuLink} to="/amazon/cadastrar-regras">
                          Configurar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )} */}
                    {isMarketplacePresent('Mercado Livre') && (
                      <NavDropdown title="MERCADO LIVRE" id="mercadolivre-dropdown">
                        <NavDropdown.Item as={ProtectedMenuLink} to="/config-regras-frete-ML">
                          Configurar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Magalu') && (
                      <NavDropdown title="MAGALU" id="magalu-dropdown">
                        <NavDropdown.Item as={ProtectedMenuLink} to="/regra-magalu">
                          Configurar Regras magalu
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Americanas') && (
                      <NavDropdown title="AMERICANAS" id="magalu-dropdown">
                        <NavDropdown.Item
                          as={ProtectedMenuLink}
                          to="/config-regras-frete-americanas/americanas"
                        >
                          Configurar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Via Varejo') && (
                      <NavDropdown title="VIA VAREJO" id="magalu-dropdown">
                        <NavDropdown.Item
                          as={ProtectedMenuLink}
                          to="/config-regras-frete-americanas/viavarejo"
                        >
                          Configurar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    <PrivateMenuItem tela="CONFIGURAR MARKETPLACES EM USO">
                      <NavDropdown.Item as={Link} to="/tenant-marketplaces-config">
                        CONFIGURAR MARKETPLACES EM USO
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                  </NavDropdown>
                </PrivateMenuItem>
                {/* criar abaixo mais um dropdown de nome Explicativo com 2 submenus, um de nome Regras gerais e outr de nome regras do vendedor, nao precisa usar PrivateMenuItem */}
                <NavDropdown title="Explicativo" id="explicativo-dropdown">
                  <NavDropdown.Item as={Link} to="/explicativo-regras-gerais">
                    Regras Gerais
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/regras-vendedor">
                    Regras do Vendedor
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link as={Link} to="/fale-conosco">
                  Contato
                </Nav.Link>

                <PrivateMenuItem tela="ClientEmail">
                  <Nav.Link as={ProtectedMenuLink} to="/email-client" className="">
                    Email Client
                  </Nav.Link>
                </PrivateMenuItem>
                <PrivateMenuItem tela="Assinar Plano">
                  {assinaturaStatus !== 'ativa' && (
                    <Nav.Link as={Link} to="/assinar-plano" className="cta-button">
                      Assinar Plano
                    </Nav.Link>
                  )}
                </PrivateMenuItem>
                <NavDropdown
                  title={
                    <span>
                      <FaUser /> Usuário
                    </span>
                  }
                  id="usuario-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/alterar-dados">
                    Alterar Dados
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/alterar-senha">
                    Alterar Senha
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => deslogar(logout, 'precofacilmarket')}>
                    Sair
                  </NavDropdown.Item>
                </NavDropdown>
                <PrivateMenuItem tela="Notificacoes">
                  <Nav.Item>
                    <NotificationBell />
                  </Nav.Item>
                </PrivateMenuItem>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}

        <AppRoutes />
      </div>
    </QueryClientProvider>
  )
}

export default AppPrecoFacilMarket
