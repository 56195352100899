import { useState, useEffect } from 'react'
import { Table, Button, Form, Alert, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../../../src/backend/model'
import { FaInfoCircle } from 'react-icons/fa' // Ícone de informação

const CadastroRegraDescontoFreteML = () => {
  const [regras, setRegras] = useState([])
  const [novaRegra, setNovaRegra] = useState({
    categoria: '',
    desconto: ''
  })
  const [mensagem, setMensagem] = useState('')
  const [tipoAlerta, setTipoAlerta] = useState('success')
  const [showAlert, setShowAlert] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [regraParaExcluir, setRegraParaExcluir] = useState(null)

  useEffect(() => {
    carregarRegras()
  }, [])

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [showAlert])

  const carregarRegras = async () => {
    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'CarregarRegrasDescontoFreteMercadoLivre' }
      const response = await fetchData(url, dados)

      if (response) {
        setRegras(response)
      }
    } catch (error) {
      console.error('Erro ao carregar regras:', error)
    }
  }

  const handleChange = (e, index = null) => {
    const { name, value } = e.target

    if (index !== null) {
      setRegras(prevRegras => {
        const novasRegras = [...prevRegras]
        novasRegras[index][name] = value
        return novasRegras
      })
    } else {
      setNovaRegra(prev => ({ ...prev, [name]: value }))
    }
  }

  const salvarRegra = async index => {
    const regra = regras[index]
    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'AtualizarRegraDescontoFreteML', regraId: regra._id, ...regra }
      await fetchData(url, dados)

      setMensagem('Regra atualizada com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
    } catch (error) {
      console.error('Erro ao salvar regra:', error)
      setMensagem('Erro ao salvar regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const adicionarRegra = async () => {
    if (!novaRegra.categoria || !novaRegra.desconto) {
      setMensagem('Todos os campos são obrigatórios.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }

    try {
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'GravarRegraDescontoFreteMercadoLivre', ...novaRegra }
      const response = await fetchData(url, dados)

      if (response) {
        setMensagem('Nova regra adicionada com sucesso!')
        setTipoAlerta('success')
        setShowAlert(true)
        setNovaRegra({ categoria: '', desconto: '' })
        carregarRegras()
      }
    } catch (error) {
      console.error('Erro ao adicionar regra:', error)
      setMensagem('Erro ao adicionar nova regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const confirmarExclusao = index => {
    setRegraParaExcluir(index)
    setShowConfirmModal(true)
  }

  const excluirRegra = async () => {
    try {
      const regraId = regras[regraParaExcluir]._id
      const url = `${config.host}/regraDescontoFrete`
      const dados = { requisicao: 'ExcluirRegraDescontoFreteML', regraId }
      await fetchData(url, dados)

      setMensagem('Regra excluída com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
      setShowConfirmModal(false)
      carregarRegras()
    } catch (error) {
      console.error('Erro ao excluir regra:', error)
      setMensagem('Erro ao excluir regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  return (
    <div className="container">
      <h2 className="mt-4 d-flex align-items-center">
        Cadastro de Regras de Desconto - Mercado Livre{' '}
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-sobre">
              <strong>O que é isso?</strong> <br />
              Aqui você pode gerenciar as regras de desconto do Mercado Livre. <br />
              - Defina a categoria do produto (Verde, Amarela...). <br />
              - Informe o percentual de desconto aplicado a cada categoria. <br />- Edite ou exclua
              regras existentes conforme necessário.
            </Tooltip>
          }
        >
          <span className="ms-2">
            <FaInfoCircle size={20} style={{ cursor: 'pointer', color: '#007bff' }} />
          </span>
        </OverlayTrigger>
      </h2>

      {showAlert && (
        <Alert variant={tipoAlerta} onClose={() => setShowAlert(false)} dismissible>
          {mensagem}
        </Alert>
      )}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Categoria</th>
            <th>Desconto (%)</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {regras.map((regra, index) => (
            <tr key={regra._id}>
              <td>
                <Form.Control
                  type="text"
                  name="categoria"
                  value={regra.categoria}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="desconto"
                  value={regra.desconto}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Button variant="success" onClick={() => salvarRegra(index)}>
                  Salvar
                </Button>{' '}
                <Button variant="danger" onClick={() => confirmarExclusao(index)}>
                  Excluir
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h4>Adicionar Nova Regra</h4>
      <div className="d-flex gap-2">
        <Form.Control
          type="text"
          name="categoria"
          placeholder="Categoria (Verde, Amarela...)"
          value={novaRegra.categoria}
          onChange={handleChange}
        />
        <Form.Control
          type="number"
          name="desconto"
          placeholder="Desconto (%)"
          value={novaRegra.desconto}
          onChange={handleChange}
        />
        <Button variant="primary" onClick={adicionarRegra}>
          Adicionar
        </Button>
      </div>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que deseja excluir esta regra?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={excluirRegra}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CadastroRegraDescontoFreteML
