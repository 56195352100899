import { useState, useEffect, useContext } from 'react'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { Table, FormCheck, FormControl, Button } from 'react-bootstrap'
import { AuthContext } from '../../../../../../src/context/AuthContext'

const ListaProdutosSimulacao = ({ onSelectProduto }) => {
  const { tenantId } = useContext(AuthContext)
  const [produtos, setProdutos] = useState([])
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState(1)
  const [filtro, setFiltro] = useState('')
  const [mostrarTodos, setMostrarTodos] = useState(false)

  useEffect(() => {
    carregarProdutos(1, mostrarTodos)
  }, [mostrarTodos])

  const carregarProdutos = async (pagina, todos) => {
    const url = `${config.host}/simulacaoPF`
    const dados = {
      requisicao: todos ? 'getTodosProdutosPaginados' : 'getProdutosSemSimulacao',
      tenantId
    }
    
    const response = await fetchData(url, dados)
    

    if (response.success) {
      // Verifica se a resposta possui a estrutura paginada
      if (response.data.produtos) {
        setProdutos(response.data.produtos)
        setTotalPaginas(response.data.totalPaginas)
        setPaginaAtual(pagina)
      } else {
        // Quando a resposta é um array simples de produtos
        setProdutos(response.data)
        // Como não há paginação, definimos apenas uma página
        setTotalPaginas(1)
        setPaginaAtual(1)
      }
    }
  }

  const handleSearch = async valor => {
    setFiltro(valor)
    if (valor.trim() === '') {
      carregarProdutos(1, mostrarTodos)
      return
    }
    const resultadoLocal = produtos.filter(
      p => p.descricao.toLowerCase().includes(valor.toLowerCase()) || p.sku.includes(valor)
    )
    if (resultadoLocal.length > 0) {
      setProdutos(resultadoLocal)
    } else {
      const url = `${config.host}/simulacaoPF`
      const dados = {
        requisicao: 'buscarProdutoPorNome',
        filtro: valor,
        tenantId
      }
      const response = await fetchData(url, dados)
      if (response.success) {
        setProdutos(response.data)
      }
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <FormCheck
          type="checkbox"
          label="Mostrar apenas produtos sem simulação"
          checked={!mostrarTodos}
          onChange={() => setMostrarTodos(!mostrarTodos)}
        />
        <FormControl
          type="text"
          placeholder="Pesquisar produtos..."
          value={filtro}
          onChange={e => handleSearch(e.target.value)}
        />
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Código</th>
            <th>Descrição</th>
            <th>Peso</th>
            <th>Custo</th>
            {mostrarTodos && <th>Simulação</th>}
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {produtos?.map(produto => (
            <tr key={produto._id}>
              <td>{produto.sku}</td>
              <td>{produto.descricao}</td>
              <td>{produto.gramas}g</td>
              <td>R$ {produto.custo.toFixed(2)}</td>
              {mostrarTodos && <td>{produto.hasSimulacao ? 'Sim' : 'Não'}</td>}
              <td>
                <Button variant="primary" onClick={() => onSelectProduto(produto)}>
                  Selecionar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between mt-4">
        <Button
          variant="secondary"
          disabled={paginaAtual === 1}
          onClick={() => carregarProdutos(paginaAtual - 1, mostrarTodos)}
        >
          Anterior
        </Button>
        <span>
          Página {paginaAtual} de {totalPaginas}
        </span>
        <Button
          variant="secondary"
          disabled={paginaAtual === totalPaginas}
          onClick={() => carregarProdutos(paginaAtual + 1, mostrarTodos)}
        >
          Próxima
        </Button>
      </div>
    </div>
  )
}

export default ListaProdutosSimulacao
