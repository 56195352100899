import { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Alert, Card } from 'react-bootstrap'
import { fetchData } from '../../../../../../src/backend/model.js'
import { config } from '../../../../../../src/backend/configConexaoGeral.js'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner.jsx'

const CadastroRegraFreteShopee = () => {
  const [percentualFrete, setPercentualFrete] = useState(6) // Valor padrão inicial
  const [marketplaceId, setMarketplaceId] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('success')

  // Carregar o último valor do percentual de Frete Grátis Extra ao montar o componente
  useEffect(() => {
    async function carregarConfiguracao() {
      try {
        const dados = { requisicao: 'CarregarRegrasFreteShopee' }
        const url = `${config.host}/regrasFreteShopee`
        const response = await fetchData(url, dados)

        if (response.length > 0) {
          setMarketplaceId(response[0].marketplaceId)
          setPercentualFrete(response[0].percentualFreteGratisExtra || 6)
        }
      } catch (error) {
        console.error('Erro ao carregar configurações:', error)
        setAlertMessage('Erro ao buscar as configurações. Verifique sua conexão.')
        setAlertVariant('danger')
        setShowAlert(true)
      }
    }

    carregarConfiguracao()
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()

    const dados = {
      marketplaceId,
      percentualFreteGratisExtra: percentualFrete,
      requisicao: 'AtualizarRegrasFreteShopee'
    }

    try {
      const url = `${config.host}/regrasFreteShopee`
      const response = await fetchData(url, dados)

      if (response.message?.toLowerCase().includes('sucesso')) {
        setAlertMessage('Percentual de Frete Grátis Extra atualizado com sucesso!')
        setAlertVariant('success')
      } else {
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
        setAlertVariant('danger')
      }
      setShowAlert(true)
    } catch (error) {
      console.error('Erro ao atualizar configuração:', error)
      setAlertMessage('Erro ao tentar atualizar. Tente novamente.')
      setAlertVariant('danger')
      setShowAlert(true)
    }
  }

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}
      >
        <div style={{ width: '60%' }}>
          <Banner
            title="Configuração do Frete Grátis Extra - Shopee"
            color="#FF5722"
            fontSize={40}
          />
        </div>
      </div>

      {/* Explicação sobre a configuração */}
      <div className="d-flex justify-content-center">
        <Card
          style={{
            width: '50%',
            marginBottom: '20px',
            padding: '20px',
            backgroundColor: '#f8f9fa'
          }}
        >
          <Card.Body>
            <Card.Title>O que é o Frete Grátis Extra?</Card.Title>
            <Card.Text>
              A Shopee oferece um programa chamado <strong>Frete Grátis Extra</strong>, onde o
              comprador pode receber frete grátis em sua compra, e o vendedor arca com um pequeno
              percentual do valor do pedido. Atualmente, esse percentual é de{' '}
              <strong>6% sobre o valor total da compra</strong>.
            </Card.Text>
            <Card.Text>
              Aqui, você pode definir esse percentual. Se a Shopee alterar essa taxa no futuro,
              basta atualizar este valor para que ele seja aplicado automaticamente a todos os
              produtos que utilizam o Frete Grátis Extra.
            </Card.Text>
            <Card.Text>
              <strong>Importante:</strong> Esse percentual será cobrado sobre todas as vendas dos
              produtos onde o vendedor ativou a opção de Frete Grátis Extra.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      <div
        className="d-flex justify-content-center"
        style={{ height: '100vh', position: 'relative', marginTop: '20px' }}
      >
        <Form className="mx-auto" style={{ maxWidth: '500px' }} onSubmit={handleSubmit}>
          {showAlert && (
            <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>
              {alertMessage}
            </Alert>
          )}

          <Form.Group as={Row} controlId="percentualFreteGratisExtra">
            <Form.Label column sm={4}>
              Percentual do Frete Grátis Extra (%)
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="number"
                value={percentualFrete}
                onChange={e => setPercentualFrete(parseFloat(e.target.value))}
                placeholder="Digite o percentual (%)"
                required
              />
            </Col>
          </Form.Group>

          <Button type="submit" className="btn btn-primary" style={{ marginTop: '20px' }}>
            Atualizar Configuração
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default CadastroRegraFreteShopee
