import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../../src/context/AuthContext.jsx'
import PropTypes from 'prop-types'
import { fetchData } from '../../../backend/model'
import { config } from '../../../backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../utils/form-utils.jsx'
import { NavbarModelagemcompleta } from '../Home/NavbarModelagemcompleta.jsx'

const VisualizarTenants = () => {
  // Estado para armazenar os tenants
  const [tenants, setTenants] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('success')
  const [searchTerm, setSearchTerm] = useState('')
  const { RotaBase } = useContext(AuthContext)
  const [editMode, setEditMode] = useState(false)
  const [editingTenant, setEditingTenant] = useState(null)
  const [modulosValores, setModulosValores] = useState({
    corte: 0,
    modelagem: 0,
    plotagem: 0
  })

  // Função para buscar os tenants do backend
  const fetchTenants = async () => {
    setLoading(true)
    setError('')
    try {
      const url = `${config.host}/tenants`
      const dados = {
        requisicao: 'listar_tenants',
        rota_base: RotaBase
      }
      const response = await fetchData(url, dados)

      if (response && Array.isArray(response)) {
        setTenants(response)
      } else {
        setError('Nenhum tenant encontrado.')
      }
    } catch (err) {
      console.error('Erro ao buscar tenants:', err)
      setError('Erro ao buscar tenants. Por favor, tente novamente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  // useEffect para buscar os tenants ao montar o componente
  useEffect(() => {
    fetchTenants()
  }, [])

  // Função para lidar com a busca
  const handleSearch = e => {
    setSearchTerm(e.target.value)
  }

  // Função para deletar um tenant
  const deleteTenant = async (tenantId, tenantNome) => {
    try {
      const confirmDelete = window.confirm(`Tem certeza que deseja excluir ${tenantNome}?`)
      if (!confirmDelete) return

      const url = `${config.host}/tenants`
      const dados = { requisicao: 'deleteTenant', tenantId }

      const response = await fetchData(url, dados)

      if (response && response.message === 'Tenant excluído com sucesso.') {
        setTenants(prevTenants => prevTenants.filter(tenant => tenant._id !== tenantId))
        setAlertMessage('Tenant excluído com sucesso.')
        setAlertVariant('success')
        setShowAlert(true)
      } else {
        setAlertMessage(response.message || 'Erro ao excluir o tenant.')
        setAlertVariant('danger')
        setShowAlert(true)
      }
    } catch (err) {
      console.error('Erro ao excluir tenant:', err)
      setAlertMessage('Erro ao excluir tenant. Por favor, tente novamente mais tarde.')
      setAlertVariant('danger')
      setShowAlert(true)
    }
  }

  // Função para editar um tenant
  const editTenant = tenant => {
    setEditMode(true)
    setEditingTenant({ ...tenant })

    // Atualizar valores dos módulos com os valores do tenant atual ou valores padrões
    setModulosValores({
      corte: tenant.valoresModulos?.find(modulo => modulo.nome === 'corte')?.preco || 0,
      modelagem: tenant.valoresModulos?.find(modulo => modulo.nome === 'modelagem')?.preco || 0,
      plotagem: tenant.valoresModulos?.find(modulo => modulo.nome === 'plotagem')?.preco || 0
    })
  }

  // Função para salvar as alterações do tenant
  const saveTenant = async () => {
    try {
      if (!editingTenant) return

      const url = `${config.host}/tenants`
      const dados = {
        requisicao: 'editTenant',
        ...editingTenant,
        valoresModulos: modulosValores // Adiciona os valores dos módulos ao objeto enviado
      }

      const response = await fetchData(url, dados)

      if (response && response.message === 'Tenant editado com sucesso.') {
        setTenants(prevTenants =>
          prevTenants.map(tenant =>
            tenant._id === editingTenant._id
              ? { ...editingTenant, valoresModulos: modulosValores }
              : tenant
          )
        )
        setAlertMessage('Tenant editado com sucesso.')
        setAlertVariant('success')
        setShowAlert(true)
        setEditMode(false)
        setEditingTenant(null)
      } else {
        setAlertMessage(response.message || 'Erro ao editar o tenant.')
        setAlertVariant('danger')
        setShowAlert(true)
      }
    } catch (err) {
      console.error('Erro ao editar tenant:', err)
      setAlertMessage('Erro ao editar tenant. Por favor, tente novamente mais tarde.')
      setAlertVariant('danger')
      setShowAlert(true)
    }
  }

  // Função para lidar com mudanças no formulário de edição
  const handleEditChange = e => {
    const { name, value } = e.target
    setEditingTenant(prevTenant => ({ ...prevTenant, [name]: value }))
  }

  // Filtrar tenants com base no termo de busca
  const filteredTenants = tenants.filter(tenant => {
    const term = searchTerm.toLowerCase()
    return tenant.nome.toLowerCase().includes(term) || tenant.email.toLowerCase().includes(term)
  })
  const toggleAcesso = async (tenantId, liberar) => {
    try {
      const confirmMessage = liberar
        ? 'Tem certeza que deseja liberar acesso para este tenant?'
        : 'Tem certeza que deseja bloquear o acesso para este tenant?'
      if (!window.confirm(confirmMessage)) return

      const url = `${config.host}/tenants`
      // Define a requisição com base na ação desejada
      const requisicao = liberar ? 'liberarAcesso' : 'bloquearAcesso'
      const dados = { requisicao, tenantId }

      const response = await fetchData(url, dados)
      if (response && response.success) {
        setAlertMessage(liberar ? 'Acesso liberado com sucesso.' : 'Acesso bloqueado com sucesso.')

        setAlertVariant('success')
        setShowAlert(true)
        fetchTenants() // Atualiza a lista para refletir a mudança
      } else {
        setAlertMessage(response.message || 'Erro ao alterar o acesso.')
        setAlertVariant('danger')
        setShowAlert(true)
      }
    } catch (error) {
      console.error('Erro ao alternar acesso:', error)
      setAlertMessage('Erro ao alterar acesso. Por favor, tente novamente.')
      setAlertVariant('danger')
      setShowAlert(true)
    }
  }
  // return (
  //   <div>
  //     <NavbarModelagemcompleta />
  //     <ShowAlertGeneric
  //       showAlert={showAlert}
  //       message={alertMessage}
  //       isBlurred={true}
  //       setShowAlert={setShowAlert}
  //       variant={alertVariant}
  //       className="VT-show-alert-generic"
  //       fontSize="1.5rem"
  //       dismissible={true}
  //       timeOut={3000}
  //     />
  //     <div className="VT-main-container">
  //       <h1 className="VT-main-title">Clientes do Sistema</h1>
  //       <div className="VT-content">
  //         <div className="VT-search-container">
  //           <input
  //             type="text"
  //             placeholder="Buscar por nome, e-mail, usuário ou CPF..."
  //             value={searchTerm}
  //             onChange={handleSearch}
  //             className="VT-search-input"
  //           />
  //         </div>
  //         {loading ? (
  //           <div className="VT-loading">
  //             <p>Carregando tenants...</p>
  //           </div>
  //         ) : error ? (
  //           <div className="VT-error-container">
  //             <p>{error}</p>
  //           </div>
  //         ) : editMode ? (
  //           <div className="VT-edit-container">
  //             <h2>Editando Tenant</h2>
  //             <form>
  //               <div className="VT-form-field">
  //                 <label>Nome:</label>
  //                 <input
  //                   type="text"
  //                   name="nome"
  //                   value={editingTenant.nome}
  //                   onChange={handleEditChange}
  //                 />
  //               </div>
  //               <div className="VT-form-field">
  //                 <label>Tipo:</label>
  //                 <input
  //                   type="text"
  //                   name="tipoLocatario"
  //                   value={editingTenant.tipoLocatario}
  //                   onChange={handleEditChange}
  //                 />
  //               </div>
  //               <div className="VT-form-field">
  //                 <label>Identificação Fiscal:</label>
  //                 <input
  //                   type="text"
  //                   name="identificacaoFiscal"
  //                   value={editingTenant.identificacaoFiscal}
  //                   onChange={handleEditChange}
  //                 />
  //               </div>
  //               <div className="VT-form-field">
  //                 <label>Endereço:</label>
  //                 <input
  //                   type="text"
  //                   name="endereco"
  //                   value={editingTenant.endereco}
  //                   onChange={handleEditChange}
  //                 />
  //               </div>
  //               <div className="VT-form-field">
  //                 <label>Email:</label>
  //                 <input
  //                   type="email"
  //                   name="email"
  //                   value={editingTenant.email}
  //                   onChange={handleEditChange}
  //                 />
  //               </div>
  //               <div className="VT-form-field">
  //                 <label>Telefone:</label>
  //                 <input
  //                   type="text"
  //                   name="telefone"
  //                   value={editingTenant.telefone}
  //                   onChange={handleEditChange}
  //                 />
  //               </div>
  //               <div className="VT-form-field">
  //                 <label>Nível de Acesso:</label>
  //                 <input
  //                   type="text"
  //                   name="nivel_acesso"
  //                   value={editingTenant.nivel_acesso}
  //                   onChange={handleEditChange}
  //                 />
  //               </div>
  //               <div className="VT-form-field">
  //                 <label>Rota Base:</label>
  //                 <input
  //                   type="text"
  //                   name="rota_base"
  //                   value={editingTenant.rota_base}
  //                   onChange={handleEditChange}
  //                 />
  //               </div>
  //               <div className="VT-form-field">
  //                 <label>Email Verificado:</label>
  //                 <input
  //                   type="checkbox"
  //                   name="isEmailVerified"
  //                   checked={editingTenant.isEmailVerified}
  //                   onChange={e =>
  //                     setEditingTenant(prev => ({
  //                       ...prev,
  //                       isEmailVerified: e.target.checked
  //                     }))
  //                   }
  //                 />
  //               </div>
  //               <div className="VT-form-field">
  //                 <label>Módulos:</label>
  //                 <input
  //                   type="text"
  //                   name="modulos"
  //                   value={editingTenant.modulos.join(', ')}
  //                   onChange={e =>
  //                     setEditingTenant(prev => ({
  //                       ...prev,
  //                       modulos: e.target.value.split(',').map(mod => mod.trim())
  //                     }))
  //                   }
  //                 />
  //               </div>
  //               <div className="VT-form-field">
  //                 <label>Valores dos Módulos:</label>
  //                 <div className="VT-module-values">
  //                   <div className="VT-form-field">
  //                     <label>Corte:</label>
  //                     <input
  //                       type="number"
  //                       name="corte"
  //                       value={modulosValores.corte}
  //                       onChange={e =>
  //                         setModulosValores(prev => ({ ...prev, corte: e.target.value }))
  //                       }
  //                     />
  //                   </div>
  //                   <div className="VT-form-field">
  //                     <label>Modelagem:</label>
  //                     <input
  //                       type="number"
  //                       name="modelagem"
  //                       value={modulosValores.modelagem}
  //                       onChange={e =>
  //                         setModulosValores(prev => ({ ...prev, modelagem: e.target.value }))
  //                       }
  //                     />
  //                   </div>
  //                   <div className="VT-form-field">
  //                     <label>Plotagem:</label>
  //                     <input
  //                       type="number"
  //                       name="plotagem"
  //                       value={modulosValores.plotagem}
  //                       onChange={e =>
  //                         setModulosValores(prev => ({ ...prev, plotagem: e.target.value }))
  //                       }
  //                     />
  //                   </div>
  //                 </div>
  //               </div>

  //               <button type="button" onClick={saveTenant} className="btn btn-success">
  //                 Salvar
  //               </button>
  //               <button
  //                 type="button"
  //                 onClick={() => {
  //                   setEditMode(false)
  //                   setEditingTenant(null)
  //                 }}
  //                 className="btn btn-secondary"
  //               >
  //                 Cancelar
  //               </button>
  //             </form>
  //           </div>
  //         ) : (
  //           <div className="VT-table-container">
  //             <table className="table VT-tenant-table">
  //               <thead>
  //                 <tr>
  //                   <th>Usuário</th>
  //                   <th>Nome</th>
  //                   <th>Tipo</th>
  //                   <th>Identificação Fiscal</th>
  //                   <th>Endereço</th>
  //                   <th>E-mail</th>
  //                   <th>Nível de Acesso</th>
  //                   <th>Módulos</th>
  //                   <th>Telefone</th>
  //                   <th>Data de Cadastro</th>
  //                   <th>Ações</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {filteredTenants.length > 0 ? (
  //                   filteredTenants.map(tenant => (
  //                     <tr key={tenant._id}>
  //                       <td data-label="Usuário">{tenant.usuario}</td>
  //                       <td data-label="Nome">{tenant.nome}</td>
  //                       <td data-label="Tipo">{tenant.tipoLocatario}</td>
  //                       <td data-label="Identificação Fiscal">{tenant.identificacaoFiscal}</td>
  //                       <td data-label="Endereço">{tenant.endereco || 'N/A'}</td>
  //                       <td data-label="E-mail">{tenant.email}</td>
  //                       <td data-label="Nível de Acesso">{tenant.nivel_acesso}</td>
  //                       <td data-label="Módulos">
  //                         {tenant.rota_base === 'precofacilmarket'
  //                           ? 'PrecofacilMarket'
  //                           : tenant.modulos.join(', ')}
  //                       </td>

  //                       <td data-label="Telefone">{tenant.telefone}</td>
  //                       <td data-label="Data de Cadastro">
  //                         {new Date(tenant.dataCadastro).toLocaleDateString()}
  //                       </td>
  //                       <td data-label="Ações">
  //                         <button
  //                           className={`btn btn-warning VT-action-btn`}
  //                           onClick={() => editTenant(tenant)}
  //                         >
  //                           Editar
  //                         </button>
  //                         <button
  //                           className={`btn btn-danger VT-action-btn`}
  //                           onClick={() => {
  //                             deleteTenant(tenant._id, tenant.nome)
  //                           }}
  //                         >
  //                           Excluir
  //                         </button>
  //                         <button
  //                           className="btn btn-info VT-action-btn"
  //                           onClick={() =>
  //                             toggleAcesso(tenant._id, !tenant.liberadoPeloDono.liberado)
  //                           }
  //                         >
  //                           {tenant.liberadoPeloDono.liberado
  //                             ? 'Bloquear acesso'
  //                             : 'Liberar Acesso'}
  //                         </button>
  //                       </td>
  //                     </tr>
  //                   ))
  //                 ) : (
  //                   <tr>
  //                     <td colSpan="11" className="text-center">
  //                       Nenhum tenant encontrado.
  //                     </td>
  //                   </tr>
  //                 )}
  //               </tbody>
  //             </table>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // )
  return (
    <div>
      <NavbarModelagemcompleta />
      <ShowAlertGeneric
        showAlert={showAlert}
        message={alertMessage}
        isBlurred={true}
        setShowAlert={setShowAlert}
        variant={alertVariant}
        className="VT-show-alert-generic"
        fontSize="1.5rem"
        dismissible={true}
        timeOut={3000}
      />
      <div style={{ padding: '20px' }}>
        <h1 style={{ marginBottom: '20px' }}>Clientes do Sistema</h1>
        <div>
          <div style={{ marginBottom: '20px' }}>
            <input
              type="text"
              placeholder="Buscar por nome, e-mail, usuário ou CPF..."
              value={searchTerm}
              onChange={handleSearch}
              style={{
                padding: '8px',
                width: '100%',
                boxSizing: 'border-box',
                border: '1px solid #ccc',
                borderRadius: '4px'
              }}
            />
          </div>
          {loading ? (
            <div style={{ textAlign: 'center', padding: '10px' }}>
              <p>Carregando tenants...</p>
            </div>
          ) : error ? (
            <div style={{ textAlign: 'center', padding: '10px', color: 'red' }}>
              <p>{error}</p>
            </div>
          ) : editMode ? (
            <div>
              {/* Conteúdo do modo edição */}
              <h2>Editando Tenant</h2>
              <form>
                {/* Campos de edição */}
                <div style={{ marginBottom: '10px' }}>
                  <label>Nome:</label>
                  <input
                    type="text"
                    name="nome"
                    value={editingTenant.nome}
                    onChange={handleEditChange}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
                {/* Continue com os demais campos */}
                <button type="button" onClick={saveTenant} style={{ marginRight: '5px' }}>
                  Salvar
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setEditMode(false)
                    setEditingTenant(null)
                  }}
                >
                  Cancelar
                </button>
              </form>
            </div>
          ) : (
            <div style={{ overflowX: 'auto' }}>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  tableLayout: 'fixed'
                }}
              >
                <thead>
                  <tr>
                    {[
                      'Usuário',
                      'Nome',
                      'Tipo',
                      'Identificação Fiscal',
                      'Endereço',
                      'E-mail',
                      'Nível de Acesso',
                      'Módulos',
                      'Telefone',
                      'Data de Cadastro',
                      'Ações'
                    ].map(header => (
                      <th
                        key={header}
                        style={{
                          padding: '8px',
                          border: '1px solid #ddd',
                          textAlign: 'left',
                          verticalAlign: 'middle',
                          wordWrap: 'break-word',
                          minWidth: header === 'Ações' ? '250px' : 'auto'
                        }}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredTenants.length > 0 ? (
                    filteredTenants.map(tenant => (
                      <tr key={tenant._id}>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                          {tenant.usuario}
                        </td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>{tenant.nome}</td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                          {tenant.tipoLocatario}
                        </td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                          {tenant.identificacaoFiscal}
                        </td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                          {tenant.endereco || 'N/A'}
                        </td>
                        <td
                          style={{
                            padding: '8px',
                            border: '1px solid #ddd',
                            whiteSpace: 'normal',
                            wordBreak: 'break-all'
                          }}
                        >
                          {tenant.email}
                        </td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                          {tenant.nivel_acesso}
                        </td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                          {tenant.rota_base === 'precofacilmarket'
                            ? 'PrecofacilMarket'
                            : tenant.modulos.join(', ')}
                        </td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                          {tenant.telefone}
                        </td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                          {new Date(tenant.dataCadastro).toLocaleDateString()}
                        </td>
                        <td
                          style={{
                            padding: '8px',
                            border: '1px solid #ddd',
                            minWidth: '250px'
                          }}
                        >
                          <button
                            style={{
                              marginRight: '5px',
                              padding: '6px 12px',
                              border: '1px solid #ffc107',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              backgroundColor: '#ffc107',
                              color: '#fff'
                            }}
                            onClick={() => editTenant(tenant)}
                          >
                            Editar
                          </button>
                          <button
                            style={{
                              marginRight: '5px',
                              padding: '6px 12px',
                              border: '1px solid #dc3545',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              backgroundColor: '#dc3545',
                              color: '#fff'
                            }}
                            onClick={() => deleteTenant(tenant._id, tenant.nome)}
                          >
                            Excluir
                          </button>
                          <button
                            style={{
                              marginRight: '5px',
                              padding: '6px 12px',
                              border: '1px solid',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              backgroundColor: tenant.liberadoPeloDono.liberado
                                ? 'black'
                                : '#28a745',
                              borderColor: tenant.liberadoPeloDono.liberado ? '#fff' : '#28a745',
                              color: '#fff'
                            }}
                            onClick={() =>
                              toggleAcesso(tenant._id, !tenant.liberadoPeloDono.liberado)
                            }
                          >
                            {tenant.liberadoPeloDono.liberado
                              ? 'Bloquear acesso'
                              : 'Liberar Acesso'}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="11"
                        style={{
                          textAlign: 'center',
                          padding: '8px',
                          border: '1px solid #ddd'
                        }}
                      >
                        Nenhum tenant encontrado.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VisualizarTenants
