import { useState, useContext, useEffect } from 'react'
import { Button, FormGroup, Row, Col, Modal, Tab, Nav } from 'react-bootstrap'
import Select from 'react-select'
import { FaPlus } from 'react-icons/fa'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils.jsx'
import CadastroCategoria from '/PRECO_FACIL_MARKET/src/components/pages/Categorias/CadastroCategoria/cadastro-categoria'
import { AuthContext } from '../../../../../../src/context/AuthContext'
import ImportarProdutos from './importacao-produtos.jsx' // Certifique-se de ajustar o caminho conforme necessário

const CadastroProdutoPF = () => {
  // Estado para alternar entre abas

  // Estados do formulário de cadastro individual
  const [produtoPF, setProdutoPF] = useState({
    sku: '',
    descricao: '',
    gramas: '',
    custo: '',
    categoriaId: ''
  })
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('danger')
  const { tenantId } = useContext(AuthContext)
  const [categorias, setCategorias] = useState([])
  const [isLoadingCategorias, setIsLoadingCategorias] = useState(false)
  const [showCategoriaModal, setShowCategoriaModal] = useState(false)

  // Carregar categorias assim que tenantId estiver definido
  useEffect(() => {
    if (tenantId) {
      const fetchCategorias = async () => {
        setIsLoadingCategorias(true)
        try {
          const categoriasCarregadas = await CarregarCategorias(tenantId)
          setCategorias(categoriasCarregadas || [])
        } catch (error) {
          console.error('Erro ao carregar categorias:', error)
        }
        setIsLoadingCategorias(false)
      }
      fetchCategorias()
    }
  }, [tenantId])

  const toggleCategoriaModal = () => setShowCategoriaModal(!showCategoriaModal)

  const handleChangeCategoria = selectedOption => {
    setProdutoPF(prevState => ({
      ...prevState,
      categoriaId: selectedOption ? selectedOption.value : ''
    }))
  }

  const handleChange = e => {
    setProdutoPF({
      ...produtoPF,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const dados = {
      ...produtoPF,
      categoriaId: produtoPF.categoriaId,
      tenantId: tenantId,
      requisicao: 'GravarProdutoPF'
    }
    const url = config.host + '/produtoPF'
    try {
      const response = await fetchData(url, dados)
      if (response.produto) {
        setProdutoPF({
          sku: '',
          descricao: '',
          gramas: '',
          custo: '',
          categoriaId: ''
        })
        setShowAlert(true)
        setCorAlerta('success')
        setAlertMessage('Produto Gravado Com Sucesso')
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar gravar o produto. Tente novamente.')
    }
  }

  // Opções para o react‑select a partir das categorias carregadas
  const categoriaOptions = categorias
    ? categorias.map(categoria => ({
        value: categoria._id,
        label: categoria.nome
      }))
    : []

  return (
    <div
      className="form-container"
      // Removemos d-flex e align-items-center
      // Substituímos height: '100vh' por minHeight: '100vh' (ou removemos de vez)
      style={{ minHeight: '100vh', position: 'relative' }}
    >
      {/* Centraliza horizontalmente se desejar, mas sem forçar a centralização vertical */}
      <div className="text-center mx-auto" style={{ maxWidth: '2000px' }}>
        {/* Remova o marginTop negativo se não precisar dele */}
        <Banner
          title="Cadastro de Produto"
          color="#2e8b57"
          marginTop="30px"
          marginBottom="50px"
          classEfeito3dMouseOver=""
        />

        <Tab.Container defaultActiveKey="individual">
          <Nav variant="tabs" className="d-flex justify-content-center">
            <Nav.Item>
              <Nav.Link eventKey="individual">Cadastro Individual</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="lote">Cadastro por Lote</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="mt-3">
            <Tab.Pane eventKey="individual">
              <FormGroup style={{ maxWidth: '1100px', margin: 'auto', paddingTop: '20px' }}>
                {showAlert && (
                  <ShowAlertGeneric
                    showAlert={showAlert}
                    message={alertMessage}
                    isBlurred={true}
                    setShowAlert={setShowAlert}
                    variant={corAlerta}
                  />
                )}
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6} className="mb-3">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Select
                          value={categoriaOptions.find(
                            option => option.value === produtoPF.categoriaId
                          )}
                          onChange={handleChangeCategoria}
                          options={categoriaOptions}
                          isLoading={isLoadingCategorias}
                          isClearable
                          isSearchable
                          placeholder="Selecione ou pesquise a Categoria"
                          styles={{
                            container: base => ({ ...base, width: 350, marginRight: '8px' })
                          }}
                        />
                        <FaPlus
                          onClick={toggleCategoriaModal}
                          size={20}
                          color="green"
                          style={{ cursor: 'pointer' }}
                          title="Adicionar Categoria"
                        />
                        <Modal show={showCategoriaModal} onHide={toggleCategoriaModal}>
                          <Modal.Header closeButton>
                            <Modal.Title>Cadastrar Nova Categoria</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <CadastroCategoria fontSize={40} classEfeito3dMouseOver={''} />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={toggleCategoriaModal}>
                              Fechar
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </Col>
                    <Col md={6} className="mb-3 pl-md-4">
                      <input
                        type="text"
                        name="sku"
                        className="form-control"
                        placeholder="SKU do Produto"
                        onChange={handleChange}
                        value={produtoPF.sku}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} className="mb-3">
                      <input
                        type="text"
                        name="descricao"
                        className="form-control"
                        placeholder="Descrição do Produto"
                        onChange={handleChange}
                        value={produtoPF.descricao}
                      />
                    </Col>
                    <Col md={4} className="mb-3">
                      <input
                        type="number"
                        name="gramas"
                        className="form-control"
                        placeholder="Gramas"
                        onChange={handleChange}
                        value={produtoPF.gramas}
                      />
                    </Col>
                    <Col md={4} className="mb-3">
                      <input
                        type="number"
                        name="custo"
                        className="form-control"
                        placeholder="Custo"
                        onChange={handleChange}
                        value={produtoPF.custo}
                      />
                    </Col>
                  </Row>
                  <Button type="submit" className="btn btn-success" style={{ marginTop: '20px' }}>
                    Cadastrar
                  </Button>
                </form>
              </FormGroup>
            </Tab.Pane>
            <Tab.Pane eventKey="lote">
              <div style={{ padding: '20px', minHeight: '400px', overflowY: 'auto' }}>
                <ImportarProdutos />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  )
}

export default CadastroProdutoPF

// Função para carregar as categorias do produto PF
export async function CarregarCategorias(tenantId) {
  try {
    const dados = {
      requisicao: 'CarregarCategoriasPF',
      tenantId: tenantId
    }
    const url = config.host + '/categoriaPF'
    const response = await fetchData(url, dados)
    return response
  } catch (error) {
    console.error('Erro ao carregar categorias:', error)
  }
}
