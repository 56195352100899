import { useState, useContext, useEffect } from 'react'
import Select from 'react-select'
import { Button } from 'react-bootstrap'
import FeedbackImportacao from './FeedbackImportacao'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { AuthContext } from '../../../../../../src/context/AuthContext'
function ImportarProdutos() {
  const { tenantId } = useContext(AuthContext)
  const [dados, setDados] = useState('')
  const [tabela, setTabela] = useState([])
  const [mapeamento, setMapeamento] = useState({})
  const [feedback, setFeedback] = useState(null) // Estado para armazenar o feedback da importação
  const [showTooltip, setShowTooltip] = useState(false)
  const [categoriaSelecionada, setCategoriaSelecionada] = useState('')
  const [categorias, setCategorias] = useState([])
  const [isLoadingCategorias, setIsLoadingCategorias] = useState(false)
  // Armazena os produtos extraídos da tabela (planilha)

  // Carrega as categorias do banco para o tenant atual
  useEffect(() => {
    if (tenantId) {
      const carregarCategorias = async () => {
        setIsLoadingCategorias(true)
        try {
          const dados = {
            requisicao: 'CarregarCategoriasPF',
            tenantId: tenantId
          }
          const url = config.host + '/categoriaPF'
          const response = await fetchData(url, dados)
          setCategorias(response || [])
        } catch (error) {
          console.error('Erro ao carregar categorias:', error)
        }
        setIsLoadingCategorias(false)
      }
      carregarCategorias()
    }
  }, [tenantId])
  // Converte as categorias em opções para o react‑select
  const categoriaOptions = categorias.map(categoria => ({
    value: categoria._id,
    label: categoria.nome
  }))
  const getProdutosFromTabela = () => {
    if (tabela.length === 0) return []
    const headers = tabela[0]
    return tabela.slice(1).map(row => {
      const produto = {}
      headers.forEach((header, index) => {
        const key = mapeamento[header]
        produto[key] = row[index]
      })
      return produto
    })
  }

  // Função para limpar os estados
  const handleClear = () => {
    setDados('')
    setTabela([])
    setMapeamento({})
    setFeedback(null)
  }

  // Mapeia os cabeçalhos para os nomes de campos do produto
  const mapearColunas = headers => {
    const mapping = {}
    headers.forEach(header => {
      const h = header.toLowerCase()
      if (h.includes('sku')) {
        mapping[header] = 'sku'
      } else if (h.includes('descrição') || h.includes('descr')) {
        mapping[header] = 'descricao'
      } else if (h.includes('peso')) {
        mapping[header] = 'gramas'
      } else if (h.includes('custo') || h.includes('preço')) {
        mapping[header] = 'custo'
      } else {
        mapping[header] = 'outro'
      }
    })
    return mapping
  }

  // Processa o texto colado para gerar a tabela e o mapeamento
  const processarDados = texto => {
    const linhas = texto.trim().split('\n')
    if (linhas.length === 0) return
    const cabecalho = linhas[0].split('\t')
    const registros = linhas.slice(1).map(linha => linha.split('\t'))
    setTabela([cabecalho, ...registros])
    const mapping = mapearColunas(cabecalho)
    setMapeamento(mapping)
  }

  const handleChange = e => {
    const texto = e.target.value
    setDados(texto)
    processarDados(texto)
  }

  // Função para dividir um array em pedaços (chunks) de tamanho definido
  const chunkArray = (array, size) => {
    const result = []
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size))
    }
    return result
  }

  // Função para enviar os produtos para inserção (sem sobrescrever)
  const handleCadastrar = async () => {
    // Verifica se uma categoria foi selecionada
    if (!categoriaSelecionada) {
      alert('Aviso! Selecione uma categoria antes de importar.')
      return
    }
    // Validação dos campos: deve haver os campos necessários e nenhum campo "outro"
    const requiredFields = ['sku', 'descricao', 'gramas', 'custo']
    const mappedValues = Object.values(mapeamento)
    const hasOutro = mappedValues.includes('outro')
    const hasAllRequired = requiredFields.every(field => mappedValues.includes(field))
    if (hasOutro || !hasAllRequired) {
      // Em vez de alert(), você pode definir algum estado de erro (aqui simplificamos com console)
      console.error('Erro: As colunas devem ser exatamente: "SKU", "Descrição", "Peso" e "Custo".')
      return
    }

    const produtosArray = getProdutosFromTabela()
    if (produtosArray.length === 0) {
      console.error('Nenhum produto encontrado para importação.')
      return
    }

    // URL correta conforme a correção
    const url = config.host + '/produtoPF'
    const chunks = chunkArray(produtosArray, 100)
    let acumuladoInseridos = []
    let acumuladoDuplicados = []

    // Para cada lote, envia a requisição de inserção (a função do servidor usa "GravarProdutosEmLote")
    for (const chunk of chunks) {
      try {
        const response = await fetchData(url, {
          tenantId,
          categoriaId: categoriaSelecionada.value,
          produtos: chunk,
          requisicao: 'GravarProdutosEmLote'
        })

        if (response.inseridos) {
          acumuladoInseridos = acumuladoInseridos.concat(response.inseridos)
        }
        if (response.duplicados) {
          acumuladoDuplicados = acumuladoDuplicados.concat(response.duplicados)
        }
      } catch (error) {
        console.error('Erro ao enviar lote:', error)
      }
    }

    // Define o feedback com os dados acumulados e armazena também os produtos importados

    setFeedback({
      inseridos: acumuladoInseridos,
      duplicados: acumuladoDuplicados,
      produtosImportados: produtosArray
    })
  }

  // Callback para sobrescrever um único produto duplicado
  const handleOverwriteProduct = async produto => {
    try {
      const url = config.host + '/produtoPF'
      // Envia apenas o produto selecionado para atualização (usando a função "SobrescreverProdutosEmLote")
      const response = await fetchData(url, {
        produtos: [produto],
        requisicao: 'SobrescreverProdutosEmLote'
      })

      // Atualiza o feedback removendo este SKU da lista de duplicados
      setFeedback(prev => {
        const novasDuplicados = prev.duplicados.filter(
          dup => dup.sku.toLowerCase() !== produto.sku.toLowerCase()
        )
        const novosInseridos = prev.inseridos.concat(response.atualizados || [])
        return { ...prev, duplicados: novasDuplicados, inseridos: novosInseridos }
      })
    } catch (error) {
      console.error('Erro ao sobrescrever o produto:', error)
    }
  }

  // Callback para sobrescrever todos os produtos duplicados
  const handleOverwriteAll = async () => {
    if (!feedback || !feedback.duplicados || feedback.duplicados.length === 0) return
    // Filtra os produtos duplicados a partir da lista de produtos importados
    const duplicadosSkus = feedback.duplicados.map(dup => dup.sku.toLowerCase())
    const produtosParaSobrescrever = feedback.produtosImportados.filter(prod =>
      duplicadosSkus.includes(prod.sku.toLowerCase())
    )
    try {
      const url = config.host + '/produtoPF'
      const response = await fetchData(url, {
        produtos: produtosParaSobrescrever,
        requisicao: 'SobrescreverProdutosEmLote'
      })
      console.log('Sobrescrever todos:', response)
      // Atualiza o feedback: todos os duplicados foram sobrescritos
      setFeedback(prev => {
        const novosInseridos = prev.inseridos.concat(response.atualizados || [])
        return { ...prev, duplicados: [], inseridos: novosInseridos }
      })
    } catch (error) {
      console.error('Erro ao sobrescrever todos os produtos:', error)
    }
  }

  // Caso exista feedback e duplicados, calcula arrays para exibição nas tabelas:
  let duplicadosEnviados = []
  let duplicadosExistentes = []
  if (feedback && feedback.duplicados && feedback.produtosImportados) {
    // Cria um set com os SKUs duplicados (em lowercase)

    const setDup = new Set(feedback.duplicados.map(d => d.sku.toLowerCase()))
    // Produtos enviados duplicados (dados do array original)
    duplicadosEnviados = feedback.produtosImportados.filter(prod =>
      setDup.has(prod.sku.toLowerCase())
    )
    // Produtos existentes no banco (assumindo que o servidor retornou o objeto do produto existente)
    duplicadosExistentes = feedback.duplicados.map(
      d =>
        d.produtoExistente || {
          sku: d.sku,
          descricao: d.descricao,
          custo: d.custo,
          gramas: d.gramas
        }
    )
    console.log('duplicadosExistentes3:', duplicadosExistentes)
    console.log('feedback.duplicados3:', feedback.duplicados)
  }

  return (
    <div>
      <h2>Importar Produtos</h2>
      {/* Select para escolha da categoria, similar ao feito em CadastroProdutoPF */}
      <div style={{ marginBottom: '10px', width: '400px' }}>
        <label htmlFor="categoriaSelect" style={{ marginRight: '10px' }}>
          Categoria:
        </label>
        <Select
          id="categoriaSelect"
          options={categoriaOptions}
          value={categoriaSelecionada}
          onChange={setCategoriaSelecionada}
          isLoading={isLoadingCategorias}
          placeholder="Selecione a categoria..."
          isClearable
        />
      </div>
      <div>
        <div style={{ marginTop: '20px' }}>
          <span
            style={{
              textDecoration: 'underline',
              color: 'blue',
              cursor: 'pointer',
              fontWeight: 'bold'
            }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            Regras Básicas
          </span>
        </div>
        {showTooltip && (
          <div
            style={{
              position: 'absolute',
              top: '25px',
              left: '0',
              width: '300px',
              backgroundColor: '#f0f8ff',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              zIndex: 1
            }}
          >
            <h4 style={{ marginTop: 0 }}>Instruções Básicas</h4>
            <ul style={{ textAlign: 'left', marginBottom: 0 }}>
              <li>
                Renomeie as colunas da sua planilha para: <strong>SKU</strong>,{' '}
                <strong>Descrição</strong>, <strong>Peso</strong> e <strong>Custo</strong>.
              </li>
              <li>Copie e cole todos os dados, incluindo o cabeçalho, na caixa de texto abaixo.</li>
              <li>
                Confira a pré-visualização antes de cadastrar para garantir que os dados estão
                corretos.
              </li>
            </ul>
          </div>
        )}
        <textarea
          rows="10"
          cols="50"
          placeholder="Cole aqui os dados da planilha..."
          value={dados}
          onChange={handleChange}
          style={{ width: '100%' }}
        />
      </div>

      {tabela.length > 0 && (
        <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
          <Button variant="success" onClick={handleCadastrar} style={{ width: '200px' }}>
            Cadastrar Produtos
          </Button>
          <Button variant="danger" onClick={handleClear} style={{ width: '200px' }}>
            Limpar Tudo
          </Button>
        </div>
      )}

      {tabela.length > 0 && (
        <div style={{ marginTop: '20px', maxHeight: '400px', overflowY: 'auto' }}>
          <h3>Pré-visualização dos Dados</h3>
          <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                {tabela[0].map((col, index) => (
                  <th
                    key={index}
                    style={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 2 }}
                  >
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tabela.slice(1).map((linha, rowIndex) => (
                <tr key={rowIndex}>
                  {linha.map((celula, colIndex) => (
                    <td key={colIndex}>{celula}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* Se houver feedback (resultado da importação), exibe o componente de feedback */}
      {feedback && (
        <FeedbackImportacao
          insertedCount={feedback.inseridos.length}
          duplicateCount={feedback.duplicados.length}
          duplicadosEnviados={duplicadosEnviados}
          duplicadosExistentes={duplicadosExistentes}
          onOverwriteProduct={handleOverwriteProduct}
          onOverwriteAll={handleOverwriteAll}
        />
      )}
    </div>
  )
}

export default ImportarProdutos
