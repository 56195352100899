import { useState, useContext, useRef } from 'react'
import { AuthContext } from '../../../context/AuthContext.jsx'
import { Form, Button, Container, Alert } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { NavbarModelagemcompleta } from './NavbarModelagemcompleta.jsx'
import logoTopoModelagemCompleta from '../../../../src/components/pages/Usuarios/login/logo_topo.jpg'
import logoModaNegocios360 from '../../../../src/components/pages/Usuarios/login/4.png'
import nomeModaNegocios360 from '../../../../src/components/pages/Usuarios/login/6.png'
import { fetchData } from '../../../backend/model.js'
import { config } from '../../../backend/configConexaoGeral.js'
import logoPrecoFacilMarket from '../../../../PRECO_FACIL_MARKET/src/components/pages/Usuarios/login/logo_topo.jpg'

import Banner from '../../../../src/components/pages/CadastroGeral/Banner.jsx'
const ContatoContainer = styled(Container)`
  max-width: 600px;
  max-height: 600px;
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
`

const SubmitButton = styled(Button)`
  position: absolute;
  top: 10px;
  width: 15%;
  left: 84%;
`

const MensagemTextarea = styled(Form.Control)`
  display: block;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 196%;
  border-radius: 8px;
  border: 1px solid #ced4da;
  padding: 10px;
`

export default function FaleConosco() {
  const location = useLocation()
  const hostname = window.location.hostname
  const pathname = location.pathname
  const { RotaBase, email } = useContext(AuthContext)
  console.log('user', email)
  // Determina se estamos no domínio de Moda Negócios 360
  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  // Verifica se é Preço Fácil Market
  const isPrecoFacilMarket =
    (hostname && hostname.includes('precofacilmarket')) ||
    (pathname && pathname.includes('precofacilmarket'))

  // Define as variáveis de logo, dimensões e título conforme o sistema
  let logoTopo, logoTopoHeight, logoTopoWidth, title, titleHeight

  if (isModaNegocios360) {
    logoTopo = logoModaNegocios360
    logoTopoHeight = '55px'
    logoTopoWidth = '65px'
    title = <img src={nomeModaNegocios360} alt="Moda Negócios 360" style={{ height: '150px' }} />
    titleHeight = '60px'
  } else if (isPrecoFacilMarket) {
    logoTopo = logoPrecoFacilMarket
    logoTopoHeight = '60px' // ajuste conforme sua identidade visual
    logoTopoWidth = '70px'
    title = (
      <Banner
        title="Preço Fácil Market"
        fontSize={50}
        width="48vw"
        color="#2e8b57"
        marginTop="10px"
      />
    )
    titleHeight = '60px'
  } else {
    // Caso padrão – por exemplo, Modelagem Completa
    logoTopo = logoTopoModelagemCompleta
    logoTopoHeight = '60px'
    logoTopoWidth = '70px'
    title = 'Modelagem Completa'
    titleHeight = undefined
  }

  // Estado do formulário
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    assunto: '',
    mensagem: ''
  })

  const [status, setStatus] = useState({
    loading: false,
    success: null,
    error: null
  })

  // Referência para o textarea
  const mensagemRef = useRef(null)

  // Validação básica do email
  const validarEmail = email => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  // Atualiza os valores do formulário
  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  // Submissão do formulário
  const handleSubmit = async e => {
    e.preventDefault()
    // Se o email do contexto existir, use-o; caso contrário, use o valor que o usuário digitou
    const emailToUse = email || formData.email
    // Validação básica usando o emailToUse
    if (!formData.nome || !emailToUse || !formData.assunto || !formData.mensagem) {
      setStatus({ loading: false, success: null, error: 'Todos os campos são obrigatórios.' })
      return
    }

    if (!validarEmail(emailToUse)) {
      setStatus({ loading: false, success: null, error: 'Por favor, insira um e-mail válido.' })
      return
    }

    setStatus({ loading: true, success: null, error: null })

    try {
      const url = `${config.host}/tenants` // Rota correta para o servidor
      const dados = {
        formData: { ...formData, email: emailToUse }, // Sobrescreve o email com o valor do AuthContext, se existir
        rota_base: RotaBase,
        requisicao: 'enviarContato' // Nome da requisição no backend
      }

      const response = await fetchData(url, dados)

      setStatus({ loading: false, success: response.message, error: null })
      // Limpa o formulário após o sucesso
      setFormData({ nome: '', email: emailToUse, assunto: '', mensagem: '' })
      if (mensagemRef.current) {
        mensagemRef.current.value = '' // Limpa o textarea
      }
    } catch (error) {
      setStatus({
        loading: false,
        success: null,
        error:
          error.response?.data?.message || 'Erro ao enviar mensagem. Tente novamente mais tarde.'
      })
    }
  }

  return (
    <div className="fale-conosco-wrapper">
      {!email && (
        <NavbarModelagemcompleta
          setShowAlertMessage={() => {}}
          setAlertMessage={() => {}}
          logoTopo={logoTopo}
          logoTopoHeight={logoTopoHeight}
          logoTopoWidth={logoTopoWidth}
          title={
            isModaNegocios360 ? (
              <img src={nomeModaNegocios360} alt="Moda Negócios 360" style={{ height: '150px' }} />
            ) : (
              title
            )
          }
          titleHeight={titleHeight}
        />
      )}

      <ContatoContainer>
        <h2 className="fale-conosco-title" style={{ textAlign: 'center' }}>
          Formulário de Contato
        </h2>
        {status.success && <Alert variant="success">{status.success}</Alert>}
        {status.error && <Alert variant="danger">{status.error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNome" className="mb-3">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite seu nome"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              required
            />
          </Form.Group>

          {!email && (
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="Digite seu e-mail"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          )}

          <Form.Group controlId="formAssunto" className="mb-3">
            <Form.Label>Assunto</Form.Label>
            <Form.Control
              type="text"
              placeholder="Assunto"
              name="assunto"
              value={formData.assunto}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formMensagem" className="mb-3">
            <Form.Label>Mensagem</Form.Label>
            <MensagemTextarea
              as="textarea"
              rows={6}
              ref={mensagemRef}
              name="mensagem"
              value={formData.mensagem}
              onChange={handleChange}
              placeholder="Digite sua mensagem"
              required
            />
          </Form.Group>
        </Form>
        <SubmitButton
          variant="primary"
          type="button"
          onClick={handleSubmit}
          disabled={status.loading}
        >
          {status.loading ? 'Enviando...' : 'Enviar'}
        </SubmitButton>
      </ContatoContainer>
    </div>
  )
}
