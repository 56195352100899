// AtivoFilter.jsx
import { useState } from 'react'
import { Form, Button, Collapse } from 'react-bootstrap'

const AtivoFilter = ({ carregarPrecosVenda, setFiltro, filtro }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleRadioChange = e => {
    const novoFiltro = e.target.value
    setFiltro(novoFiltro)
    if (typeof carregarPrecosVenda === 'function') {
      carregarPrecosVenda(novoFiltro)
    }
    setIsExpanded(false)
  }

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '1rem',
        borderRadius: '4px',
        marginBottom: '1rem'
      }}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <Button
        variant="secondary"
        style={{ marginBottom: '0.5rem', width: '100%', backgroundColor: '#FFFFFF', color: '#000' }}
      >
        ativos/desativados {isExpanded ? '▲' : '▼'}
      </Button>
      <Collapse in={isExpanded}>
        <div id="ativo-fields-collapse">
          <Form>
            <Form.Check
              type="radio"
              label="Mostrar ativos"
              id="ativos"
              name="ativoFilter"
              value="ativos"
              checked={filtro === 'ativos'}
              onChange={handleRadioChange}
            />
            <Form.Check
              type="radio"
              label="Mostrar desativados"
              id="desativados"
              name="ativoFilter"
              value="desativados"
              checked={filtro === 'desativados'}
              onChange={handleRadioChange}
            />
            <Form.Check
              type="radio"
              label="Mostrar todos"
              id="todos"
              name="ativoFilter"
              value="todos"
              checked={filtro === 'todos'}
              onChange={handleRadioChange}
            />
          </Form>
        </div>
      </Collapse>
    </div>
  )
}

export default AtivoFilter
