//calculo-precoPF.js
import diacritics from 'diacritics'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
export function ResultSearch(inputText, todos_dados) {
  if (!todos_dados) return []
  const searchInput = diacritics.remove(inputText).toLowerCase().trim()
  return todos_dados.filter(item => {
    // Convertendo campos numéricos para string e verificando se eles contêm o texto de entrada
    const descricaoMatch =
      item.descricao && diacritics.remove(item.descricao).toLowerCase().includes(searchInput)
    const skuMatch = item.sku && diacritics.remove(item.sku).toLowerCase().includes(searchInput)
    const gramasMatch = item.gramas.toString().includes(searchInput) // `gramas` como número, apenas convertendo para string
    const custoMatch = item.custo.toString().includes(searchInput) // `custo` como número, apenas convertendo para string

    return descricaoMatch || skuMatch || gramasMatch || custoMatch
  })
}
//----------------------------------------------------------------
export function addColorsToMarketplaces(marketplaces, colors) {
  if (!Array.isArray(marketplaces)) {
    console.error('marketplaces is not an array', marketplaces)
    return []
  }
  return marketplaces.map(marketplace => ({
    ...marketplace,
    cor: colors[marketplace.nome] || '#000000' // Usa preto como cor padrão se não encontrar correspondência
  }))
}
//----------------------------------------------------------------
export function mergeComissoesWithMarketplaces(marketplaces, comissoes, marketplaceColors) {
  // Cria um mapa de comissões para um acesso mais rápido
  if (!Array.isArray(marketplaces)) {
    console.error('marketplaces is not an array', marketplaces)
    return []
  }
  if (!Array.isArray(comissoes)) {
    console.error('comissoes is not an array', comissoes)
    return marketplaces?.map(marketplace => ({
      ...marketplace,
      comissao: ''
    }))
  }

  marketplaces = addColorsToMarketplaces(marketplaces, marketplaceColors)

  const comissoesMap = comissoes.reduce((acc, comissao) => {
    acc[comissao.marketplaceId] = comissao.comissao
    return acc
  }, {})

  // Mapeia os marketplaces para incluir a comissão de acordo com o _id
  return marketplaces?.map(marketplace => ({
    ...marketplace,
    comissao: comissoesMap[marketplace._id] || '' // Se não encontrar, coloca um valor padrão
  }))
}
//----------------------------------------------------------------
export async function CarregarComissoesProduto(categoriaId) {
  try {
    let dados = {
      requisicao: 'CarregarComissoesProduto',
      categoriaId: categoriaId
    }
    let url = config.host + '/produtoPF'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar produtos:', error)
    // Trate o erro conforme necessário
  }
}
//----------------------------------------------------------------
export function ComissaoMarketplace(comissoes, marketplace) {
  // Encontrar a comissão que corresponde ao marketplace fornecido

  const comissaoEncontrada = comissoes.find(comissao => comissao.marketplaceId === marketplace._id)

  // Retorna o valor da comissão se uma correspondência foi encontrada, caso contrário retorna null ou algum valor padrão
  return comissaoEncontrada ? comissaoEncontrada.comissao : null
}
//----------------------------------------------------------------
export async function CarregarRegrasPorGrupoAmVv(grupoNum, marketplaceId) {
  try {
    let dados = {
      requisicao: 'CarregarRegrasPorGrupoAmVv',
      grupoNum: grupoNum,
      marketplaceId: marketplaceId
    }
    let url = config.host + '/regraDescontoFrete'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar regras por grupo:', error)
    // Trate o erro conforme necessário
  }
}
export async function carregarRegrasAmericanas() {
  const dados = {
    requisicao: 'carregarRegrasFreteMarketplace',
    marketplace: 'americanas'
  }

  const url = config.host + '/regrasFreteAmericanasViaVarejo'

  const regras = await fetchData(url, dados)

  return regras
}
export async function carregarRegrasViaVarejo() {
  const dados = {
    requisicao: 'carregarRegrasFreteMarketplace',
    marketplace: 'viavarejo'
  }
  const url = config.host + '/regrasFreteAmericanasViaVarejo'
  const regras = await fetchData(url, dados)
  return regras
}
export async function carregarDescontoVendedorAmericanas(tenantId, idMarketplaceAmericanas) {
  const dados = {
    requisicao: 'CarregarConfigAmVv',
    tenantId, // ou outro identificador do vendedor/tenant
    marketplaceId: idMarketplaceAmericanas,
    marketplace: 'americanas'
  }

  const url = config.host + '/regraDescontoFrete'
  const response = await fetchData(url, dados)

  // Supondo que o endpoint retorne um objeto com { desconto: 10 } para 10%
  return response.desconto || 0
}

export async function carregarDescontoVendedorViaVarejo(tenantId, idMarketplaceViaVarejo) {
  const dados = {
    requisicao: 'CarregarConfigAmVv',
    tenantId, // ou outro identificador do vendedor/tenant
    marketplaceId: idMarketplaceViaVarejo,
    marketplace: 'viavarejo'
  }
  const url = config.host + '/regraDescontoFrete'
  const response = await fetchData(url, dados)
  // Supondo que o endpoint retorne um objeto com { desconto: 10 } para 10%
  return response.desconto || 0
}
export function calcularFreteParaAmericanas(peso, precoVendaBruto, regras, descontoVendedor = 0) {
  // Verifica se o preço e o peso informados estão dentro dos limites da regra e se a regra é para "americanas"
  if (regras.length == 0) return
  console.log('regras em calcularFreteParaAmericanas', regras)
  const regraEncontrada = regras.find(regra => {
    return (
      precoVendaBruto >= regra.valor_min_produto &&
      precoVendaBruto <= regra.valor_max_produto &&
      peso >= regra.peso_min &&
      peso <= regra.peso_max
    )
  })

  if (!regraEncontrada) {
    throw new Error('Nenhuma regra encontrada para o preço e peso informados para Americanas.')
  }

  // Aplica o desconto do vendedor (porcentagem) ao valor base do frete
  const valorBase = regraEncontrada.valor_base_frete

  const valorFreteFinal = valorBase * (1 - descontoVendedor / 100)

  return valorFreteFinal
}

export function calcularFreteParaShopee(peso, precoVendaBruto, regrasPesoFreteShopee) {
  let faixaPrecoCorreta = null
  let faixaPesoCorreta = null

  // Percorre as regras específicas para a Shopee
  for (const regra of regrasPesoFreteShopee) {
    if (
      precoVendaBruto >= regra.faixa_preco.min &&
      (regra.faixa_preco.max === null || precoVendaBruto <= regra.faixa_preco.max)
    ) {
      faixaPrecoCorreta = regra
      // Procura a faixa de peso que contemple o peso informado
      faixaPesoCorreta = regra.regras.find(r => peso >= r.peso.min && peso <= r.peso.max)
      if (faixaPesoCorreta) {
        break
      }
    }
  }

  if (!faixaPrecoCorreta) {
    throw new Error(
      'Nenhuma faixa de preço encontrada para o preço de venda bruto atual na Shopee.'
    )
  }

  if (!faixaPesoCorreta) {
    throw new Error('Nenhuma faixa de peso encontrada para o peso atual na Shopee.')
  }

  return faixaPesoCorreta.valor_frete
}

export function calcularFreteParaAmazon(peso, valorProduto, regrasPesoAmazon) {
  // Filtra as faixas que atendem ao peso atual

  const faixasPeso = regrasPesoAmazon.filter(
    regra => peso >= regra.limite_inferior && peso <= regra.limite_superior
  )

  if (faixasPeso.length === 0) {
    console.warn('Nenhuma faixa de peso encontrada para a Amazon.')
    return 0
  }

  // Filtra as faixas que também se aplicam ao valor do produto
  const faixasValidas = faixasPeso.filter(regra => valorProduto >= regra.valor_minimo_produto)

  if (faixasValidas.length === 0) {
    // Se nenhuma faixa "válida" for encontrada, podemos optar por
    // retornar o valor do frete da faixa com o menor valor_minimo_produto,
    // indicando que o valor do produto está abaixo do requisito para desconto.
    const faixaMenor = faixasPeso.reduce((prev, curr) =>
      prev.valor_minimo_produto < curr.valor_minimo_produto ? prev : curr
    )
    return parseFloat(faixaMenor.valor_frete)
  }

  // Se houver mais de uma faixa válida, escolhe aquela com o maior valor_minimo_produto,
  // que seria a mais "exigente" (ou seja, para produtos de maior valor)
  const faixaEscolhida = faixasValidas.reduce((prev, curr) =>
    prev.valor_minimo_produto > curr.valor_minimo_produto ? prev : curr
  )

  return parseFloat(faixaEscolhida.valor_frete)
}

export function calcularFreteComDescontoMagalu(
  regrasPesoFreteMagalu,
  pesoAtual,
  descontoPercentual
) {
  // Encontrar a faixa de peso que inclui o peso atual

  const faixa = regrasPesoFreteMagalu.find(
    peso => peso.limite_inferior <= pesoAtual && peso.limite_superior >= pesoAtual
  )

  if (!faixa) {
    throw new Error('Nenhuma faixa de peso encontrada para o peso atual.')
  }

  // Calcular o valor do frete após aplicar o desconto
  const valorFreteComDesconto = ((faixa.valor_frete * (100 - descontoPercentual)) / 100).toFixed(2)

  return parseFloat(valorFreteComDesconto)
}
