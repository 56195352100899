//login.js do Preço Fácil Market
import { useState, useContext, useEffect } from 'react' // Adicionado useContext aqui
import { AuthContext } from '/src/context/AuthContext'
import { jwtDecode } from 'jwt-decode'
// Certifique-se de que o caminho está correto
import { fetchData } from '../../../../../../src/backend/model'
import { Link } from 'react-router-dom'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import Home from '../../../../../../src/routes/routeModelagemCompleta'
import { PrivateRoute } from '../../../../../../src/routes/routeModelagemCompleta'
import bcrypt from 'bcryptjs'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import logoTopo from './logo_topo.jpg'
import logoCentro from './logo_centro.jpeg'
import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils.jsx'

import { loadIps } from '../../../../../../src/components/pages/Usuarios/login/login'
const LoginForm = () => {
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [ips, setIps] = useState([])
  const [ip_atual, set_ip_atual] = useState([])
  const navigate = useNavigate()

  const location = useLocation()
  //const searchParams = new URLSearchParams(location.search)
  const [searchParams] = useSearchParams()
  const from = searchParams.get('from')

  const token = searchParams.get('token')

  let expirationMessage = ''
  if (token) {
    try {
      const decodedToken = jwtDecode(token)
      const expirationDate = new Date(decodedToken.exp * 1000)
      expirationMessage = `O link de renovação expira em ${expirationDate.toLocaleDateString(
        'pt-BR',
        {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }
      )}.`
    } catch (error) {
      console.error('Erro ao decodificar o token:', error)
    }
  }

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        set_ip_atual(data.ip)
      } catch (error) {
        console.error('Ocorreu um erro ao obter o IP:', error)
      }
    }

    fetchIP()
  }, [])

  useEffect(() => {
    // Define uma função assíncrona dentro do useEffect
    const fetchIps = async () => {
      try {
        const response = await loadIps() // Chama a funcao que deve retornar os IPs
        setIps(response) // Atualiza o estado com os IPs retornados
      } catch (error) {
        console.error('Ocorreu um erro ao carregar os IPs:', error)
      }
    }

    fetchIps() // Executa a função assíncrona definida
  }, []) // Dependências vazias indicam que isso acontece apenas na montagem do componente

  const authContextValues = useContext(AuthContext)
  const handleFormSubmit = event => {
    event.preventDefault()
    handleSubmit(
      username,
      password,
      ips,
      ip_atual,
      navigate,
      setShowAlertMessage,
      setAlertMessage,
      setErrorMessage,
      authContextValues // Passa os valores do contexto como argumento para a função
    )
  }
  return (
    <div>
      <div>
        <header className="top-header">
          <Link to="/" style={{ top: 0, left: 0, zIndex: 9999 }}>
            <img src={logoTopo} alt="Logo" className="logo-topoPF" />
          </Link>

          <div className="contact-linkPF">Fale conosco</div>
        </header>
        <ShowAlertGeneric
          showAlert={showAlertMessage}
          message={alertMessage}
          isBlurred={true} // ou algum outro critério que deseja usar para blur
          setShowAlert={setShowAlertMessage}
        />
        <div className="top-menuPF">Home &gt; Login</div>

        <div className="container">
          <Banner
            title="Preço Fácil Market"
            className="banner-containerPF"
            fontFamily="Josefin Sans"
            color="#2e8b57"
            width="75%"
            fontSize="90px"
          />
          <div className="content-center">
            <form id="passwordFormPF" onSubmit={handleFormSubmit}>
              <div className="system-intro-textPF">
                Utilize seu usuário e senha para acessar o sistema.
              </div>
              <div className="mb-3">
                {from === 'renew' && (
                  <>
                    <p style={{ color: 'red' }}>
                      Por favor, faça login para renovar sua assinatura.
                    </p>
                    <p style={{ color: 'red' }}>{expirationMessage}</p>
                  </>
                )}
                <label htmlFor="username" className="form-label">
                  Usuário ou e-mail
                </label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  className="form-control login-input"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="form-label">
                  Senha
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  className="form-control login-input"
                />
              </div>
              <div className="text-links">
                <Link to="/password-recovery/precofacilmarket" className="link-textPF">
                  Esqueceu sua senha?
                </Link>

                <div>
                  Ainda não tem cadastro?{' '}
                  <a href="#" className="link-textPF" onClick={() => {}}>
                    Inscreva-se agora!
                  </a>
                </div>
              </div>
              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-primary btn-lg login-buttonPF">
                  Entrar
                </button>
              </div>

              {errorMessage && (
                <div className="alert alert-danger mt-3" role="alert">
                  {errorMessage}
                </div>
              )}
            </form>
            <div className="local-banner-centro">
              <img src={logoCentro} alt="Logo" className="logo-centroPF" />
              <p
                className="system-intro-textPF"
                style={{
                  marginLeft: '50px',
                  width: '100%',
                  marginTop: '-10px'
                }}
              >
                Sistema Preço Facil Market
              </p>
              <p className="form-label" style={{ marginLeft: '60px' }}>
                Gerencie sua empresa de qualquer lugar
              </p>
            </div>
          </div>
          <div id="loading" className="loader-container">
            <div className="loaderPF"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginForm

const handleSubmit = async (
  username,
  password,
  ips,
  ip_atual,
  navigate,
  setShowAlertMessage,
  setAlertMessage,
  setErrorMessage,
  contextValues // Adiciona os valores do contexto como parâmetro
) => {
  const {
    login,
    logout,
    setIdNivelAcesso,
    setUser,
    setSenha,
    setIsAuthenticated,
    setSuperUser,
    RotaBase,
    setTenantId
  } = contextValues // Desestrutura os valores necessários do contexto

  try {
    let url = `${config.host}/login`

    let dados = {
      username: username.trim(),
      password: password,
      rota_base: RotaBase,
      requisicao: 'login'
    }

    let log = await fetchData(url, dados)
    const ipPermitido = ips.some(ip => ip.ip_liberado === ip_atual)
    if (
      !ipPermitido &&
      log.user.nivel_acesso != 'Administrador' &&
      log.user.nivel_acesso !== 'owner'
    ) {
      setAlertMessage('IP SEM PERMISSÃO DE ACESSO 1!')
      setShowAlertMessage(true)
      logout()
      return
    }

    if (log && log.success) {
      console.log('log', log)
      setTenantId(log.user.tenantId)
      setIdNivelAcesso(log.user.id_nivel_acesso)
      setUser(log.user.nome_usuario)
      setSenha(log.user.email)
      login()
      setErrorMessage('')
      console.log('entrei aqui 2')
      navigate('/inicio-pf')
    }
  } catch (error) {
    // A lógica para tratar um superusuário deve ser ajustada caso necessário
    setUser('')
    setSenha('')
    logout()
    setErrorMessage('Erro ao tentar se conectar ao servidor.')
    if (error.message) {
      setAlertMessage('Erro na autenticação.')
      setShowAlertMessage(true)
    }
  }
}

//--------------------------------------------------------------
