import { useState, useEffect, useContext } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { fetchData } from '../../../../../../../src/backend/model'
import { config } from '../../../../../../../src/backend/configConexaoGeral'
import { AuthContext } from '../../../../../../../src/context/AuthContext'
import logoAmericanas from '../logoAmericanas.png'
import logoViaVarejo from '../logoViaVarejo.jpeg'
import Banner from '../../../../../../../src/components/pages/CadastroGeral/Banner'
import { carregaIdMarketplace } from '../../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraMagalu/regra-magalu.jsx'
import { ShowAlertGeneric } from '../../../../../../../src/utils/form-utils.jsx'
import { useParams } from 'react-router-dom'

const SelecionarNivelVendedor = () => {
  const { marketplace } = useParams()
  const [niveis, setNiveis] = useState([])
  const [nivelSelecionado, setNivelSelecionado] = useState(null)
  const { tenantId } = useContext(AuthContext)
  const [idMarketplace, setIdMarketplace] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('danger')

  // Nova URL utilizando as novas funções de servidor para níveis de vendedor
  const url = config.host + '/niveisVendedorAmericanasViaVarejo'

  // Carregar os níveis disponíveis
  useEffect(() => {
    const carregarNiveis = async () => {
      try {
        const dados = { requisicao: 'carregarNiveisVendedorMarketplace' }
        if (marketplace) dados.marketplace = marketplace
        const response = await fetchData(url, dados)
        setNiveis(response)
      } catch (error) {
        console.error('Erro ao carregar níveis:', error)
      }
    }
    carregarNiveis()
  }, [marketplace])

  // Obter o ID do marketplace
  useEffect(() => {
    async function carregarDados() {
      let idMarketplaceValue
      if (marketplace === 'americanas') {
        idMarketplaceValue = await carregaIdMarketplace('Americanas')
      } else if (marketplace === 'viavarejo') {
        idMarketplaceValue = await carregaIdMarketplace('Via Varejo')
      } else {
        console.error('Marketplace inválido:', marketplace)
      }
      if (idMarketplaceValue && idMarketplaceValue.length > 0) {
        setIdMarketplace(idMarketplaceValue[0]._id)
      }
    }
    carregarDados()
  }, [marketplace])

  // Carregar a configuração atual do nível para o tenant
  useEffect(() => {
    const carregarConfiguracao = async () => {
      try {
        const url = config.host + '/regraDescontoFrete'
        const dados = {
          tenantId: tenantId,
          marketplaceId: idMarketplace,
          marketplace: marketplace,
          requisicao: 'CarregarConfigAmVv'
        }
        const response = await fetchData(url, dados)

        if (response) {
          setNivelSelecionado(response.nivel)
        }
      } catch (error) {
        setNivelSelecionado(null)
        console.error('Erro ao carregar configuração:', error)
      }
    }
    if (tenantId && idMarketplace) {
      carregarConfiguracao()
    }
  }, [tenantId, idMarketplace])

  // Atualiza a configuração quando o usuário seleciona um nível
  const handleChange = async e => {
    const grupo = e.target.value
    console.log('grupo:', grupo)
    setNivelSelecionado(grupo)

    try {
      const dados = {
        tenantId: tenantId,
        nivel: grupo,
        marketplaceId: idMarketplace,
        requisicao: 'GravarConfigAmVv'
      }
      const url = config.host + '/regraDescontoFrete'

      await fetchData(url, dados)
      setShowAlert(true)
      setCorAlerta('success')
      setAlertMessage('Grupo Configurado com sucesso!')
    } catch (error) {
      console.error('Erro ao gravar grupo:', error)
      alert('Erro ao gravar grupo.')
    }
  }

  return (
    <div
      className="d-flex align-items-center form-container"
      style={{ height: '100vh', position: 'relative', paddingTop: '16px', overflow: 'auto' }}
    >
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          isBlurred={true}
          setShowAlert={setShowAlert}
          variant={corAlerta}
          className="show-alert-genericPF"
          fontSize="1.5rem"
          dismissible={false}
        />
      )}
      <div className="mx-auto" style={{ maxWidth: '1100px', padding: '16px' }}>
        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src={
                marketplace === 'americanas'
                  ? logoAmericanas
                  : marketplace === 'viavarejo'
                  ? logoViaVarejo
                  : ''
              }
              alt="Logo"
              className="logo-americanas"
              style={{ width: '153px', height: '69px' }}
            />
            <div style={{ marginLeft: '16px' }}>
              <Banner title="Configuração de Nível" color="#2e8b57" fontSize={50} />
            </div>
          </div>
        </div>

        <h5 style={{ textAlign: 'center' }}>Selecione o Nível do Vendedor</h5>
        <Form style={{ textAlign: 'center' }}>
          <Form.Group as={Row} style={{ justifyContent: 'center' }}>
            <Col sm={12} style={{ maxWidth: '300px' }}>
              {niveis.map(nivel => (
                <div
                  key={nivel._id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    justifyContent: 'center'
                  }}
                >
                  <Form.Check
                    type="radio"
                    label={nivel.nome_nivel}
                    name="nivel"
                    value={nivel.nome_nivel}
                    onChange={handleChange}
                    checked={nivelSelecionado === nivel.nome_nivel}
                    style={{ marginRight: '10px' }}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}

export default SelecionarNivelVendedor
