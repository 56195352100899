//comissao-display.jsx
import { useEffect, useState, useContext, useRef, useCallback } from 'react'

import { Table, Form, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../../../src/backend/model'
import { CustomField } from '../../../../../../src/components/CustomField/custom-field'
import {
  calcularFreteParaAmericanas,
  calcularFreteParaShopee
} from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/calculo-precoPF.js'
import { AuthContext } from '../../../../../../src/context/AuthContext'
import { calcularFreteComDescontoMagalu } from './calculo-precoPF.js'
import { carregarRegrasPesoFrete } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/regras-peso-frete.js'
import {
  addColorsToMarketplaces,
  addFixoParameter,
  calcularPrecoVendaIterativoMagalu,
  calcularViaVarejoPrecoVendaIterativoMargem,
  calcularAmericanasPrecoVendaIterativoMargem,
  calcularViaVarejoPrecoVendaIterativoLucro,
  obterDescontoMagalu,
  calcularAmericanasPrecoVendaIterativo,
  CarregarRegrasFreteShopee,
  recalcShopeeCalculations,
  recalcMLCalculations,
  // calcularFreteComDescontoMercadoLivre,
  calcularMLPrecoVendaSimples,
  calcularMLPrecoVendaIterativo,
  // recalcAmazonCalculations,
  calcularMLPrecoVendaIterativoMargem,
  fetchValorMinFrete,
  // calcularPrecoFinalComFrete,
  calcularFretePorMarketplace,
  calcularValoresSemFrete,
  ConfiguraFreteEFixoMercadoLivre,
  ConfiguraFreteEFixoAmazon,
  calcularAmazonPrecoVendaSimples,
  calcularAmazonPrecoVendaIterativoMargem,
  calcularMagaluPrecoVendaIterativoMargem,
  calcularAmazonPrecoVendaIterativoLucro
} from './comissao-display.js'

export const ProfitMarginCalculator = ({
  produtoSelecionado,
  marketplacesWithComissoes,
  selectedMarketplaces,

  descontoAtualFreteMagalu,
  descontoAtualFreteMercadoLivre,
  regrasDescontoFreteMagalu,
  regrasPesoFreteMercadoLivre,
  peso,
  setValorFreteMagalu,
  setValorFreteMercadoLivre,
  setValorFreteAmazon,
  setValorFreteAmericanas,
  setValorFreteViaVarejo,
  valorFreteMagalu,
  valorFreteMercadoLivre,
  valorFreteAmazon,
  valorFreteAmericanas,
  valorFreteViaVarejo,

  setValorFreteShopee,
  marketplaceColors,

  regrasPesoFreteAmazon,
  ultimoImposto,
  regrasAmericanas,
  regrasViaVarejo,
  descontoVendedorAmericanas,
  descontoVendedorViaVarejo,
  onSimulacaoUpdate,
  reset,
  precoParaRevisao,
  lucroParaRevisao,
  margemParaRevisao,
  revisaoId,
  mostrarBotaoManter = false,
  isLoadingRegrasAmericanas
}) => {
  // Novo: estados para venda, lucro e margem
  const [venda, setVenda] = useState({})
  const [lucro, setLucro] = useState({})
  const [margem, setMargem] = useState({})
  const [freteGratisExtra, setFreteGratisExtra] = useState({})
  const [regraFreteShopee, setRegraFreteShopee] = useState({}) // setRegraFreteShopee
  const [selectedMarketplacesData, setSelectedMarketplacesData] = useState([])
  const [valorMinFreteGratisML, setValorMinFreteGratisML] = useState(79) // Valor padrão de fallback
  ProfitMarginCalculator.propTypes = {
    produtoSelecionado: PropTypes.object,
    marketplacesWithComissoes: PropTypes.array,
    selectedMarketplaces: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    descontoAtualFreteMagalu: PropTypes.number,
    descontoAtualFreteMercadoLivre: PropTypes.number,
    regrasDescontoFreteMagalu: PropTypes.array,
    regrasPesoFreteMercadoLivre: PropTypes.array,
    peso: PropTypes.number,
    setValorFreteMagalu: PropTypes.func,
    setValorFreteMercadoLivre: PropTypes.func,
    setValorFreteAmazon: PropTypes.func,
    setValorFreteAmericanas: PropTypes.func,
    setValorFreteViaVarejo: PropTypes.func,
    valorFreteMagalu: PropTypes.number,
    valorFreteMercadoLivre: PropTypes.number,
    valorFreteAmazon: PropTypes.number,
    valorFreteAmericanas: PropTypes.number,
    valorFreteViaVarejo: PropTypes.number,
    setValorFreteShopee: PropTypes.func,
    marketplaceColors: PropTypes.object,
    regrasPesoFreteAmazon: PropTypes.array,
    ultimoImposto: PropTypes.number,
    regrasAmericanas: PropTypes.array,
    regrasViaVarejo: PropTypes.array,
    descontoVendedorAmericanas: PropTypes.number,
    descontoVendedorViaVarejo: PropTypes.number,
    onSimulacaoUpdate: PropTypes.func,
    reset: PropTypes.bool,
    precoParaRevisao: PropTypes.string,
    lucroParaRevisao: PropTypes.string,
    margemParaRevisao: PropTypes.string,
    revisaoId: PropTypes.number,
    mostrarBotaoManter: PropTypes.bool
  }

  const { RotaBase, tenantId, assinaturaStatus, acessoModulos } = useContext(AuthContext)
  const [activeField, setActiveField] = useState(null)
  const [campoLiberado, setCampoLiberado] = useState(false)
  const [regrasPesoFreteMagalu, setRegrasPesoFreteMagalu] = useState([])
  const debounceLucroTimeoutRef = useRef(null)
  const debounceMargemTimeoutRef = useRef(null)
  const [regrasPesoFreteShopee, setRegrasPesoFreteShopee] = useState([])

  const [initialLoaded, setInitialLoaded] = useState(false)

  useEffect(() => {
    setInitialLoaded(false)
  }, [revisaoId])

  useEffect(() => {
    setInitialLoaded(false)
  }, [produtoSelecionado?._id, precoParaRevisao, lucroParaRevisao, margemParaRevisao])

  useEffect(() => {
    if (reset) {
      // Reinicializa todos os estados internos
      setVenda({})
      setLucro({})
      setMargem({})
      setFreteGratisExtra({})
      // ... limpe os demais estados que precisar
    }
  }, [reset])

  // Garante que o efeito roda quando `selectedMarketplacesData` muda
  useEffect(() => {
    // Verifica se as variáveis essenciais estão carregadas

    if (
      !produtoSelecionado ||
      !selectedMarketplacesData?.length ||
      venda == null ||
      lucro == null ||
      margem == null ||
      !regrasAmericanas ||
      !regrasPesoFreteAmazon ||
      !regrasPesoFreteMagalu ||
      !regrasPesoFreteMercadoLivre ||
      !regrasPesoFreteShopee ||
      !regrasViaVarejo ||
      descontoAtualFreteMagalu == null ||
      descontoAtualFreteMercadoLivre == null ||
      descontoVendedorAmericanas == null ||
      descontoVendedorViaVarejo == null ||
      valorMinFreteGratisML == null
    ) {
      return // Sai do useEffect se algum dado ainda não estiver pronto
    }

    const simulacaoAtual = {}
    selectedMarketplacesData.forEach(marketplace => {
      const marketplaceId = marketplace._id
      const freteCalculado = calcularFretePorMarketplace({
        marketplace,
        venda,
        produtoSelecionado,
        regrasPesoFreteMercadoLivre,
        peso,
        descontoAtualFreteMercadoLivre,
        valorMinFreteGratisML,
        regrasPesoFreteAmazon,
        regrasPesoFreteShopee,
        regrasAmericanas,
        descontoVendedorAmericanas,
        regrasViaVarejo,
        descontoVendedorViaVarejo,
        regrasPesoFreteMagalu,
        descontoAtualFreteMagalu,
        setValorFreteMagalu
      })

      simulacaoAtual[marketplaceId] = {
        precoVenda: venda[marketplaceId],
        lucro: lucro[marketplaceId],
        margem: margem[marketplaceId],
        frete: freteCalculado.toFixed(2),
        fixo: parseFloat(marketplace.fixo) || 0,
        comissao: parseFloat(marketplace.comissao) || 0
      }
    })

    if (onSimulacaoUpdate) {
      onSimulacaoUpdate(simulacaoAtual)
    }
  }, [venda, lucro, margem, selectedMarketplacesData])

  useEffect(() => {
    async function fetchRegras() {
      const result = await carregarRegrasPesoFrete('magalu')

      setRegrasPesoFreteMagalu(result)
    }
    fetchRegras()
  }, [])
  useEffect(() => {
    async function buscarRegrasShopee() {
      const regras = await CarregarRegrasFreteShopee()
      // Se a resposta for um array, você pode armazenar a regra correspondente em um estado
      if (regras && regras.length > 0) {
        // Exemplo: pega o primeiro elemento
        setRegrasPesoFreteShopee(regras)
        setRegraFreteShopee(regras[0]?.percentualFreteGratisExtra || 0)
      }
    }
    buscarRegrasShopee()
  }, [])

  useEffect(() => {
    if (assinaturaStatus === 'ativa' || acessoModulos.precofacilmarket) {
      setCampoLiberado(true)
    }
  }, [assinaturaStatus, acessoModulos])

  useEffect(() => {
    fetchValorMinFrete(setValorMinFreteGratisML)
  }, [])

  const marketplacesColored = addColorsToMarketplaces(marketplacesWithComissoes, marketplaceColors)
  const select = marketplacesColored.filter(marketplace => selectedMarketplaces[marketplace._id])

  useEffect(() => {
    const updatedSelect = addFixoParameter(select)
    setSelectedMarketplacesData(updatedSelect)
  }, [marketplacesWithComissoes, selectedMarketplaces, marketplaceColors])

  useEffect(() => {
    if (
      !initialLoaded &&
      precoParaRevisao &&
      lucroParaRevisao &&
      margemParaRevisao &&
      selectedMarketplacesData.length > 0
    ) {
      const newVenda = {}
      const newLucro = {}
      const newMargem = {}

      selectedMarketplacesData.forEach(marketplace => {
        newVenda[marketplace._id] = precoParaRevisao
        newLucro[marketplace._id] = lucroParaRevisao
        newMargem[marketplace._id] = margemParaRevisao
      })

      setVenda(newVenda)
      setLucro(newLucro)
      setMargem(newMargem)
      setInitialLoaded(true)
    }
  }, [
    precoParaRevisao,
    lucroParaRevisao,
    margemParaRevisao,
    selectedMarketplacesData,
    initialLoaded
  ])

  // --- HANDLER PARA CAMPO "VENDA" ---

  const handlePrecoVendaChange = useCallback(
    async (marketplaceId, event) => {
      const rawValue = event.target.value.replace(',', '.')
      setVenda(prev => ({ ...prev, [marketplaceId]: rawValue }))
      const precoVendaNum = parseFloat(rawValue) || 0
      const custo = parseFloat(produtoSelecionado?.custo) || 0

      let marketplace = marketplacesWithComissoes.find(m => m._id === marketplaceId)
      marketplace = addFixoParameter(marketplace)
      const fixoNum = parseFloat(marketplace.fixo) || 0
      const comissaoPercent = parseFloat(marketplace.comissao) || 0
      const impostoPercent = ultimoImposto

      if (marketplace.nome === 'Mercado Livre') {
        const { frete, lucro, margem } = calcularMLPrecoVendaSimples(
          precoVendaNum,
          custo,
          fixoNum,
          comissaoPercent,
          impostoPercent,
          regrasPesoFreteMercadoLivre,
          peso,
          descontoAtualFreteMercadoLivre,
          valorMinFreteGratisML
        )

        ConfiguraFreteEFixoMercadoLivre(
          precoVendaNum,
          setValorFreteMercadoLivre,
          setSelectedMarketplacesData,
          marketplace,
          frete
        )
        setLucro(prev => ({ ...prev, [marketplaceId]: lucro.toFixed(2) }))
        setMargem(prev => ({ ...prev, [marketplaceId]: margem.toFixed(2) }))
        return
      }
      if (marketplace.nome === 'Amazon') {
        const { frete, lucro, margem } = calcularAmazonPrecoVendaSimples(
          precoVendaNum,
          custo,
          fixoNum,
          comissaoPercent,
          impostoPercent,
          regrasPesoFreteAmazon,
          peso
        )
        setLucro(prev => ({ ...prev, [marketplaceId]: lucro.toFixed(2) }))
        setMargem(prev => ({ ...prev, [marketplaceId]: margem.toFixed(2) }))
        ConfiguraFreteEFixoAmazon(
          precoVendaNum,
          setValorFreteAmazon,
          setSelectedMarketplacesData,
          marketplace,
          frete
        )
        return
      }
      if (marketplace.nome === 'Magalu') {
        const descontoEncontrado = await obterDescontoMagalu(
          tenantId,
          marketplace,
          regrasDescontoFreteMagalu
        )
        const valorFrete = calcularFreteComDescontoMagalu(
          regrasPesoFreteMagalu,
          peso,
          descontoEncontrado
        )
        setValorFreteMagalu(valorFrete)
        const denominador = 1 - (impostoPercent + comissaoPercent) / 100
        const netSale = precoVendaNum * denominador - valorFrete
        const lucroCalc = netSale - (custo + fixoNum)
        const margemCalc = precoVendaNum > 0 ? (lucroCalc / precoVendaNum) * 100 : 0
        setLucro(prev => ({ ...prev, [marketplaceId]: lucroCalc.toFixed(2) }))
        setMargem(prev => ({ ...prev, [marketplaceId]: margemCalc.toFixed(2) }))
        return
      }

      if (marketplace.nome === 'Americanas') {
        console.log('marketplace.nome ', marketplace.nome)

        // Verifica se as regras já foram carregadas
        if (isLoadingRegrasAmericanas) {
          console.warn('Aguardando o carregamento das regras para Americanas.')
          return // ou defina um valor padrão para o frete
        }

        // Verifica se o array de regras está preenchido
        if (!regrasAmericanas || regrasAmericanas.length === 0) {
          console.warn('Nenhuma regra encontrada para Americanas.')
          return // ou trate a situação exibindo uma mensagem ao usuário
        }

        const valorFrete = calcularFreteParaAmericanas(
          peso,
          precoVendaNum,
          regrasAmericanas,
          descontoVendedorAmericanas
        )
        setValorFreteAmericanas(valorFrete)

        const denominador = 1 - (impostoPercent + comissaoPercent) / 100
        const netSale = precoVendaNum * denominador - valorFrete
        const lucroCalc = netSale - (custo + fixoNum)
        const margemCalc = precoVendaNum > 0 ? (lucroCalc / precoVendaNum) * 100 : 0

        setLucro(prev => ({ ...prev, [marketplaceId]: lucroCalc.toFixed(2) }))
        setMargem(prev => ({ ...prev, [marketplaceId]: margemCalc.toFixed(2) }))

        return
      }

      if (marketplace.nome === 'Via Varejo') {
        console.log('marketplace.nome', marketplace.nome)
        console.log('regrasViaVarejo no if', regrasViaVarejo)
        const valorFrete = calcularFreteParaAmericanas(
          peso,
          precoVendaNum,
          regrasViaVarejo,
          descontoVendedorViaVarejo
        )
        setValorFreteViaVarejo(valorFrete)
        const denominador = 1 - (impostoPercent + comissaoPercent) / 100
        const netSale = precoVendaNum * denominador - valorFrete
        const lucroCalc = netSale - (custo + fixoNum)
        const margemCalc = precoVendaNum > 0 ? (lucroCalc / precoVendaNum) * 100 : 0
        setLucro(prev => ({ ...prev, [marketplaceId]: lucroCalc.toFixed(2) }))
        setMargem(prev => ({ ...prev, [marketplaceId]: margemCalc.toFixed(2) }))
        return
      }
      const extraFrete =
        marketplace.nome === 'Shopee' && freteGratisExtra[marketplaceId]
          ? parseFloat(regraFreteShopee)
          : 0
      const denominador = 1 - (impostoPercent + comissaoPercent + extraFrete) / 100
      if (denominador <= 0) {
        console.error('❌ ERRO: Denominador inválido, evitando divisão por zero.')
        return
      }
      const netSale = precoVendaNum * (1 - (impostoPercent + comissaoPercent + extraFrete) / 100)
      const lucroCalc = netSale - (custo + fixoNum)
      const margemCalc = precoVendaNum > 0 ? (lucroCalc / precoVendaNum) * 100 : 0
      setLucro(prev => ({ ...prev, [marketplaceId]: lucroCalc.toFixed(2) }))
      setMargem(prev => ({ ...prev, [marketplaceId]: margemCalc.toFixed(2) }))
      if (marketplace.nome === 'Shopee' && freteGratisExtra[marketplaceId]) {
        const freteExtraValue = precoVendaNum * (extraFrete / 100)
        setValorFreteShopee(freteExtraValue)
      }
    },
    [
      produtoSelecionado,
      marketplacesWithComissoes,
      regrasPesoFreteMercadoLivre,
      regrasAmericanas,
      descontoVendedorAmericanas,
      regrasPesoFreteAmazon,
      regrasDescontoFreteMagalu,
      regrasPesoFreteMagalu,
      ultimoImposto,
      peso,
      descontoAtualFreteMercadoLivre,
      valorMinFreteGratisML,
      descontoVendedorViaVarejo,
      regrasViaVarejo,
      freteGratisExtra,
      regraFreteShopee
    ]
  )

  // --- HANDLER PARA CAMPO "MARGEM" ---
  async function handleMargemChange(marketplaceId, event) {
    const rawValue = event.target.value.replace(',', '.')
    // Atualiza imediatamente o estado para acompanhar a digitação
    setMargem(prev => ({ ...prev, [marketplaceId]: rawValue }))

    const margemNum = parseFloat(rawValue) || 0
    const custo = parseFloat(produtoSelecionado?.custo) || 0

    let marketplace = marketplacesWithComissoes.find(m => m._id === marketplaceId)
    marketplace = addFixoParameter(marketplace)

    const fixoNum = parseFloat(marketplace.fixo) || 0
    const comissaoPercent = parseFloat(marketplace.comissao) || 0
    const impostoPercent = ultimoImposto

    const extraFrete =
      marketplace.nome === 'Shopee' && freteGratisExtra[marketplaceId]
        ? parseFloat(regraFreteShopee)
        : 0

    // Tratamento específico para Mercado Livre
    if (marketplace.nome === 'Mercado Livre') {
      console.log('🔎 Mercado Livre: Iniciando cálculo iterativo para margem...')

      // Se já houver debounce em andamento, cancela-o
      if (debounceMargemTimeoutRef.current) {
        clearTimeout(debounceMargemTimeoutRef.current)
      }

      // Aguarda 3 segundos de inatividade para efetuar o cálculo iterativo
      debounceMargemTimeoutRef.current = setTimeout(() => {
        const resultadoIterativo = calcularMLPrecoVendaIterativoMargem(
          custo,
          fixoNum,
          comissaoPercent,
          impostoPercent,
          regrasPesoFreteMercadoLivre,
          peso,
          descontoAtualFreteMercadoLivre,
          valorMinFreteGratisML,
          margemNum // margem desejada digitada
        )

        // Atualiza os estados com os valores formatados com 2 casas decimais
        setVenda(prev => ({ ...prev, [marketplace._id]: resultadoIterativo.precoVenda.toFixed(2) }))
        setLucro(prev => ({ ...prev, [marketplace._id]: resultadoIterativo.lucro.toFixed(2) }))
        ConfiguraFreteEFixoMercadoLivre(
          resultadoIterativo.precoVenda,
          setValorFreteMercadoLivre,
          setSelectedMarketplacesData,
          marketplace,
          resultadoIterativo.frete
        )
        // setValorFreteMercadoLivre(resultadoIterativo.frete.toFixed(2))
        setMargem(prev => ({
          ...prev,
          [marketplace._id]: resultadoIterativo.margem.toFixed(2)
        }))
      }, 2000)
      return
    }

    if (marketplace.nome === 'Amazon') {
      // Se já houver debounce em andamento, cancela-o
      if (debounceMargemTimeoutRef.current) {
        clearTimeout(debounceMargemTimeoutRef.current)
      }

      debounceMargemTimeoutRef.current = setTimeout(async () => {
        const resultadoIterativo = calcularAmazonPrecoVendaIterativoMargem(
          custo,
          fixoNum,
          comissaoPercent,
          impostoPercent,
          regrasPesoFreteAmazon,
          peso,
          margemNum // margem desejada digitada
        )
        console.log('resultadoIterativo', resultadoIterativo)
        // console.log('resultadoIterativo', resultadoIterativo)
        // Atualiza os estados com os valores formatados com 2 casas decimais

        setVenda(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.precoVenda.toFixed(2) }))
        setLucro(prev => ({
          ...prev,
          [marketplaceId]: resultadoIterativo.lucro.toFixed(2)
        }))
        ConfiguraFreteEFixoAmazon(
          resultadoIterativo.precoVenda,
          setValorFreteAmazon,
          setSelectedMarketplacesData,
          marketplace,
          resultadoIterativo.frete
        )

        setMargem(prev => ({
          ...prev,
          [marketplaceId]: resultadoIterativo.margem.toFixed(2)
        }))
      }, 2000)

      return
    }

    if (marketplace.nome === 'Magalu') {
      // Se já houver debounce em andamento, cancela-o
      if (debounceMargemTimeoutRef.current) {
        clearTimeout(debounceMargemTimeoutRef.current)
      }

      debounceMargemTimeoutRef.current = setTimeout(async () => {
        // 1) Obter o desconto via as regras
        const descontoEncontrado = await obterDescontoMagalu(
          tenantId,
          marketplace,
          regrasDescontoFreteMagalu
        )

        // 2) Calcular frete com esse desconto
        const valorFrete = calcularFreteComDescontoMagalu(
          regrasPesoFreteMagalu,
          peso,
          descontoEncontrado
        )

        setValorFreteMagalu(valorFrete)

        // 3) Chute inicial para o preço de venda
        const precoVendaInicial = parseFloat(venda[marketplaceId]) || 0

        // 4) Chama a função auxiliar para calcular iterativamente o preço de venda que gere a margem desejada
        const descontoFrete = await obterDescontoMagalu(
          tenantId,
          marketplace,
          regrasDescontoFreteMagalu
        )
        const resultadoIterativo = calcularMagaluPrecoVendaIterativoMargem({
          regrasDescontoFreteMagalu,
          regrasPesoFreteMagalu,
          peso: peso,
          precoVendaInicial,
          custo,
          fixo: fixoNum,
          impostoPercent,
          comissaoPercent,
          valorFrete,
          margemDesejada: margemNum,
          tenantId,
          marketplace,
          descontoFrete
        })
        // console.log('Resultado iterativo para Magalu:', resultadoIterativo)

        // 5) Atualiza os estados com os valores calculados
        setVenda(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.precoVenda.toFixed(2) }))
        setLucro(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.lucroCalc.toFixed(2) }))

        setMargem(prev => ({
          ...prev,
          [marketplaceId]: resultadoIterativo.margemCalcMagalu.toFixed(2)
        }))
      }, 2000)
      return
    }

    if (marketplace.nome === 'Americanas') {
      // Se já houver debounce em andamento, cancela-o
      if (debounceMargemTimeoutRef.current) {
        clearTimeout(debounceMargemTimeoutRef.current)
      }

      debounceMargemTimeoutRef.current = setTimeout(() => {
        const resultadoIterativo = calcularAmericanasPrecoVendaIterativoMargem({
          custo,
          fixo: fixoNum,
          comissaoPercent,
          impostoPercent,
          regrasAmericanas,
          peso,
          descontoVendedorAmericanas,
          margemDesejada: margemNum // agora a margem desejada
        })

        setVenda(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.precoVenda.toFixed(2) }))
        setLucro(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.lucro.toFixed(2) }))
        setValorFreteAmericanas(resultadoIterativo.frete.toFixed(2))
        setMargem(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.margem.toFixed(2) }))
      }, 2000)
      return
    }

    if (marketplace.nome === 'Via Varejo') {
      // Debounce para evitar cálculos excessivos durante a digitação
      if (debounceMargemTimeoutRef.current) {
        clearTimeout(debounceMargemTimeoutRef.current)
      }

      debounceMargemTimeoutRef.current = setTimeout(() => {
        const resultadoIterativo = calcularViaVarejoPrecoVendaIterativoMargem({
          custo,
          fixo: fixoNum,
          comissaoPercent,
          impostoPercent,
          regrasViaVarejo, // suas regras específicas para Via Varejo
          peso,
          descontoVendedorViaVarejo, // desconto específico para Via Varejo
          margemDesejada: margemNum // margem desejada digitada pelo usuário
        })

        // Atualizando estados:
        setValorFreteViaVarejo(resultadoIterativo.frete.toFixed(2))
        setVenda(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.precoVenda.toFixed(2) }))
        setLucro(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.lucro.toFixed(2) }))
        setMargem(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.margem.toFixed(2) }))
      }, 2000)

      return
    }

    // --- Cálculo genérico para outros marketplaces (sem iteração) ---
    // Fórmula: venda = (custo + fixo) / [ (1 - (imposto + comissão + extraFrete)/100) - (margem/100) ]
    const denominador = 1 - (impostoPercent + comissaoPercent + extraFrete) / 100 - margemNum / 100
    if (denominador <= 0) return
    const vendaCalc = (custo + fixoNum) / denominador
    const lucroCalc = vendaCalc * (margemNum / 100)

    setVenda(prev => ({ ...prev, [marketplaceId]: vendaCalc.toFixed(2) }))
    setLucro(prev => ({ ...prev, [marketplaceId]: lucroCalc.toFixed(2) }))

    if (marketplace.nome === 'Shopee' && freteGratisExtra[marketplaceId]) {
      const freteExtraValue = vendaCalc * (extraFrete / 100)
      setValorFreteShopee(freteExtraValue.toFixed(2))
    }
  }

  // --- HANDLER PARA CAMPO "LUCRO" ---

  function handleLucroChange(marketplaceId, event) {
    const rawValue = event.target.value.replace(',', '.')
    // Atualiza imediatamente o estado "lucro" com o que o usuário digita,
    // para que o campo acompanhe a digitação
    setLucro(prev => ({ ...prev, [marketplaceId]: rawValue }))

    const novoLucroNum = parseFloat(rawValue) || 0
    const custo = parseFloat(produtoSelecionado?.custo) || 0

    let marketplace = marketplacesWithComissoes.find(m => m._id === marketplaceId)
    marketplace = addFixoParameter(marketplace)
    const fixoNum = parseFloat(marketplace.fixo) || 0
    const comissaoPercent = parseFloat(marketplace.comissao) || 0
    const impostoPercent = ultimoImposto

    // Tratamento específico para Mercado Livre
    if (marketplace.nome === 'Mercado Livre') {
      const mercadoLivre = marketplacesWithComissoes.find(m => m.nome === 'Mercado Livre')

      if (mercadoLivre) {
        // Se já houver um debounce em andamento, cancela-o
        if (debounceLucroTimeoutRef.current) {
          clearTimeout(debounceLucroTimeoutRef.current)
        }

        // Aguarda 3 segundos de inatividade para efetuar o cálculo iterativo
        debounceLucroTimeoutRef.current = setTimeout(() => {
          // Chama a função iterativa que ajusta o preço de venda para que o lucro calculado se aproxime do lucro desejado
          const resultadoIterativo = calcularMLPrecoVendaIterativo(
            custo,
            fixoNum,
            comissaoPercent,
            impostoPercent,
            regrasPesoFreteMercadoLivre,
            peso,
            descontoAtualFreteMercadoLivre,
            valorMinFreteGratisML,
            novoLucroNum
          )

          setValorFreteMercadoLivre(resultadoIterativo.frete.toFixed(2))
          setVenda(prev => ({
            ...prev,
            [marketplace._id]: resultadoIterativo.precoVenda.toFixed(2)
          }))
          setMargem(prev => ({
            ...prev,
            [marketplace._id]: resultadoIterativo.margem.toFixed(2)
          }))

          setLucro(prev => ({
            ...prev,
            [marketplace._id]: resultadoIterativo.lucro.toFixed(2)
          }))
        }, 2000)
        return
      }
    }
    if (marketplace.nome === 'Amazon') {
      // Aplica debounce para evitar cálculos a cada tecla digitada
      if (debounceLucroTimeoutRef.current) {
        clearTimeout(debounceLucroTimeoutRef.current)
      }
      debounceLucroTimeoutRef.current = setTimeout(async () => {
        const resultadoIterativo = calcularAmazonPrecoVendaIterativoLucro({
          custo,
          fixo: fixoNum,
          comissaoPercent,
          impostoPercent,
          regrasPesoFreteAmazon,
          peso,
          lucroDesejado: novoLucroNum
        })

        setValorFreteAmazon(resultadoIterativo.frete.toFixed(2))
        setVenda(prev => ({
          ...prev,
          [marketplace._id]: resultadoIterativo.precoVenda.toFixed(2)
        }))
        setMargem(prev => ({
          ...prev,
          [marketplace._id]: resultadoIterativo.margem.toFixed(2)
        }))

        setLucro(prev => ({
          ...prev,
          [marketplace._id]: resultadoIterativo.lucro.toFixed(2)
        }))
      }, 2000)
      return
    }

    if (marketplace.nome === 'Magalu') {
      // Aplica debounce para evitar cálculos a cada tecla digitada
      if (debounceLucroTimeoutRef.current) {
        clearTimeout(debounceLucroTimeoutRef.current)
      }
      debounceLucroTimeoutRef.current = setTimeout(async () => {
        // 1) Obter o desconto via as regras
        const descontoEncontrado = await obterDescontoMagalu(
          tenantId,
          marketplace,
          regrasDescontoFreteMagalu
        )

        // 2) Calcular o valor do frete com esse desconto
        const valorFrete = calcularFreteComDescontoMagalu(
          regrasPesoFreteMagalu,
          peso,
          descontoEncontrado
        )
        setValorFreteMagalu(valorFrete)

        // 3) Calcular o preço de venda que gera o lucro desejado usando a fórmula direta
        const denominador = 1 - (impostoPercent + comissaoPercent) / 100
        const vendaCalc = (novoLucroNum + custo + fixoNum + valorFrete) / denominador

        // 4) Recalcular os demais valores:
        const netSale = vendaCalc * denominador - valorFrete
        const lucroCalc = netSale - (custo + fixoNum)
        const margemCalc = vendaCalc > 0 ? (lucroCalc / vendaCalc) * 100 : 0

        // 5) Atualiza os estados
        setVenda(prev => ({ ...prev, [marketplaceId]: vendaCalc.toFixed(2) }))
        setLucro(prev => ({ ...prev, [marketplaceId]: lucroCalc.toFixed(2) }))
        setMargem(prev => ({ ...prev, [marketplaceId]: margemCalc.toFixed(2) }))
      }, 2000)
      return
    }
    if (marketplace.nome === 'Americanas') {
      // Aplica debounce para evitar cálculos em excesso

      if (debounceLucroTimeoutRef.current) {
        clearTimeout(debounceLucroTimeoutRef.current)
      }

      debounceLucroTimeoutRef.current = setTimeout(() => {
        // Supondo que 'custo', 'fixoNum', 'comissaoPercent', 'impostoPercent',
        // 'peso', 'regrasAmericanas' e 'descontoVendedorAmericanas' já estejam definidos
        const resultadoIterativo = calcularAmericanasPrecoVendaIterativo(
          custo,
          fixoNum,
          comissaoPercent,
          impostoPercent,
          regrasAmericanas, // array de regras para Americanas
          peso,
          descontoVendedorAmericanas, // percentual de desconto do vendedor para Americanas
          novoLucroNum // margem desejada digitada pelo usuário
        )

        // Atualiza os estados com os valores calculados
        setValorFreteAmericanas(resultadoIterativo.frete.toFixed(2))
        setVenda(prev => ({ ...prev, [marketplace._id]: resultadoIterativo.precoVenda.toFixed(2) }))
        setLucro(prev => ({ ...prev, [marketplace._id]: resultadoIterativo.lucro.toFixed(2) }))
        setMargem(prev => ({ ...prev, [marketplace._id]: resultadoIterativo.margem.toFixed(2) }))
      }, 2000)

      return
    }

    if (marketplace.nome === 'Via Varejo') {
      // Aplica debounce para evitar cálculos excessivos
      if (debounceLucroTimeoutRef.current) {
        clearTimeout(debounceLucroTimeoutRef.current)
      }

      debounceLucroTimeoutRef.current = setTimeout(() => {
        const resultadoIterativo = calcularViaVarejoPrecoVendaIterativoLucro(
          custo,
          fixoNum,
          comissaoPercent,
          impostoPercent,
          regrasViaVarejo, // regras específicas para Via Varejo
          peso,
          descontoVendedorViaVarejo, // desconto do vendedor para Via Varejo
          novoLucroNum // lucro desejado digitado pelo usuário
        )

        // Atualizando os estados com os valores calculados:
        setValorFreteViaVarejo(resultadoIterativo.frete.toFixed(2))
        setVenda(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.precoVenda.toFixed(2) }))
        setLucro(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.lucro.toFixed(2) }))
        setMargem(prev => ({ ...prev, [marketplaceId]: resultadoIterativo.margem.toFixed(2) }))
      }, 2000)

      return
    }

    // --- Cálculo genérico para outros marketplaces ---
    const extraFrete =
      marketplace.nome === 'Shopee' && freteGratisExtra[marketplaceId]
        ? parseFloat(regraFreteShopee)
        : 0

    const denominador = 1 - (impostoPercent + comissaoPercent + extraFrete) / 100
    if (denominador <= 0) {
      console.error('❌ ERRO: Denominador inválido, evitando divisão por zero.')
      return
    }

    const precoVendaCalc = (custo + fixoNum + novoLucroNum) / denominador
    const margemCalc = precoVendaCalc > 0 ? (novoLucroNum / precoVendaCalc) * 100 : 0

    console.log('✅ Preço de venda calculado (genérico):', precoVendaCalc)
    console.log('📊 Margem calculada (genérico):', margemCalc)

    setVenda(prev => ({ ...prev, [marketplaceId]: precoVendaCalc.toFixed(2) }))
    setMargem(prev => ({ ...prev, [marketplaceId]: margemCalc.toFixed(2) }))

    if (marketplace.nome === 'Shopee' && freteGratisExtra[marketplaceId]) {
      const freteExtraValue = precoVendaCalc * (extraFrete / 100)
      setValorFreteShopee(freteExtraValue.toFixed(2))
    }
  }

  return (
    <div className="comissao-display-container" style={{ width: '62vw', left: '8.8vw' }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '25vw' }}>Marketplace</th>
            <th>
              Margem %
              {!campoLiberado && (
                <div style={{ color: 'red', fontSize: '0.8em' }}>
                  Assine o Plano para ter acesso.
                </div>
              )}
            </th>
            <th>
              Venda Sugerida
              {!campoLiberado && (
                <div style={{ color: 'red', fontSize: '0.8em' }}>
                  Assine o Plano para ter acesso.
                </div>
              )}
            </th>
            <th>
              Lucro
              {!campoLiberado && (
                <div style={{ color: 'red', fontSize: '0.8em' }}>
                  Assine o Plano para ter acesso.
                </div>
              )}
            </th>
            <th>Comissão</th>
            <th>Fixo</th>
            <th>Frete</th>
            <th>Imposto</th>
          </tr>
        </thead>
        <tbody>
          {selectedMarketplacesData.map(marketplace => {
            const freteDisplay =
              marketplace.nome === 'Magalu'
                ? valorFreteMagalu
                : marketplace.nome === 'Amazon'
                ? valorFreteAmazon
                : marketplace.nome === 'Mercado Livre'
                ? valorFreteMercadoLivre
                : marketplace.nome === 'Americanas'
                ? valorFreteAmericanas
                : marketplace.nome === 'Via Varejo'
                ? valorFreteViaVarejo
                : marketplace.frete || ''

            // console.log('freteGratisExtra[marketplace._id]:', freteGratisExtra[marketplace._id])
            return (
              <tr key={marketplace._id}>
                <td className="comissao-texto" style={{ color: marketplace.cor }}>
                  {marketplace.nome}
                  {marketplace.nome === 'Shopee' && (
                    <div style={{ marginTop: '4px', fontSize: '0.7em' }}>
                      <Form.Check
                        type="checkbox"
                        label="Frete Grátis"
                        checked={!!freteGratisExtra[marketplace._id]}
                        onChange={e => {
                          const checked = e.target.checked
                          setFreteGratisExtra(prev => ({
                            ...prev,
                            [marketplace._id]: checked
                          }))
                          // Se já houver um valor no campo "lucro", recalcule os valores
                          if (lucro[marketplace._id] !== undefined) {
                            recalcShopeeCalculations(
                              marketplace._id,
                              checked,
                              marketplacesWithComissoes,
                              lucro,
                              produtoSelecionado,
                              ultimoImposto,
                              regraFreteShopee,
                              setVenda,
                              setMargem
                            )
                          }
                        }}
                      />
                    </div>
                  )}
                  {/* Checkbox Frete Grátis Mercado Livre */}
                  {/* {marketplace.nome === 'Mercado Livre' && (
                    <div style={{ marginTop: '4px', fontSize: '0.7em' }}>
                      <Form.Check
                        type="checkbox"
                        label="Frete Grátis"
                        checked={!!freteGratisExtra[marketplace._id]}
                        onChange={e => {
                          const checked = e.target.checked
                          setFreteGratisExtra(prev => ({
                            ...prev,
                            [marketplace._id]: checked
                          }))
                          if (lucro[marketplace._id] !== undefined) {
                            recalcMLCalculations(
                              marketplace._id,
                              checked,
                              produtoSelecionado,
                              marketplacesWithComissoes,
                              ultimoImposto,
                              venda,
                              setValorFreteMercadoLivre,
                              setLucro,
                              setMargem,
                              regrasPesoFreteMercadoLivre,
                              peso,
                              descontoAtualFreteMercadoLivre,
                              valorMinFreteGratisML,
                              setSelectedMarketplacesData,
                              marketplace
                            )
                          }
                        }}
                      />
                    </div>
                  )} */}

                  {/* {marketplace.nome === 'Amazon' && (
                    <div style={{ marginTop: '4px', fontSize: '0.7em' }}>
                      <Form.Check
                        type="checkbox"
                        label="Frete Grátis"
                        checked={!!freteGratisExtra[marketplace._id]}
                        onChange={e => {
                          const checked = e.target.checked
                          setFreteGratisExtra(prev => ({
                            ...prev,
                            [marketplace._id]: checked
                          }))
                          // Quando o checkbox muda, recalcula os valores da Amazon
                          if (lucro[marketplace._id] !== undefined) {
                            recalcAmazonCalculations(marketplace._id, checked)
                          }
                        }}
                      />
                    </div>
                  )} */}
                </td>

                <td>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '6vw' }}>
                    {mostrarBotaoManter && (
                      <Button
                        style={{
                          fontSize: '0.9rem',
                          padding: '2px 4px',
                          marginBottom: '-20px',
                          width: '76px',
                          marginLeft: '10px',
                          zIndex: 9999
                        }}
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          const syntheticEvent = { target: { value: margem[marketplace._id] } }
                          handleMargemChange(marketplace._id, syntheticEvent)
                        }}
                      >
                        manter
                      </Button>
                    )}

                    <CustomField
                      value={margem[marketplace._id] || ''}
                      onChange={e => {
                        if (
                          activeField &&
                          activeField.id === marketplace._id &&
                          activeField.field === 'margem'
                        ) {
                          handleMargemChange(marketplace._id, e)
                        }
                      }}
                      onFocus={() => {
                        setActiveField({ id: marketplace._id, field: 'margem' })
                      }}
                      onClear={() => setMargem(prev => ({ ...prev, [marketplace._id]: '' }))}
                      width="5,5vw"
                      editable={{
                        isEditable: campoLiberado,
                        alertMessage: 'Assine o Plano para ter acesso.'
                      }}
                    />
                  </div>
                </td>

                <td>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '7vw' }}>
                    {mostrarBotaoManter && (
                      <Button
                        style={{
                          fontSize: '0.9rem',
                          padding: '2px 4px',
                          marginBottom: '-20px',
                          width: '86px',
                          marginLeft: '10px',
                          zIndex: 9999
                        }}
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          const syntheticEvent = { target: { value: venda[marketplace._id] } }
                          handlePrecoVendaChange(marketplace._id, syntheticEvent)
                        }}
                      >
                        manter
                      </Button>
                    )}
                    <CustomField
                      value={venda[marketplace._id] || ''}
                      onChange={e => {
                        const rawValue = e.target.value.replace(',', '.')
                        setVenda(prev => ({ ...prev, [marketplace._id]: rawValue }))
                        handlePrecoVendaChange(marketplace._id, e)
                      }}
                      onFocus={() => {
                        setActiveField({ id: marketplace._id, field: 'venda' })
                      }}
                      onBlur={e => {
                        const valor = parseFloat(e.target.value.replace(',', '.')) || 0
                        setVenda(prev => ({ ...prev, [marketplace._id]: valor.toFixed(2) }))
                      }}
                      onClear={() => setVenda(prev => ({ ...prev, [marketplace._id]: '' }))}
                      width="7vw"
                      editable={{
                        isEditable: campoLiberado,
                        alertMessage: 'Assine o Plano para ter acesso.'
                      }}
                    />
                  </div>
                </td>

                <td>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '9vw' }}>
                    {mostrarBotaoManter && (
                      <Button
                        style={{
                          fontSize: '0.9rem',
                          padding: '2px 4px',
                          marginBottom: '-20px',
                          width: '100px',
                          marginLeft: '10px',
                          zIndex: 9999
                        }}
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          const syntheticEvent = { target: { value: lucro[marketplace._id] } }
                          handleLucroChange(marketplace._id, syntheticEvent)
                        }}
                      >
                        manter
                      </Button>
                    )}

                    <CustomField
                      value={lucro[marketplace._id] || ''}
                      onChange={e => {
                        if (
                          activeField &&
                          activeField.id === marketplace._id &&
                          activeField.field === 'lucro'
                        ) {
                          handleLucroChange(marketplace._id, e)
                        }
                      }}
                      onFocus={() => {
                        setActiveField({ id: marketplace._id, field: 'lucro' })
                      }}
                      onClear={() => setLucro(prev => ({ ...prev, [marketplace._id]: '' }))}
                      width="8vw"
                      editable={{
                        isEditable: campoLiberado,
                        alertMessage: 'Assine o Plano para ter acesso.'
                      }}
                    />
                  </div>
                </td>
                <td className="comissao-texto" style={{ color: marketplace.cor }}>
                  {marketplace.comissao}%
                </td>
                <td className="comissao-texto" style={{ color: marketplace.cor }}>
                  {marketplace?.fixo}
                </td>
                <td className="comissao-texto" style={{ color: marketplace.cor }}>
                  {marketplace.nome === 'Shopee' && freteGratisExtra[marketplace._id]
                    ? regraFreteShopee + '%'
                    : !isNaN(parseFloat(freteDisplay))
                    ? parseFloat(freteDisplay).toFixed(2)
                    : '0.00'}
                </td>

                <td className="comissao-texto" style={{ color: marketplace.cor }}>
                  {ultimoImposto || 0}%
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default ProfitMarginCalculator
