import { useState, useEffect } from 'react'
import {
  Table,
  Button,
  Form,
  Alert,
  Modal,
  OverlayTrigger,
  Tooltip,
  Row,
  Col
} from 'react-bootstrap'
import { config } from '../../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../../../../src/backend/model'
import Banner from '../../../../../../../src/components/pages/CadastroGeral/Banner'
// import { CustomField } from '../../../../../../../src/components/CustomField/custom-field.jsx'
import { FaInfoCircle } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

const GerenciarRegrasFreteAmericanasViaVarejo = () => {
  const { marketplace } = useParams() // Espera-se que a URL contenha o marketplace (ex.: "americanas" ou "viavarejo")
  const [regras, setRegras] = useState([])
  const [novaRegra, setNovaRegra] = useState({
    valor_min_produto: '',
    valor_max_produto: '',
    peso_min: '',
    peso_max: '',
    valor_base_frete: ''
  })
  const [mensagem, setMensagem] = useState('')
  const [tipoAlerta, setTipoAlerta] = useState('success')
  const [showAlert, setShowAlert] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [indexParaExcluir, setIndexParaExcluir] = useState(null)

  const url = `${config.host}/regrasFreteAmericanasViaVarejo`

  useEffect(() => {
    carregarRegras()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace])

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [showAlert])

  const carregarRegras = async () => {
    try {
      const dados = { requisicao: 'carregarRegrasFreteMarketplace' }
      if (marketplace) dados.marketplace = marketplace
      const resultado = await fetchData(url, dados)
      setRegras(resultado)
    } catch (error) {
      console.error('Erro ao carregar regras:', error)
      setMensagem('Erro ao carregar as regras.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const handleChange = (e, index = null) => {
    const { name, value } = e.target

    if (index !== null) {
      setRegras(prevRegras => {
        const novasRegras = [...prevRegras]
        novasRegras[index][name] = value
        return novasRegras
      })
    } else {
      setNovaRegra(prev => ({ ...prev, [name]: value }))
    }
  }

  const salvarRegra = async index => {
    const regra = regras[index]
    try {
      const dados = {
        requisicao: 'atualizarRegraFreteMarketplace',
        id: regra._id,
        ...regra
      }
      await fetchData(url, dados)
      setMensagem('Regra atualizada com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
      carregarRegras()
    } catch (error) {
      console.error('Erro ao salvar regra:', error)
      setMensagem('Erro ao salvar a regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const adicionarRegra = async () => {
    const { valor_min_produto, valor_max_produto, peso_min, peso_max, valor_base_frete } = novaRegra
    if (!valor_min_produto || !valor_max_produto || !peso_min || !peso_max || !valor_base_frete) {
      setMensagem('Todos os campos são obrigatórios.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }

    try {
      const dados = {
        requisicao: 'criarRegraFreteMarketplace',
        marketplace,
        ...novaRegra
      }
      await fetchData(url, dados)
      setMensagem('Nova regra adicionada com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
      setNovaRegra({
        valor_min_produto: '',
        valor_max_produto: '',
        peso_min: '',
        peso_max: '',
        valor_base_frete: ''
      })
      carregarRegras()
    } catch (error) {
      console.error('Erro ao adicionar regra:', error)
      setMensagem('Erro ao adicionar nova regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const confirmarExclusao = index => {
    if (index === null || index < 0 || index >= regras.length) {
      setMensagem('Erro ao excluir: índice inválido.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }
    setIndexParaExcluir(index)
    setShowConfirmModal(true)
  }

  const excluirRegra = async () => {
    if (indexParaExcluir === null || regras.length === 0) {
      setMensagem('Erro ao excluir regra: índice inválido.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }

    const regraId = regras[indexParaExcluir]?._id

    if (!regraId) {
      setMensagem('Erro ao excluir: ID da regra não encontrado.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }

    try {
      const dados = {
        requisicao: 'excluirRegraFreteMarketplace',
        id: regraId
      }
      await fetchData(url, dados)
      setMensagem('Regra excluída com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
      setRegras(prevRegras => prevRegras.filter((_, index) => index !== indexParaExcluir))
      setShowConfirmModal(false)
      setIndexParaExcluir(null)
    } catch (error) {
      console.error('Erro ao excluir regra:', error)
      setMensagem('Erro ao excluir a regra.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  return (
    <div className="container">
      <Banner titulo="Cadastro de Regras de Frete - Americanas e Via Varejo" />
      <h2 className="mt-4 d-flex align-items-center">
        Regras de Frete{' '}
        <span className="ms-2" style={{ color: '#007bff', fontWeight: 'bold' }}>
          ({marketplace ? marketplace.toUpperCase() : 'Marketplace'})
        </span>{' '}
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-sobre">
              <strong>Informações:</strong> <br />
              Aqui você pode gerenciar as regras de frete para o marketplace selecionado.
              <br />- Defina o intervalo de valor do produto.
              <br />- Informe as faixas de peso.
              <br />- Configure o valor base do frete.
            </Tooltip>
          }
        >
          <span className="ms-2">
            <FaInfoCircle size={20} style={{ cursor: 'pointer', color: '#007bff' }} />
          </span>
        </OverlayTrigger>
      </h2>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que deseja excluir esta regra?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={excluirRegra}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      {showAlert && (
        <Alert variant={tipoAlerta} onClose={() => setShowAlert(false)} dismissible>
          {mensagem}
        </Alert>
      )}

      <h4>Adicionar Nova Regra</h4>
      <Row className="mb-3 flex-row">
        <Col xs={2}>
          <Form.Control
            type="number"
            name="valor_min_produto"
            placeholder="Valor Mínimo do Produto (R$)"
            value={novaRegra.valor_min_produto}
            onChange={handleChange}
          />
        </Col>
        <Col xs={2}>
          <Form.Control
            type="number"
            name="valor_max_produto"
            placeholder="Valor Máximo do Produto (R$)"
            value={novaRegra.valor_max_produto}
            onChange={handleChange}
          />
        </Col>
        <Col xs={2}>
          <Form.Control
            type="number"
            name="peso_min"
            placeholder="Peso Mínimo (g)"
            value={novaRegra.peso_min}
            onChange={handleChange}
          />
        </Col>
        <Col xs={2}>
          <Form.Control
            type="number"
            name="peso_max"
            placeholder="Peso Máximo (g)"
            value={novaRegra.peso_max}
            onChange={handleChange}
          />
        </Col>
        <Col xs={2}>
          <Form.Control
            type="number"
            name="valor_base_frete"
            placeholder="Valor Base do Frete (R$)"
            value={novaRegra.valor_base_frete}
            onChange={handleChange}
          />
        </Col>
        <Col xs={2} className="d-flex align-items-center">
          <Button variant="primary" onClick={adicionarRegra}>
            Adicionar
          </Button>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Valor Mínimo do Produto (R$)</th>
            <th>Valor Máximo do Produto (R$)</th>
            <th>Peso Mínimo (g)</th>
            <th>Peso Máximo (g)</th>
            <th>Valor Base do Frete (R$)</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {regras.map((regra, index) => (
            <tr key={regra._id}>
              <td>
                <Form.Control
                  type="number"
                  name="valor_min_produto"
                  value={regra.valor_min_produto}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="valor_max_produto"
                  value={regra.valor_max_produto}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="peso_min"
                  value={regra.peso_min}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="peso_max"
                  value={regra.peso_max}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="valor_base_frete"
                  value={regra.valor_base_frete}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Button variant="success" onClick={() => salvarRegra(index)}>
                  Salvar
                </Button>{' '}
                <Button variant="danger" onClick={() => confirmarExclusao(index)}>
                  Excluir
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default GerenciarRegrasFreteAmericanasViaVarejo
