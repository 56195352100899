import { config } from '../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../../../src/backend/model'

export const carregarRegrasPesoFrete = async marketplace => {
  try {
    const url = `${config.host}/regraDescontoFrete`
    const dados = { requisicao: 'CarregarRegrasPesoFrete', marketplace }

    const response = await fetchData(url, dados)
    return response
  } catch (error) {
    console.error('Erro ao carregar regras:', error)
  }
}
