/**
 * SmartTable: Um Componente React Flexível e Interativo
 *
 * Descrição:
 * O componente SmartTable é uma solução robusta e versátil para a exibição de dados em tabelas dentro de aplicações React.
 * Ele permite a criação dinâmica de colunas e a exibição de dados com base em um esquema de campos e rótulos fornecidos.
 * Equipado com funcionalidades integradas de pesquisa e seleção de itens, o SmartTable oferece uma experiência interativa ao usuário,
 * permitindo a filtragem e manipulação eficiente dos dados apresentados.
 *
 * Funcionalidades:
 * - Criação dinâmica de colunas: As colunas da tabela são geradas automaticamente com base nos campos de dados fornecidos.
 * - Pesquisa integrada: Os usuários podem pesquisar e filtrar os dados exibidos na tabela, facilitando a localização de registros específicos.
 * - Seleção de itens: Inclui checkboxes para seleção de múltiplos itens, permitindo ações em lote ou análise detalhada de conjuntos de dados.
 * - Flexibilidade e reutilização: Projetado para ser reutilizável e adaptável a diferentes estruturas de dados, tornando-o ideal para uma variedade de cenários de uso em aplicações web.
 *
 * Exemplo de Uso:
 * <SmartTable data={seusDados} camposLabels={[{field: "nome", label: "Nome"}, {field: "descricao", label: "Descrição"}]} />
 *
 * Autor: [Claudio magno fernandes]
 * Data: [24/01/2023]
 */

import React, { useState, useEffect } from 'react'
import { Table, Form, Button } from 'react-bootstrap'
import { handleMouseOver, handleMouseOut } from '/src/components/CustomTable/custom-table'
import { remove } from 'diacritics'
import { CustomField } from '../CustomField/custom-field'
import Banner from '../pages/CadastroGeral/Banner'
import { formatarComoMoeda } from '/src/utils/form-utils'
function SmartTable({
  data,
  camposLabels,
  fixedHeight = '300px',
  fixedWidth = '100%',
  tituloProps = {},
  onSelectedItemsChange,
  radioBox = false,
  pesquisa = 'on',
  enableSelection = true,
  selectedItems: selectedItemsProp,
  Efeito3dMouseOverclass = 'Efeito3dMouseOver-class',
  showButtonDetalhes = false,
  onDetalhesClick,
  onDadosAlterados
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    if (selectedItemsProp) {
      setSelectedItems(selectedItemsProp)
    }
  }, [selectedItemsProp])

  const handleCheckboxChange = itemId => {
    if (radioBox) {
      setSelectedItems(itemId)
      if (onSelectedItemsChange) {
        onSelectedItemsChange(itemId)
      }
    } else {
      setSelectedItems(prevSelectedItems => {
        const newSelectedItems = Array.isArray(prevSelectedItems)
          ? prevSelectedItems.includes(itemId)
            ? prevSelectedItems.filter(id => id !== itemId)
            : [...prevSelectedItems, itemId]
          : [itemId]

        if (onSelectedItemsChange) {
          onSelectedItemsChange(newSelectedItems)
        }

        return newSelectedItems
      })
    }
  }
  const filteredData = data?.filter(item => {
    return camposLabels.some(({ field }) => {
      const value = item[field] ? item[field].toString().toLowerCase() : ''
      const cleanedValue = remove(value)
      return cleanedValue.includes(remove(searchTerm.toLowerCase()))
    })
  })

  useEffect(() => {
    if (onDadosAlterados) {
      onDadosAlterados(filteredData)
    }
  }, [filteredData])

  return (
    <div className="smart-table-container" style={{ maxHeight: fixedHeight, maxWidth: fixedWidth }}>
      <Banner {...tituloProps} />
      {pesquisa === 'on' && (
        <CustomField
          label="Pesquisar"
          placeholder="Digite para pesquisar..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onClear={() => setSearchTerm('')}
          width="100%"
          className={Efeito3dMouseOverclass}
          type="text"
        />
      )}

      <Table striped bordered hover className="smart-table">
        <thead>
          <tr>
            {camposLabels?.map(({ label }, index) => (
              <th key={index}>{label}</th>
            ))}
            {enableSelection && <th>{'Selecionar'}</th>}
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((item, index) => (
            <tr key={item._id || index}>
              {camposLabels.map(({ field, isFormatMoeda }, index) => (
                <td key={index}>{isFormatMoeda ? formatarComoMoeda(item[field]) : item[field]}</td>
              ))}
              {showButtonDetalhes && (
                <td>
                  <Button
                    variant="light"
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    style={{
                      background: 'white',
                      marginRight: '5px',
                      border: '1px solid gray',
                      borderRadius: '16px'
                    }}
                    onClick={() => {
                      onDetalhesClick({
                        item
                      })
                    }}
                  >
                    Detalhes
                  </Button>
                </td>
              )}
              {enableSelection && ( // Renderiza condicionalmente os controles de seleção
                <td>
                  <Form.Check
                    type={radioBox ? 'radio' : 'checkbox'}
                    name={radioBox ? 'smartTableRadioGroup' : `checkbox-${item._id}`}
                    checked={
                      radioBox
                        ? selectedItems === item._id
                        : Array.isArray(selectedItems) && selectedItems.includes(item._id)
                    }
                    onChange={() => handleCheckboxChange(item._id)}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default SmartTable
