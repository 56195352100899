import { useState, useEffect } from 'react'
import {
  Table,
  Button,
  Form,
  Alert,
  Modal,
  OverlayTrigger,
  Tooltip,
  Row,
  Col
} from 'react-bootstrap'
import { config } from '../../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../../../../src/backend/model'
import Banner from '../../../../../../../src/components/pages/CadastroGeral/Banner'
import { FaInfoCircle } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

const GerenciarNiveisVendedorAmericanasViaVarejo = () => {
  const { marketplace } = useParams()
  const [niveis, setNiveis] = useState([])
  const [novoNivel, setNovoNivel] = useState({
    nome_nivel: '',
    desconto: ''
  })
  const [mensagem, setMensagem] = useState('')
  const [tipoAlerta, setTipoAlerta] = useState('success')
  const [showAlert, setShowAlert] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [indexParaExcluir, setIndexParaExcluir] = useState(null)

  const url = `${config.host}/niveisVendedorAmericanasViaVarejo`

  const carregarNiveis = async () => {
    try {
      const dados = { requisicao: 'carregarNiveisVendedorMarketplace' }
      if (marketplace) dados.marketplace = marketplace
      const resultado = await fetchData(url, dados)
      setNiveis(resultado)
    } catch (error) {
      setMensagem('Erro ao carregar os níveis.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  useEffect(() => {
    carregarNiveis()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace])

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [showAlert])

  const handleChange = (e, index = null) => {
    const { name, value } = e.target
    if (index !== null) {
      setNiveis(prevNiveis => {
        const novosNiveis = [...prevNiveis]
        novosNiveis[index][name] = value
        return novosNiveis
      })
    } else {
      setNovoNivel(prev => ({ ...prev, [name]: value }))
    }
  }

  const adicionarNivel = async () => {
    if (!novoNivel.nome_nivel || novoNivel.desconto === '') {
      setMensagem('Todos os campos são obrigatórios.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }

    try {
      const dados = {
        requisicao: 'criarNivelVendedorMarketplace',
        marketplace,
        ...novoNivel
      }
      await fetchData(url, dados)
      setMensagem('Novo nível adicionado com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
      setNovoNivel({ nome_nivel: '', desconto: '' })
      carregarNiveis()
    } catch (error) {
      setMensagem('Erro ao adicionar novo nível.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const salvarNivel = async index => {
    const nivel = niveis[index]
    try {
      const dados = {
        requisicao: 'atualizarNivelVendedorMarketplace',
        id: nivel._id,
        ...nivel
      }
      await fetchData(url, dados)
      setMensagem('Nível atualizado com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
      carregarNiveis()
    } catch (error) {
      setMensagem('Erro ao salvar o nível.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  const confirmarExclusao = index => {
    if (index === null || index < 0 || index >= niveis.length) {
      setMensagem('Índice inválido para exclusão.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }
    setIndexParaExcluir(index)
    setShowConfirmModal(true)
  }

  const excluirNivel = async () => {
    if (indexParaExcluir === null || niveis.length === 0) {
      setMensagem('Índice inválido para exclusão.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }

    const nivelId = niveis[indexParaExcluir]?._id
    if (!nivelId) {
      setMensagem('ID do nível não encontrado.')
      setTipoAlerta('danger')
      setShowAlert(true)
      return
    }

    try {
      const dados = {
        requisicao: 'excluirNivelVendedorMarketplace',
        id: nivelId
      }
      await fetchData(url, dados)
      setMensagem('Nível excluído com sucesso!')
      setTipoAlerta('success')
      setShowAlert(true)
      setNiveis(prevNiveis => prevNiveis.filter((_, i) => i !== indexParaExcluir))
      setShowConfirmModal(false)
      setIndexParaExcluir(null)
    } catch (error) {
      setMensagem('Erro ao excluir o nível.')
      setTipoAlerta('danger')
      setShowAlert(true)
    }
  }

  return (
    <div className="container">
      <Banner titulo="Cadastro de Níveis de Vendedor - Americanas e Via Varejo" />
      <h2 className="mt-4 d-flex align-items-center">
        Níveis de Vendedor{' '}
        <span className="ms-2" style={{ color: '#007bff', fontWeight: 'bold' }}>
          ({marketplace ? marketplace.toUpperCase() : 'Marketplace'})
        </span>{' '}
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-sobre">
              <strong>Informações:</strong> <br />
              Aqui você pode gerenciar os níveis de vendedor para o marketplace selecionado.
              <br />- Defina o nome do nível.
              <br />- Informe o desconto correspondente.
            </Tooltip>
          }
        >
          <span className="ms-2">
            <FaInfoCircle size={20} style={{ cursor: 'pointer', color: '#007bff' }} />
          </span>
        </OverlayTrigger>
      </h2>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que deseja excluir este nível?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={excluirNivel}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      {showAlert && (
        <Alert variant={tipoAlerta} onClose={() => setShowAlert(false)} dismissible>
          {mensagem}
        </Alert>
      )}

      <h4>Adicionar Novo Nível</h4>
      <Row className="mb-3 flex-row">
        <Col xs={6}>
          <Form.Control
            type="text"
            name="nome_nivel"
            placeholder="Nome do Nível"
            value={novoNivel.nome_nivel}
            onChange={handleChange}
          />
        </Col>
        <Col xs={4}>
          <Form.Control
            type="number"
            name="desconto"
            placeholder="Desconto (%)"
            value={novoNivel.desconto}
            onChange={handleChange}
          />
        </Col>
        <Col xs={2} className="d-flex align-items-center">
          <Button variant="primary" style={{ width: '120px' }} onClick={adicionarNivel}>
            Adicionar
          </Button>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nome do Nível</th>
            <th>Desconto (0-1)</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {niveis.map((nivel, index) => (
            <tr key={nivel._id}>
              <td>
                <Form.Control
                  type="text"
                  name="nome_nivel"
                  value={nivel.nome_nivel}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  name="desconto"
                  value={nivel.desconto}
                  onChange={e => handleChange(e, index)}
                />
              </td>
              <td>
                <Button variant="success" onClick={() => salvarNivel(index)}>
                  Salvar
                </Button>{' '}
                <Button variant="danger" onClick={() => confirmarExclusao(index)}>
                  Excluir
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default GerenciarNiveisVendedorAmericanasViaVarejo
