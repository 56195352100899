import { config } from '../../../backend/configConexaoGeral'
import { fetchData } from '../../../backend/model'
import { useState, useEffect } from 'react'
export const modulosAssinados = async (setSubscribedModules, tenantId, RotaBase) => {
  const url = config.host + '/tenants' // Rota fornecida pelo usuário
  const dados = { tenantId, rota_base: RotaBase, requisicao: 'modulosAssinados' } // Enviando tenantId como parte dos dados

  try {
    const response = await fetchData(url, dados)

    // Supondo que a resposta contenha um array de nomes de módulos assinados
    const modules = response?.modules || {}
    // Filtrar apenas os módulos que têm `estaAssinado` igual a true
    const assinados = Object.values(modules)
      .filter(mod => mod.estaAssinado === true)
      .map(mod => mod.modulo)

    // Se `setSubscribedModules` foi passado, chama-o. Caso contrário, retorna os assinados
    if (typeof setSubscribedModules === 'function') {
      setSubscribedModules(assinados)
    } else {
      return assinados
    }
    // setSelectedModules(prev => [...new Set([...prev, ...assinados])]) // Garante que os módulos assinados estejam selecionados
  } catch (error) {
    console.error('Erro ao carregar módulos assinados:', error)
  }
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const useFetchModules = (tenantId, MODULOS_DISPONIVEIS) => {
  const [modulosDisponiveis, setModulosDisponiveis] = useState(MODULOS_DISPONIVEIS)

  useEffect(() => {
    const fetchModuleValues = async () => {
      const url = config.host + '/tenants'
      const dados = { tenantId, requisicao: 'ConsultarValoresModulos' }

      try {
        const response = await fetchData(url, dados)
        const modulosValores = response || {}

        // Atualiza o estado dos módulos disponíveis com os valores carregados do banco
        const modulosAtualizados = updateModulePrices(MODULOS_DISPONIVEIS, modulosValores)

        setModulosDisponiveis(modulosAtualizados)
      } catch (error) {
        console.error('Erro ao carregar os valores dos módulos:', error)
      }
    }

    fetchModuleValues()
  }, [tenantId, MODULOS_DISPONIVEIS])

  const updateModulePrices = (availableModules, moduleValues) => {
    //moduleValues representa os modulos quando tem precos personalizados por cliente
    //availableModules representa quando os modulo vao ter precos padrao para todos os cliente
    // no momento da data de hoje 10/12/2024 o sistema esta sendo usando com os modulos usando precos personalizados por cliente
    const usingPrecosPersonalizadoPorCliente = true // Altere para false quando desejar usar preços padrão
    return availableModules.map(modulo => {
      const moduloBanco = moduleValues.find(moduloValor => moduloValor.nome === modulo.nome)
      return {
        nome: modulo.nome,
        preco: usingPrecosPersonalizadoPorCliente
          ? moduloBanco
            ? moduloBanco.preco
            : modulo.preco // Usa preço personalizado se disponível
          : modulo.preco // Usa preço padrão
      }
    })
  }

  return modulosDisponiveis
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const getAvailableModules = async (tenantId, RotaBase) => {
  try {
    // Buscar os módulos assinados
    let assinados = await modulosAssinados(null, tenantId, RotaBase)

    // Garante que assinados seja um array vazio se for null ou undefined
    assinados = assinados || []

    // Buscar módulos em acesso gratuito
    const url = config.host + '/tenants'
    const dados = { tenantId, rota_base: RotaBase, requisicao: 'modulosComAcessoGratuito' }

    const response = await fetchData(url, dados)
    const modulosComAcessoGratuito = response?.modules || {}

    // Cria um Set para garantir que não haja duplicatas
    const availableModulesSet = new Set()

    // Adiciona todos os módulos assinados ao Set
    assinados.forEach(modulo => {
      availableModulesSet.add(modulo)
    })

    // Adiciona todos os módulos que estão no período gratuito ao Set
    for (const [moduloNome, details] of Object.entries(modulosComAcessoGratuito)) {
      if (details.periodoGratuito) {
        availableModulesSet.add(moduloNome)
      }
    }

    // Retorna um array com todos os módulos disponíveis para uso
    return Array.from(availableModulesSet)
  } catch (error) {
    console.error('Erro ao obter os módulos disponíveis:', error)
    return []
  }
}
