import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

function Banner() {
  return (
    <div>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={true}
        showArrows={true}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
      >
        {/* Slide 1 */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
            backgroundColor: '#FFFFFF',
            textAlign: 'center'
          }}
        >
          <h1 style={{ color: '#2e8b57', fontSize: '64px', marginBottom: '20px' }}>
            Precificação Inteligente
          </h1>
          <p style={{ fontSize: '22px', fontWeight: 'bold', color: '#5E35B1' }}>
            Maximize seus lucros com a precificação inteligente.
          </p>
        </div>

        {/* Slide 2 */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
            backgroundColor: '#FFFFFF',
            textAlign: 'center'
          }}
        >
          <h1 style={{ color: '#2e8b57', fontSize: '64px', marginBottom: '20px' }}>
            Análise de Mercado
          </h1>
          <p style={{ fontSize: '22px', fontWeight: 'bold', color: '#FF7043' }}>
            Esteja sempre à frente com análises de mercado precisas.
          </p>
        </div>

        {/* Slide 3 */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
            backgroundColor: '#FFFFFF',
            textAlign: 'center'
          }}
        >
          <h1 style={{ color: '#2e8b57', fontSize: '64px', marginBottom: '20px' }}>
            Estratégias Dinâmicas
          </h1>
          <p style={{ fontSize: '22px', fontWeight: 'bold', color: '#039BE5' }}>
            Adapte-se rapidamente com estratégias de precificação dinâmicas.
          </p>
        </div>

        {/* Slide 4 */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
            backgroundColor: '#FFFFFF',
            textAlign: 'center'
          }}
        >
          <h1 style={{ color: '#2e8b57', fontSize: '64px', marginBottom: '20px' }}>
            Otimização de Lucros
          </h1>
          <p style={{ fontSize: '22px', fontWeight: 'bold', color: '#7CB342' }}>
            Alcance o sucesso com estratégias de precificação que impulsionam lucros.
          </p>
        </div>

        {/* Slide 5 */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
            backgroundColor: '#FFFFFF',
            textAlign: 'center'
          }}
        >
          <h1 style={{ color: '#2e8b57', fontSize: '64px', marginBottom: '20px' }}>
            Gestão Eficiente
          </h1>
          <p style={{ fontSize: '22px', fontWeight: 'bold', color: '#F4511E' }}>
            Transforme dados em decisões de precificação eficazes.
          </p>
        </div>
      </Carousel>
    </div>
  )
}

export default Banner
